import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@envs/environment';
import { ScopeDynamicFieldSettingModel } from '@app/features/scoping/models/scope-dynamic-field-setting.model';

@Injectable({
  providedIn: 'root',
})
export class ScopeDynamicFieldSettingService {
  private readonly BASE_URL: string = environment.scopeApiUrl + 'scope/dynamic-field-setting';

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<ScopeDynamicFieldSettingModel[]> {
    return this.http.get<ScopeDynamicFieldSettingModel[]>(`${this.BASE_URL}`);
  }

  get(id: number): Observable<ScopeDynamicFieldSettingModel> {
    return this.http.get<ScopeDynamicFieldSettingModel>(`${this.BASE_URL}/${id}`);
  }

  create(request: ScopeDynamicFieldSettingModel): Observable<ScopeDynamicFieldSettingModel> {
    return this.http.post<ScopeDynamicFieldSettingModel>(`${this.BASE_URL}`, request);
  }

  update(id: number, request: ScopeDynamicFieldSettingModel): Observable<ScopeDynamicFieldSettingModel> {
    return this.http.put<ScopeDynamicFieldSettingModel>(`${this.BASE_URL}/${id}`, request);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.BASE_URL}/${id}`);
  }

  getExternalSystemResource(request: any): Observable<any[]> {
    return this.http.post<any[]>(`${this.BASE_URL}/external-system-resource`, request);
  }

  evictExternalSystemResource(request: any): Observable<void> {
    return this.http.post<void>(`${this.BASE_URL}/external-system-resource/cache-evict`,request);
  }
}
