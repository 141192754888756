import { Component, EventEmitter, Input, Output } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import {
  ScopeUiDropdownComponent
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component'

@Component({
  standalone: true,
  selector: 'scope-ui-paginator',
  templateUrl: './scope-ui-paginator.component.html',
  imports: [
    SharedModule,
    ScopeUiDropdownComponent,
  ],
  styleUrls: ['./scope-ui-paginator.component.scss'],
})
export class ScopeUiPaginatorComponent {

  @Input() currentPage: number = 0
  @Input() pageSize: number = 10
  @Input() pageSetSize: number = 10
  @Input() sizeOptions?: number[]
  @Input() set totalCount(value: number) {
    if (value !== undefined) {
      this.total = value
      this.setPageSets()
    }
  }
  @Output() changePage = new EventEmitter<any>()

  total: number
  pages: number[] = []
  visiblePages: { first: number, last: number }
  pageSets: { first: number, last: number }[] = []

  setPageSets() {
    this.pages = []
    this.pageSets = []
    for (let i = 1; i <= Math.ceil(this.total/this.pageSize); i++) {
      this.pages.push(i);
    }
    for (let i = 1; i <= this.pages.length; i += this.pageSetSize) {
      this.pageSets.push({ first: i, last: Math.min(i + this.pageSetSize - 1, this.pages.length) })
    }

    if (!this.pageSets.includes(this.visiblePages)) {
      this.visiblePages = this.pageSets[0]
    }
  }

  onChangePage(page) {
    if (page < 0 || page > this.pages.length-1) {
      return;
    }
    if (page + 1 > this.visiblePages.last) {
      this.visiblePages = this.pageSets[this.pageSets.indexOf(this.visiblePages)+1]
    } else if (page + 1 < this.visiblePages.first) {
      this.visiblePages = this.pageSets[this.pageSets.indexOf(this.visiblePages)-1]
    }
    this.changePage.emit({ pageIndex: page, pageSize: this.pageSize });
  }

  onChangeSize(size) {
    this.pageSize = size
    this.currentPage = 0
    this.visiblePages = null
    this.setPageSets()
    this.changePage.emit({ pageIndex: 0, pageSize: this.pageSize });
  }

  displayFn = (option: { first: number, last: number }) => {
    return `${ option.first }${ option.last != option.first ? ' to ' + option.last : '' }`
  }
}
