<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>Edit Item</h1>
    </div>

    <div class="mt-2 w-450">
      <span>Enter the name of the line item and select if the amount is to be added or discounted</span>
    </div>

    <button class="close-button" mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content aria-label="Generic modal">
  <div class="w-full">
    <form [formGroup]="modalForm">
      <div class="flex flex-row gap-8">
        <mat-button-toggle-group name="feeDiscount" [formControl]="typeControl" aria-label="Fee or Discount">
          <mat-button-toggle value="FEE">Fee</mat-button-toggle>
          <mat-button-toggle value="REBATE">Discount</mat-button-toggle>
        </mat-button-toggle-group>
        <scope-ui-input
          class="flex-1"
          [inputLabel]="'Line item name'"
          [initialValue]="updatedMsaLineItem.name"
          [required]="true"
          [maximumLength]='100'
          (onInputChange)="updatedMsaLineItem.name = $event" />
      </div>
      <div class="flex flex-row gap-4 mt-8">
        <scope-ui-input
          class="flex-1"
          [inputLabel]="'Amount'"
          [initialValue]="updatedMsaLineItem.value"
          [required]="true"
          [parseNumber]="true"
          [isCurrency]="amountTypeControl.value === 'AMOUNT'"
          [isPercentage]="amountTypeControl.value === 'PERCENTAGE'"
          [currency]="currencyCode"
          (onInputChange)="updatedMsaLineItem.value = $event" />
        <mat-button-toggle-group name="discountType" [formControl]="amountTypeControl" aria-label="Discount type">
          <mat-button-toggle value="PERCENTAGE">Percentage</mat-button-toggle>
          <mat-button-toggle value="AMOUNT">Quantity</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="flex flex-row mt-8">
        <scope-ui-input
          class="flex-1"
          [inputLabel]="'Reason'"
          [maximumLength]="250"
          [isTextarea]="true"
          [required]='true'
          [initialValue]="updatedMsaLineItem.reason"
          (onInputChange)="updatedMsaLineItem.reason = $event"/>
      </div>
    </form>
  </div>
  <div class="mt-8 flex justify-end">
    <button mat-flat-button class="ml-8" (click)="confirm()" [disabled]="!isFormValid()">
      <span class='tracking-normal'>Update</span>
    </button>
  </div>
</mat-dialog-content>


