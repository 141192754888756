<ng-container *ngIf="isParentBreadcrumb && breadcrumbs?.length">
  <div class="breadcrumb-container">
    <ol>
      <li *ngFor="let breadcrumb of breadcrumbs">
        <span [routerLink]="breadcrumb.url">
          {{ breadcrumb.label }}
          <i class="material-icons material-symbols-rounded">chevron_right</i>
        </span>
      </li>
    </ol>
  </div>
</ng-container>

<ng-container *ngIf="isScopeBreadcrumb">
  <div class="breadcrumb-scope-container">
    <ol>
      <li *ngFor="let breadcrumb of breadcrumbs">
        <span
          routerLinkActive="router-link-active"
          [routerLink]="breadcrumb.url"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ breadcrumb.label }}
          <i class="material-icons material-symbols-rounded">arrow_forward</i>
        </span>
      </li>
    </ol>
  </div>
</ng-container>
