<div class="output-info">
  <div class="text-container">
    Edit your documents to match your needs. Preview your documents with ease, ensuring they look just right before
    finalising.

    <span *ngIf="showMoreInfo">
      <br>Download your creations in various formats such as CSV, Word document, PDF, or Excel document.
      <br>You can even bring your own templates to the table, making every project a true reflection of your individuality.
    </span>
    <span class="show-more-output orange pointer" (click)="showMoreInfo = !showMoreInfo">{{!showMoreInfo ? 'Read more' :
      'Read less'}}</span>
  </div>
</div>
<div class='scope-page pt-2 h-full'>
  <scope-ui-tabs [fontSize]="'16px'">
    <scope-ui-tab tabTitle="Document">
      <app-output-editor *ngIf='outputTemplate' (addBlock)="onAddBlock($event)" [outputTemplate]='outputTemplate'
                          [currentScope]='currentScope' [currentUser]='loggedInUser'
                          (onUpdateTemplate)="updateTemplate($event)" (onPreviewExport)="previewExport()"
                          (onDownload)="updateTemplate($event, true)" [(updateInProgress)]="updateInProgress"
                          (onUpdateTemplateImmediate)="sendUpdateRequest($event)">
      </app-output-editor>
    </scope-ui-tab>
    <scope-ui-tab tabTitle="Spreadsheet" class="flex-1">
      <div class="output-container" *ngIf="xlsxTemplate != null">
        <div class="members-container__header clients output">
          <div class="left output-left">
            <div class="def-output">
              <span class="title orange">Template: </span>{{xlsxTemplate.name}}
            </div>
          </div>
          <div class="right">
            <a (click)="previewXlsxExport()" class="browse-btn">Preview</a>
          </div>
        </div>
      </div>
    </scope-ui-tab>
  </scope-ui-tabs>
</div>
