import { createAction, props } from '@ngrx/store';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { Tab } from '@shared/utils/tabs-util.service';
import { ScopeUiTabComponent } from '@shared/components/ui-components/scope-ui-tab/scope-ui-tab.component';
import { Page } from '@app/features/scoping/models/page.model';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { Sort } from '@angular/material/sort';

export enum CompanyManagementActionTypes {
  GET_OUTPUT_TEMPLATES = '[COMPANY MANAGEMENT CONFIG] Get Output Templates',
  GET_OUTPUT_TEMPLATES_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Output Templates Success',
  GET_OUTPUT_TEMPLATES_FAILURE = '[COMPANY MANAGEMENT CONFIG] Get Output Templates Failure',

  SET_GROUP_ID = '[COMPANY MANAGEMENT CONFIG] Set Group Id',
  TAB_CHANGE_START = '[COMPANY MANAGEMENT CONFIG] Tab Change Start',

  GET_TASKS = '[COMPANY MANAGEMENT CONFIG] Get Tasks',
  GET_TASKS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Tasks Success',
  GET_TASKS_FAIL = '[COMPANY MANAGEMENT CONFIG] Get Tasks Fail',
}

const getOutputTemplates = createAction(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES);
const getOutputTemplatesSuccess = createAction(
  CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES_SUCCESS,
  props<{ outputTemplates: OutputTemplate[] }>()
);
const getOutputTemplatesFail = createAction(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES_FAILURE, props<{ error: Error }>());

const setGroupId = createAction(CompanyManagementActionTypes.SET_GROUP_ID, props<{ groupId: number }>());

const tabChangeStart = createAction(CompanyManagementActionTypes.TAB_CHANGE_START, props<{ additionalCtx: any, tabs: Tab | ScopeUiTabComponent }>());

const getTasks = createAction(CompanyManagementActionTypes.GET_TASKS, props<{ page: number, searchText?: string, sort: Sort }>());
const getTasksSuccess = createAction(CompanyManagementActionTypes.GET_TASKS_SUCCESS, props<{ tasks: Page<Deliverable> }>());
const getTasksFail = createAction(CompanyManagementActionTypes.GET_TASKS_FAIL, props<{ error: Error }>());

export const CompanyManagementActions = {
  getOutputTemplates,
  getOutputTemplatesSuccess,
  getOutputTemplatesFail,
  setGroupId,
  tabChangeStart,
  getTasks,
  getTasksSuccess,
  getTasksFail,
}
