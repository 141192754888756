import { UserRoleLevel } from '@core/model/enums/user-role-level.enum';
import { Type } from 'class-transformer';
import { Company } from '@core/model/company.model';
import { PrivilegeRule } from '@core/model/privilege-rule.model';

export class PrivilegeGroup {
  id: number;
  name: string;
  roleLevel: UserRoleLevel;
  isUserGroup: boolean;
  isCompanyGroup: boolean;
  isDeleted: boolean;
  isSystem: boolean;
  @Type(() => Company) companyRel: Company
  @Type(() => PrivilegeRule) privilegeRules: PrivilegeRule[]
}
