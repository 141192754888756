<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>Add {{'output' | langf}} borders</h1>
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Generic modal">
    <div class="modal__body">
      <div class="inputs-block">
        <scope-ui-dropdown
          [selectedValue]="selectedStyle"
          [controlLabel]="'Border style*'"
          [dropdownOptions]="borderStyles"
          [isStandardDropdown]="true"
          (onSelectionChange)="selectedStyle = $event; outputBorders.borderStyle = $event.value" />

        <scope-ui-dropdown
          [selectedValue]="selectedWidth"
          [controlLabel]="'Border width*'"
          [dropdownOptions]="borderWidths"
          [isStandardDropdown]="true"
          (onSelectionChange)="selectedWidth = $event; outputBorders.borderWidth = $event.value" />
      </div>

      <div class="inputs-block mt-4">
        <div>
          <scope-ui-input
            [initialValue]="outputBorders.fromTop?.toString()"
            [hasPlaceholder]="true"
            [inputLabel]="'Top margin'"
            [type]="'number'"
            [minimum]="0"
            [maximum]="31"
            (onInputBlurChange)="outputBorders.fromTop = $event"
            (onInputChange)="outputBorders.fromTop = $event"
          />
        </div>
        <div>
          <scope-ui-input
            [initialValue]="outputBorders.fromBottom?.toString()"
            [hasPlaceholder]="true"
            [inputLabel]="'Bottom margin'"
            [type]="'number'"
            [minimum]="0"
            [maximum]="31"
            (onInputChange)="outputBorders.fromBottom = $event"
            (onInputBlurChange)="outputBorders.fromBottom = $event"
          />
        </div>
      </div>

      <div class="inputs-block mt-4">
        <div>
          <scope-ui-input
            [initialValue]="outputBorders.fromLeft?.toString()"
            [hasPlaceholder]="true"
            [inputLabel]="'Left margin'"
            [type]="'number'"
            [minimum]="0"
            [maximum]="31"
            (onInputChange)="outputBorders.fromLeft = $event"
            (onInputBlurChange)="outputBorders.fromLeft = $event"
          />
        </div>
        <div>
          <scope-ui-input
            [initialValue]="outputBorders.fromRight?.toString()"
            [hasPlaceholder]="true"
            [inputLabel]="'Right margin'"
            [type]="'number'"
            [minimum]="0"
            [maximum]="31"
            (onInputChange)="outputBorders.fromRight = $event"
            (onInputBlurChange)="outputBorders.fromRight = $event"
          />
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="scope-actions">
    <button (click)="applyBorderSettings()" [disabled]="!isFormValid()" mat-button class="scope-ui-modal-flat-button">
      <span class="font-newJuneBold">Add Template borders</span>
    </button>
  </mat-dialog-actions>
</div>
