import { PrivilegeDescriptor } from "./definitions/privilege-descriptor.interface";
import { Privilege } from "./enums/privilege.enum";


export class PrivilegeDefinition {

  constructor(private privilege: Privilege, private des: PrivilegeDescriptor) { }

  getPrivilege(): Privilege {
    return this.privilege;
  }

  getName() {
    return this.des.name;
  }

  getDescription() {
    return this.des.description;
  }

  isRestrictable() {
    return this.des.restrictions != null && Object.keys(this.des.restrictions).length !== 0;
  }

  get restrictions() {
    return this.des.restrictions
  }
}
