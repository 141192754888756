import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { FormControl, UntypedFormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { ScopeVersion } from '@app/core/model/scope-version'
import { Preference } from '@app/core/model/user-preferences.interface'
import { User } from '@app/core/model/user.model'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component'
import { PipesModule } from '@app/shared/pipe/index.module'
import { SharedModule } from '@app/shared/shared.module'
import { trackById } from '@shared/utils/utils'
import { ApprovalChecks } from '../../model/approval-checks.interface'
import {
  DeliverableReviewTableData
} from '../../model/deliverable-review-table.model'
import { DialogEventsService } from '../../service/dialog-events.service'

export interface ReviewSubmitModalConfig {
  currentScope: ScopeVersion
  deliverables: Deliverable[]
  deliverableColumns: ReviewSubmitModalDeliverableColumns
  deliverableTableData: DeliverableReviewTableData
  stateChecks: ApprovalChecks
  currentUser: User
  limitModalWidth?: boolean
  title: string
  body?: string
  text: string
  customContainerClass?: string
}

export type ReviewSubmitModalDeliverableColumns = string[] | Preference[]

@Component({
  selector: 'review-submit-modal',
  standalone: true,
  imports: [CommonModule, PipesModule, ScopeUiTableComponent, MatDialogModule, SharedModule],
  templateUrl: './review-submit-modal.component.html',
  styleUrls: ['./review-submit-modal.component.scss'],
})
export class ReviewSubmitModalComponent {
  modalForm: UntypedFormGroup

  comment: string = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReviewSubmitModalConfig,
    private dialogEventsService: DialogEventsService
  ) {
    // this.getReviewStatusClass(this.data.deliverables[0], this.data.deliverableTableData.rowData.cells[1])
    this.modalForm = new UntypedFormGroup({ comment: new FormControl('') })
  }

  openCompleteReviewModal(text: string, hasOverride: boolean) {
    this.dialogEventsService.emitEvent({ key: 'openCompleteReviewModal', optionalData: { text, hasOverride } })
  }

  openAcceptAllDeliverablesDialog() {
    this.dialogEventsService.emitEvent({ key: 'openAcceptDeliverableDialog' })
  }

  openRejectDeliverableDialog() {
    this.dialogEventsService.emitEvent({ key: 'openRejectDeliverableDialog' })
  }

  protected readonly trackById = trackById;
}
