<div>
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ isNewCost ? 'Create ' + ('scope' | lang) + ' Third Party Cost' : 'Add Saved Third Party Cost' }}</h1>
      </div>
      <div *ngIf="!isNewCost" class="modal-text mt-2">
        <span>Find Third Party Cost and add it to the {{'scope' | lang }}</span>
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Generic modal">
    <loader-modal *ngIf="loading" [overlay]="true"></loader-modal>
    <ng-container *ngIf="isNewCost && !loading">
      <scope-ui-input class="resize-none roles-search"
                      [hasPlaceholder]="true"
                      [inputLabel]="'Name*'"
                      [inputClass]="'icon-field'"
                      (onInputChange)="onNameChange($event)" />
        <div class="desc-menu">
          <scope-ui-input
            class="desc-input mt-1"
            [inputLabel]="'Description*'"
            [initialValue]="newThirdPartyCost.description"
            [isTextarea]="true"
            (onInputChange)="newThirdPartyCost.description = $event"/>
        </div>
        <scope-ui-dropdown
          class="roles-search"
          [controlLabel]="'Formula'"
          [displayFn]="displayName"
          [dropdownOptions]="formulas"
          [isStandardDropdown]="true"
          (onSelectionChange)="onSelectFormula($event)" />
        <div class="flex gap-9 price-menu mt-6">
          <scope-ui-input class="price-input"
                          [hasPlaceholder]="true"
                          [type]="'number'"
                          [control]="costControl"
                          [inputLabel]="'Unit cost*'"
                          [minimum]="0"
                          [preventPaste]="true"
                          [inputClass]="'mx-10 icon-field'"
                          (onInputChange)="onCostChange($event)" />
          <scope-ui-input class="price-input"
                          [hasPlaceholder]="true"
                          [type]="'number'"
                          [control]="markupControl"
                          [inputLabel]="'Mark up (%)*'"
                          [minimum]="0"
                          [preventPaste]="true"
                          [inputClass]="'mx-10 icon-field'"
                          (onInputChange)="onMarkUpChange()" />
          <scope-ui-input class="price-input"
                          [hasPlaceholder]="true"
                          [type]="'number'"
                          [control]="priceControl"
                          [inputLabel]="'Selling price*'"
                          [minimum]="0"
                          [preventPaste]="true"
                          [inputClass]="'mx-10 icon-field'"
                          (onInputChange)="onPriceChange()" />
        </div>
    </ng-container>
    <ng-container *ngIf="!isNewCost && !loading">
      <scope-ui-autocomplete
        class="roles-search"
        [label]="'Find Third Party Cost'"
        [iconName]="'search'"
        [hasIcon]="true"
        [options]="thirdPartyCosts"
        (onSelectionChange)="onSelectCost($event)" />
    </ng-container>
    <div class="mt-8 flex justify-end" *ngIf="!loading">
      <button mat-button mat-dialog-close>
        <span class='tracking-normal'>Cancel</span>
      </button>
      <button mat-flat-button class="ml-8" (click)="submit()" [disabled]="disableSubmit()">
        <span class='tracking-normal'>{{isNewCost ? 'Create' : 'Add'}}</span>
      </button>
    </div>
    <div class="custom-del-button flex justify-center">
      <button mat-button (click)="switch()">
      <span class='font-newJuneBold flex items-center'>
        <mat-icon class="material-symbols-rounded orange mb-1">add</mat-icon>
        {{isNewCost ? 'Add a saved Third Party Cost' : 'Create Third Party Cost'}}
      </span>
      </button>
    </div>
  </mat-dialog-content>
</div>
