<div class="flex d-flex-justify-between pt-6 px-10">
  <h3 class="product-heading-bold">{{'scope_type.p' | lang}}</h3>
  <button [has-privilege]="'SCOPE_TYPE__CREATE'" (click)="showAddScopeTypeDialog()"
          mat-flat-button>
    <mat-icon class="material-symbols-rounded">add</mat-icon> Add {{ 'scope_type' | lang }}
  </button>
</div>
<div class="card-wrapper p-10" [hidden]="!loadingScopeTypes">
  <div class="card-skeleton" *ngFor=" let x of [].constructor(16)"></div>
</div>
<div class="card-wrapper p-10">
  <scope-ui-card-master
    *ngFor="let scopeType of scopeTypes | orderBy: 'name': 'asc': false; trackBy: trackById"
    (click)="showEditScopeTypeDialog(scopeType)"
    [actionButtons]="[{ icon: 'delete', name: 'Delete', eventId: 'Delete' }]"
    [hasSubtitle]="isParentCard(scopeType, loggedInUser.company)"
    [subTitle]="'Inherited from parent'"
    [hasButtons]="loggedInUser.hasPrivilege('SCOPE_TYPE__DELETE') && !isParentCard(scopeType, loggedInUser.company)"
    [cardTitle]="scopeType.name"
    (buttonClicked)="showDeleteScopeTypeModal(scopeType)" />
</div>
