import { Type } from 'class-transformer';
import { ThirdPartyCostType } from '@app/features/scoping/models/third-party-cost-type.model';

export class ThirdPartyCostGroup {
  name!: string
  id!: number
  parentId!: number
  overridden: boolean
  active: boolean
  groupType: string
  description: string
  nonTranslatedName: string
  nonTranslatedDescription: string
  @Type(() => ThirdPartyCostType) typeOptions: ThirdPartyCostType[]
}
