<mat-select (valueChange)="onChangeCurrentCompany($event)" [(value)]="parentChildService.selectedCompanyName" class="child-switcher company-list"
            [ngClass]="{'active': parentChildService.authorisedAsChildCompany, 'showChildSwitcher': showChildSwitcher}"
            (openedChange)="this.showChildSwitcher = $event"
            *ngIf="parentChildService.childCompanies.length > 0" [hidden]="removeAccessToSwitcher()">
  <mat-option [value]="parentChildService.originalCompany?.name">
    {{parentChildService.originalCompany?.name}}
  </mat-option>
  <mat-option [value]="child.name" [ngClass]="{'orange': parentChildService.authorisedAsChildCompany }"
              *ngFor="let child of parentChildService.childCompanies trackBy: trackById">
    {{child.name}}
  </mat-option>
</mat-select>

<div class="overlay" *ngIf="loading">
  <div class="overlay-content">
    <span class="text-sm">Switching to</span>
    <p class="text-2xl">{{parentChildService.selectedCompanyName}}</p>
    <div class="loading">
      <div class="loading__dot"></div>
      <div class="loading__dot"></div>
      <div class="loading__dot"></div>
    </div>
  </div>
</div>
