import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoaderModalModule } from './components/loader-modal/loader-modal.module';
import { DirectivesModule } from './directives/index.module';
import { PipesModule } from './pipe/index.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { UnauthorisedComponent } from './components/unauthorised/unauthorised.component';
import { MessageDisplayComponent } from './components/message-display/message-display.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/plugins/material/material.module';
import { ErrorComponent } from './components/error/error.component';
import { ScopeUiGoBackBreadcrumbComponent } from './components/scope-ui-go-back-breadcrumb/scope-ui-go-back-breadcrumb.component';
import { DescriptionEditorComponent } from "@shared/components/description-editor/description-editor.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NotificationSidebarComponent } from '@shared/components/notification-sidebar/notification-sidebar.component';
import { CommentableComponent } from '@shared/components/commentable/commentable.component'
import { TableLoaderComponent } from './components/ui-components/table-loader/table-loader.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    MaintenanceComponent,
    UnauthorisedComponent,
    MessageDisplayComponent,
    ErrorComponent,
    DescriptionEditorComponent,
    CommentableComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DirectivesModule,
    LoaderModalModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MaterialModule,
    FormsModule,
    PipesModule,
    ScopeUiGoBackBreadcrumbComponent,
    CKEditorModule,
    NotificationSidebarComponent,
    TableLoaderComponent
  ],
  exports: [
    LoaderModalModule,
    PipesModule,
    DirectivesModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    DatePipe,
    CurrencyPipe,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MessageDisplayComponent,
    DescriptionEditorComponent,
    CommentableComponent,
    TableLoaderComponent
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
