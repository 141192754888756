import { Type } from 'class-transformer';
import { SecondParty } from '@core/model/second-party.model';
import { Brand } from '@app/features/scope-overview/model/brand.model';

export class PrivilegeRuleRestriction {
  id: number;
  @Type(() => SecondParty) secondParty?: SecondParty;
  @Type(() => SecondParty) brand?: Brand
  rangeValueType?: string
}
