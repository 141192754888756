import { Component, Input } from '@angular/core';
import { HeaderStyleService, HIDE_HEADER } from '@shared/services/header-style.service';
import { EnvService } from '@envs/env-service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-invitation-setup',
  templateUrl: './invitation-setup.component.html',
  styleUrls: ['./invitation-setup.component.scss']
})
export class InvitationSetupComponent {
  @Input() id!: number;
  baseJsUrl: string

  constructor(private headerStyleService: HeaderStyleService, private envService: EnvService, private router: Router, private titleService: Title) {
    this.headerStyleService.setHeaderClass(HIDE_HEADER)
    this.titleService.setTitle('Sign Up')
  }

  ngOnInit() {
    this.baseJsUrl = `${this.envService.baseJsUrl}/invitation/${this.id}`
  }
}
