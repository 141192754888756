import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { OutputBorders, OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { OutputField } from '@app/features/scope-overview/model/output-field.model';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipe/index.module';
import { FormControl, FormsModule } from '@angular/forms';
import { CKEditorModule } from 'ckeditor4-angular';
declare const CKEDITOR: any;
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  OutputResourceObjects,
  OutputVariables,
  getDefinitionNameWidgets,
  getDefinitionNameVariables,
  DEFINITIONS,
} from '@shared/utils/output-variables';
import { ScopeVersion } from '@app/core/model/scope-version';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ChangeScopeOutputModalComponent, ChangeScopeOutputModalConfig, ParentChildOutputWhitelistInfo,
} from '@app/features/scope-overview/components/change-scope-output-modal/change-scope-output-modal.component';
import { Preference } from '@core/model/user-preferences.interface';
import { User } from '@core/model/user.model';
import { DialogEventsService } from '@app/features/scope-overview/service/dialog-events.service';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { MatIconModule } from '@angular/material/icon';
import * as $ from 'jquery';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  SetBordersModalComponent, SetBordersModalConfig,
} from '@app/features/scope-overview/components/set-borders-modal/set-borders-modal.component';
import {
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import { DirectivesModule } from '@shared/directives/index.module'
import { ckeConfig } from '@shared/utils/ckeConfig';
import { cloneDeep } from 'lodash';
import { trackById } from '@shared/utils/utils';
import { SharedModule } from '@shared/shared.module';
import { LanguageService } from '@core/service/language.service';
import { SortBySupportsPipe } from '@shared/pipe/sort-by-supports.pipe';

@Component({
  selector: 'app-output-editor',
  standalone: true,
  templateUrl: './output-editor.component.html',
  imports: [
    CommonModule, PipesModule, FormsModule, CKEditorModule, MatTooltipModule, ScopeUiInputComponent, MatIconModule, CdkDropList, CdkDrag, DirectivesModule, SharedModule, SortBySupportsPipe,
  ],
  styleUrls: ['./output-editor.component.scss'],
})
export class OutputEditorComponent implements OnDestroy {
  @Input() outputTemplate!: OutputTemplate
  @Input() currentScope: ScopeVersion
  @Input() currentUser: User
  @Input() subject!: BehaviorSubject<OutputTemplate>
  @Input() templateWhitelist: ParentChildOutputWhitelistInfo
  @Input() updateInProgress: boolean
  @Output() updateInProgressChange = new EventEmitter<boolean>()
  @Output() onPreviewExport: EventEmitter<void>
  @Output() onDownload: EventEmitter<OutputTemplate>
  @Output() onDuplicate: EventEmitter<string>
  @Output() onUploadBaseDocx: EventEmitter<void>
  @Output() onUpdateTemplate: EventEmitter<OutputTemplate>
  @Output() onUpdateTemplateImmediate: EventEmitter<OutputTemplate>
  @Output() addBlock = new EventEmitter();
  @Output() onDeleteField = new EventEmitter<OutputField>();

  @ViewChild('editor') editor: any

  editName!: boolean
  activeSection: OutputField = null
  previewLoading: boolean = false
  holdSelection: boolean
  totalCustomVars: number = 0
  customVars: number = 0
  lineWidth: number = 0
  sortedBlocks: OutputField[] = []
  fields: OutputField[]
  editorClass: string
  widgets: any[]
  variables: any[]
  scopeVariables: any[]
  scopeVariablesVisible: boolean
  scopeTablesVisible: boolean
  showWidgetsMenu: boolean
  scopeTables: any[]
  searchName: string

  private subscription: Subscription
  private changeMasterTemplateDialogConfig: MatDialogRef<ChangeScopeOutputModalComponent, any>
  private autocompleteConfigured = false

  constructor(private cdr: ChangeDetectorRef,
              private snackbarService: SnackbarService,
              private dialog: MatDialog,
              private dialogEventsService: DialogEventsService,
              private lang: LanguageService) {
    this.onPreviewExport = new EventEmitter<void>()
    this.onDownload = new EventEmitter<OutputTemplate>()
    this.onDuplicate = new EventEmitter<string>()
    this.onUploadBaseDocx = new EventEmitter<void>()
    this.onUpdateTemplate = new EventEmitter<OutputTemplate>()
    this.onUpdateTemplateImmediate = new EventEmitter<OutputTemplate>()
  }

  ngOnInit() {
    this.setSortedBlocks()
    this.onMasterUpdate()
    this.setupSideMenu()
  }

  setupSideMenu() {
    this.widgets = Object.keys(OutputResourceObjects).map(key => ({
      key,
      name: OutputResourceObjects[key].name,
      description: OutputResourceObjects[key].description,
      supports: OutputResourceObjects[key].supports,
      visible: false,
    })).filter(widget => widget.supports !== undefined);

    this.variables = Object.keys(OutputVariables).map(key => ({
      key,
      name: OutputVariables[key].name,
      description: OutputVariables[key].description,
      supportsEntity: OutputVariables[key].supportsEntity,
    })).filter(widget => widget.supportsEntity !== undefined);

    this.scopeVariables = this.variables.filter(value => value.supportsEntity.includes('SCOPE'))
    this.widgets.forEach(widget => {
      widget.variables = this.variables.filter(value => value.supportsEntity.includes(DEFINITIONS[widget.key]))
    })
    this.scopeTables = this.widgets.filter(value => value.variables.length === 0)
    this.widgets = this.widgets.filter(item => this.scopeTables.indexOf(item) === -1);
  }

  removeWidgetName = (variable: any, widgetName: string): string => variable.replace(new RegExp(`\\b${widgetName.split(' ')[0]}\\b`, 'gi'), '').replace(/\s{2,}/g, ' ').trim();
  formatSupports = (supports: any[]): string => supports.sort((a, b) => a.localeCompare(b)).map(item => item.toLowerCase().split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(' content, ') + ' content';
  formatDescription = (widget: any): string => `You can place this widget in: ${this.formatSupports(widget.supports)}`;

  onSearch(name: string) {
    this.searchName = name;
    this.widgets.forEach(widget => widget.visible = !!this.searchName)
    this.scopeVariablesVisible = !!this.searchName;
    this.scopeTablesVisible = !!this.searchName;
  }

  variableVisible(variable: any) {
    if (!this.searchName) return true;
    return variable?.name.toLowerCase().includes(this.searchName.toLowerCase());
  }

  widgetVisible(widget: any) {
    if (!this.searchName) return true;
    return widget.variables.some(variable => variable?.name.toLowerCase().includes(this.searchName.toLowerCase()));
  }

  tableVisible() {
    if (!this.searchName) return true;
    return this.scopeTables.some(variable => variable?.name.toLowerCase().includes(this.searchName.toLowerCase()));
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.outputTemplate.outputFields, event.previousIndex, event.currentIndex);
    if (event.previousIndex !== event.currentIndex) {
      this.updateBlocksOrder(this.outputTemplate.outputFields);
    }
  }

  setSortedBlocks() {
    this.sortedBlocks = this.outputTemplate.outputFields
      .sort((a, b) => {
        return a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      })
  }

  updateBlocksOrder(outputFields: OutputField[]) {
    outputFields.forEach(field => {
      this.sortedBlocks.forEach(f => {
        if (f.id == field.id) {
          field.order = this.sortedBlocks.indexOf(f)
        }
      });
    })
    this.setSortedBlocks();
    this.updateTemplate();
  }

  updateSectionContent = () => {
    if (this.editor && this.activeSection) {
      this.activeSection.content = this.editor.instance.getData();
      this.updateTemplate()
    }
  }

  onChange = this.updateSectionContent

  updateTemplate() {
    this.cdr.markForCheck()
    this.onUpdateTemplate.emit(this.outputTemplate);
    this.updateInProgressChange.emit(true)
  }

  submitName() {
    this.editName = false
    this.cdr.markForCheck()
    this.onUpdateTemplateImmediate.emit(this.outputTemplate);
    this.updateInProgressChange.emit(true)
  }

  EDITOR_TABS = [
    {
      name: "Preview data",
      active: false,
      icon: "preview",
      visible: () => !!this.currentScope,
      onClick: (tab: any) => this.openPreviewTab(tab)
    },
    {
      name: "Editor",
      icon: "edit",
      active: true,
      visible: () => true,
      onClick: (tab: any) => this.openEditorTab(tab)
    }, {
      name: "Code",
      active: false,
      icon: "code",
      visible: () => true,
      onClick: (tab: any) => this.openCodeTab(tab)
    }
  ]

  openCodeTab(tab: any) {
    if (!this.editor) {
      return
    }
    if (this.EDITOR_TABS.find(tab => tab.name === "Preview data").active) {
      this.editor.instance.execCommand('togglePreview');
    }
    if (!tab.active) {
      this.editor.instance.execCommand('source');
    }
    this.EDITOR_TABS.forEach(t => t.active = false);
    this.editorClass = 'code';
    $('.cke_top').css('display', 'none');
    $('#active-section').css('border-top', 'none');
    this.previewLoading = false;
    tab.active = true;
  }

  blockHidden(activeSection: OutputField, outputTemplate: OutputTemplate) {
    if (activeSection) {
      return !((!activeSection.children || activeSection.children.length == 0) && (activeSection.fieldType?.indexOf('FOOTER') == -1 || outputTemplate.htmlTemplate))
    } else {
      return true
    }
  }

  dataReady() {
    if (!this.autocompleteConfigured) {
      if (this.outputTemplate.templateType !== 'SCOPE') {
        this.configureScopeVariables(OutputVariables.sowContentBlockValues())
        this.configureScopeResources(this.editor.instance, OutputResourceObjects.sowValues())
      } else {
        this.configureScopeVariables(OutputVariables.scopeContentBlockValues())
        this.configureScopeResources(this.editor.instance, OutputResourceObjects.scopeValues())
      }
      this.autocompleteConfigured = true
    }
  }

  namespaceLoaded() {
    OutputResourceObjects.names().forEach(function(name: any) {
      CKEDITOR.dtd[name] = CKEDITOR.dtd;
      CKEDITOR.dtd.$nonEditable[name] = 1;
      CKEDITOR.dtd.$object[name] = 1;
      CKEDITOR.dtd.$block[name] = 1;
      CKEDITOR.dtd['body'][name] = 1;
    })
  }

  setEditorData() {
    this.onChange = () => this.onChange = this.updateSectionContent
    this.editor.instance.setData(this.activeSection?.content)
    this.editor.instance.readOnly = !this.activeSection?.editable
  }

  onReady() {
    this.setEditorData()
    this.applyScopeAssetsStyles()
  }

  configureScopeResources(editor: any, resourceObjects: any) {
    resourceObjects.forEach((r: { id: any; }) => {
      const name = r.id;
      editor.filter.allow(name + "[!*]", name, true);
    })

    var itemTemplate = '<li data-id="{id}">' +
      '<div><strong class="item-title">{name}</strong></div>' +
      '<div><small>{description}</sm></div>' +
      '</li>';

    function matchCallback (pattern: any) {
      return function(text:any, offset:any){
        var match = text.slice(0, offset)
          .replaceAll(' ', ' ')
          .match(pattern);
        if (!match) {
          return null;
        }
        return {
          start: match.index,
          end: offset
        };
      }
    }

    var autocomplete = new CKEDITOR.plugins.autocomplete(editor, {
      textTestCallback: function textTestCallback(range: any) {
        if (!range.collapsed) {
          return null;
        }

        return CKEDITOR.plugins.textMatch.match(range, matchCallback(/#\{([A-z ]|})*$/));
      },
      dataCallback: this.dataCallback('#{ITEM_NAME}',resourceObjects),
      itemTemplate: itemTemplate,
    });

    // Override default getHtmlToInsert to enable rich content output.
    autocomplete.getHtmlToInsert = function (item: any) {
      return editor.widgets.registered[item.widget].template.source
    }
  }

  configureScopeVariables(variables: any) {
    let itemTemplate = '<li data-id="{id}">' +
      '<div><strong class="item-title">{name}</strong></div>' +
      '<div><small>{description}</sm></div>' +
      '</li>';
    let outputTemplate = '<variableplaceholder variable-key="{id}">{name}</variableplaceholder>';

    function textTestCallback  (range: any) {
      if (!range.collapsed) {
        return null;
      }
      return CKEDITOR.plugins.textMatch.match(range, matchCallback(/\$\{([A-z ]|})*$/));
    }

    function matchCallback (pattern: RegExp) {
      return function<T extends string>(text:T, offset:any){
        var match = text.slice(0, offset)
          .replaceAll(' ', ' ')
          .match(pattern);
        if (!match) {
          return null;
        }
        return {
          start: match.index,
          end: offset
        };
      }
    }
    let clonedVariables = cloneDeep(variables);
    this.currentScope?.companyScopeCustomFieldsDefinition?.customFields.filter(cf=>!cf.hidden).forEach(cf => {
      clonedVariables.push({
          name: cf.name,
          id:"scope.custom_field_" + cf.order,
          description: "custom field" + cf.order,
          supportsEntity: ['SCOPE'],
          supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
        }
      )
    })
    let autocomplete = new CKEDITOR.plugins.autocomplete(this.editor.instance, {
      textTestCallback: textTestCallback,
      dataCallback: this.dataCallback('${ITEM_NAME}', clonedVariables),
      itemTemplate: itemTemplate,
      outputTemplate: outputTemplate,
    });

    // Override default getHtmlToInsert to enable rich content output.
    autocomplete.getHtmlToInsert = function (item: any) {
      return autocomplete.outputTemplate.output(item);
    };
  }

  dataCallback(template: any, variables:any) {
    const templateContentStartIndex = template.indexOf('ITEM_NAME');
    const tempVars = variables;
    return function(matchInfo:any, callback: any){
      if (matchInfo.autocomplete.editor.widgets.widgetHoldingFocusedEditable) {
        if (matchInfo.query.startsWith("#{")) {
          let definitionName = getDefinitionNameWidgets(matchInfo);
          if (definitionName == null) {
            return;
          }
          variables = OutputResourceObjects.widgetValues(definitionName)
        } else if (matchInfo.query.startsWith("${")) {
          let definitionName = getDefinitionNameVariables(matchInfo);
          if (definitionName == null) {
            return;
          }
          variables = OutputVariables.scopeWidgetValues(definitionName)
        }
      } else {
      variables = tempVars;
      }
      var data = variables.filter(function (item:any) {
        let itemName = item.name.toLowerCase()
        let query = matchInfo.query.substr(templateContentStartIndex, matchInfo.query.length);
        return itemName.indexOf(query.toLowerCase()
          .trimStart()
          .replaceAll(' ', ' ')
        ) != -1;
      });

      callback(data);
    }
  }

  applyScopeAssetsStyles() {
    let boxIcon = $(".cke_button__add_box_icon").parent()
    let addWidgetIcon = $(".cke_button__add_widget_icon").parent()
    let signature = $(".cke_button__signature").parent()
    boxIcon.parent().addClass('insert-toolbar')
    boxIcon.parent().prepend("<label style='text-align: center;'>Scope Assets</label>");
    boxIcon.css({"display": "flex", "flex-direction": "column", "align-items": "center"});
    addWidgetIcon.css({"display": "flex", "flex-direction": "column", "align-items": "center"});
    boxIcon.append("<span style='color: white'>Widgets</span>");
    addWidgetIcon.append("<span style='color: white'>Variables</span>");
    signature.parent().addClass('signature-toolbar')
    signature.prepend("<label>Signature</label>");
  }

  position = [
    {key: "LEFT", name: "Align left", order: 1},
    {key: "RIGHT", name: "Align right", order: 2},
    {key: "CENTER", name: "Align center", order: 3},
    {key: "NONE", name: "Hide", order: 4}
  ];

  changeAlign(field: any, position: any) {
    field.content = position.key;
    this.updateTemplate();
  };

  openEditorTab(tab: any) {
    if (this.EDITOR_TABS.find(tab => tab.name === "Code").active) {
      this.editor.instance.execCommand('source');
    }
    if (this.EDITOR_TABS.find(tab => tab.name === "Preview data").active) {
      this.editor.instance.execCommand('togglePreview');
    }
    this.editorClass = 'edit';
    $('.cke_top').css('display', 'inherit')
    $('#active-section').css('border-top', 'none');
    this.EDITOR_TABS.filter(t => t.name !== "Editor").forEach(t => t.active = false);
    this.previewLoading = false;
    tab.active = true
  }

  setTotalVariablesCount() {
    const previewWidgets = this.editor ? this.editor.instance.document.find('.resourceobject-preview').count() : 0;
    const variableplaceholders = this.editor ? this.editor.instance.document.find('variableplaceholder').toArray()
      .filter(vp => (!(vp.getParents().find(c => c.hasClass('resourceobject-template') || c.hasClass('resourceobject-preview'))))).length : 0;
    this.totalCustomVars = variableplaceholders + previewWidgets;
    if (variableplaceholders > this.totalCustomVars && variableplaceholders % this.totalCustomVars === 0) {
      this.customVars = this.customVars % this.totalCustomVars;
    } else {
      this.customVars += 1;
    }
  }

  showLoad(total: number, vars: number) {
    setTimeout(() => {
      let line = $(".line")
      line.css("width", this.lineWidth + "px");
      this.lineWidth += 20;
      if (vars % total === 0) {
        line.css("width", "200px");
        setTimeout(() => {
          this.previewLoading = false;
          this.cdr.detectChanges();
        },1000);
        this.customVars = 0;
      }
    }, 1000);
  }

  loadPreview() {
    this.customVars = 0;
    this.lineWidth = 20;
    this.setTotalVariablesCount();
    if (this.totalCustomVars === 0) {
      this.previewLoading = false;
      this.cdr.detectChanges();
      return;
    }
    const _this = this;
    this.editor.instance.on('requestReady', () => {
      _this.setTotalVariablesCount();
      _this.showLoad(_this.totalCustomVars, _this.customVars);
    })
    setTimeout(() => {
      $(".preview-text").fadeOut(1000);
      $(".hidden-preview-text").delay(980).fadeIn(1000);
    }, 8000);
  }

  openPreviewTab(tab: any) {
    if (!this.editor) {
      return
    }
    if (this.EDITOR_TABS.find(tab => tab.name === "Code").active) {
      this.editor.instance.execCommand('source');
    }
    if (!tab.active) {
      this.previewLoading = true;
      this.loadPreview();
      setTimeout(() => {
        this.editor.instance.execCommand('togglePreview');
      }, 100)

    }
    this.EDITOR_TABS.forEach(t => t.active = false);
    this.editorClass = 'preview';
    $('.cke_top').css('display', 'none');
    $('#active-section').css('border-top', '3px solid #484E5D');
    tab.active = true;
  }

  isWhitelisted(template: OutputTemplate) {
    if (this.currentScope) return false
    return (this.templateWhitelist.inheritAll && template.company.id === this.currentUser.company.parentCompany?.id) || this.templateWhitelist.templatesIds.includes(template.id);

  }

  lockOrUnlockField(field: OutputField, lock: boolean) {
    field.editable = lock;
    this.updateTemplate();
  }

  temporarilyDeleteField(field: OutputField){
    let body = this.currentScope ?
      `Are you sure you want to hide the '${field.name}' block? You won't see it in the preview or generated output.` :
      `Are you sure you want to delete the '${field.name}' block? It will be temporarily hidden in preview and output until you delete it permanently.`
    const deleteScopeTpcModalConfig: ModalConfig = {
      title: `${this.currentScope ? 'Hide' : 'Delete'} '${field.name}'`,
      body: body,
      confirmText: this.currentScope ? 'Hide' : 'Delete',
      confirmCallback: () => {
        field.softDeleted = true;
        this.updateTemplate()
        dialog.close()
      },
      hasCancelButton: true,
      cancelCallback: () => dialog.close(),
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: deleteScopeTpcModalConfig,
    })
  }

  restoreField(field: OutputField){
    field.softDeleted = false;
    this.updateTemplate()
  }

  deleteField(field: OutputField){
    const deleteScopeTpcModalConfig: ModalConfig = {
      title: `Delete '${field.name}' content block permanently`,
      body: `Are you sure you want to delete the '${field.name}' block permanently? This cannot be reverted.`,
      confirmText: 'Delete',
      confirmCallback: () => {
        this.onDeleteField.emit(field)
        this.activeSection = null;
        this.cdr.detectChanges()
        dialog.close()
      },
      hasCancelButton: true,
      cancelCallback: () => dialog.close(),
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: deleteScopeTpcModalConfig,
    })
  }

  showAddBordersModal(){
    const dialogConfig: SetBordersModalConfig = {
      currentScope: this.currentScope,
      outputTemplate: this.outputTemplate,
      confirmCallback: (outputBorders: OutputBorders) => {
        this.outputTemplate.outputTemplateBorders = outputBorders
        this.updateTemplate()
        dialog.close()
      }
    }

    let dialog = this.dialog.open(SetBordersModalComponent, {
      data: dialogConfig,
    })
  }

  previewExport(){
    if (!this.updateInProgress) {
      this.onPreviewExport.emit()
    } else {
      this.snackbarService.showSnackbar('Wait until template is updated', SNACKBAR_LENGTH_SHORT, SnackbarEventType.WARNING)
    }
  }

  downloadExport(){
    this.snackbarService.showPrioritisedSnackbar('Downloading... Please wait', SNACKBAR_LENGTH_SHORT, SnackbarEventType.INFO)
    this.cdr.markForCheck()
    this.onDownload.emit(this.outputTemplate);
    this.updateInProgressChange.emit(true)
  }

  showDuplicateTemplateModal(){
    const duplicateModalConfig: ModalConfig = {
      title: `Duplicate ${this.lang.get('doc_template')}`,
      confirmText: 'Duplicate template',
      confirmCallback: (form: FormControl) => {
        dialog.close()
        this.onDuplicate.emit(form.get('name')?.value)
      },
      inputs: [{ name: 'name', control: new FormControl(''), type: 'text', label: `${this.lang.get('doc_template')} name`, maxLength: 50 }],
      limitBodyWidth: true,
      isFormValid: (form: FormControl) => {
        return !!form.get('name')?.value
      }
    }
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: duplicateModalConfig
    })
  }

  uploadDocxBaseTemplateModal(){
    this.onUploadBaseDocx.emit()
  }

  showChangeScopeTemplate(){
    const dialogConfig: ChangeScopeOutputModalConfig = {
      currentScope: this.currentScope,
      user: this.currentUser!,
      deliverables: this.currentScope.deliverables,
      deliverableColumns: [{ key: 'DELIVERABLE' }, { key: 'REVIEW_STATUS' }] as Preference[],
      title: `Complete Scope Review`,
      limitModalWidth: true,
      text: 'comment',
    }
    this.changeMasterTemplateDialogConfig = this.dialog.open(ChangeScopeOutputModalComponent, {
      data: dialogConfig,
    })
  }

  showAddContentBlockModal(){
      let dialog = this.dialog.open(ScopeUiModalComponent, {
        data: new ModalConfig(
          `Add content block`,
          `Enter the name of new content block`,
          'Complete',
          undefined,
          (form: FormControl) => {
            const outputField = new OutputField();
            outputField.name = form.get('name')?.value;
            let order = this.getOrder(this.outputTemplate.outputFields);
            outputField.fieldType = 'CONTENT_BLOCK';
            outputField.order = order;
            outputField.editable = true;
            outputField.outputFieldBorders = null;
            outputField.content = '';
            outputField.disabled = false;
            outputField.headerRepeatable = false;
            outputField.footerRepeatable = false;
            this.outputTemplate.outputFields.push(outputField);
            this.updateInProgressChange.emit(true)
            this.addBlock.emit(this.outputTemplate)
            dialog.close();
          },
          undefined,
          [{ name: 'name', control: new FormControl(''), type: 'text', label: '' }],
          false,
          false,
          null,
          function (form: FormControl, _: any) {
            return !(form.get('name')?.value == "" || form.get('name')?.value == null);
          }
        ),
      })
  }

  getOrder(fields: OutputField[]): number {
    let order: number
    let sortedFields =  cloneDeep(fields).sort((a, b) => a.order - b.order);
    if (sortedFields.length > 1) {
      order = sortedFields[sortedFields.length - 2].order + 1
    } else {
      order = sortedFields.length
    }
    return order
  }

  setRepeatable(field: OutputField){
    if (field.fieldType == 'BLANK_HEADER') {
      field.headerRepeatable = !field.headerRepeatable
    } else if (field.fieldType == 'BLANK_FOOTER') {
      field.footerRepeatable = !field.footerRepeatable
    }
    this.updateTemplate();
  }

  applyMasterTemplateChanges(){
    let _this = this
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Apply master template changes?`,
        `This will overwrite all your custom changes to this template`,
        'Apply',
        undefined,
        () => {
          dialog.close()
          _this.dialogEventsService.emitEvent({ key: 'applyMasterTemplateChanges'})
          _this.activeSection = new OutputField()
          _this.activeSection.content = ''
        },
        undefined,
        []
      ),
    })
  }

  onMasterUpdate() {
    this.subscription = this.dialogEventsService.dialogEventEmitter
      .subscribe((data: { key: { key: string; optionalData: { text: OutputTemplate; hasOverride: boolean } } }) => {
        let {
          key: { key, optionalData },
        } = data
        if (key === 'appliedMasterTemplateUpdate') {
          this.outputTemplate = optionalData.text;
          this.outputTemplate.outputFields.forEach(field => field.active = false)
          this.activeSection = null
          this.cdr.markForCheck()
        }
      })
  }

  setSectionActive(section: OutputField, outputFields: OutputField[]){
    if (this.holdSelection) {
      return
    }
    const mainTab = this.EDITOR_TABS.find(tab => tab.name === "Editor");
    section.active = !section.active && section.editable && this.outputTemplate.documentTemplate !== 'PHM_TEMPLATE';
    if (section.id) {
      outputFields.filter(f => f.id !== section.id).forEach(field => field.active = false);
    } else {
      outputFields.filter(f => f.name !== section.name).forEach(field => field.active = false);
    }
    if (this.activeSection != null && (this.activeSection.id === section.id || this.activeSection.name === section.name)) {
      this.activeSection = null
    } else {
      this.holdSelection = true
      this.activeSection = section;
      if (this.editor) {
        this.setEditorData()
      }
      this.holdSection()
    }
    mainTab?.onClick(mainTab);
  }

  holdSection() {
    setTimeout(() => {
      this.holdSelection = false
      this.cdr.detectChanges()
    }, 1000)
  }

  getFormattedDate() {
    return this.outputTemplate.updatedTs.toLocaleDateString()
  }

  showLanguage(lang: string) {
    switch (lang) {
      case 'EN': {
        return 'English';
      }
      case 'ES': {
        return 'Spanish';
      }
      case 'DE': {
        return 'German';
      }
      case 'PR': {
        return 'Portuguese';
      }
      case 'FR': {
        return 'French';
      }
    }
    return 'Unknown';
  }

  ngOnDestroy() {
    this.editor?.instance?.destroy()
  }

  protected readonly ckeConfig = ckeConfig;
  protected readonly trackById = trackById;
}
