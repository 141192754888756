import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'table-loader',
    templateUrl: 'table-loader.component.html',
    styleUrls: ['./table-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule],
    standalone: true,
})

export class TableLoaderComponent {
    @Input() loading: boolean
    @Input() condensed: boolean
    @Input() amountOfRows = 10
}