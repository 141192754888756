import { createSelector } from '@ngrx/store'
import * as fromFeature from '@app/features/scoping/store/reducers/index'
import { ScopingState } from '../../models/scope.state.model'
import { Pagination } from '@core/model/definitions/pagination.interface'
import { DeliverablePreferences, ScopePreferences } from '../../models/scope-columns.model'
import { ScopeFilter } from '@core/model/scope-filter.model'
import { Sort } from '@angular/material/sort'
import { Page } from '@app/features/scoping/models/page.model'
import { ScopeVersion } from '@core/model/scope-version'
import { FolderVersion } from '@core/model/folder-version.model'
import { Preference } from '@core/model/user-preferences.interface'
import { ScopeOverviewFilter } from '@core/model/scope-overview-filter.model'
import { FindRolesSearchParams } from '@core/model/definitions/findRoleSearchParams.interface'

export const getScopingFromState = createSelector(
  fromFeature.getScopesState,
  (state: fromFeature.State) => state.scoping
)

const getScopes = (state: ScopingState): Page<ScopeVersion> => state.scopes
const getArchivedScopes = (state: ScopingState): Page<ScopeVersion> => state.archivedScopes
const getScopesLoaded = (state: ScopingState): boolean => state.loaded
const getFolders = (state: ScopingState): Page<FolderVersion> => state.folders
const getArchivedFolders = (state: ScopingState): Page<FolderVersion> => state.archivedFolders
const getAllFolders = (state: ScopingState): FolderVersion[] => state.allFolders
const getHighlights = (state: ScopingState): any => state.highlights
const searchDeliverables = (state: ScopingState): any => state.deliverables
const searchComponents = (state: ScopingState): any => state.components
const getPagination = (state: ScopingState): Pagination => state.pagination
const getPaginationArchived = (state: ScopingState): Pagination => state.paginationArchived
const getSort = (state: ScopingState): Sort | undefined => state.sort
const getSortArchived = (state: ScopingState): Sort | undefined => state.sortArchived
const getFilters = (state: ScopingState): ScopeFilter => state.filters
const getScopeOverviewFilters = (state: ScopingState): ScopeOverviewFilter => state.overviewFilters
const getMyScopesColumnPreferences = (state: ScopingState): ScopePreferences => state.columns
const getDeliverableColumns = (state: ScopingState): DeliverablePreferences => state.deliverablesColumns
const getSecondParties = (state: ScopingState): any => state.secondParties?.filter(s => !s.archived)
const getErrorMessage = (state: ScopingState): any => state.error?.message

const getAvailableRoles = (state: ScopingState): any => state.availableRoles
const getAvailableDepartments = (state: ScopingState): any => state.availableDepartments
const getAvailableComponents = (state: ScopingState): any => state.availableComponents
const getAvailableDeliverables = (state: ScopingState): any => state.availableDeliverables
const getSearchParams = (state: ScopingState): any => state.searchParams
const getSelectedReplaceRole = (state: ScopingState): any => state.selectedReplaceRole
const getSelectedReplaceWithRole = (state: ScopingState): any => state.selectedReplaceWithRole
const getReplaceSummarySuccess = (state: ScopingState): any => state.replaceSummarySuccess
const getRevertSummarySuccess = (state: ScopingState): any => state.revertSummarySuccess
const getReplacedRolesResponse = (state: ScopingState): any => state.replacedRolesResponse
const getRevertRolesResponse = (state: ScopingState): any => state.revertSummaryResponse
const getIsLoading = (state: ScopingState): any => state.loading
const getIsLoadingArchive = (state: ScopingState): any => state.loadingArchive
const getIsLoadingColumns = (state: ScopingState): any => state.loadingColumns
const getIsLoaded = (state: ScopingState): any => state.loaded
const getIsLoadedArchive = (state: ScopingState): any => state.loadedArchive
const getRateCard = (state: ScopingState): any => state.ratecard
const getOfficeLocations = (state: ScopingState): any => state.officeLocations
const getScopeTemplates = (state: ScopingState): any => state.scopeTemplates
const getWordOutputTemplates = (state: ScopingState): any => state.wordTemplates
const getXlsxOutputTemplates = (state: ScopingState): any => state.xlsxTemplates?.filter(element => element.active === true &&  element.templateType === 'SCOPE')

const selectMyScopes = createSelector(getScopingFromState, getScopes)
const selectArchivedScopes = createSelector(getScopingFromState, getArchivedScopes)
const selectFolders = createSelector(getScopingFromState, getFolders)
const selectArchivedFolders = createSelector(getScopingFromState, getArchivedFolders)
const selectAllFolders = createSelector(getScopingFromState, getAllFolders)
const selectHighlights = createSelector(getScopingFromState, getHighlights)
const selectSearchedDeliverables = createSelector(getScopingFromState, searchDeliverables)
const selectSearchedComponents = createSelector(getScopingFromState, searchComponents)
const selectDeliverablesByScopeMap = createSelector(getScopingFromState, (state) => state.deliverablesByScope)
const selectScopesByFolderMap = createSelector(getScopingFromState, (state) => state.scopesByFolder)
const selectDeliverablesByScope = (scopeId: number) =>
  createSelector(getScopingFromState, (state): any => {
    return state.deliverablesByScope[scopeId]
  })
const selectScopesByFolder = (folderId: number) =>
  createSelector(getScopingFromState, (state: ScopingState): any => {
    return state.scopesByFolder[folderId]
  })
const selectScopesLoaded = createSelector(getScopingFromState, getScopesLoaded)
const selectPagination = createSelector(getScopingFromState, getPagination)
const selectPaginationArchived = createSelector(getScopingFromState, getPaginationArchived)
const selectSort = createSelector(getScopingFromState, getSort)
const selectSortArchived = createSelector(getScopingFromState, getSortArchived)
const selectFilters = createSelector(getScopingFromState, getFilters)
const selectScopeOverviewFilters = createSelector(getScopingFromState, getScopeOverviewFilters)
const selectMyScopesColumnsPreferences = createSelector(getScopingFromState, getMyScopesColumnPreferences)

export const selectScopeOverviewColumns = createSelector(
  getScopingFromState,
  getDeliverableColumns
)

export const selectDisplayedScopeColumns = createSelector(getScopingFromState, (state): Preference[] => {
    return Object.values(state.columns)
      .map((p) => (p.key === 'SCOPE_NUMBER') ? { ...p, name: '{LANG:scope} Number' } : p)
})

export const selectDisplayedFolderColumns = createSelector(
    getScopingFromState,
    (state): Preference[] => {
      return Object.values(state.columns)
        .filter((p) => p.key !== 'RATECARD' && p.key !== 'SOW_NAME')
        .map((p) => (p.key === 'SCOPE_NUMBER') ? { ...p, name: '{LANG:sow} Number' } : p)
    }
  )

export const selectDisplayedTemplateColumns = createSelector(
  getScopingFromState,
  (state): Preference[] => {
    return Object.values(state.columns)
      .filter((p) => p.key !== 'STATUS' && p.key !== 'DATE_APPROVED' && p.key !== 'SOW_NAME')
      .map((p) => (p.key === 'SCOPE_NUMBER') ? { ...p, name: '{LANG:scope} Number' } : p)
  }
)

const selectSecondParties = createSelector(getScopingFromState, getSecondParties)

const selectErrorMessage = createSelector(getScopingFromState, getErrorMessage)

const selectAvailableRoles = createSelector(getScopingFromState, getAvailableRoles)

const selectAvailableComponents = createSelector(getScopingFromState, getAvailableComponents)

const selectAvailableDepartments = createSelector(getScopingFromState, getAvailableDepartments)

const selectAvailableDeliverables = createSelector(getScopingFromState, getAvailableDeliverables)

const selectSearchParams = createSelector(getScopingFromState, getSearchParams)

const selectSelectedReplaceRole = createSelector(getScopingFromState, getSelectedReplaceRole)

const selectSelectedReplaceWithRole = createSelector(getScopingFromState, getSelectedReplaceWithRole)

const selectReplaceSummarySuccess = createSelector(getScopingFromState, getReplaceSummarySuccess)

const selectReplaceRolesResponse = createSelector(getScopingFromState, getReplacedRolesResponse)

const selectRevertRolesResponse = createSelector(getScopingFromState, getRevertRolesResponse)

const selectRevertSummarySuccess = createSelector(getScopingFromState, getRevertSummarySuccess)

const selectIsLoading = createSelector(getScopingFromState, getIsLoading)

const selectIsLoadedArchive = createSelector(getScopingFromState, getIsLoadedArchive)

const selectIsLoadingArchive = createSelector(getScopingFromState, getIsLoadingArchive)

const selectIsLoaded = createSelector(getScopingFromState, getIsLoaded)

const selectIsLoadingColumns = createSelector(getScopingFromState, getIsLoadingColumns)

export const selectFindReplaceBreadcrumbs = createSelector(
  selectAvailableRoles,
  selectSelectedReplaceRole,
  selectSearchParams,
  (availableRoles: any[], selectedRole: any, searchParams: FindRolesSearchParams) => {
    if (availableRoles && selectedRole && searchParams) {
      return availableRoles
        .filter((role: any) =>
          role.roleId === selectedRole.roleId &&
          role.deliverable.name === (searchParams.deliverableName || role.deliverable.name) &&
          role.component.name === (searchParams.componentName || role.component.name) &&
          role.department.name === (searchParams.departmentName || role.department.name)
        )
        .map((role) => ({
          ...role,
          showHideHoursInput: false,
          componentId: role.component.id,
          deliverableId: role.deliverable.id,
          deliverableName: role.deliverable.name,
        }))
    } else {
      return availableRoles
    }
  }
)
const selectRateCard = createSelector(getScopingFromState, getRateCard)

const selectFilteredRoles = createSelector(selectRateCard, (ratecard) => {
  return ratecard?.departments[0].roles
})

const selectOfficeLocations = createSelector(getScopingFromState, getOfficeLocations)
const selectScopeTemplates = createSelector(getScopingFromState, getScopeTemplates)
const selectWordOutputTemplates = createSelector(getScopingFromState, getWordOutputTemplates)
const selectXlsxOutputTemplates = createSelector(getScopingFromState, getXlsxOutputTemplates)
const selectScopeTypes = createSelector(getScopingFromState, (state: ScopingState): any => state.scopeTypes)
const selectCustomFields = createSelector(getScopingFromState, (state: ScopingState): any => state.customFields)
const selectTrafficSystem = createSelector(getScopingFromState, (state: ScopingState): any => state.trafficSystem)
const selectAllFeeItems = createSelector(getScopingFromState, (state: ScopingState): any => state.feeItems)
const selectDynamicFieldSettings = createSelector(getScopingFromState, (state: ScopingState) => state.dynamicFieldSettings);
const selectDynamicFieldSettingsLoading = createSelector(getScopingFromState, (state: ScopingState) => state.loadingDynamicFieldSettings);
const selectDynamicFieldSettingsError = createSelector(getScopingFromState, (state: ScopingState) => state.errorDynamicFieldSettings);
const selectDynamicFieldSetting = createSelector(getScopingFromState, (state: ScopingState) => state.dynamicFieldSettings);

export const ScopingSelectors = {
  selectMyScopes,
  selectArchivedScopes,
  selectScopesLoaded,
  selectFolders,
  selectArchivedFolders,
  selectHighlights,
  selectSearchedDeliverables,
  selectSearchedComponents,
  selectPagination,
  selectPaginationArchived,
  selectSort,
  selectSortArchived,
  selectFilters,
  selectScopeOverviewFilters,
  selectMyScopesColumnsPreferences,
  selectDisplayedScopeColumns,
  selectScopeOverviewColumns,
  selectDisplayedFolderColumns,
  selectDisplayedTemplateColumns,
  selectDeliverablesByScopeMap,
  selectScopesByFolderMap,
  selectDeliverablesByScope,
  selectScopesByFolder,
  selectAllFolders,
  selectSecondParties,
  selectErrorMessage,
  selectAvailableRoles,
  selectFilteredRoles,
  selectAvailableComponents,
  selectAvailableDepartments,
  selectAvailableDeliverables,
  selectFindReplaceBreadcrumbs,
  selectSelectedReplaceRole,
  selectSelectedReplaceWithRole,
  selectReplaceSummarySuccess,
  selectReplaceRolesResponse,
  selectRevertRolesResponse,
  selectRevertSummarySuccess,
  selectRateCard,
  selectOfficeLocations,
  selectScopeTemplates,
  selectWordOutputTemplates,
  selectXlsxOutputTemplates,
  selectScopeTypes,
  selectCustomFields,
  selectTrafficSystem,
  selectIsLoaded,
  selectIsLoading,
  selectIsLoadedArchive,
  selectIsLoadingArchive,
  selectIsLoadingColumns,
  selectAllFeeItems,
  selectDynamicFieldSettings,
  selectDynamicFieldSettingsLoading,
  selectDynamicFieldSettingsError,
  selectDynamicFieldSetting
}
