<div class="flex d-flex-justify-between pt-6 px-10">
  <h3 class="product-heading-bold">Groups</h3>
  <button [has-privilege]="'COMPANY_GROUP__CREATE'" (click)="showAddGroupDialog()"
          mat-flat-button>
    <mat-icon class="material-symbols-rounded">add</mat-icon> Add group
  </button>
</div>
<div class="card-wrapper p-10" [hidden]="!loadingGroups">
  <div class="card-skeleton" *ngFor=" let x of [].constructor(16)"></div>
</div>
<div class="card-wrapper p-10">
  <scope-ui-card-master
    *ngFor="let group of groups | orderBy: 'name'; trackBy: trackById"
    (click)="selectGroup(group.id)"
    [hasProfilePic]="true"
    [cardTitle]="group.name"
    [subTitle]="group.createdTs | dateFormat: 'shortDate'"
    [user]="group.createdBy"
    [cdnConfig]="cdnConfig" />
</div>
