import { Type } from 'class-transformer';
import { CompanySummary } from '@core/model/company-summary.model';
import { User } from '@core/model/user.model';

export class SpreadsheetTemplate {
  id!: number
  templateType!: string
  name!: string
  path: string
  fileName: string
  @Type(() => CompanySummary) company!: CompanySummary;
  active!: boolean
  version!: number
  @Type(() => Date) updatedTs!: Date;
  @Type(() => User) updatedBy!: User;
}
