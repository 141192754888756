import { Injectable } from '@angular/core';
import { Language, Languages } from '@core/model/enums/language.enum';
import { LanguageService } from '@core/service/language.service';
import { forkJoin, map, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OutputField } from '@app/features/scope-overview/model/output-field.model';
import { plainToInstance } from 'class-transformer';
import { asMap } from '@shared/utils/utils';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';

declare const CKEDITOR: any;

export type OutputThemeDefinition = { name: string, title: string, languages: Language[], applicationSetting?: string,
  thumbnailUri?: string, baseUri?: string, blocks?: any[], companyType: string[], pageBreakable?: boolean,
  selectedLanguage?: Language }

@Injectable({
  providedIn: 'root',
})

export class OutputThemeService {
  scopeTemplates: {[key: string]: OutputThemeDefinition}
  sowTemplates: {[key: string]: OutputThemeDefinition}

  constructor(private lang: LanguageService, private http: HttpClient) {
    this.scopeTemplates = {
      "BLANK": {
        name: "BLANK",
        title: "Blank Template",
        languages: [Languages.EN, Languages.FR, Languages.DE, Languages.PR, Languages.ES],
        applicationSetting: "SCOPE_OUTPUT_DOCX__BLANK_TEMPLATE",
        companyType: ["AGENCY", "CLIENT", "LEGAL", "COMPANY", "TBMC"]
      },
      "ENGAGEMENT_LETTER": {
        name: "ENGAGEMENT_LETTER",
        title: "Engagement Letter",
        thumbnailUri: "standard-template.png",
        companyType: ["LEGAL"],
        languages: [Languages.EN],
        baseUri: "assets/output-templates/scope/engagement-letter",
        blocks: [
          { name: "block.header", fieldType: "BLANK_HEADER" },
          { name: "block.introduction", template: "/html/01-introduction.html" },
          { name: "block.initial.matter", template: "/html/02-initial-matter.html" },
          { name: "block.scope.of.legal.services", template: "/html/03-scope-of-legal-services.html" },
          { name: "block.concerns", template: "/html/04-concerns.html" },
          { name: "block.signature.and.terms", template: "/html/05-signature-and-terms.html" },
          { name: "block.footer", fieldType: "BLANK_FOOTER" }
        ]
      },
      "STANDARD_TEMPLATE": {
        name: "STANDARD_TEMPLATE",
        title: `${this.lang.get('scope')} ${this.lang.get('output')} Template`,
        thumbnailUri: "standard-template.png",
        companyType: ["AGENCY", "CLIENT", "COMPANY", "TBMC"],
        languages: [Languages.EN, Languages.FR, Languages.DE, Languages.PR, Languages.ES],
        baseUri: "assets/output-templates/scope/standard-template",
        blocks: [
          { name: "block.header", fieldType: "BLANK_HEADER" },
          { name: "block.summary", template: "/html/01.html" },
          { name: "block.exe-summary", template: "/html/02.html" },
          { name: "block.background", template: "/html/03.html" },
          { name: "block.terms", template: "/html/04.html" },
          { name: "block.footer", template: "/html/footer.html", fieldType: "BLANK_FOOTER" }
        ]
      },
      "CHANGE_REQUEST_TEMPLATE": {
        name: "CHANGE_REQUEST_TEMPLATE",
        title: "Change Request Template",
        thumbnailUri: "change-request-template.png",
        companyType: ["AGENCY", "CLIENT", "COMPANY", "TBMC"],
        pageBreakable: false,
        languages: [Languages.EN],
        baseUri: "assets/output-templates/scope/change-request-template",
        blocks: [
          { name: "block.header", template: "/html/header.html", fieldType: "BLANK_HEADER" },
          { name: "block.summary", template: "/html/01-summary.html" },
          { name: "block.deliverables", template: "/html/02-deliverables.html" },
          { name: "block.terms", template: "/html/03-terms.html" },
          { name: "block.signatures", template: "/html/04-signatures.html" },
          { name: "block.footer", template: "/html/footer.html", fieldType: "BLANK_FOOTER" }
        ]
      },
      "NUMBER_TWO": {
        name: "NUMBER_TWO",
        title: `${this.lang.get('output')} Template - No2 (Legacy)`,
        thumbnailUri: "cmi_output_thumbnail.png",
        languages: [Languages.EN],
        companyType: ["AGENCY", "CLIENT", "COMPANY", "TBMC"]
      },
      "NUMBER_THREE": {
        name: "NUMBER_THREE",
        title: "PHM Template 2 (Legacy)",
        thumbnailUri: "document-output/thumb/PHMtemplate2Thumb.jpg",
        languages: [],
        companyType: []
      },
      "STATEMENT_OF_WORK": {
        name: "STATEMENT_OF_WORK",
        title: "Statement of Work Template (Legacy)",
        thumbnailUri: "ogilvy-singapore-template.png",
        languages: [Languages.EN],
        companyType: ["AGENCY", "CLIENT", "COMPANY", "TBMC"]
      },
      "GENERAL_SOW": {
        name: "GENERAL_SOW",
        title: `General Statement of Work ${this.lang.get('output')} Template (Legacy)`,
        thumbnailUri: "ogilvy-template.png",
        languages: [Languages.EN],
        companyType: ["AGENCY", "CLIENT", "COMPANY", "TBMC"]
      },
      "ICON": {
        name: "ICON",
        title: "Icon Template (Legacy)",
        thumbnailUri: "icon_theme.png",
        languages: [Languages.EN],
        companyType: ["AGENCY", "CLIENT", "COMPANY", "TBMC"]
      },
      "ICON_NO_RATES": {
        name: "ICON_NO_RATES",
        title: "Icon Template (No Rates) (Legacy)",
        thumbnailUri: "icon_theme.png",
        languages: [Languages.EN],
        companyType: ["AGENCY", "CLIENT", "COMPANY", "TBMC"]
      },
      "ICON_NO_RATES_NO_TOTAL": {
        name: "ICON_NO_RATES_NO_TOTAL",
        title: "Icon Template (No Rates, No Total) (Legacy)",
        thumbnailUri: "icon_theme.png",
        languages: [Languages.EN],
        companyType: ["AGENCY", "CLIENT", "COMPANY", "TBMC"]
      },
      "STANDARD": { // Default template type - not selectable
        name: "STANDARD",
        title: `Default (Legacy)`,
        thumbnailUri: "icon_theme.png",
        languages: [],
        companyType: []
      },
      "STANDARD_TBMC": { // Default template type - not selectable
        name: "STANDARD_TBMC",
        title: `Default (Legacy)`,
        thumbnailUri: "icon_theme.png",
        languages: [],
        companyType: []
      }
    }

    this.sowTemplates = {
      "BLANK": {
        name: "BLANK",
        title: `${this.lang.get('sow')} Blank Template`,
        languages: [Languages.EN, Languages.FR, Languages.DE, Languages.PR, Languages.ES],
        companyType: ["AGENCY", "CLIENT", "LEGAL", "COMPANY"]
      },
      "STANDARD_TEMPLATE": {
        name: "STANDARD_TEMPLATE",
        title: `${this.lang.get('sow')} ${this.lang.get('output')} Template`,
        thumbnailUri: "standard-template.png",
        companyType: ["AGENCY", "CLIENT", "COMPANY"],
        languages: [Languages.EN, Languages.FR, Languages.DE, Languages.PR, Languages.ES],
        baseUri: "assets/output-templates/sow/standard-template",
        blocks: [
          { name: "block.header", fieldType: "BLANK_HEADER" },
          { name: "block.summary", template: "/html/01.html" },
          { name: "block.exe-summary", template: "/html/02.html" },
          { name: "block.background", template: "/html/03.html" },
          { name: "block.terms", template: "/html/04.html" },
          { name: "block.footer", template: "/html/footer.html", fieldType: "BLANK_FOOTER" }
        ]
      },
      "STATEMENT_OF_WORK_SINGLE": { // Default template type - not selectable
        name: "STATEMENT_OF_WORK_SINGLE",
        title: `Default (Legacy)`,
        thumbnailUri: "icon_theme.png",
        languages: [],
        companyType: []
      },
      "STATEMENT_OF_WORK_SINGLE_TBMC": { // Default template type - not selectable
        name: "STATEMENT_OF_WORK_SINGLE",
        title: `Default (Legacy)`,
        thumbnailUri: "icon_theme.png",
        languages: [],
        companyType: []
      }
    };
  }

  getFromTemplate(templateScopeType: boolean, documentTemplate: string) {
    return (templateScopeType ? this.scopeTemplates[documentTemplate]?.title :
        this.sowTemplates[documentTemplate]?.title) || "From Deleted Template";
  }

  composeSections(template: OutputTemplate, documentTemplate: OutputThemeDefinition) {
    if (!documentTemplate.baseUri){
      return of(null)
    } else {
      return this.fetchLanguageProps(documentTemplate.baseUri, template.language).pipe(
        switchMap((langProps: any) =>  {
          let promises = []

          documentTemplate.blocks.forEach((block, index) => {
            let field = plainToInstance(OutputField, {
              name: langProps.get(block.name) || block.name,
              fieldType: block.fieldType || "CONTENT_BLOCK",
              order: index + 1
            })
            template.outputFields.push(field);
            if (block.template) {
              promises.push(this.applyTemplateContent(documentTemplate.baseUri + block.template, field, langProps))
            } else {
              field.content = "";
            }
          })
          return forkJoin(promises)
        })
      )
    }
  }

  private fetchLanguageProps(baseUri, language){
    return this.http.get(`${baseUri}/lang/${language.toLowerCase()}.properties`, { responseType: 'json' }).pipe(
      map((langProps: string) =>  {
        return {
          langCode: language.toLowerCase(),
          props: langProps,
          get: (key) => {
            return langProps[key]
          }
        }
      })
    )
  }

  private applyTemplateContent(templateUri, outputField, langProps){
    return this.http.get(templateUri, { responseType: 'text' }).pipe(
      switchMap((html: string) => {
        let tmp = $('<div></div>').html(html)
        this.initLangs(tmp, langProps)
        return this.initWidgets(tmp, langProps.langCode).pipe(
          map(() => {
            outputField.content = tmp.html()
          })
        )
      })
    )
  }

  private initLangs(tmp, langProps){
    tmp.find("[lang-key]").each((i, el) => {

      let filters = el.getAttribute('lang-key').split("|").map(k => k.trim());
      let key = filters[0];
      let value = langProps.get(key);
      if (value == null){
        value = "??" + el.innerText + "??";
      }
      if (filters.length > 1){
        let f1 = filters[1]
        if (f1 == "uppercase"){
          value = value.toUpperCase();
        }
      }

      el.innerText = value;
    })
  }

  private initWidgets(tmp, langCode) {
    let uninitialisedWidgets = tmp.find("[widget-init-required]")
    if (uninitialisedWidgets.length > 0) {
      let keys = Object.keys(asMap(uninitialisedWidgets.toArray(), "tagName", i => i.toLowerCase()))
      let promises = []

      keys.forEach((tag) => {
        promises.push(CKEDITOR.plugins.load(tag, (plugins: any[]) => {
          let resourceHtml = plugins[tag].createWidgetTemplate(langCode);
          let els = tmp.find(tag);
          els.replaceWith(resourceHtml);
        }))
      })
      return forkJoin(keys)
    }
    return of([])
  }
}
