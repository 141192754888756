import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'sortBySupports',
})
export class SortBySupportsPipe implements PipeTransform {
  transform(value: any[]): any[] {
    const priorityMap = {
      'SCOPE': 1,
      'SCOPE-SECTION': 2,
      'DELIVERABLE': 3
    };

    return value.map(item => {
      item.priority = this.getPriority(item.supports, priorityMap);
      return item;
    }).sort((item1, item2) => {
      const priorityDifference = item1.priority - item2.priority;
      if (priorityDifference !== 0) {
        return priorityDifference;
      }
      return item1.name.localeCompare(item2.name);
    });
  }

  private getPriority(supports: string[], priorityMap: any): number {
    for (const support of supports) {
      if (priorityMap[support]) {
        return priorityMap[support];
      }
    }
    return 4;
  }
}
