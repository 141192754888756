import { Component, HostListener } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { User } from '@core/model/user.model'
import { AuthService } from '@core/service/auth.service'
import { EnvService } from '@envs/env-service'
import { environment } from '@envs/environment'
import { HeaderStyleService, HIDE_HEADER } from '@shared/services/header-style.service'
import { ParentChildService } from '@shared/services/parent-child.service'
import { authorisedAsChildCompany } from '@shared/utils/utils'
import { UserRoleLevel } from '@core/model/enums/user-role-level.enum'
import _ from 'lodash'
import { Store } from '@ngrx/store'
import { CompanyManagementSelectors } from '../../store/selectors/company-management.selectors'
import { Observable } from 'rxjs'

@Component({
  selector: 'manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent {
  baseJsUrl: string
  loggedInUser!: User
  groupId$: Observable<number>

  constructor(private store$: Store,
              private headerStyleService: HeaderStyleService,
              private envService: EnvService,
              private router: Router,
              public route: ActivatedRoute,
              private titleService: Title,
              private authService: AuthService,
              public parentChildService: ParentChildService) {
    this.loggedInUser = this.authService.loggedInUser
    this.groupId$ = this.store$.select(CompanyManagementSelectors.selectGroupId)
  }

  ngOnInit() {
    let tab = this.route.snapshot.queryParamMap.get("tab")
    this.baseJsUrl = `${this.envService.baseJsUrl}/admin?host=${environment.baseHost}&childCompany=${this.loggedInUser.company.id}${tab ? '&tab=' + tab : ''}`
    // Handles back button clicks from rate card page
    this.headerStyleService.setHeaderClass(null)
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data === 'hideHeader') {
      this.headerStyleService.setHeaderClass(HIDE_HEADER);
    } else if (event.data === 'showHeader') {
      this.headerStyleService.setHeaderClass(null);
    } else if (event.data.action === 'goToAccountSettings') {
      this.router.navigate(
        [`/account-setup/${event.data.id}`],
        {
          relativeTo: this.route,
          queryParams: { 'tab': 'details' }
        }
      )
    } else if (event.data.action === 'redirectToOauth2') {
      window.open(event.data.redirectUrl, "_self")
    }
  }

  onSelect($event: string) {
    this.titleService.setTitle(_.startCase(_.camelCase($event)) + ' | Admin | Scope')
    this.baseJsUrl = `${this.envService.baseJsUrl}/admin?host=${environment.baseHost}&childCompany=${this.loggedInUser.company.id}&tab=${$event}`
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { 'tab': $event }
      }
    )
  }

  protected readonly authorisedAsChildCompany = authorisedAsChildCompany;
  protected readonly UserRoleLevel = UserRoleLevel;
}
