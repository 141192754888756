import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionButton } from '@app/core/model/definitions/action-button.interface';
import { ScopeUiDropdownComponent } from '../scope-ui-dropdown/scope-ui-dropdown.component';
import { ScopeUiInputComponent } from '../scope-ui-input/scope-ui-input.component';
import { SharedModule } from '@app/shared/shared.module';
import { User } from '@core/model/user.model';
import { CdnConfig } from '@core/model/cdn-config.model';

@Component({
  selector: 'scope-ui-card-master',
  standalone: true,
  imports: [CommonModule, SharedModule, ScopeUiDropdownComponent, ScopeUiInputComponent],
  templateUrl: './scope-ui-card-master.component.html',
  styleUrls: ['./scope-ui-card-master.component.scss'],
})
export class ScopeUiCardMasterComponent {
  @Input() actionButtons: ActionButton[] = [];

  @Input() userProfilePicUrl: string;

  @Input() statusIcon: string;

  @Input() statusIconClass: string | string[] = '';

  @Input() status: string;

  @Input() cardTitle!: string;

  @Input() subTitle: string;

  @Input() metaData: string;

  @Input() inputLabel: string;

  @Input() inputPlaceholder: string;

  @Input() customSelectIcon: string;

  @Input() controlLabel: string;

  @Input() hasSubtitle: boolean = true;

  @Input() hasMetadata: boolean = false;

  @Input() hasDropDown: boolean = false;

  @Input() hasInputField: boolean = false;

  @Input() hasIcon: boolean = false;

  @Input() hasStatus: boolean = false;

  @Input() hasButtons: boolean = false;

  @Input() hasProfilePic: boolean = false;

  @Input() hasPlaceholder: boolean = false;

  @Input() isStandardDropdown: boolean;

  @Input() isBold: boolean = false;

  @Input() onClick!: boolean;

  @Input() user: User;

  @Input() cdnConfig?: CdnConfig

  @Input() dropdownOptions: any[];

  @Input() dropdownSelectedValue: any;

  @Input() dropdownValueFn: (option: any) => any;

  @Input() showDropdownToggle?: boolean

  @Input() dropdownToggleText?: string

  @Input() dropdownMinimum?: number

  @Output() buttonClicked;

  @Output() onSelectionChange;

  @Output() onInputChange;

  showDropdown = false

  constructor() {
    this.buttonClicked = new EventEmitter<string>();
    this.onSelectionChange = new EventEmitter<any>();
    this.onInputChange = new EventEmitter<any>();
  }

  handleButtonClick(btn: ActionButton, event: MouseEvent): void {
    event.stopPropagation()
    if (btn.callback) {
      const dataToSend = {
        /*... whatever data you want to send ...*/
      };
      btn.callback(dataToSend);
    } else if (btn.eventId) {
      this.buttonClicked.emit(btn.eventId);
    }
  }

  onSelection(value: any) {
    this.onSelectionChange.emit(value);
  }

  onInput(value: any) {
    this.onInputChange.emit(value);
  }
}
