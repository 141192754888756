<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>Import - Rate Cards</h1>
      </div>

      <div class="mt-2 w-450">
        <span *ngIf="page?.data?.length > 0" >List of {{trafficSystem.name}} Rate cards</span>
        <span *ngIf="page?.data?.length == 0" >Currently there is nothing new to import.</span>
      </div>

      <button class="close-button" mat-dialog-close>
        <mat-icon class="material-symbols-rounded">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <loader-modal *ngIf="showLoader" [overlay]="true"></loader-modal>
    <div class="modal__body" *ngIf="page?.data?.length > 0 && !importErrors.hasErrors()">
      <div class="row">
        <div class="ten--columns">
          <h3 class="product-heading-bold">Rate cards to import</h3>
        </div>
      </div>
      <ul class="ratecard-list">
        <li class="ratecards costume-li-ratecard" *ngFor="let rateCard of page.data" id="CL-{{rateCard.id}}">
          <div class="row">
            <div data-ng-class="{'scopemark': rateCard.selected}">
              <label [ngClass]="{'ultra-grey': rateCard.imported}">
                <mat-checkbox class="client-name"
                              [checked]='rateCard.selected'
                              [disabled]="(countSelected() == IMPORT_LIMIT && !rateCard.selected) || !!rateCard.imported"
                              (change)='rateCard.selected = $event.checked; selectItem(rateCard)'
                              aria-label='Select client'
                ></mat-checkbox>
                <span *ngIf="rateCard.imported">[IMPORTED]</span> {{rateCard.name}}
              </label>
            </div>
            <div class="row naming-conflict" *ngIf="rateCard.namingConflict && rateCard.selected && !rateCard.imported">
              <p class="inp-title">Rename client</p>
              <input type="text" [(ngModel)]="rateCard.newName" required>
            </div>
          </div>
          <div class="row error-container" *ngIf="showNamingConflictError(rateCard)">
            <span class="error">{{rateCard.newName ? rateCard.newName : rateCard.name}} already exists in your {{'agency|l' | lang }}, please rename.</span>
          </div>
        </li>
      </ul>
      <div class="row text-12 m_top_10">
        <div class="left">
          <span>Page {{page.pageInfo.pageNumber}} of {{page.pageInfo.totalPages}}</span><span> - ({{page.pageInfo.pageNumber == page.pageInfo.totalPages ? page.pageInfo.totalElements : (page.pageInfo.pageSize * page.pageInfo.pageNumber)}} / {{page.pageInfo.totalElements}} Items)</span>
          <div style="margin-top: 10px;">Page Imported Items: {{ page.importedData.length }}/{{page.data.length}}</div>
        </div>
        <div class="right">
          <a href="#" *ngIf="page.pageInfo.pageNumber > 1" (click)="previousPage()">Previous Page <span *ngIf="page.pageInfo.pageNumber != page.pageInfo.totalPages" class="ultra-grey"> | </span></a>
          <a href="#" *ngIf="page.pageInfo.pageNumber < page.pageInfo.totalPages" (click)="nextPage()">Next Page</a>
        </div>
      </div>
    </div>
    <div class="modal__body" *ngIf="importErrors.hasErrors()">
      <p>An error has occurred during import. Some or all rate cards may not have been imported. Please report to your administrator.</p>
      <div class="error-debug" >
        <span *ngIf="importErrors.not_received.length > 0">
          <p><b>Unreceived external ID requests:</b></p>
          <ul>
            <li *ngFor="let i of importErrors.not_received">
              <span class="listed-item">
                {{i.id}} - {{i.name}}
                <a href="#" (click)="debugExternalId(i)">{{i.fetching ? 'fetching data..' : 'debug'}}</a>
              </span>
              <div class="debug-received" *ngIf="i.debugFetch">
                <code>Received: </code>
                <code>ID:{{i.debugFetch.id}}, NAME:{{i.debugFetch.name}}, CURRENCY:{{i.debugFetch.currency}}</code>
                <code style="color:red" *ngIf="i.debugFetch.id != i.id">Error: Incorrect data returned from Traffic System</code>
              </div>
            </li>
          </ul>
        </span>

        <span *ngIf="importErrors.unrequested.length > 0">
          <span>Not requested external ID results</span>
          <ul>
            <li *ngFor="let i of importErrors.unrequested">{{i}}</li>
          </ul>
        </span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex gap-2" style="justify-content: flex-end">
    <button
      *ngIf="page?.data?.length == 0"
      mat-button
      class="scope-ui-modal-button"
      (click)="data.cancelCallback()">Ok
    </button>
    <button
      *ngIf="page?.data?.length > 0"
      mat-button
      class="confirm scope-ui-modal-flat-button"
      (click)="importRateCards()">Import ({{countSelected()}}/{{IMPORT_LIMIT}})
    </button>
  </mat-dialog-actions>
</div>

