import { HttpErrorResponse } from '@angular/common/http';
import { AuthError } from '@app/core/model/definitions/auth-error.interface';
import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../model/user.model';
import { AuthActions } from '@core/store/actions/auth.actions';
import {
  AddPrivilegeRulesSuccessAction,
  AuthenticationFailAction, AuthenticationSuccessAction,
  ForgotPasswordSuccessAction,
  GetChildCompanyAccessRulesSuccessAction,
  GetChildCompanySuccessAction,
  GetUserNotificationsSuccessAction,
  ResetPasswordFailAction,
  SaveCookieSettingsSuccessAction,
} from '../models/auth.action.interface';
import { Company } from '@core/model/company.model';
import { UserNotification } from '@core/model/user-notification.model';
import { AuthRequest } from '@core/model/definitions/auth-request.interface'
import { plainToInstance } from 'class-transformer'

export interface AuthState {
  username?: string;
  user?: User;
  authenticationError: AuthError;
  loading: boolean;
  loaded: boolean;
  forgotSuccess: boolean | null;
  resetPasswordError: HttpErrorResponse | null;
  childCompanies: Company[];
  childCompaniesAccessRules: any[];
  notifications: UserNotification[]
  unreadNotificationsCount: number
}

export const initialState: AuthState = {
  username: undefined,
  user: undefined,
  authenticationError: {
    success: false,
    companyDisabled: false,
    user: null,
    accountDisabled: false,
    emailVerificationNeeded: false,
    blackListed: false,
    errorMessage: '',
    redirectUrl: null,
  },
  loading: false,
  loaded: false,
  forgotSuccess: null,
  resetPasswordError: null,
  childCompanies: [],
  childCompaniesAccessRules: [],
  notifications: [],
  unreadNotificationsCount: 0
};

function loginHandler(state: AuthState, action: { authenticatedUser: AuthRequest | null } | null) {
  return {
    ...state,
    username: action.authenticatedUser.username,
    loading: true,
    loaded: false
  };
}

function authSuccessHandler(state: AuthState, action: AuthenticationSuccessAction) {
  return {
    ...state,
    username: undefined,
    user: action?.user,
    loading: false,
    loaded: true,
  };
}

function authFailHandler(state: AuthState, action: AuthenticationFailAction) {
  return {
    ...state,
    user: undefined,
    authenticationError: action.error,
    loading: false,
    loaded: true,
  };
}

function myAccountHandler(state: AuthState) {
  return {
    ...state,
    loading: true,
    loaded: false,
  };
}

function forgotPasswordSuccessHandler(state: AuthState, action: ForgotPasswordSuccessAction) {
  return {
    ...state,
    forgotSuccess: action?.forgotSuccess,
  };
}

function resetPasswordFailHandler(state: AuthState, action: ResetPasswordFailAction) {
  return {
    ...state,
    resetPasswordError: action.error,
  };
}

function logoutHandler(state: AuthState) {
  return {
    ...state,
  };
}

function logoutSuccessHandler(state: AuthState) {
  return {
    ...state,
    user: undefined
  };
}

function getChildCompaniesHandler(state: AuthState) {
  return {
    ...state
  };
}

function getChildCompaniesSuccessHandler(state: AuthState, action: GetChildCompanySuccessAction) {
  return {
    ...state,
    childCompanies: action.childCompanies
  };
}

function getChildCompaniesFailHandler(state: AuthState) {
  return {
    ...state
  };
}

function getUserNotificationsHandler(state: AuthState) {
  return {
    ...state
  };
}

function getChildCompaniesAccessRulesHandler(state: AuthState) {
  return {
    ...state
  };
}

function getChildCompaniesAccessRulesSuccessHandler(state: AuthState, action: GetChildCompanyAccessRulesSuccessAction) {
  return {
    ...state,
    childCompaniesAccessRules: action.childCompaniesAccessRules
  };
}

function getChildCompaniesAccessRulesFailHandler(state: AuthState) {
  return {
    ...state
  };
}

function getUserNotificationsSuccessHandler(state: AuthState, action: GetUserNotificationsSuccessAction) {
  return {
    ...state,
    notifications: action.notifications,
    unreadNotificationsCount: action.unreadNotificationsCount
  };
}

function getUserNotificationsFailHandler(state: AuthState) {
  return {
    ...state
  };
}

function saveCookieSettingsSuccessHandler(state: AuthState, action: SaveCookieSettingsSuccessAction) {
  return {
    ...state,
    user: plainToInstance(User, { ...state.user, cookieSettings: action.cookieSettings })
  };
}

function addPrivilegeRulesSuccessHandler(state: AuthState, action: AddPrivilegeRulesSuccessAction) {
  return {
    ...state,
    user: plainToInstance(User, { ...state.user, privilegeGroup: action.privilegeGroup })
  };
}

const AuthReducer = createReducer(
  initialState,
  on(AuthActions.login, loginHandler),
  on(AuthActions.loginSuccess, authSuccessHandler),
  on(AuthActions.loginFail, authFailHandler),
  on(AuthActions.myAccount, myAccountHandler),
  on(AuthActions.myAccountSuccess, authSuccessHandler),
  on(AuthActions.myAccountFail, authFailHandler),
  on(AuthActions.logOut, logoutHandler),
  on(AuthActions.logOutSuccess, logoutSuccessHandler),
  on(AuthActions.forgotPasswordSuccess, forgotPasswordSuccessHandler),
  on(AuthActions.resetPasswordFail, resetPasswordFailHandler),
  on(AuthActions.getChildCompanies, getChildCompaniesHandler),
  on(AuthActions.getChildCompaniesSuccess, getChildCompaniesSuccessHandler),
  on(AuthActions.getChildCompaniesFail, getChildCompaniesFailHandler),
  on(AuthActions.getChildCompaniesAccessRules, getChildCompaniesAccessRulesHandler),
  on(AuthActions.getChildCompaniesAccessRulesSuccess, getChildCompaniesAccessRulesSuccessHandler),
  on(AuthActions.getChildCompaniesAccessRulesFail, getChildCompaniesAccessRulesFailHandler),
  on(AuthActions.getUserNotifications, getUserNotificationsHandler),
  on(AuthActions.getUserNotificationsSuccess, getUserNotificationsSuccessHandler),
  on(AuthActions.getUserNotificationsFail, getUserNotificationsFailHandler),
  on(AuthActions.saveCookieSettingsSuccess, saveCookieSettingsSuccessHandler),
  on(AuthActions.addPrivilegeRulesSuccess, addPrivilegeRulesSuccessHandler)
);

export function reducer(state: AuthState | undefined, action: Action): AuthState {
  return AuthReducer(state, action);
}
