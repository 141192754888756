import { Action, createReducer, on } from '@ngrx/store';
import { CompanyManagementState } from '../../models/company-management.state.model';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import {
  CompanyManagementActions
} from '@app/features/company-management/store/actions/company-management.actions';
import { Page } from '@app/features/scoping/models/page.model';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { BootstrapActions } from '@core/store';
import { taskColumnBaseConfig } from '@app/features/scoping/models/scope-columns.model';

export const initialState: CompanyManagementState = {
  outputTemplates: [],
  loadingOutputTemplates: false,
  loadedOutputTemplates: false,
  groupId: undefined,
  tasks: new Page<Deliverable>(Deliverable),
  taskColumns: taskColumnBaseConfig,
  loadingTasks: false,
  loadedTasks: false,
};

function getOutputTemplatesHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingOutputTemplates: true,
    loadedOutputTemplates: false,
  };
}

function getOutputTemplatesSuccessHandler(state: CompanyManagementState, action: { outputTemplates: OutputTemplate[] }) {
  return {
    ...state,
    outputTemplates: action.outputTemplates,
    loadingOutputTemplates: false,
    loadedOutputTemplates: true,
  };
}

function getOutputTemplatesFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingOutputTemplates: false,
    loadedOutputTemplates: true,
    error: action.error,
  };
}

function getTasksHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingTasks: true,
    loadedTasks: false,
  };
}

function getTasksSuccessHandler(state: CompanyManagementState, action: { tasks: Page<Deliverable> }) {
  return {
    ...state,
    tasks: action.tasks,
    loadingTasks: false,
    loadedTasks: true,
  };
}

function getTasksFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingTasks: false,
    loadedTasks: true,
    error: action.error,
  };
}

function setGroupIdHandler(state: CompanyManagementState, { groupId }) {
  return {
    ...state,
    groupId
  };
}

function loadTaskPreferencesSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    taskColumns: {
      ...state.taskColumns,
      ...action.preferences
    }
  }
}

function updateTaskColumnsHandler(state: CompanyManagementState, action: any) {
  const { preference } = action

  return {
    ...state,
    taskColumns: {
      ...state.taskColumns,
      [preference.key]: preference,
    },
  }
}

export const CompanyManagementReducer = createReducer(
  initialState,
  on(CompanyManagementActions.getOutputTemplates, getOutputTemplatesHandler),
  on(CompanyManagementActions.getOutputTemplatesSuccess, getOutputTemplatesSuccessHandler),
  on(CompanyManagementActions.getOutputTemplatesFail, getOutputTemplatesFailHandler),
  on(CompanyManagementActions.getTasks, getTasksHandler),
  on(CompanyManagementActions.getTasksSuccess, getTasksSuccessHandler),
  on(CompanyManagementActions.getTasksFail, getTasksFailHandler),
  on(CompanyManagementActions.setGroupId, setGroupIdHandler),
  on(BootstrapActions.loadTaskPreferencesSuccess, loadTaskPreferencesSuccessHandler),
  on(BootstrapActions.updateTaskColumnPreferences, updateTaskColumnsHandler),
);

export function reducer(state: CompanyManagementState | undefined, action: Action): CompanyManagementState {
  return CompanyManagementReducer(state, action);
}
