import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DefaultView, ScopeVersion, StatusType } from '@core/model/scope-version'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service'
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import { MenuOptions } from '@core/model/definitions/menu-options.interface'
import { MatSidenav } from '@angular/material/sidenav'
import { Preference } from '@core/model/user-preferences.interface'
import { BootstrapActions } from '@core/store'
import { select, Store } from '@ngrx/store'
import { DeliverablePreferences } from '@app/features/scoping/models/scope-columns.model'
import {
  ScopeUiFilterModalComponent,
} from '@shared/components/ui-components/scope-ui-filter-modal/scope-ui-filter-modal.component'
import {
  ComponentsFilterValue,
  DateFilterValue,
  FilterOption,
  FilterOptions,
  GenericFilterValue,
  RangeFilterValue,
  TypedFilterValue,
} from '@core/model/filter-option.interface'
import { instanceToInstance, plainToInstance } from 'class-transformer'
import { MatDialog } from '@angular/material/dialog'
import { LanguageService } from '@core/service/language.service'
import { untilDestroyed } from '@shared/utils/utils'
import {combineLatest, map, Observable, of, take} from 'rxjs'
import { ScopeOverviewFilter, ScopeOverviewFilterDefaults } from '@core/model/scope-overview-filter.model'
import {cloneDeep, isEqual} from 'lodash'
import { Department } from '@app/features/scoping/models/department.model'
import { User } from '@core/model/user.model'
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model'
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { Role } from '@app/features/scoping/models/role.model'
import { ScopeComponent } from '@app/features/scoping/models/component.model'
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action'
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector'
import { WebsocketService } from '@shared/services/websocket.service'
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service'
import { Router } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ScopeUiInputComponent } from '@app/shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { SharedModule } from '@app/shared/shared.module'
import {
  ScopeUiOptionsMenuComponent,
} from '@app/shared/components/scope-ui-options-menu/scope-ui-options-menu.component'
import { FindReplaceComponent } from '@app/shared/components/find-replace/find-replace.component'
import { ToggleListComponent } from '@app/shared/components/ui-components/toggle-list/toggle-list.component'
import { DeliverableRowComponent } from './deliverable-row/deliverable-row.component'
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component'
import { ScopeTabService } from '../../service/scope-tab.service'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { UserRoleLevel } from '@core/model/enums/user-role-level.enum'
import { Privilege } from '@core/model/enums/privilege.enum'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { FindRolesSearchParams } from '@core/model/definitions/findRoleSearchParams.interface'
import { ScopeActions } from '@app/features/scoping/store/actions/scoping.actions'
import { ScopeRetainedHoursStats } from '@app/features/scope-overview/model/scope-retained-hours-stats.model';
import { ScopeUiTabComponent } from '@shared/components/ui-components/scope-ui-tab/scope-ui-tab.component'
import { ScopeUiTabsComponent } from '@shared/components/ui-components/scope-ui-tabs/scope-ui-tabs.component'
import {
  ScopeTimelineComponent
} from '@app/features/scope-overview/components/scope-tab/scope-timeline/scope-timeline.component'
import {
  SectionRowComponent
} from '@app/features/scope-overview/components/scope-tab/section-row/section-row.component'
import {
  ScopeTableComponent
} from '@app/features/scope-overview/components/scope-tab/scope-table/scope-table.component'
import { DeliverableSection } from '@app/features/scope-overview/model/deliverable-section'
import {
  ScopeUiFinancialCardComponent
} from '@shared/components/ui-components/scope-ui-financial-card/scope-ui-financial-card.component'
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { CdnConfig } from '@core/model/cdn-config.model';

@Component({
  selector: 'scope-tab',
  imports: [
    CommonModule,
    ScopeUiInputComponent,
    SharedModule,
    ScopeUiOptionsMenuComponent,
    FindReplaceComponent,
    ToggleListComponent,
    DeliverableRowComponent,
    ScopeUiTableComponent,
    ScopeUiTabComponent,
    ScopeUiTabsComponent,
    ScopeTimelineComponent,
    SectionRowComponent,
    ScopeTableComponent,
    ScopeUiFinancialCardComponent,
  ],
  templateUrl: './scope-tab.component.html',
  styleUrls: ['./scope-tab.component.scss'],
  standalone: true,
})
export class ScopeTabComponent implements OnInit, AfterViewInit {
  private readonly destroy$

  @Input() scopeVersion!: ScopeVersion;

  @Input() currentUser!: User;

  @Input() availableRoles!: any[];

  @Input() breadcrumbs!: any[];

  @Input() isLoading!: boolean | null;

  @Input() deliverablesCount!: number;

  @Input() filteredRoles: any;

  @Input() revertSummaryResponse: any;

  @Input() replaceSummarySuccess: boolean | any;

  @Input() replaceSummaryResponse: any;

  @Input() revertSummarySuccess: boolean | any;

  @Input() availableDeliverables: any;

  @Input() availableComponents: any;

  @Input() availableDepartments: any;

  @Input() showUseRetainedTeamToggle!: boolean;

  @Input() scopeRetainedHoursStats?: ScopeRetainedHoursStats;

  @Input() filters$!: Observable<ScopeOverviewFilter>;

  @Input() searchedComponents$!: Observable<TypedFilterValue[]>;

  @Input() searchedDepartments$!: Observable<GenericFilterValue[]>;

  @Input() searchedRoles$!: Observable<GenericFilterValue[]>;

  @Input() set showFilterView(value: boolean) {
    this._showFilterView = value;
  }

  @Input() filteredSections$!: Observable<ScopeSection[]>;

  @Input() filteredDeliverables$!: Observable<Deliverable[]>;

  @Input() filteredComponents$!: Observable<ScopeComponent[]>;

  @Input() filteredDepartments$!: Observable<Department[]>;

  @Input() filteredRoles$!: Observable<Role[]>;

  @Input() filteredScopeFees$!: Observable<any[]>;

  @Input() filteredDeliverableFees$!: Observable<any[]>;

  @Input() filteredComponentFees$!: Observable<any[]>;

  @Input() filteredDeliverableTPCs$!: Observable<ThirdPartyCost[]>;

  @Input() filteredDeliverableSectionTPCs$!: Observable<ThirdPartyCost[]>;

  @Input() filteredComponentTPCs$!: Observable<ThirdPartyCost[]>;

  @Input() currentRatecard: RatecardVersion

  @Input() cdnConfig?: CdnConfig

  @Output() onSearchComponents: EventEmitter<string>;

  @Output() onSearchDepartments: EventEmitter<string>;

  @Output() onSearchRoles: EventEmitter<string>;

  @Output() onFilterScope: EventEmitter<ScopeOverviewFilter>;

  @Output() onAcceptDeliverable: EventEmitter<Deliverable>;

  @Output() onRejectDeliverable: EventEmitter<Deliverable>;

  @Output() onCompleteTrade!: EventEmitter<Deliverable>

  @Output() onTradeDeliverable!: EventEmitter<Deliverable>

  @Output() onStopProgressTrade!: EventEmitter<Deliverable>

  @Output() onAddDeliverableToTrade!: EventEmitter<Deliverable>

  @Output() onConfirmTrade!: EventEmitter<Deliverable>

  @Output() onCancelTrade!: EventEmitter<Deliverable>

  @Output() onReopenTrade!: EventEmitter<Deliverable>

  @Output() onRefreshActuals!: EventEmitter<any>

  scopeActions!: MenuOptions[];
  feeMenuOptions!: MenuOptions[];
  sectionMenuOptions!: MenuOptions[];
  deliverableMenuOptions!: MenuOptions[];
  componentMenuOptions!: MenuOptions[];

  scopeOptions!: any[];

  setReplace: boolean
  _showFilterView: boolean
  showFinancials: boolean = true

  @ViewChild('sidenav') sideNav!: MatSidenav;

  @Output() onAddScopeSection: EventEmitter<any>;

  @Output() onArchiveScope: EventEmitter<any>;

  @Output() onUnarchiveScope: EventEmitter<any>;

  @Output() onCreateDeliverable: EventEmitter<any>;

  @Output() onCreateFixedFee: EventEmitter<any>;

  @Output() onFetchDeliverable: EventEmitter<number>;

  @Output() onUpdateDeliverable: EventEmitter<Deliverable>;

  @Output() onUpdateComponent: EventEmitter<ScopeComponent>;

  @Output() onMoveComponent: EventEmitter<any>;

  @Output() onMoveComponentOutsideDeliverable: EventEmitter<ScopeComponent>;

  @Output() onDeleteScope: EventEmitter<any>;

  @Output() onDeleteScopeSection: EventEmitter<any>;

  @Output() onDuplicateDeliverable!: EventEmitter<any>

  @Output() onDeleteDeliverable!: EventEmitter<any>

  @Output() onDeleteFee!: EventEmitter<any>;

  @Output() onSearch = new EventEmitter<FindRolesSearchParams>();

  @Output() onSelectionChange = new EventEmitter<any>();

  @Output() onRevert = new EventEmitter<any>();

  @Output() onSearchReplaceRoleEvent = new EventEmitter<string>();

  @Output() onSubmitFindReplace = new EventEmitter<any>();

  @Output() onAddFee = new EventEmitter<any>();

  @Output() onAddAndLinkFee = new EventEmitter<any>();

  @Output() onEditFee = new EventEmitter<any>();

  @Output() onUpdateFee = new EventEmitter<any>();

  @Output() onOverrideQuantity = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>

  @Output() onOverrideRateCardRoleHours = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent, role: Role }>

  @Output() onShowDeliverableApplyLocationCardDialog = new EventEmitter<{ deliverable: Deliverable, rateCard: RatecardVersion }>();

  @Output() onAddDiscount = new EventEmitter<any>();

  @Output() onDeleteDiscount = new EventEmitter<any>();

  @Output() onEditMsaLineItem = new EventEmitter<any>();

  @Output() onDeleteMsaLineItem = new EventEmitter<any>();

  @Input() public set filterPreferences(value: DeliverablePreferences | null) {
    if (value && this.scopeTabService.userColumns) {
      Object.entries(value).forEach(([key, value]) => (this.scopeTabService.userColumns[key].selected = value.selected));
      this.scopeTabService.updateFilterTableColumns();
      this.scopeTabService.unselectExcessColumns();
      this.mainColumnClass = this.scopeTabService.calculateMainColumnClass();
      this.componentMainColumnClass = this.scopeTabService.calculateComponentMainColumnClass();
    }
  }

  scopeFilterConfig: { [key: string]: string };

  filters!: ScopeOverviewFilter;

  disciplines?: any[];

  filteredSectionsDataSource$!: Observable<MappedEntity<ScopeSection>[]>;

  filteredDeliverablesDataSource$!: Observable<MappedEntity<Deliverable>[]>;

  filteredComponentsDataSource$!: Observable<MappedEntity<ScopeComponent>[]>;

  filteredDepartmentsDataSource$!: Observable<MappedEntity<Department>[]>;

  filteredRolesDataSource$!: Observable<MappedEntity<Role>[]>;

  filteredFeesDataSource$!: Observable<MappedEntity<any>[]>;

  filteredTPCsDataSource$!: Observable<MappedEntity<any>[]>;

  editDeliverableAction: any;

  filteredSectionsCount: number = 0;

  filteredDeliverablesCount: number = 0;

  filteredComponentsCount: number = 0;

  filteredDepartmentsCount: number = 0;

  filteredRolesCount: number = 0;

  filteredFeesCount: number = 0;

  filteredTPCsCount: number = 0;

  mainColumnClass: string;

  componentMainColumnClass: string;

  sortedSections$: Observable<ScopeSection[]>;

  sortedDefaultSectionDeliverables$: Observable<Deliverable[]>;

  currentTab: string = 'Table'

  showTimelineFees: boolean = false

  allFeesLoaded: boolean = false

  showTimelineTpcs: boolean = false

  allTpcsLoaded: boolean = false

  scopeFees$: Observable<MappedEntity<FeeItemInstance>[]>

  allFees$: Observable<MappedEntity<FeeItemInstance>[]>

  allThirdPartyCosts$: Observable<MappedEntity<ThirdPartyCost>[]>

  constructor(
    private scopeOverviewService: ScopeOverviewService,
    private store: Store,
    private lang: LanguageService,
    public dialog: MatDialog,
    private webSocketService: WebsocketService,
    public mappingService: DataMappingService,
    public scopeApprovalFlowService: ApprovalFlowService,
    public scopeTabService: ScopeTabService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private snackbarService: SnackbarService
  ) {
    this.onCompleteTrade = new EventEmitter<Deliverable>()
    this.onTradeDeliverable = new EventEmitter<Deliverable>()
    this.onStopProgressTrade = new EventEmitter<Deliverable>()
    this.onAddDeliverableToTrade = new EventEmitter<Deliverable>()
    this.onConfirmTrade = new EventEmitter<Deliverable>()
    this.onCancelTrade = new EventEmitter<Deliverable>()
    this.onReopenTrade = new EventEmitter<Deliverable>()
    this.onRefreshActuals = new EventEmitter<any>()
    this.onDuplicateDeliverable = new EventEmitter<any>()
    this.onFetchDeliverable = new EventEmitter<number>();
    this.onUpdateDeliverable = new EventEmitter<Deliverable>();
    this.onUpdateComponent = new EventEmitter<ScopeComponent>();
    this.onMoveComponent = new EventEmitter<any>();
    this.onMoveComponentOutsideDeliverable = new EventEmitter<ScopeComponent>();
    this.onAcceptDeliverable = new EventEmitter<Deliverable>();
    this.onRejectDeliverable = new EventEmitter<Deliverable>();
    this.onAddScopeSection = new EventEmitter<any>();
    this.onArchiveScope = new EventEmitter<any>();
    this.onUnarchiveScope = new EventEmitter<any>();
    this.onCreateDeliverable = new EventEmitter<any>();
    this.onCreateFixedFee = new EventEmitter<any>();
    this.onDeleteScope = new EventEmitter<any>();
    this.onDeleteScopeSection = new EventEmitter<any>();
    this.onDeleteDeliverable = new EventEmitter<any>();
    this.onDeleteFee = new EventEmitter<any>();
    this.setReplace = false;
    this.destroy$ = untilDestroyed();
    this.onSearchComponents = new EventEmitter<string>();
    this.onSearchDepartments = new EventEmitter<string>();
    this.onSearchRoles = new EventEmitter<string>();
    this.onFilterScope = new EventEmitter<ScopeOverviewFilter>();
    this.scopeFilterConfig = {
      teamMembers: `${lang.get('team')} ${lang.get('team_member')}`,
      comments: 'Comments',
      date: 'Date',
      disciplines: lang.get('discipline'),
      deliverables: lang.get('deliverable'),
      stages: 'Stage',
      components: lang.get('component'),
      departments: 'Department',
      roles: 'Role',
      thirdPartyCost: lang.get('third_party_cost'),
      budget: 'Budget',
      margin: 'Margin',
      markUp: 'Markup',
      profit: 'Profit',
      agencyPrice: `${lang.get('agency')} price`,
    };
  }

  ngOnInit() {
    this.scopeTabService.setUserColumns(this.scopeVersion);
    this.scopeTabService.setFeeColumns();
    this.setMenu();
    this.getScopeFilters();
    this.disciplines = this.scopeVersion.deliverables
      .map((deliverable) => deliverable.discipline)
      .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
    this.mapFilteredSectionsToDataSource();
    this.mapFilteredDeliverablesToDataSource();
    this.mapFilteredComponentsToDataSource();
    this.mapFilteredDepartmentsToDataSource();
    this.mapFilteredRolesToDataSource();
    this.mapFilteredFeesToDataSource();
    this.mapFilteredTPCsToDataSource();

    this.editDeliverableAction = {
      clickAction: (deliverable: Deliverable) => {
        this.editDeliverable(deliverable.id);
      },
      showOptionFunc: () => {
        return this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion)
      },
      buttonType: 'miniFab',
    };

    this.sortedSections$ = this.store.pipe(
      select(ScopeOverviewSelectors.selectCurrentScope),
      map((currentScope) =>  [...currentScope.sections]
        .sort((a, b) => a.order - b.order)
        .map((section) =>
          plainToInstance(ScopeSection, { ...section, deliverables: currentScope.deliverables
              .filter((deliverable: Deliverable) => deliverable.section?.id === section.id)
              .sort((a, b) => a.order - b.order) })
        )
      )
    );

    this.sortedDefaultSectionDeliverables$ = this.store.pipe(
      select(ScopeOverviewSelectors.selectCurrentScope),
      map((currentScope) =>
        currentScope.deliverables
          .filter((deliverable: Deliverable) => !deliverable.section)
          .sort((a, b) => a.order - b.order)
      )
    );

    this.scopeFees$ = this.store.pipe(select(ScopeOverviewSelectors.selectScopeFees),
      map((feeItems) =>
        this.mappingService.transformArray<FeeItemInstance>(feeItems, this.scopeTabService.scopeFeeColumns)
      )
    );

    this.allFees$ = this.store.pipe(select(ScopeOverviewSelectors.selectAllFees),
      map((feeItems) =>
        this.mappingService.transformArray<FeeItemInstance>(feeItems, this.scopeTabService.allFeeColumns)
      )
    );

    this.store.select(ScopeOverviewSelectors.selectAllFeesLoaded)
      .pipe(this.destroy$())
      .subscribe((loaded: boolean) => {
        this.allFeesLoaded = loaded;
        this.cdr.detectChanges();
      });

    this.allThirdPartyCosts$ = this.store.pipe(select(ScopeOverviewSelectors.selectAllThirdPartyCosts),
      map((thirdPartyCosts) =>
        this.mappingService.transformArray<ThirdPartyCost>(thirdPartyCosts, this.scopeTabService.thirdPartyCostColumns)
      )
    );

    this.store.select(ScopeOverviewSelectors.selectAllThirdPartyCostsLoaded)
      .pipe(this.destroy$())
      .subscribe((loaded: boolean) => {
        this.allTpcsLoaded = loaded;
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.currentTab = this.scopeVersion.defaultView === DefaultView.timeline &&
      this.currentUser.company.hasApplicationSetting('TIMELINE_VIEW__ENABLE') ? 'Timeline' : 'Table'
  }

  completeTrade(deliverable: Deliverable) {
    this.onCompleteTrade.emit(deliverable)
  }

  tradeDeliverable(deliverable: Deliverable) {
    this.onTradeDeliverable.emit(deliverable)
  }

  stopProgressTrade(deliverable: Deliverable) {
    this.onStopProgressTrade.emit(deliverable)
  }

  addDeliverableToTrade(deliverable: Deliverable) {
    this.onAddDeliverableToTrade.emit(deliverable)
  }

  confirmTrade(deliverable: Deliverable) {
    this.onConfirmTrade.emit(deliverable)
  }

  cancelTrade(deliverable: Deliverable) {
    this.onCancelTrade.emit(deliverable)
  }

  reopenTrade(deliverable: Deliverable) {
    this.onReopenTrade.emit(deliverable)
  }

  refreshActuals(event: any) {
    this.onRefreshActuals.emit(event)
  }

  acceptDeliverable(deliverable: Deliverable) {
    this.onAcceptDeliverable.emit(deliverable);
  }

  rejectDeliverable(deliverable: Deliverable) {
    this.onRejectDeliverable.emit(deliverable);
  }

  onToggleScopePreference(preference: Preference) {
    this.store.dispatch(BootstrapActions.updateScopeOverviewColumnPreferences({ updatedPrefs: preference }));
  }

  onSearchKeyPressed(event: any) {
    this.filters = {
      ...this.filters,
      name: event,
    };
    this.onFilterScope.emit(this.filters);
  }

  submitFindReplace(event: any) {
    this.onSubmitFindReplace.emit(event);
  }

  onSearchReplaceRole(event: string) {
    this.onSearchReplaceRoleEvent.emit(event);
  }

  onRevertEvent(event: any) {
    this.onRevert.emit(event);
  }

  onSelectionChangeEvent(event: any) {
    this.onSelectionChange.emit(event);
  }

  onSearchEvent(event: FindRolesSearchParams) {
    this.onSearch.emit(event);
  }

  deleteSection(section: ScopeSection) {
    this.onDeleteScopeSection.emit(section);
  }

  addSectionFee(section: ScopeSection) {
    this.onAddFee.emit(section);
  }

  getScopeFilters() {
    this.filters$.pipe(this.destroy$()).subscribe((filters: ScopeOverviewFilter) => {
      this.filters = filters;
    });
  }

  fetchDeliverable(deliverableId: number) {
    this.onFetchDeliverable.emit(deliverableId);
  }

  updateDeliverable(deliverable: Deliverable) {
    this.onUpdateDeliverable.emit(deliverable);
  }

  updateComponent(component: ScopeComponent) {
    this.onUpdateComponent.emit(component);
  }

  moveComponent(event: any) {
    this.onMoveComponent.emit(event);
  }

  moveComponentOutsideDeliverable(component: ScopeComponent) {
    this.onMoveComponentOutsideDeliverable.emit(component);
  }

  duplicateDeliverable(deliverable: Deliverable) {
    this.onDuplicateDeliverable.emit(deliverable)
  }

  deleteDeliverable(deliverable: Deliverable) {
    this.onDeleteDeliverable.emit(deliverable);
  }

  addDeliverableFee(deliverable: Deliverable) {
    this.onAddFee.emit(deliverable);
  }

  addComponentFee(component: ScopeComponent) {
    this.onAddFee.emit(component);
  }

  openFindReplaceDrawer() {
    this.setReplace = true;
    this.sideNav.open();
  }

  closeSideNav() {
    this.setReplace = false;
    this.sideNav.close();
    this.store.dispatch(ScopeActions.closeFindReplace())
  }

  get deliverableDrops(){
    let str: Array<string> = [];
    this.scopeVersion.deliverables.forEach((s) => {
      str.push('deliverbale-' + s.id);
    });
    return str;
  }

  updateScopeDeliverableOrder(data: Deliverable[], sectionId?: number) {
    data.forEach((d, i) => d.order = i)
    this.store.dispatch(ScopeOverviewActions.updateDeliverablesOrder( { scopeId: this.scopeVersion.identity.id, sectionId: sectionId, deliverables: data } ))
  }

  updateElement(element: ScopeSection | Deliverable | ScopeComponent| DeliverableSection) {
    if (element instanceof ScopeSection) {
      this.store.dispatch(
        ScopeOverviewActions.updateScopeSection({ scopeId: this.scopeVersion.identity.id, section: element })
      )
    } else if (element instanceof Deliverable) {
      this.onUpdateDeliverable.emit(element)
    } else if (element instanceof DeliverableSection) {
      this.store.dispatch(
        ScopeOverviewActions.updateDeliverableSection({ scopeId: this.scopeVersion.identity.id, section: element })
      )
    } else {
      this.store.dispatch(
        ScopeOverviewActions.updateScopeComponent({ scopeId: this.scopeVersion.identity.id, deliverableId: element.deliverable.id, component: element, deliverable: null })
      )
    }
  }

  editDeliverable(deliverableId: number) {
    this.router.navigateByUrl(`/scopes/scope-overview/${this.scopeVersion.identity.id}/deliverable/${deliverableId}`);
  }

  showDeliverableApplyLocationCardDialog(deliverable: Deliverable) {
    this.onShowDeliverableApplyLocationCardDialog.emit({ deliverable, rateCard: this.currentRatecard })
  }

  editFeeItem(feeItem: any, sectionId?: number, deliverableId?: number, componentId?: number) {
    this.onEditFee.emit({
      feeItem: feeItem,
      sectionId: sectionId,
      deliverableId: deliverableId,
      componentId: componentId,
    });
  }

  getDiscountValue(scopeVersion) {
    return scopeVersion.discount.getType() == 'PERCENTAGE' ? scopeVersion.discount.getValueByPercentage(scopeVersion.getTotalSellingPrice({
      excludeFees: false,
      excludeMsa: true,
      excludeDiscount: true
    })?.getValue()) : scopeVersion.discount.getValueFormatted()
  }

  deleteFeeItem(feeItem: FeeItemInstance, sectionId?: number, deliverableId?: number, componentId?: number) {
    this.onDeleteFee.emit({
      feeItem: feeItem,
      sectionId: sectionId,
      deliverableId: deliverableId,
      componentId: componentId,
    });
  }

  updateFeeItem(feeItem: FeeItemInstance, section?: ScopeSection, deliverable?: Deliverable, component?: ScopeComponent) {
    let sectionId = section?.id || feeItem.scopeSection?.id;
    let deliverableId = deliverable?.id || feeItem.deliverable?.id;
    let componentId = component?.id || feeItem.component?.id;
    this.onUpdateFee.emit({
      feeItem: feeItem,
      sectionId: sectionId,
      deliverableId: deliverableId,
      componentId: componentId,
    });
  }

  refreshActualsAvailable() {
    const trafficSystem = this.currentUser.company?.trafficSystemSettings?.trafficSystem;
    if (!trafficSystem?.externalTrafficSystemConfig) return false;

    const hasAccess = this.scopeVersion &&
      (this.scopeVersion.identity.team.hasUser(this.currentUser.id) ||
        this.currentUser.hasAccessRole(UserRoleLevel.SYSTEM) ||
        this.currentUser.hasAccessRole(UserRoleLevel.ADMIN)) &&
      this.currentUser.company.hasApplicationSetting('SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS') &&
      this.scopeVersion.traffickable;

    if (!hasAccess) return false;

    const timesheetType = trafficSystem.externalTrafficSystemConfig?.scopeTimeSheet?.type;
    return timesheetType === 'URL' || (timesheetType === 'SOW_UPLOAD' && this.scopeVersion.scopeOfWorkVersion);
  }


  overrideQuantity(event: { deliverable: Deliverable, component: ScopeComponent }) {
    this.onOverrideQuantity.emit(event)
  }

  overrideRateCardRoleHours(event: { deliverable: Deliverable, component: ScopeComponent, role: Role }) {
    this.onOverrideRateCardRoleHours.emit(event)
  }

  setMenu() {
    this.scopeActions = [
      {
        name: () => `${this.lang.get('deliverable')}`,
        icon: () => 'description',
        callback: () => {
          this.onCreateDeliverable.emit()
        },
      },
      {
        name: () => 'Fixed Fee',
        icon: () => 'request_page',
        callback: () => this.onCreateFixedFee.emit(),
      },
      {
        name: () => `${this.lang.get('stage')}`,
        icon: () => 'flag',
        callback: () => this.onAddScopeSection.emit(),
      },
    ];

    this.scopeOptions = [
      {
        name: 'Find and Replace Staff',
        icon: 'compare_arrows',
        click: () => this.openFindReplaceDrawer(),
        isVisible: () => {
          return this.scopeVersion.identity.identificationType === 'SCOPE_BY_WORK' &&
            this.scopeVersion.identity.team.findCollaboratorByUserId(this.currentUser.id) &&
            this.scopeVersion.status == StatusType.DRAFT
        }
      },
      {
        name: 'Archive',
        icon: 'archive',
        click: () => this.onArchiveScope.emit(),
        isVisible: () => {
          return !this.scopeVersion.archived && this.scopeVersion.hasPrivilege(Privilege.SCOPE__ARCHIVE, this.currentUser)
            && (this.scopeApprovalFlowService.findMember(this.currentUser) || this.currentUser.hasAccessRole(UserRoleLevel.ADMIN))
        }
      },
      {
        name: 'Unarchive',
        icon: 'unarchive',
        click: () => this.onUnarchiveScope.emit(),
        isVisible: () => {
          return this.scopeVersion.archived && this.scopeVersion.hasPrivilege(Privilege.SCOPE__ARCHIVE, this.currentUser)
            && (this.scopeApprovalFlowService.findMember(this.currentUser) || this.currentUser.hasAccessRole(UserRoleLevel.ADMIN))
        }
      },
      {
        name: `Delete ${this.scopeVersion.identity.isTemplate ? 'Template' : 'Scope'}`,
        icon: 'delete',
        click: () => this.onDeleteScope.emit(),
        isVisible: () => {
          return this.scopeApprovalFlowService.stateChecks.isDraft
            && !this.scopeVersion.scopeVersionCustomFieldValueStructure?.getCustomFieldValueById("isEngagementScopeSold")?.value
            && this.scopeVersion.hasPrivilege(this.scopeVersion.identity.isTemplate ? Privilege.SCOPE_TEMPLATE__DELETE : Privilege.SCOPE__DELETE, this.currentUser)
            && (this.scopeApprovalFlowService.findMember(this.currentUser) || this.currentUser.hasAccessRole(UserRoleLevel.ADMIN))
        }
      },
      {
        name: 'Mark as template',
        icon: 'copy_all',
        click: () => this.router.navigateByUrl('/duplicate-template/' + this.scopeVersion.identity.id),
        isVisible: () => {
          return this.scopeVersion.identity.identificationType === 'SCOPE_BY_WORK'
            && (this.scopeApprovalFlowService.stateChecks.isDraft || this.scopeApprovalFlowService.isStateClientApproval(this.scopeVersion.status) || this.scopeApprovalFlowService.isStateClosed(this.scopeVersion.status))
            && this.scopeVersion.deliverables.length > 0
            && !this.scopeVersion.identity.isTemplate && this.currentUser.hasPrivilege('SCOPE_TEMPLATE__CREATE')
        }
      },
      {
        name: 'Use template',
        icon: 'assignment',
        click: () => this.router.navigateByUrl('/use-template/' + this.scopeVersion.identity.id),
        isVisible: () => {
          return this.scopeVersion.identity.isTemplate && this.scopeVersion.identity.identificationType === 'SCOPE_BY_WORK'
        }
      },
      {
        name: 'Refresh Actuals',
        icon: 'refresh',
        click: () => this.refreshActuals(''),
        isVisible: () => {
          return this.scopeVersion.identity.identificationType === 'SCOPE_BY_WORK' && this.refreshActualsAvailable()
        }
      },
    ];

    this.feeMenuOptions = []
    if (this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion) && this.currentUser.hasPrivilege('SCOPE__DELETE_FEES')) {
      this.feeMenuOptions.push({
        callback: (element: any) => this.deleteFeeItem(element.entity, element.entity.scopeSection?.id, element.entity.deliverable?.id, element.entity.component?.id),
        name: () => 'Delete',
        icon: () => 'delete'
      })
    }
    if (this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion) && this.currentUser.hasPrivilege('SCOPE__DELETE_FEES')) {
      this.feeMenuOptions.push({
        callback: (element: any) => this.editFeeItem(element.entity, element.entity.scopeSection?.id, element.entity.deliverable?.id, element.entity.component?.id),
        name: () => 'Edit',
        icon: () => 'edit'
      })
    }

    this.sectionMenuOptions = [{
      hasPrivilege: () => 'SCOPE__CREATE_FEES',
      callback: (element) => this.addSectionFee(element.entity),
      icon: () => 'attach_money',
      name: () => 'Add fee'
    }, {
      callback: (element) => this.deleteSection(element.entity),
      icon: () => 'delete',
      name: () => `Delete`
    }]

    this.deliverableMenuOptions = [{
      callback: (element) => this.duplicateDeliverable(element.entity),
      icon: () => 'content_copy',
      name: () => 'Duplicate'
    }, {
      callback: (element) => this.deleteDeliverable(element.entity),
      icon: () => 'delete',
      name: () => `Delete`
    }, {
      hasPrivilege: () => 'SCOPE__CREATE_FEES',
      callback: (element) => this.addDeliverableFee(element.entity),
      icon: () => 'attach_money',
      name: () => `Add fee`
    }]

    this.componentMenuOptions = [{
      hasPrivilege: () => 'SCOPE__CREATE_FEES',
      callback: (element) => this.addComponentFee(element.entity),
      icon: () => 'attach_money',
      name: () => `Add fee`
    }]
  }

  addScopeFee() {
    this.onAddFee.emit();
  }

  toggleTimelineFees(event: boolean) {
    this.showTimelineTpcs = false
    this.showTimelineFees = event

    if (!this.allFeesLoaded) {
      this.store.dispatch(ScopeOverviewActions.getDeliverableAndComponentFees({ scopeId: this.scopeVersion.identity.id, versionId: this.scopeVersion.version }))
      this.store.dispatch(
        ScopeOverviewActions.searchNonDistinctComponents({
          scopeId: this.scopeVersion.identity.id,
          versionId: this.scopeVersion.version,
          searchText: '',
        })
      )
    }
  }

  toggleTimelineTpcs(event: boolean) {
    this.showTimelineFees = false
    this.showTimelineTpcs = event

    if (!this.allTpcsLoaded) {
      this.store.dispatch(ScopeOverviewActions.getAllDeliverableTPCs({ scopeId: this.scopeVersion.identity.id, versionId: this.scopeVersion.version }))
    }
  }

  addFee() {
    this.onAddAndLinkFee.emit();
  }

  addDiscount() {
    this.onAddDiscount.emit()
  }

  editDiscount() {
    this.onAddDiscount.emit()
  }

  deleteDiscount() {
    this.onDeleteDiscount.emit()
  }

  editMsaLineItem() {
    this.onEditMsaLineItem.emit()
  }

  deleteMsaLineItem() {
    this.onDeleteMsaLineItem.emit()
  }

  resetFilters() {
    this.filters = ScopeOverviewFilterDefaults;
    this.onFilterScope.emit(this.filters);
  }

  onSelectFilters() {
    const filterModal = this.dialog.open(ScopeUiFilterModalComponent, {
      width: '328px',
      position: { right: '40px' },
      data: {
        options: {
          teamMembers: new FilterOption<GenericFilterValue[]>(
            this.scopeFilterConfig['teamMembers'],
            'genericSearch',
            undefined,
            this.scopeVersion.identity.team.collaborators.map((member) =>
              plainToInstance(GenericFilterValue, { id: member.user.id, name: member.user.getFullname() })
            ),
            undefined,
            undefined,
            instanceToInstance(this.filters.teamMembers)
          ),
          comments: new FilterOption<string>(
            this.scopeFilterConfig['comments'],
            'radio',
            undefined,
            [
              { name: 'All', value: 'ALL' },
              { name: 'Yes', value: 'YES' },
              { name: 'No', value: 'NO' },
            ],
            undefined,
            undefined,
            this.filters.comments
          ),
          date: new FilterOption<DateFilterValue>(
            this.scopeFilterConfig['date'],
            'date',
            undefined,
            [
              { name: 'Created', value: 'CREATED' },
              { name: 'Edited', value: 'EDITED' },
            ],
            undefined,
            undefined,
            instanceToInstance(this.filters.date)
          ),
          disciplines: new FilterOption<GenericFilterValue[]>(
            this.scopeFilterConfig['disciplines'],
            'genericSearch',
            undefined,
            this.disciplines?.map((discipline) =>
              plainToInstance(GenericFilterValue, { id: discipline.id, name: discipline.name })
            ),
            undefined,
            undefined,
            instanceToInstance(this.filters.disciplines)
          ),
          deliverables: new FilterOption<GenericFilterValue[]>(
            this.scopeFilterConfig['deliverables'],
            'genericSearch',
            undefined,
            this.scopeVersion.deliverables.map((deliverable) =>
              plainToInstance(TypedFilterValue, {
                id: deliverable.source.id,
                name: deliverable.name,
                type: deliverable.sourceType,
                originalName: deliverable.source.name,
              })
            ).filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.name === value.name && t.type === value.type && t.id === value.id)
              ),
            undefined,
            undefined,
            instanceToInstance(this.filters.deliverables)
          ),
          stages: new FilterOption<GenericFilterValue[]>(
            this.scopeFilterConfig['stages'],
            'genericSearch',
            undefined,
            this.scopeVersion.sections.map((section) =>
              plainToInstance(GenericFilterValue, { id: section.id, name: section.name })
            ),
            undefined,
            undefined,
            instanceToInstance(this.filters.stages)
          ),
          components: new FilterOption<ComponentsFilterValue>(
            this.scopeFilterConfig['components'],
            'componentSearch',
            undefined,
            undefined,
            this.searchedComponents$,
            undefined,
            instanceToInstance(this.filters.components),
            (event: { searchString: string }) => {
              this.onSearchComponents.emit(event.searchString);
            }
          ),
          departments: new FilterOption<GenericFilterValue[]>(
            this.scopeFilterConfig['departments'],
            'genericSearch',
            undefined,
            undefined,
            this.searchedDepartments$,
            undefined,
            instanceToInstance(this.filters.departments),
            (event: { searchString: string }) => {
              this.onSearchDepartments.emit(event.searchString);
            }
          ),
          roles: new FilterOption<GenericFilterValue[]>(
            this.scopeFilterConfig['roles'],
            'genericSearch',
            undefined,
            undefined,
            this.searchedRoles$,
            undefined,
            instanceToInstance(this.filters.roles),
            (event: { searchString: string }) => {
              this.onSearchRoles.emit(event.searchString);
            }
          ),
          thirdPartyCost: new FilterOption<RangeFilterValue>(
            this.scopeFilterConfig['thirdPartyCost'],
            'range',
            undefined,
            undefined,
            undefined,
            undefined,
            instanceToInstance(this.filters.thirdPartyCost),
            undefined,
            0
          ),
          budget: new FilterOption<RangeFilterValue>(
            this.scopeFilterConfig['budget'],
            'range',
            undefined,
            undefined,
            undefined,
            undefined,
            instanceToInstance(this.filters.budget),
            undefined,
            0
          ),
          margin: new FilterOption<RangeFilterValue>(
            this.scopeFilterConfig['margin'],
            'range',
            undefined,
            undefined,
            undefined,
            undefined,
            instanceToInstance(this.filters.margin),
            undefined,
            0
          ),
          markUp: new FilterOption<RangeFilterValue>(
            this.scopeFilterConfig['markUp'],
            'range',
            undefined,
            undefined,
            undefined,
            undefined,
            instanceToInstance(this.filters.markUp),
            undefined,
            0
          ),
          profit: new FilterOption<RangeFilterValue>(
            this.scopeFilterConfig['profit'],
            'range',
            undefined,
            undefined,
            undefined,
            undefined,
            instanceToInstance(this.filters.profit),
            undefined,
            0
          ),
          agencyPrice: new FilterOption<RangeFilterValue>(
            this.scopeFilterConfig['agencyPrice'],
            'range',
            undefined,
            undefined,
            undefined,
            undefined,
            instanceToInstance(this.filters.agencyPrice),
            undefined,
            0
          ),
        },
        filterDefaults: ScopeOverviewFilterDefaults,
        reset: () => {
          this.resetFilters();
        },
        seeResults: (filters: FilterOptions) => {
          filterModal.close();
          this.filters = {
            ...this.filters,
            teamMembers: filters['teamMembers'].value,
            comments: filters['comments'].value,
            date: filters['date'].value,
            disciplines: filters['disciplines'].value,
            deliverables: filters['deliverables'].value,
            stages: filters['stages'].value,
            components: filters['components'].value,
            departments: filters['departments'].value,
            roles: filters['roles'].value,
            thirdPartyCost: filters['thirdPartyCost'].value,
            budget: filters['budget'].value,
            margin: filters['margin'].value,
            markUp: filters['markUp'].value,
            profit: filters['profit'].value,
            agencyPrice: filters['agencyPrice'].value,
          };
          this.onFilterScope.emit(this.filters);
        },
      },
    });
  }

  removeFilter(key: string) {
    this.filters = {
      ...this.filters,
      [key]: ScopeOverviewFilterDefaults[key],
    };

    this.onFilterScope.emit(this.filters);
  }

  mapFilteredSectionsToDataSource() {
    this.filteredSectionsDataSource$ = this.filteredSections$.pipe(
      map((sections: ScopeSection[]) => {
        this.filteredSectionsCount = sections.length;
        return this.mappingService.transformArray<ScopeSection>(sections, this.scopeTabService.sectionColumns);
      })
    );
  }

  mapFilteredDeliverablesToDataSource() {
    this.filteredDeliverablesDataSource$ = this.filteredDeliverables$.pipe(
      map((deliverables: Deliverable[]) => {
        this.filteredDeliverablesCount = deliverables.length;
        return this.mappingService.transformArray<Deliverable>(deliverables, this.scopeTabService.deliverableColumns);
      })
    );
  }

  mapFilteredComponentsToDataSource() {
    this.filteredComponentsDataSource$ = this.filteredComponents$.pipe(
      map((components: ScopeComponent[]) => {
        this.filteredComponentsCount = components.length;
        return this.mappingService.transformArray<ScopeComponent>(components, this.scopeTabService.componentColumns);
      })
    );
  }

  mapFilteredDepartmentsToDataSource() {
    this.filteredDepartmentsDataSource$ = this.filteredDepartments$.pipe(
      map((departments: Department[]) => {
        this.filteredDepartmentsCount = departments.length;
        return this.mappingService.transformArray<Department>(departments, this.scopeTabService.departmentColumns);
      })
    );
  }

  mapFilteredRolesToDataSource() {
    this.filteredRolesDataSource$ = this.filteredRoles$.pipe(
      map((roles: Role[]) => {
        this.filteredRolesCount = roles.length;
        return this.mappingService.transformArray<Role>(roles, this.scopeTabService.roleColumns);
      })
    );
  }

  mapFilteredFeesToDataSource() {
    this.filteredFeesDataSource$ = combineLatest([
      this.filteredScopeFees$,
      this.filteredDeliverableFees$,
      this.filteredComponentFees$,
    ]).pipe(
      map(([scopeFees, deliverableFees, componentFees]) => {
        let fees = [...scopeFees, ...deliverableFees, ...componentFees];
        this.filteredFeesCount = fees.length;
        return this.mappingService.transformArray<FeeItemInstance>(fees, this.scopeTabService.feeColumns);
      })
    );
  }

  mapFilteredTPCsToDataSource() {
    this.filteredTPCsDataSource$ = combineLatest([this.filteredDeliverableTPCs$, this.filteredDeliverableSectionTPCs$, this.filteredComponentTPCs$]).pipe(
      map(([deliverableTPCs, sectionTPCs, componentTPCs]) => {
        let thirdPartyCosts = [...deliverableTPCs, ...sectionTPCs, ...componentTPCs];
        this.filteredTPCsCount = thirdPartyCosts.length;
        return this.mappingService.transformArray<ThirdPartyCost>(thirdPartyCosts, this.scopeTabService.thirdPartyCostColumns);
      })
    );
  }

  onSelect(element: MappedEntity<any>) {
    if (!element.children$) {
      if (element.entity instanceof ScopeSection) {
        element.children$ = of(this.mappingService.transformArray<Deliverable>(
          this.scopeVersion.deliverables.filter((d) => d.section?.id === element.entity.id),
          this.scopeTabService.deliverableColumns
        ));
      } else if (element.entity instanceof Deliverable) {
        let deliverable = this.scopeVersion.deliverables.find((d) => d.id === element.entity.id)
        if (!deliverable.componentsInitialised) {
          this.onFetchDeliverable.emit(deliverable.id)
        }

        element.children$ = this.store
          .pipe(select(ScopeOverviewSelectors.selectComponentsByDeliverable(deliverable.id)))
          .pipe(
            map((components) => {
              return this.mappingService.transformArray<ScopeComponent>(components, this.scopeTabService.componentColumns);
            })
          );
      } else if (element.entity instanceof ScopeComponent) {
        element.children$ = of(this.mappingService.transformArray<Department>(
          element.entity.departments,
          this.scopeTabService.departmentColumns
        ));
      } else if (element.entity instanceof Department) {
        element.children$ = of(this.mappingService.transformArray<Role>(element.entity.roles, this.scopeTabService.roleColumns));
      }
    }
  }

  getDisplayFilters() {
    return Object.entries(this.filters).filter(
      ([key, value]) => !isEqual(value, ScopeOverviewFilterDefaults[key]) && key !== 'name'
    );
  }

  isUseRetainedHours() {
    return this.scopeVersion.useRetainedHours;
  }

  updateUseRetainedHours($event: any) {
    this.store.dispatch(ScopeOverviewActions.updateScopeRetainedHoursStats({
      sowId: this.scopeVersion.scopeOfWorkVersion.identity.id,
      scopeId: this.scopeVersion.id,
      useRetainedHours: $event.checked,
    }));
  }

  linkFeeItem(event: {feeItem: FeeItemInstance, linkTo: ScopeComponent | Deliverable | ScopeSection | ScopeVersion}) {
    if (event.feeItem.component) {
      this.linkComponentFeeItem(event)
    } else if (event.feeItem.deliverable) {
      this.linkDeliverableFeeItem(event)
    } else if (event.feeItem.scopeSection) {
      this.linkScopeSectionFeeItem(event)
    } else {
      this.linkScopeFeeItem(event)
    }
  }

  linkComponentFeeItem(event: {feeItem: FeeItemInstance, linkTo: ScopeComponent | Deliverable | ScopeSection | ScopeVersion}) {
    if (!event.linkTo) {
      this.scopeOverviewService.linkComponentFeeItemToScope(this.scopeVersion.identity.id, event.feeItem.deliverable.id, event.feeItem.component.id, event.feeItem.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteComponentFeeSuccess({ deliverableId: event.feeItem.deliverable.id, componentId: event.feeItem.component.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createScopeFeeItemSuccess({ feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to the ${this.lang.get('scope|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if ((event.linkTo instanceof ScopeComponent) && event.feeItem.component.id !== event.linkTo.id) {
      this.scopeOverviewService.linkComponentFeeItemToComponent(this.scopeVersion.identity.id, event.feeItem.deliverable.id, event.feeItem.component.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteComponentFeeSuccess({ deliverableId: event.feeItem.deliverable.id, componentId: event.feeItem.component.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createComponentFeeItemSuccess({ deliverableId: result.deliverable.id, componentId: result.component.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('component|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if (event.linkTo instanceof Deliverable) {
      this.scopeOverviewService.linkComponentFeeItemToDeliverable(this.scopeVersion.identity.id, event.feeItem.deliverable.id, event.feeItem.component.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteComponentFeeSuccess({ deliverableId: event.feeItem.deliverable.id, componentId: event.feeItem.component.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createDeliverableFeeItemSuccess({ deliverableId: result.deliverable.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('deliverable|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if (event.linkTo instanceof ScopeSection) {
      this.scopeOverviewService.linkComponentFeeItemToScopeSection(this.scopeVersion.identity.id, event.feeItem.deliverable.id, event.feeItem.component.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteComponentFeeSuccess({ deliverableId: event.feeItem.deliverable.id, componentId: event.feeItem.component.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createSectionFeeItemSuccess({ sectionId: result.scopeSection.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('stage|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    }
  }

  linkDeliverableFeeItem(event: {feeItem: FeeItemInstance, linkTo: ScopeComponent | Deliverable | ScopeSection | ScopeVersion}) {
    if (!event.linkTo) {
      this.scopeOverviewService.linkDeliverableFeeItemToScope(this.scopeVersion.identity.id, event.feeItem.deliverable.id, event.feeItem.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteDeliverableFeeItemSuccess({ deliverableId: event.feeItem.deliverable.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createScopeFeeItemSuccess({ feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to the ${this.lang.get('scope|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if (event.linkTo instanceof ScopeComponent) {
      this.scopeOverviewService.linkDeliverableFeeItemToComponent(this.scopeVersion.identity.id, event.feeItem.deliverable.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteDeliverableFeeItemSuccess({ deliverableId: event.feeItem.deliverable.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createComponentFeeItemSuccess({ deliverableId: result.deliverable.id, componentId: result.component.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('component|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if ((event.linkTo instanceof Deliverable) && event.feeItem.deliverable.id !== event.linkTo.id) {
      this.scopeOverviewService.linkDeliverableFeeItemToDeliverable(this.scopeVersion.identity.id, event.feeItem.deliverable.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteDeliverableFeeItemSuccess({ deliverableId: event.feeItem.deliverable.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createDeliverableFeeItemSuccess({ deliverableId: result.deliverable.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('deliverable|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if (event.linkTo instanceof ScopeSection) {
      this.scopeOverviewService.linkDeliverableFeeItemToScopeSection(this.scopeVersion.identity.id, event.feeItem.deliverable.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteDeliverableFeeItemSuccess({ deliverableId: event.feeItem.deliverable.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createSectionFeeItemSuccess({ sectionId: result.scopeSection.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('stage|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    }
  }

  linkScopeSectionFeeItem(event: {feeItem: FeeItemInstance, linkTo: ScopeComponent | Deliverable | ScopeSection | ScopeVersion}) {
    if (!event.linkTo) {
      this.scopeOverviewService.linkSectionFeeItemToScope(this.scopeVersion.identity.id, event.feeItem.scopeSection.id, event.feeItem.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteSectionFeeItemSuccess({ sectionId: event.feeItem.scopeSection.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createScopeFeeItemSuccess({ feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to the ${this.lang.get('scope|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if (event.linkTo instanceof ScopeComponent) {
      this.scopeOverviewService.linkSectionFeeItemToComponent(this.scopeVersion.identity.id, event.feeItem.scopeSection.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteSectionFeeItemSuccess({ sectionId: event.feeItem.scopeSection.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createComponentFeeItemSuccess({ deliverableId: result.deliverable.id, componentId: result.component.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('component|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if (event.linkTo instanceof Deliverable) {
      this.scopeOverviewService.linkSectionFeeItemToDeliverable(this.scopeVersion.identity.id, event.feeItem.scopeSection.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteSectionFeeItemSuccess({ sectionId: event.feeItem.scopeSection.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createDeliverableFeeItemSuccess({ deliverableId: result.deliverable.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('deliverable|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if ((event.linkTo instanceof ScopeSection) && event.feeItem.scopeSection.id !== event.linkTo.id) {
      this.scopeOverviewService.linkSectionFeeItemToScopeSection(this.scopeVersion.identity.id, event.feeItem.scopeSection.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteSectionFeeItemSuccess({ sectionId: event.feeItem.scopeSection.id, feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createSectionFeeItemSuccess({ sectionId: result.scopeSection.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('stage|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    }
  }

  linkScopeFeeItem(event: {feeItem: FeeItemInstance, linkTo: ScopeComponent | Deliverable | ScopeSection | ScopeVersion}) {
    if (event.linkTo instanceof ScopeComponent) {
      this.scopeOverviewService.linkScopeFeeItemToComponent(this.scopeVersion.identity.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteScopeFeeItemSuccess({ feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createComponentFeeItemSuccess({ deliverableId: result.deliverable.id, componentId: result.component.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('component|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if (event.linkTo instanceof Deliverable) {
      this.scopeOverviewService.linkScopeFeeItemToDeliverable(this.scopeVersion.identity.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteScopeFeeItemSuccess({ feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createDeliverableFeeItemSuccess({ deliverableId: result.deliverable.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('deliverable|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    } else if (event.linkTo instanceof ScopeSection) {
      this.scopeOverviewService.linkScopeFeeItemToScopeSection(this.scopeVersion.identity.id, event.feeItem.id, event.linkTo.id).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.deleteScopeFeeItemSuccess({ feeItemId: event.feeItem.id }))
          this.store.dispatch(ScopeOverviewActions.createSectionFeeItemSuccess({ sectionId: result.scopeSection.id, feeItem: result }))
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred during linking of fee to ${this.lang.get('stage|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        },
      })
    }
  }

  protected readonly Array = Array;
  protected readonly Privilege = Privilege
}
