<loader-modal *ngIf="loading"></loader-modal>
<div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ data.title }}</h1>
      </div>
      <div class="mt-2" [ngClass]="{ 'w-450': data.limitModalWidth }">
        <span >{{ data.body }}</span>
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>
  <mat-dialog-content aria-label="Generic modal">
      <mat-dialog-actions class="gap-2" style="display: inherit; margin-top: 20px" >
        <div style="display: inherit"  class="row" *ngFor="let integration of data.integrations">
          <mat-checkbox class="role-name"
                        [checked]='integration.selected'
                        (change)='integration.selected = !integration.selected'
                        aria-label='Select role'
          >{{integration.apiIntegration.name}}</mat-checkbox>
        </div>
        <div class="row" style="margin-top: 20px">
          <div class="column columns-7"></div>
          <button mat-button (click)="dialogRef.close(); traffickingService.exportStatus.reset()" class="scope-ui-modal-button column columns-1">
            Close
          </button>
          <button  mat-button class="scope-ui-modal-button column columns-3" (click)="trafficScope()"
                   [class.disabled]="getSelectedIntegrations().length == 0"
                   [class.confirm]="getSelectedIntegrations().length > 0"
                   [disabled]="getSelectedIntegrations().length == 0">
            Traffic
          </button>
        </div>
      </mat-dialog-actions>
  </mat-dialog-content>
