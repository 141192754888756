import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '@app/shared/pipe/index.module';
import { SharedModule } from '@app/shared/shared.module';
import { LanguageService } from '@core/service/language.service';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { ScopeTraffickingService } from '@app/features/scope-overview/service/scope-trafficking.service';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';

@Component({
  selector: 'import-second-party-modal',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDialogModule, SharedModule],
  templateUrl: './import-second-party-modal.component.html',
  styleUrls: ['./import-second-party-modal.component.scss'],
})
export class ImportSecondPartyModalComponent {
  showLoader = true
  page: any
  IMPORT_LIMIT = 30;
  trafficSystem: any
  selectedSecondPartiesToImport = {};
  cachedPages = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private lang: LanguageService,
    private manageService: CompanyManagementService,
    private traffickingService: ScopeTraffickingService,
    private snackbarService: SnackbarService
  ) {
    this.trafficSystem = data.trafficSystem
    this.getSecondParties()
  }

  countSelected() {
    return Object.keys(this.selectedSecondPartiesToImport).length;
  }

  getSecondParties(pageNumber = 1) {
    this.showLoader = true
    let cache = this.cachedPages[pageNumber];
    if (cache != null){
      this.page = cache;
    } else {
      this.manageService.getTrafficSystemSecondParties(pageNumber).subscribe({
        next: (data: any) => {
          this.showLoader = false
          this.cachedPages[pageNumber] = data;
          let nameIdxCount = {};
          this.page = data;
          this.page.data = data.data
            .map((secondParty) => {
              //Already imported
              secondParty.selected = secondParty.imported;

              if (!secondParty.imported) {
                //Already selected
                let selected = this.selectedSecondPartiesToImport[secondParty.id];
                if (selected) {
                  secondParty.selected = true;
                  secondParty.newName = selected.newName;
                }

                //Same name
                let count = this.getNameCount(nameIdxCount, secondParty);
                if (count > 1) {
                  if (secondParty.namingConflict) {
                    count++;
                  }
                  secondParty.namingConflict = true;
                  secondParty.newName = secondParty.name + ' (' + --count + ')';
                  this.decrementNameCount(nameIdxCount, secondParty);
                } else {
                  secondParty.newName = secondParty.name + ' (1)';
                }
              }
              return secondParty;
            });

          this.page.importedData = this.page.data.filter(d => d.imported)
        },
        error: (error) => {
          this.showLoader = false
          this.traffickingService.validateServerError(error, () => {
            this.data.cancelCallback()
          }, () => {
            this.renderDownloadFile()
          })
        }
      })
    }
  }

  selectItem(item){
    if (item.selected){
      this.selectedSecondPartiesToImport[item.id] = item;
    } else {
      delete this.selectedSecondPartiesToImport[item.id]
    }
  };

  renderDownloadFile() {
    let pageNumber = 1;
    if (this.page) {
      pageNumber = this.page.pageInfo.pageNumber
    }
    this.manageService.renderTrafficSystemSecondPartiesError(pageNumber).subscribe({
      next: (response: any) => {
        let blob = new Blob([JSON.stringify(response.data)], { type: 'text/plain' });
        let name = "JsonData.txt";
        let downloadUrl = (window.URL || window.webkitURL).createObjectURL(blob);
        let a = $(`<a href="${downloadUrl}" download="${name}"></a>`);
        a[0].click();
      },
      error: () => {
        this.snackbarService.showSnackbar("The cached data has expired.", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
      }
    });
  }

  getNameCount(idx, secondParty){
    return idx[secondParty.name.trim().toLowerCase()]
  }

  decrementNameCount(idx, secondParty){
    idx[secondParty.name.trim().toLowerCase()] = --idx[secondParty.name.trim().toLowerCase()];
  }

  nextPage(){
    let currentPage = this.page;
    let pageInfo = currentPage.pageInfo;
    this.getSecondParties(pageInfo.pageNumber+1)
  }

  previousPage(){
    let currentPage = this.page;
    let pageInfo = currentPage.pageInfo;
    this.getSecondParties(pageInfo.pageNumber-1)
  }

  importSecondParties(){
    let selected = Object.values(this.selectedSecondPartiesToImport);
    if (selected.length > 0){
      this.showLoader = true
      let selectedIdsToNewNameMap = {};
      selected.forEach((secondParty: any) =>{
        selectedIdsToNewNameMap[secondParty.id] = secondParty.namingConflict ? secondParty.newName : null;
      });

      this.manageService.importTrafficSystemSecondParties(selectedIdsToNewNameMap).subscribe({
        next: (data) => {
          this.showLoader = false
          this.data.confirmCallback(data)
        },
        error: (error) => {
          if (error.status == 409){
            let { secondParty, pageNumber } = this.findTrafficSystemSecondParty(error.data.conflictedTrafficSystemEntityId);
            if (this.page.pageInfo.pageNumber != pageNumber){
              this.getSecondParties(pageNumber)
            } else {
              this.showLoader = false
            }
            secondParty.conflictingName = error.data.conflictedTrafficSystemEntityName
            secondParty.namingConflict = true
          } else {
            this.showLoader = false
            this.traffickingService.validateServerError(error, () => this.data.cancelCallback(), () => this.renderDownloadFile())
          }
        }
      })
    }
  }

  findTrafficSystemSecondParty(id){
    let values: any = Object.values(this.cachedPages);
    for (let i = 0; i < values.length; i++) {
      let secondParties = values[i].data.filter(function(secondParty){
        return secondParty.id == id;
      });
      if (secondParties.length == 1){
        return {pageNumber: i+1, secondParty: secondParties[0]};
      }
    }
    return null;
  }

  showNamingConflictError(secondParty){
    if (secondParty.namingConflict && secondParty.selected && !secondParty.imported){
      if (secondParty.conflictingName && secondParty.newName && secondParty.conflictingName.trim() == secondParty.newName.trim()){
        return true;
      }
      return !secondParty.newName || secondParty.newName.trim().length == 0 || secondParty.newName.trim() == secondParty.name.trim()
    }
    return false;
  }
}
