import { Component, HostListener, Input } from "@angular/core";
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { HeaderStyleService, HIDE_HEADER } from '@app/shared/services/header-style.service';
import { EnvService } from "@envs/env-service";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import _ from "lodash";
import { AuthService } from '@core/service/auth.service'
import { environment } from '@envs/environment';

@Component({
  selector: 'app-ratecard',
  templateUrl: './ratecard.component.html',
  styleUrls: ['./ratecard.component.scss'],
  providers: [ SafePipe ],

})
export class RatecardComponent {
  @Input() id!: number;
  @Input() tab!: string;
  baseJsUrl: string

  constructor(private headerStyleService: HeaderStyleService, private envService: EnvService, private router: Router, private route: ActivatedRoute, private titleService: Title, private authService: AuthService) {
    this.headerStyleService.setHeaderClass(HIDE_HEADER)
  }

  ngOnInit() {
    if (this.id) {
      this.baseJsUrl = `${this.envService.baseJsUrl}/admin/ratecard/${this.id}?host=${environment.baseHost}&childCompany=${this.authService.loggedInUser?.company.id}`
    } else {
      this.baseJsUrl = `${this.envService.baseJsUrl}/admin/ratecard?host=${environment.baseHost}&childCompany=${this.authService.loggedInUser?.company.id}&${this.router.url.split('?')[1]}`
    }
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data.action === 'adminNavigate') {
      this.headerStyleService.setHeaderClass(null)
      let location = event.data.location === 'ratecards' ? 'rate cards' : event.data.location
      this.titleService.setTitle(_.startCase(_.camelCase(location)) + ' | Admin | Scope')
      this.router.navigate(
        [`/manage`],
        {
          queryParams: { 'tab': location }
        }
      )
    }
  }
}
