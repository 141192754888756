import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "orderBy",
  pure: false
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, sortBy: string | string[], order?: "asc" | "desc" | Array<"asc"|"desc">, copyArray: boolean = true): any[] {
    if (copyArray) { // Set this to false if the array is not read only and additions/deletions occur in place
      array = Object.assign([], array);
    }
    if (Array.isArray(sortBy)) {
      const sortOrder = order || ['asc']// setting default ascending order
      return array?.sort((a, b) => {
        for (let i = 0; i < sortBy.length; i++) {
          let result
          if (typeof a[sortBy[i]] === 'number' && typeof b[sortBy[i]] === 'number') {
            result = sortOrder === "desc" ? b[sortBy[i]] - a[sortBy[i]] : a[sortBy[i]] - b[sortBy[i]]
          } else {
            result = sortOrder[i] === "desc" ? b[sortBy[i]]?.toString().localeCompare(a[sortBy[i]]?.toString()) :
              a[sortBy[i]]?.toString().localeCompare(b[sortBy[i]]?.toString())
          }
          if (result) return result
        }
      })
    } else {
      const sortOrder = order || 'asc'// setting default ascending order
      return array?.sort((a, b) => {
        if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
          return sortOrder === "desc" ? b[sortBy] - a[sortBy] : a[sortBy] - b[sortBy]
        }
        return sortOrder === "desc" ? b[sortBy]?.toString().localeCompare(a[sortBy]?.toString()):
          a[sortBy]?.toString().localeCompare(b[sortBy]?.toString())
      })
    }
  }
}
