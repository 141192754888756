import { HostListener, Injectable } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { AuthSelectors } from '@core/store/selectors/auth.selector';
import { Store } from '@ngrx/store';
import { AuthActions } from '@core/store';
import { Company } from '@core/model/company.model';
import { AuthService } from '@core/service/auth.service';
import { User } from '@core/model/user.model';

@Injectable({
  providedIn: 'root',
})
export class ParentChildService {
  loggedInUser!: User
  originalCompany!: Company | undefined
  selectedCompany!: Company
  selectedCompanyName!: string
  authorisedAsChildCompany!: boolean

  childCompanies: Company[] = []
  allChildCompanies: Company[] = []
  childCompaniesAccessRules: any[] = []

  childCompanies$ = this.store.select(AuthSelectors.selectChildCompanies)
  childCompaniesAccessRules$ = this.store.select(AuthSelectors.selectChildCompaniesAccessRules)

  constructor(private store: Store,
              private authService: AuthService) {
    this.loggedInUser = this.authService.loggedInUser;
    this.setOriginalCompany();

    if (this.originalCompany && this.originalCompany.hasApplicationSetting('COMPANY_CHILD_ACCOUNT_ACCESS_RULES')) {
      combineLatest([this.childCompanies$, this.childCompaniesAccessRules$]).pipe(
        map(([companies, rules]) => {
          this.allChildCompanies = companies
          this.childCompaniesAccessRules = rules
          let childCompanies = rules
            .filter(c => c.member.id === this.loggedInUser.id)
            .map(r => r.childCompany)
          if (childCompanies.length !== 0) {
            this.childCompanies = rules.filter(ch=>ch.childCompany.enabled === true)
              .filter(c => c.member.id === this.loggedInUser.id)
              .map(r => r.childCompany)
          } else {
            this.childCompanies = []
          }
        })
      ).subscribe()
      this.store.dispatch(AuthActions.getChildCompaniesAccessRules());
    } else {
      this.childCompanies$.subscribe(c => this.childCompanies = c);
    }
    this.store.dispatch(AuthActions.getChildCompanies());
  }

  setOriginalCompany() {
    if (this.loggedInUser.company.parentCompany == null) {
      this.originalCompany = this.loggedInUser.company
      this.selectedCompany = this.loggedInUser.company
      this.selectedCompanyName = this.loggedInUser.company.name
    } else if (!this.originalCompany) {
      this.originalCompany = this.loggedInUser.company.parentCompany
      this.selectedCompany = this.loggedInUser.company
      this.selectedCompanyName = this.loggedInUser.company.name
      this.authorisedAsChildCompany = true
    }
  }
}
