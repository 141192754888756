<div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ data.title }}</h1>
      </div>

      <div class="mt-2" [ngClass]="{ 'w-450': data.limitModalWidth }">
        <span>
          {{ data.body }}
        </span>
      </div>

      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Generic modal">
    <div>
      <mat-dialog-actions class="export-scope-actions">

        <button *ngIf="hasCustomXlsxTemplate" (click)="exportScopeToXlsxTemplate()" [has-privilege]="'SCOPE__EXPORT_XLSX'" class="export-scope-button">
          <span class="export-scope-circle">X</span>
          <span class="export-scope-type">{{ data.currentUser.company.name }} Custom {{'output' | langf }}</span>
        </button>

        <!-- TODO: Add Scope By Role Standard Task Output L76 app/src/assets/partials/scope/scopeoverview/statusmodals/export-modal.html-->

        <button [has-privilege]="'SCOPE__EXPORT_XLSX'" (click)="exportScopeToExcel()" class="export-scope-button">
          <span class="export-scope-circle">X</span>
          <span class="export-scope-type">Scope Detailed Output</span>
        </button>

        <button [has-privilege]="'SCOPE__EXPORT_XLSX'" (click)="exportScopeToExcelSummary()" class="export-scope-button">
          <span class="export-scope-circle">X</span>
          <span class="export-scope-type">Staff Plan - Scope level</span>
        </button>

        <button [has-privilege]="'SCOPE__EXPORT_XLSX'" (click)="exportScopeBreakdownToExcelSummary()" class="export-scope-button">
          <span class="export-scope-circle">X</span>
          <span class="export-scope-type">Staff Plan - by Deliverable</span>
        </button>

        <button [has-privilege]="'SCOPE__EXPORT_XLSX'" (click)="exportScopeBreakdownComponentToExcelSummary()" class="export-scope-button">
          <span class="export-scope-circle">X</span>
          <span class="export-scope-type">Staff Plan - by Component</span>
        </button>

        <button [has-privilege]="'SCOPE__EXPORT_XLSX'" (click)="exportScopeDepartmentsHoursToExcelSummary()" class="export-scope-button">
          <span class="export-scope-circle">X</span>
          <span class="export-scope-type">Total Hours by Dept</span>
        </button>

        <!-- TODO: Add SOW export options as part of SOW implementation -->

      </mat-dialog-actions>
    </div>
  </mat-dialog-content>
