export interface Language { code: string, name: string }

export const Languages = {
  EN: {
    code: "EN",
      name: "English"
  },
  ES: {
    code: "ES",
      name: "Spanish",
  },
  DE: {
    code: "DE",
      name: "German",
  },
  PR: {
    code: "PR",
      name: "Portuguese",
  },
  FR: {
    code: "FR",
      name: "French",
  }
}
