import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScopeVersion } from '@core/model/scope-version';
import { User } from '@app/core/model/user.model';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { NgxMaskDirective } from 'ngx-mask'
import {
  DeliverableRowComponent
} from '@app/features/scope-overview/components/scope-tab/deliverable-row/deliverable-row.component'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { ToggleListComponent } from '@shared/components/ui-components/toggle-list/toggle-list.component'
import {
  SectionRowComponent
} from '@app/features/scope-overview/components/scope-tab/section-row/section-row.component'
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model';
import { Department } from '@app/features/scoping/models/department.model';
import { cloneDeep } from 'lodash';
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service';
import { Preference } from '@core/model/user-preferences.interface';
import { Store } from '@ngrx/store';
import { Role } from '@app/features/scoping/models/role.model';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import { formatDisplayHoursToMinutes, formatHours, trackById, untilDestroyed } from '@shared/utils/utils';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service';
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action';
import { ScopeUiCounterComponent } from '@shared/components/ui-components/scope-ui-counter/scope-ui-counter.component';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';
import { plainToInstance } from 'class-transformer';
import { Sort } from '@angular/material/sort';
import { ScopeUiDuration } from '@shared/components/ui-components/scope-ui-duration/scope-ui-duration.component';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from '@core/service/language.service';
import { SNACKBAR_LENGTH_SHORT, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { CdnConfig } from '@core/model/cdn-config.model';

@Component({
  selector: 'scope-by-role-task',
  imports: [CommonModule, SharedModule, ScopeUiTableComponent, NgxMaskDirective, DeliverableRowComponent, ScopeUiInputComponent, ToggleListComponent, SectionRowComponent, ScopeUiCounterComponent, ScopeUiDuration],
  templateUrl: './scope-by-role-task.component.html',
  styleUrls: ['./scope-by-role-task.component.scss'],
  standalone: true,
})
export class ScopeByRoleTaskComponent {
  private readonly destroy$;

  @Input() set deliverable(deliverable: Deliverable) {
    this.task = deliverable
    this.mapDepartments()
  }

  @Input() currentUser!: User;
  @Input() currentRatecard!: RatecardVersion;

  @Input() set scopeVersion(scope: ScopeVersion) {
    this.currentScope = scope
    this.mapDepartments()
    if (this.currentScope && !this.taskActions) {
      this.taskActions = [
        {
          name: () => 'Rename',
          icon: 'border_color',
          click: () => this.editName = true,
          isVisible: () => {
            return this.scopeApprovalFlowService.isScopeEditable(this.currentScope)
          }
        },
        {
          name: () => `${this.scopeTabService.taskOpenStates[this.task.id] ? 'Hide' : 'View'} info`,
          icon: 'info',
          click: () => this.scopeTabService.taskOpenStates[this.task.id] = !this.scopeTabService.taskOpenStates[this.task.id]
        },
        {
          name: () => `Save`,
          icon: 'bookmark_add',
          click: this.saveTask,
          showDivider: true,
          isVisible: () => {
            return this.scopeApprovalFlowService.isScopeEditable(this.currentScope)
          }
        },
        {
          name: () => `Delete`,
          icon: 'delete',
          click: () => this.deleteTask.emit(),
          showDivider: true,
          isVisible: () => {
            return this.scopeApprovalFlowService.isScopeEditable(this.currentScope) && this.currentScope.deliverables.length > 1
          }
        }
      ]
    }
  }

  @Input() tableExpanded: boolean
  @Input() departmentSort: Sort
  @Input() cdnConfig?: CdnConfig
  @Output() tableExpandedChange = new EventEmitter<boolean>()
  @Output() deleteTask = new EventEmitter<void>()
  @Output() onAcceptDeliverable = new EventEmitter<Deliverable>()
  @Output() onRejectDeliverable = new EventEmitter<Deliverable>()

  task: Deliverable
  currentScope: ScopeVersion
  mappedDepartmentsDataSource!: MappedEntity<Department>[]
  departmentColumns: Preference[]
  departmentColumnKeys!: string[]
  taskActions: any[]
  editName: boolean

  constructor(private scopeOverviewService: ScopeOverviewService,
              private store: Store,
              private mappingService: DataMappingService,
              public scopeTabService: ScopeTabService,
              public scopeApprovalFlowService: ApprovalFlowService,
              private dialog: MatDialog,
              private lang: LanguageService,
              private snackbarService: SnackbarService) {
    this.destroy$ = untilDestroyed();
    this.setColumns();
  }

  mapDepartments() {
    if (this.currentScope && this.task?.componentsInitialised) {
      this.mappedDepartmentsDataSource = this.mappingService.transformArray(
        cloneDeep(this.task.components[0]?.departments)
          .sort((a, b) => a.source.id - b.source.id),
        this.departmentColumns, 'roles')
    }
  }

  setColumns() {
    this.departmentColumns = [
      {
        key: 'FTE',
        name: `% FTE`,
        value: (entity: Role | Department) => {
          return entity.getFtePercentage(this.currentScope.identity.getFteValue())
        },
        selected: true,
        noSort: true,
        headerValue: () => {
          return this.task.getFtePercentage(this.currentScope.identity.getFteValue(), true)
        },
        inputType: (entity: Role | Department) =>
          (entity instanceof Role && this.scopeApprovalFlowService.isScopeEditable(this.currentScope)) ? 'number' : null,
        onInputChange: this.updateRoleFte,
        inputContext: { isDecimal: true, maxLength: 4 },
        commentable: () => {
          return { key:'ftePercentage', rootEntity: this.task.components[0] }
        },
        headerCommentable: () => {
          return { key: 'ftePercentage', entity: this.task }
        }
      },
      {
        key: 'HOURS',
        name: `Hours`,
        value: (entity: Role | Department) => {
          if (entity instanceof Department) {
            return formatHours(entity.getTotalRateCardHours())
          } else {
            return this.scopeApprovalFlowService.isScopeEditable(this.currentScope) ?
              entity.rateCardMinutes : formatHours(entity.rateCardMinutes)
          }
        },
        selected: true,
        noSort: true,
        headerValue: () => {
          return formatHours(this.task.getTotalRateCardHours(true))
        },
        inputType: (entity: Role | Department) =>
          (entity instanceof Role && this.scopeApprovalFlowService.isScopeEditable(this.currentScope)) ? 'number' : null,
        onInputChange: this.updateRoleHours,
        inputContext: { enableMinors: true, majorStep: 60, minorStep: 15, max: 9999 * 60, maxLength: 4,
          formatDisplayValue: (val) => formatHours(val / 60),
          parseDisplayValue: (val) => formatDisplayHoursToMinutes(val) },
        commentable: () => {
          return { key:'rateCardHours', rootEntity: this.task.components[0] }
        },
        headerCommentable: () => {
          return { key: 'rateCardHours', entity: this.task }
        }
      }
    ]
    this.departmentColumnKeys = this.departmentColumns.map((column) => column.key)
  }

  updateRoleFte = (role: Role, value: string) => {
    role.fte = value
    if (role.fte == null && role.fte === "") {
      return;
    }
    let updateValue = {
      type: "FTE",
      fte: role.fte / 100
    }
    let newHours = this.currentScope.identity.getSecondParty().fteValue * (role.fte / 100)
    role.setRateCardHours(parseFloat(newHours.toFixed(2)))
    role.editFte = false
    this.updateRole(role, updateValue)
  }

  updateRoleHours = (role, value) => {
    role.rateCardMinutes = value
    let updateValue = {
      type: "MINUTES",
      minutes: role.rateCardMinutes
    }
    role.fte = role.getFtePercentage(this.currentScope.identity.getSecondParty().fteValue)
    this.updateRole(role, updateValue)
  }

  updateRole = (role: Role, updateValue: any) => {
    this.store.dispatch(ScopeOverviewActions.updateRole({ scopeId: this.currentScope.identity.id, taskId: this.task.id, role: role, updateValue: updateValue }));
  }

  onUpdateQuantity(quantity: number) {
    this.store.dispatch(
      ScopeOverviewActions.updateScopeDeliverable({ scopeId: this.currentScope.identity.id, deliverable: plainToInstance(Deliverable, { ...this.task, quantity }) })
    )
  }

  updateTaskName($event: any) {
    if (!$event) return
    this.editName = false
    if ($event !== this.task.name) {
      this.store.dispatch(
        ScopeOverviewActions.updateScopeDeliverable({
          scopeId: this.currentScope.identity.id,
          deliverable: plainToInstance(Deliverable, { ...this.task, name: $event })
        })
      )
    }
  }

  updateStartDate($event: any) {
    this.store.dispatch(
      ScopeOverviewActions.updateScopeDeliverable({ scopeId: this.currentScope.identity.id, deliverable: plainToInstance(Deliverable, { ...this.task, startDate: $event }) })
    )
  }

  updateEndDate($event: any) {
    this.store.dispatch(
      ScopeOverviewActions.updateScopeDeliverable({ scopeId: this.currentScope.identity.id, deliverable: plainToInstance(Deliverable, { ...this.task, endDate: $event }) })
    )
  }

  updateDescription($event: any) {
    this.store.dispatch(
      ScopeOverviewActions.updateScopeDeliverable({ scopeId: this.currentScope.identity.id, deliverable: plainToInstance(Deliverable, { ...this.task, description: $event }) })
    )
  }

  updateInternalNote($event: any) {
    this.store.dispatch(
      ScopeOverviewActions.updateScopeDeliverable({ scopeId: this.currentScope.identity.id, deliverable: plainToInstance(Deliverable, { ...this.task, internalNote: $event }) })
    )
  }

  saveTask = () => {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Save "` + this.task.name + `" to your Data Library?`,
        `The task will be saved and used for the future ${this.lang.get('scope.p|l')}. It will not affect current ${this.lang.get('scope|l')}.`,
        'Save',
        'Discard',
        () => {
          dialog.close();
          this.scopeOverviewService.createLibraryTaskTemplate(this.currentScope.id, [this.task.id]).subscribe({
            next: () => {
              this.snackbarService.showSnackbar('Task was successfully added to Data Library.', SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            },
            error: (error) => {
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(error)
              return error
            }
          })
        },
        () => dialog.close(),
        [],
        false,
        true,
        true
      ),
    })
  }

  acceptDeliverable(deliverable: Deliverable) {
    this.onAcceptDeliverable.emit(deliverable)
  }

  rejectDeliverable(deliverable: Deliverable) {
    this.onRejectDeliverable.emit(deliverable)
  }

  protected readonly trackById = trackById;
}
