import { Component } from '@angular/core';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';
import { LanguageService } from '@core/service/language.service';
import { Tab, TabDefinition, Tabs, TabUtil } from '@shared/utils/tabs-util.service';
import { Privilege } from '@core/model/enums/privilege.enum';
import { environment } from '@envs/environment';
import { EnvService } from '@envs/env-service';

@Component({
  selector: 'manage-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent {
  loggedInUser!: User
  tabs: Tabs
  baseJsUrl: string
  globalTabs: TabDefinition[] = [
    {
      key: "DELIVERABLES",
      name: this.lang.get('deliverable.p'),
      icon: 'description'
    },
    {
      key: "DISCIPLINES",
      name: this.lang.get('discipline.p'),
      icon: 'schema'
    },
    {
      key: "TASKS",
      name: "Tasks",
      privileges: [Privilege.LIBRARY_DELIVERABLE__VIEW],
      icon: 'task_alt'
    },
    {
      key: "FEES",
      name: "Fees",
      privileges: [Privilege.FEE_ITEM__VIEW],
      settings: ["FEE_ITEM__ENABLE"],
      icon: 'request_quote'
    },
    {
      key: "COST_PLUS",
      name: "Cost plus",
      settings: ["COST_PLUS"],
      icon: 'compare_arrows'
    },
    {
      key: "THIRDPARTYCOSTS",
      name: this.lang.get('third_party_cost.p'),
      icon: 'request_quote'
    },
    {
      key: "UNIT_TYPES",
      name: 'Unit Types',
      icon: 'straighten',
      subTab: true
    },
    {
      key: "FORMULAS",
      name: 'Formulas',
      icon: 'calculate',
      subTab: true
    },
    {
      key: "RATECARD_STRUCTURE",
      name: "Ratecard Structure",
      privileges: [Privilege.RATECARD_STRUCTURE__VIEW],
      icon: 'demography'
    },
    {
      key: "RATECARD_LOCATIONS",
      name: "Ratecard Location",
      settings: ["RATECARD_LOCATION__ENABLE"],
      icon: 'location_on'
    }
  ]

  constructor(private authService: AuthService,
              private lang: LanguageService,
              private tabUtils: TabUtil,
              private envService: EnvService) {
    this.loggedInUser = this.authService.loggedInUser
    this.baseJsUrl = `${this.envService.baseJsUrl}/admin?host=${environment.baseHost}&childCompany=${this.loggedInUser.company.id}&tab=data&subTab=deliverables`
  }

  ngOnInit() {
    this.tabs = this.tabUtils.createTabs(this.globalTabs, this.loggedInUser)
  }

  onSelect(tab: Tab) {
    tab.switch({}, this.tabUtils.shouldDisableTabSwitching)
    this.baseJsUrl = `${this.envService.baseJsUrl}/admin?host=${environment.baseHost}&childCompany=${this.loggedInUser.company.id}&tab=data&subTab=${tab.key.toLowerCase()}`
  }
}
