import { Type } from 'class-transformer';
import { Department } from '@app/features/scoping/models/department.model';

export class RatecardStructureVersion {
  id!: number
  name!: string
  version!: number
  identity!: any;
  @Type(() => Department) departments!: Department[];
}
