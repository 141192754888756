<div class="title">
  <h1 class="product-heading-bold">Tasks</h1>
</div>
<span class="tasks-heading">You would find here all the task and stages that are been saved from {{'scope' | lang }} by roles.
  <br>When you are scoping you will be able to use them anytime.</span>

<div class="flex flex-row justify-end items-center gap-2 h-12">
  <!--TODO: Add stages tab in phase 2-->
  <!--<scope-ui-tabs
    class='flex-1'
    [fontSize]="'14px'"
    (onSelect)="currentTab = $event">
    <scope-ui-tab
      tabTitle="Tasks"
      class="scope-tab white-background"
      [active]="currentTab === 'Tasks'" >
    </scope-ui-tab>
    <scope-ui-tab
      tabTitle="Stages"
      class="scope-tab white-background"
      [active]="currentTab === 'Stages'">
    </scope-ui-tab>
  </scope-ui-tabs>-->
  <button
    class="text-link ml-4 mr-4"
    aria-label="Show descriptions"
    (click)="showDescriptions = !showDescriptions"
    onclick="this.blur()">
    <mat-icon class="material-symbols-rounded icon-small orange">{{showDescriptions ? 'visibility_off' : 'visibility'}}</mat-icon>
    {{showDescriptions ? 'Hide Descriptions' : 'Show Descriptions'}}
  </button>
  <mat-divider vertical />
  <scope-ui-input
    [hasPlaceholder]="false"
    [inputPlaceholder]="''"
    [icon]="'search'"
    [inputLabel]="'Search'"
    [cancelIcon]="'cancel'"
    (onEnter)="onSearchKeyPressed($event)"
    (onCancel)="onSearchKeyPressed('')"
    [inputClass]="'search-field'" />
  <mat-divider vertical />
  <button class="action-button" (click)="upload()" mat-button>
    <mat-icon class="material-symbols-rounded">upload_file</mat-icon>Upload Tasks
  </button>
  <button class="action-button" (click)="addTask()" mat-button>
    <mat-icon class="material-symbols-rounded">add</mat-icon>Add Task
  </button>
</div>
<mat-divider></mat-divider>
<scope-ui-table
  class="block mt-1"
  *ngIf="currentTab === 'Tasks'"
  [loading]="loading$ | async"
  [tableClass]="'overview-table'"
  [loggedInUser]='loggedInUser'
  [data]="mappedTasksDataSource$ | async"
  [displayedColumns]="taskColumns$ | async"
  [addToggleListColumn]="true"
  [totalCount]="tableData?.totalCount"
  [page]="tableData?.page"
  [showToggleMenu]="true"
  [menuOptions]="menuOptions"
  [expandable]="false"
  [showDescription]="true"
  [showHideDescriptionOnly]="true"
  [displayAllDescriptions]="showDescriptions"
  [rowGaps]="true"
  [condensed]="true"
  (pageChange)="onChangePage($event)"
  (onToggle)="onTogglePreference($event)"
  (onSort)="onSort($event)">
</scope-ui-table>
