import { Company } from '@core/model/company.model';
import { User } from '@core/model/user.model';
import {
  setAuthorisedAsChildCompany, trackById,
  untilDestroyed,
} from '@shared/utils/utils';
import { Component, HostListener } from '@angular/core';
import { Store } from '@ngrx/store'
import { AuthActions } from '@core/store';
import { AuthService } from '@core/service/auth.service'
import { ParentChildService } from '@shared/services/parent-child.service';

@Component({
  selector: 'app-parent-child-switcher',
  templateUrl: './parent-child-switcher.component.html',
  styleUrls: ['./parent-child-switcher.component.scss']
})
export class ParentChildSwitcherComponent {
  private readonly destroy$;

  showChildSwitcher: boolean = false
  loggedInUser!: User
  loading: boolean = false

  constructor(private store: Store,
              private authService: AuthService,
              public parentChildService: ParentChildService) {
    this.destroy$ = untilDestroyed();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data === 'updateParentChildAccessRules') {
      this.store.dispatch(AuthActions.getChildCompaniesAccessRules());
    }
    if (event.data === 'updateCompanySettings') {
      this.store.dispatch(AuthActions.myAccount())
    }
  }

  ngOnInit() {
    this.loggedInUser = this.authService.loggedInUser;
  }

  removeAccessToSwitcher() {
    if (this.loggedInUser.company.hasApplicationSetting("COMPANY_CHILD_ACCOUNT_ACCESS_RULES")) {
      return this.parentChildService.childCompaniesAccessRules.filter(c => c.member.id === this.loggedInUser.id).length == 0;
    } else {
      return false
    }
  }

  onChangeCurrentCompany(companyName: string) {
    this.loading = true;
    const company = this.findCompany(companyName);
    this.changeCurrentCompany(company)
  }

  changeCurrentCompany(newCompany: Company) {
    if (newCompany.id === this.loggedInUser.company.parentCompany?.id) {
      setAuthorisedAsChildCompany(null);
    } else {
      setAuthorisedAsChildCompany(newCompany.id);
    }
    setTimeout(() => {
      location.reload();
    }, 1500)
  }

  findCompany(companyName: string) {
    let company;
    if (this.parentChildService.originalCompany?.name == companyName) {
      company = this.parentChildService.originalCompany
    } else {
      this.parentChildService.childCompanies.forEach(c=> {
        if (c.name == companyName) {
          company = c
        }
      })
    }
    return company;
  }

  protected readonly trackById = trackById;
}
