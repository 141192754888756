<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>Import - {{ 'second_party.p' | lang }}</h1>
      </div>

      <div class="mt-2 w-450">
        <span *ngIf="page?.data?.length > 0" >List of {{trafficSystem.name}} {{ 'second_party.p' | lang }}</span>
        <span *ngIf="page?.data?.length == 0" >Currently there is nothing new to import.</span>
      </div>

      <button class="close-button" mat-dialog-close>
        <mat-icon class="material-symbols-rounded">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <loader-modal *ngIf="showLoader" [overlay]="true"></loader-modal>
    <div class="modal__body" *ngIf="page?.data?.length > 0">
      <div class="row">
        <div class="ten--columns">
          <h3 class="product-heading-bold">{{ 'second_party.p' | lang }} to import</h3>
        </div>
      </div>
      <ul class="ratecard-list">
        <li class="ratecards costume-li-ratecard" *ngFor="let secondParty of page.data" id="CL-{{secondParty.id}}">
          <div class="row">
            <div data-ng-class="{'scopemark': secondParty.selected}">
              <label [ngClass]="{'ultra-grey': secondParty.imported}">
                <mat-checkbox class="client-name"
                              [checked]='secondParty.selected'
                              [disabled]="(countSelected() == IMPORT_LIMIT && !secondParty.selected) || !!secondParty.imported"
                              (change)='secondParty.selected = $event.checked; selectItem(secondParty)'
                              aria-label='Select client'
                ></mat-checkbox>
                <span *ngIf="secondParty.imported">[IMPORTED]</span> {{secondParty.name}}
              </label>
            </div>
            <div class="row naming-conflict" *ngIf="secondParty.namingConflict && secondParty.selected && !secondParty.imported">
              <p class="inp-title">Rename client</p>
              <input type="text" [(ngModel)]="secondParty.newName" required>
            </div>
          </div>
          <div class="row error-container" *ngIf="showNamingConflictError(secondParty)">
            <span class="error">{{secondParty.newName ? secondParty.newName : secondParty.name}} already exists in your {{'agency|l' | lang }}, please rename.</span>
          </div>
        </li>
      </ul>
      <div class="row text-12 m_top_10">
        <div class="left">
          <span>Page {{page.pageInfo.pageNumber}} of {{page.pageInfo.totalPages}}</span><span> - ({{page.pageInfo.pageNumber == page.pageInfo.totalPages ? page.pageInfo.totalElements : (page.pageInfo.pageSize * page.pageInfo.pageNumber)}} / {{page.pageInfo.totalElements}} Items)</span>
          <div style="margin-top: 10px;">Page Imported Items: {{ page.importedData.length }}/{{page.data.length}}</div>
        </div>
        <div class="right">
          <a href="#" *ngIf="page.pageInfo.pageNumber > 1" (click)="previousPage()">Previous Page <span *ngIf="page.pageInfo.pageNumber != page.pageInfo.totalPages" class="ultra-grey"> | </span></a>
          <a href="#" *ngIf="page.pageInfo.pageNumber < page.pageInfo.totalPages" (click)="nextPage()">Next Page</a>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex gap-2" style="justify-content: flex-end">
    <button
      *ngIf="page?.data?.length == 0"
      mat-button
      class="scope-ui-modal-button"
      (click)="data.cancelCallback()">Ok
    </button>
    <button
      *ngIf="page?.data?.length > 0"
      mat-button
      class="confirm scope-ui-modal-flat-button"
      (click)="importSecondParties()">Import ({{countSelected()}}/{{IMPORT_LIMIT}})
    </button>
  </mat-dialog-actions>
</div>

