<button class="toggle-button" [matMenuTriggerFor]="menu"
        (menuClosed)="isOpen = false; updateState()"
        (menuOpened)="isOpen = true; updateState()">
  <i class="material-icons material-symbols-rounded">filter_list</i>
</button>

<mat-menu #menu="matMenu" [class]="disableAnimation ? 'no-animate' : ''">
  <ng-template matMenuContent>
    <div
      [ngClass]="{ 'label-selected': !preference.selected, 'icon-selected': preference.selected }"
      *ngFor="let preference of _preferences">
      <mat-slide-toggle
        class="scope-ui-toggle"
        [disableRipple]="true"
        [checked]="preference.selected"
        [disabled]="selectedCount >= maxSelected && !preference.selected && preference.key !== 'SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS'"
        (change)="onTogglePreference($event, preference)">
        {{ preference.name | langf }}
      </mat-slide-toggle>
    </div>
  </ng-template>
</mat-menu>
