<div class="description-edit-row font-newJuneBold" *ngIf="isScope || status == 'DRAFT' || (status != 'DRAFT' && (description || model.internalNote))" [ngClass]="{'top-page' : isScope, 'toggle-open': visible}">
  <ng-container *ngIf="!(hideNote || showNoteBelow) && !showHideDescriptionOnly">
    <div class='flex mr-4' *ngIf="!visible && status == 'DRAFT'">
      <div class="flex items-center pointer" (click)="showDescription(); $event.stopPropagation()">
        <mat-icon class="material-symbols-rounded orange">{{description ? 'visibility' : 'edit_note'}}</mat-icon>
        <span>{{(description || model.internalNote) ? (description ? "Show Description and Internal note" : "Add Description") : "Add Description and Internal note"}}</span>
      </div>
    </div>
    <div class='flex mr-4' *ngIf="!visible && status != 'DRAFT' && (description || model.internalNote)">
      <div class="flex items-center pointer" (click)="showDescription(); $event.stopPropagation()">
        <mat-icon class="material-symbols-rounded orange">visibility</mat-icon>
        <span>{{description ? (model.internalNote ? "Show Description and Internal note" : "Show Description") : "Show Internal note"}}</span>
      </div>
    </div>
    <div class='flex mr-4' *ngIf="visible && status == 'DRAFT'">
      <div class="flex items-center pointer" (click)="hideDescription(); $event.stopPropagation()">
        <mat-icon class="material-symbols-rounded orange">visibility_off</mat-icon>
        <span>Hide Description and Internal note</span>
      </div>
    </div>
    <div class='flex mr-4' *ngIf="visible && status != 'DRAFT' && (description || model.internalNote)">
      <div class="flex items-center pointer" (click)="hideDescription(); $event.stopPropagation()">
        <mat-icon class="material-symbols-rounded orange">visibility_off</mat-icon>
        <span>{{description ? (model.internalNote ? "Hide Description and Internal note" : "Hide Description") : "Hide Internal note"}}</span>
      </div>
    </div>
    <div class='flex mr-4' *ngIf="!editingDescription && status == 'DRAFT' && description && editable">
      <div class="flex items-center pointer" (click)="editDescription(); $event.stopPropagation()">
        <mat-icon class="material-symbols-rounded orange fill">mode_edit</mat-icon>
        <span>Edit Description</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(hideNote || showNoteBelow) && !showHideDescriptionOnly">
    <div class='flex mr-4' *ngIf="!visible && !description">
      <div class="flex items-center pointer" (click)="showDescription(); $event.stopPropagation()">
        <mat-icon class="material-symbols-rounded orange">edit_note</mat-icon>
        <span>Add Description{{ descriptionRequired ? '*' : '' }}</span>
      </div>
    </div>
    <div class='flex mr-4' *ngIf="!editingDescription && description && editable">
      <div class="flex items-center pointer" (click)="editDescription(); $event.stopPropagation()">
        <mat-icon class="material-symbols-rounded orange fill">mode_edit</mat-icon>
        <span>Edit Description</span>
      </div>
    </div>
    <span *ngIf="descriptionRequired && descriptionDirty && !description" class="error">This is required</span>
  </ng-container>
  <ng-container *ngIf="showHideDescriptionOnly">
    <div class='flex mr-4' *ngIf="!visible">
      <div class="flex items-center pointer" (click)="showDescription(); $event.stopPropagation()">
        <mat-icon class="material-symbols-rounded orange">visibility</mat-icon>
        <span>Show Description</span>
      </div>
    </div>
    <div class='flex mr-4' *ngIf="visible">
      <div class="flex items-center pointer" (click)="hideDescription(); $event.stopPropagation()">
        <mat-icon class="material-symbols-rounded orange">visibility_off</mat-icon>
        <span>Hide Description</span>
      </div>
    </div>
  </ng-container>
  <div class='flex mr-4'
       *ngIf="visible && commentingService.globalUseCommentable(commentable) && commentingService.globalShowInputCommentable() && description">
    <div class="flex items-center pointer" (click)="toggleCommenting()">
      <mat-icon *ngIf="commentable?.comments?.length == 0" class="material-symbols-rounded orange">add_comment</mat-icon>
      <div class="comments-number" *ngIf="commentable?.comments?.length > 0">{{commentable?.comments?.length}}</div>
      <span>{{commenting ? 'Finish commenting' : 'Add Comments'}}</span>
    </div>
  </div>
  <div class='flex mr-4'
       *ngIf="visible && commentingService.globalUseCommentable(commentable) && !commentingService.globalShowInputCommentable()">
    <div class="flex items-center">
      <div class="comments-number" *ngIf="commentable?.comments?.length > 0">{{commentable?.comments?.length}}</div>
      <span>Comments</span>
    </div>
  </div>
</div>
<div class="flex {{showNoteBelow ? 'flex-col' : 'flex-row'}} cursor-auto gap-8 my-2" [hidden]='!visible'
     (mouseout)="disableDragging=false; disableDraggingChange.emit(disableDragging)"
     (mouseover)="disableDragging=true; disableDraggingChange.emit(disableDragging)">
  <div class="description-editor {{showNoteBelow ? 'w-full' : ''}}" [class.description-editor__text]="!editingDescription">
    <commentable
      [c-if]="commenting || (commentingService.globalUseCommentable(commentable) && !commentingService.globalShowInputCommentable())"
      [c-entity]="model"
      [c-key]="'description'"
      (onSetCommentable)="setCommentable($event)"
      data-nodrag
      *ngIf="!editingDescription && (status == 'DRAFT' || description)"
      class="description-text">
      <div [innerHTML]="description | safe:true"></div>
    </commentable>
    <ng-container *ngIf='editingDescription'>
      <ckeditor [editor]="Editor" [ngModel]="description" (ngModelChange)="description = $event; descriptionDirty = true"></ckeditor>

      <div class="flex justify-end mt-1.5 items-center">
        <button mat-button class="ml-8" (click)="cancelDescription()">
          <span class="tracking-normal">Cancel</span>
        </button>
        <button class="confirm ml-4" *ngIf="editable" (click)="saveDescription()">
          <span class="tracking-normal">Save</span>
        </button>
      </div>
    </ng-container>
  </div>

  <div class="description-editor {{showNoteBelow ? 'w-full' : ''}}" *ngIf="!hideNote && !showHideDescriptionOnly && (status == 'DRAFT' || model.internalNote)">
    <div class="internal-note flex flex-col">
      <div class="internal-note-label">Internal Note</div>

      <mat-form-field *ngIf="status == 'DRAFT'" class='w-full'>
        <textarea
          matInput
          name="internalNote"
          cols="100"
          rows="1"
          placeholder="Click here to add an internal note"
          [disabled]="model.internalNote && !editingNote"
          (focus)="editNote()"
          [maxLength]="10000"
          [(ngModel)]="internalNote">
        </textarea>
      </mat-form-field>
      <div class="text-m-top-10" *ngIf="status != 'DRAFT' && model.internalNote">{{model.internalNote}}</div>

      <div>
        <span class="save-button" *ngIf="editingNote && editable" (click)="saveNote()">Save</span>
        <span class="orange right bold pointer" *ngIf="!editingNote && model.internalNote && status == 'DRAFT'" (click)="editNote(); $event.stopPropagation()">Edit Note</span>
        <span class="cancel-button" *ngIf="editingNote" (click)="cancelNote()">Cancel</span>
      </div>
    </div>
  </div>
</div>
