import { Type } from 'class-transformer';
import { PrivilegeRuleRestriction } from '@core/model/privilege-rule-restriction.model';
import { PrivilegeStringMap } from '@core/model/definitions/privilege.builder';

export class PrivilegeRule {
  id: number;
  privilege: string;
  disabled: boolean;
  isEditEnabled: boolean;
  @Type(() => PrivilegeRuleRestriction) restrictions: PrivilegeRuleRestriction[]

  get privilegeMeta() {
    return PrivilegeStringMap.get(this.privilege)
  }

  addRestriction(restriction: PrivilegeRuleRestriction) {
    if (restriction.secondParty == null) {
      let indexOfNullableSecondPartyRestriction = this.restrictions.findIndex(it => it.secondParty == null);
      if (indexOfNullableSecondPartyRestriction >= 0) {
        this.restrictions.splice(indexOfNullableSecondPartyRestriction, 1, restriction);
      } else {
        this.restrictions.push(restriction);
      }
    } else {
      this.restrictions.push(restriction);
    }
  }
}
