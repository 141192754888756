import { Component, Inject, Input } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SharedModule } from '@shared/shared.module'
import { FormGroup, UntypedFormGroup } from '@angular/forms'
import { GenericModalInput, ModalConfig } from '@core/model/modal-config.model'
import { ScopeUiAutocompleteComponent } from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { User } from '@core/model/user.model'
import { CdnConfig } from '@core/model/cdn-config.model'
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeUiDuration } from '@shared/components/ui-components/scope-ui-duration/scope-ui-duration.component'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import {
  ScopeUiDropdownComponent
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';

@Component({
  selector: 'scope-ui-generic-modal',
  templateUrl: './scope-ui-modal.component.html',
  styleUrls: ['./scope-ui-modal.component.scss'],
  imports: [SharedModule, ScopeUiAutocompleteComponent, NgxMaskDirective, ScopeUiDuration, ScopeUiInputComponent, ScopeUiDropdownComponent],
  standalone: true,
})
export class ScopeUiModalComponent {
  @Input() cdnConfig?: CdnConfig

  modalForm: UntypedFormGroup

  selections: any[] = []

  editDescription: boolean = false

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalConfig) {
    this.modalForm = new FormGroup(Object.fromEntries(data.inputs.map((input: any) => [input.name, input.control])))
  }

  confirm() {
    if (this.data.confirmCallback) {
      this.data.confirmCallback(this.modalForm, this.selections)
    }
  }

  cancel() {
    if (this.data.cancelCallback) {
      this.data.cancelCallback(this.modalForm)
    }
  }

  onInputChange(value: string | boolean, formControlName: string) {
    this.modalForm.get(formControlName).setValue(value)
  }

  onAutocompleteSelect($event: { event: any; componentId?: any }, input: GenericModalInput) {
    if ($event.event) {
      this.selections.push($event.event)
      input.options = input.options?.filter((option) => option.id !== $event.event.id)
    } else {
      this.selections = []
    }
  }

  saveDescription(description: string) {
    this.modalForm.get('description').setValue(description)
  }

  setStartDate(startDate: Date) {
    let value = this.modalForm.get('duration').value || {}
    this.modalForm.get('duration').setValue({ ...value, startDate })
  }

  setEndDate(endDate: Date) {
    let value = this.modalForm.get('duration').value || {}
    this.modalForm.get('duration').setValue({ ...value, endDate })
  }

  protected readonly User = User
}
