import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { LoginFormComponent } from './features/user-account/components/login/login-form.component';
import { isLoggedInGuard } from '@core/guard/is-logged-in-guard';
import { LoaderModalComponent } from '@shared/components/loader-modal/loader-modal.component';
import { MyScopesComponent } from './features/scoping/components/myscopes/myscopes.component';
import { ManageComponent } from './features/company-management/components/manage/manage.component';
import { LibraryHomeComponent } from './features/library-management/components/library-home/library-home.component';
import { DashboardOverviewComponent } from './features/dashboards/components/dashboard-overview/dashboard-overview.component';
import { SystemAdminOverviewComponent } from './features/system-admin/components/system-admin-overview/system-admin-overview.component';
import { ProfileComponent } from './features/user-account/components/profile/profile.component';
import { NotificationSettingsComponent } from './features/user-account/components/notification-settings/notification-settings.component';
import { UserAccountRoutingModule } from './features/user-account/user-account-routing.module';
import { Privilege } from '@core/model/enums/privilege.enum';
import { UserRoleLevel } from '@app/core/model/enums/user-role-level.enum';
import { privilegesGuard } from '@core/guard/privilege-guard';
import { UnauthorisedComponent } from '@shared/components/unauthorised/unauthorised.component';
import { roleGuard } from '@core/guard/role-based-guard';
import { ErrorComponent } from '@shared/components/error/error.component';
import { CreateScopeComponent } from "@app/features/scoping/components/create-scope/create-scope.component";
import { CreateTemplateComponent } from './features/scoping/components/create-template/create-template.component';
import { CreateFolderComponent } from './features/scoping/components/create-folder/create-folder.component';
import {
  DeliverableBuilderComponent
} from "@app/features/scoping/components/deliverable-builder/deliverable-builder.component";
import { FolderOverviewComponent } from "@app/features/scoping/components/folder-overview/folder-overview.component";
import {
  AccountSetupComponent
} from "@app/features/company-management/components/account-setup/account-setup.component";
import {
  InvitationSetupComponent
} from "@app/features/company-management/components/invitation-setup/invitation-setup.component";
import {
  AccountVerificationComponent
} from '@app/features/user-account/components/account-verification/account-verification.component';
import { DuplicateFolderComponent } from '@app/features/scoping/components/duplicate-folder/duplicate-folder.component'
import { ScopeGuard } from './features/scope-overview/guard/scope-overview.guard';
import {
  DuplicateScopeTemplateComponent
} from "@app/features/scope-overview/components/scope-tab/duplicate-scope-template/duplicate-scope-template.component";
import { RatecardComponent } from '@app/features/company-management/components/ratecard/ratecard.component';
import { SecondPartyComponent } from '@app/features/company-management/components/second-party/second-party.component';
import { GroupComponent } from '@app/features/company-management/components/group/group.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {path: '', canActivateChild:[isLoggedInGuard], children: [
    {
      path: '',
      redirectTo: 'scopes',
      pathMatch: 'full',
    },
    {
      path: 'scopes',
      component: MyScopesComponent,
      loadChildren: () => import('./features/scoping/scoping.module').then((m) => m.ScopingModule),
      title: 'My Scopes | Scope',
      data: {
        breadcrumb: 'My Scopes',
      },
    },
    {
      path: 'create-scope',
      component: CreateScopeComponent,
      title: 'Create Scope By Work | Scope',
      data: {
        breadcrumb: 'Create Scope',
      },
    },
    {
      path: 'create-scope-by-role',
      component: CreateScopeComponent,
      title: 'Create Scope By Role | Scope',
      data: {
        breadcrumb: 'Create Scope',
      },
    },
    {
      path: 'create-template',
      component: CreateTemplateComponent,
      title: 'Create Template | Scope',
      data: {
        breadcrumb: 'Create Template',
      },
    },
    {
      path: 'create-folder',
      component: CreateFolderComponent,
      title: 'Create Folder | Scope',
      data: {
        breadcrumb: 'Create Folder',
      },
    },
    {
      path: 'duplicate-folder/:id',
      component: DuplicateFolderComponent,
      title: 'Duplicate Folder | Scope',
      data: {
        breadcrumb: 'Duplicate Folder',
      },
    },
    {
      path: 'create-matter',
      component: CreateScopeComponent,
      title: 'Create Matter | Scope Matters',
      data: {
        breadcrumb: 'Create Matter',
      },
    },
    {
      path: 'duplicate-scope/:id',
      component: CreateScopeComponent,
      title: 'Duplicate Scope | Scope',
      data: {
        breadcrumb: 'Duplicate Scope',
      },
    },
    {
      path: 'use-template/:id',
      component: CreateScopeComponent,
      title: 'Create Scope From Template | Scope',
      data: {
        breadcrumb: 'Create Scope From Template',
      },
    },
    {
      path: 'scopes/scope-overview/:id',
      loadChildren: () =>
        import('./features/scope-overview/scope-overview.module').then((m) => m.ScopeOverviewModule),
        canActivate: [ScopeGuard],
    },
    {
      path: 'scopes/scope-by-role-overview/:id',
      loadChildren: () =>
        import('./features/scope-overview/scope-overview.module').then((m) => m.ScopeOverviewModule),
        canActivate: [ScopeGuard],
    },
    {
      path: 'scopes/scope-overview/:id/deliverable/:deliverableId',
      component: DeliverableBuilderComponent,
    },
    {
      path: 'duplicate-template/:id',
      component: DuplicateScopeTemplateComponent,
    },
    {
      path: 'scopes/folder-overview/:id',
      component: FolderOverviewComponent,
    },
    {
      path: 'manage',
      component: ManageComponent,
      loadChildren: () =>
        import('./features/company-management/company-management.module').then((m) => m.CompanyManagementModule),
      data: {
        privileges: [
          Privilege.COMPANY_MEMBERS__VIEW,
          Privilege.SECOND_PARTY__VIEW,
          Privilege.RATECARD__VIEW,
          Privilege.COMPANY_TRAFFIC_SYSTEM__VIEW,
          Privilege.COMPANY_DETAILS__VIEW,
        ],
        breadcrumb: 'Manage',
      },
      canActivate: [privilegesGuard],
    },
    {
      path: 'account-setup/:id',
      component: AccountSetupComponent,
      data: {
        breadcrumb: 'Company Setup',
      }
    },
    {
      path: 'manage/ratecard',
      component: RatecardComponent,
      data: {
        breadcrumb: 'Ratecard',
      }
    },
    {
      path: 'manage/ratecard/:id',
      component: RatecardComponent,
      data: {
        breadcrumb: 'Ratecard',
      }
    },
    {
      path: 'manage/client/:id',
      component: SecondPartyComponent,
      data: {
        breadcrumb: 'Client',
      }
    },
    {
      path: 'manage/group/:id',
      component: GroupComponent,
      data: {
        breadcrumb: 'Group',
      }
    },
    {
      path: 'invitation/:id',
      component: InvitationSetupComponent,
      data: {
        breadcrumb: 'Sign Up',
        disabledAuthorizationCheck: true
      }
    },
    {
      path: 'account-verification',
      component: AccountVerificationComponent,
      data: {
        breadcrumb: 'Account Verification',
        disabledAuthorizationCheck: true
      }
    },
    {
      path: 'library',
      loadChildren: () =>
        import('./features/library-management/library-management.module').then((m) => m.LibraryManagementModule),
      data: {
        privileges: [
          Privilege.LIBRARY_COMPONENT__VIEW,
          Privilege.LIBRARY_DELIVERABLE__VIEW,
          Privilege.LIBRARY_THIRD_PARTY_COST__VIEW,
        ],
        breadcrumb: 'Library',
      },
      title: 'Library | Scope',
      canActivate: [privilegesGuard],
    },
    {
      path: 'dashboard/:area',
      component: DashboardOverviewComponent,
      loadChildren: () =>
        import('./features/dashboards/dashboard-overview.module').then((m) => m.DashboardOverviewModule),
      data: {
        privileges: [Privilege.DASHBOARD__VIEW],
        breadcrumb: 'Dashboard',
      },
      title: 'Quantity | Dashboard | Scope',
      canActivate: [privilegesGuard],
    },
    {
      path: 'system-management',
      component: SystemAdminOverviewComponent,
      loadChildren: () => import('./features/system-admin/system-admin.module').then((m) => m.SystemAdminModule),
      data: {
        role: UserRoleLevel.SYSTEM,
      },
      title: 'System Admin | Scope',
      canActivate: [roleGuard],
    },
    {
      path: 'profile',
      component: ProfileComponent,
      title: 'Profile | Scope',
    },
    {
      path: 'notifications',
      component: NotificationSettingsComponent,
    },
    {
      path: 'ui-demo',
      title: 'Scope UI Component Demo',
      loadChildren: () => import('./ui-demo/ui-demo/ui-demo.module').then((m) => m.UiDemoModule),
      data: {
        breadcrumb: 'UI Demo',
      },
    },
    {
      path: 'headers',
      loadChildren: () => import('./shared/site-header/site-header.module').then((m) => m.SiteHeaderComponentModule),
    },
    {
      path: 'auth',
      loadChildren: () => import('./features/user-account/user-account.module').then((m) => m.UserAccountModule),
    },
    {
      path: 'login',
      component: LoginFormComponent,
      title: 'Login | Scope',
      data: {
        disabledAuthorizationCheck: true,
        redirectToScopes: true
      }
    },
    {
      path: 'externalRedirect',
      resolve: {
        url: externalUrlProvider,
      },
      component: LoaderModalComponent,
      data: {
        disabledAuthorizationCheck: true
      }
    },
    {
      path: 'unauthorised',
      component: UnauthorisedComponent,
    },
    {
      path: 'error',
      component: ErrorComponent,
    },
    {
      path: '**',
      component: NotFoundComponent,
    }
  ]}
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl?.toString(), '_self');
      },
    },
  ],
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true }), UserAccountRoutingModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
