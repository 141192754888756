import { Component, Inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '@app/shared/pipe/index.module'
import { SharedModule } from '@app/shared/shared.module'
import { AuthService } from '@core/service/auth.service';
import { LanguageService } from '@core/service/language.service';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import {
  OutputThemeDefinition,
  OutputThemeService,
} from '@app/features/company-management/service/output-theme.service';
import { CdnConfig } from '@core/model/cdn-config.model';
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { Store } from '@ngrx/store';
import { trackById, untilDestroyed } from '@shared/utils/utils';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { plainToInstance } from 'class-transformer';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import {
  ScopeUiDropdownComponent
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { Languages } from '@core/model/enums/language.enum';
import { CKEditorModule } from 'ckeditor4-angular';
import { ckeConfig } from '@shared/utils/ckeConfig';

@Component({
  selector: 'add-output-modal',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDialogModule, SharedModule, ScopeUiInputComponent, NgOptimizedImage, ScopeUiDropdownComponent, CKEditorModule],
  templateUrl: './add-output-modal.component.html',
  styleUrls: ['./add-output-modal.component.scss'],
})
export class AddOutputModalComponent {
  private readonly destroy$;
  defaultLanguage: string
  name = ''
  scopeTemplates: OutputThemeDefinition[]
  sowTemplates: OutputThemeDefinition[]
  cdnConfig: CdnConfig
  formSubmitted: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: string, successFn?: (result) => void },
    public dialog: MatDialog,
    private authService: AuthService,
    private langService: LanguageService,
    private themeService: OutputThemeService,
    private manageService: CompanyManagementService,
    private store: Store,
    private snackbarService: SnackbarService
  ) {
    this.destroy$ = untilDestroyed();
    this.defaultLanguage = authService.loggedInUser.company.language
    this.scopeTemplates = Object.values(themeService.scopeTemplates)
      .filter(c => c.companyType.includes(String(authService.loggedInUser.company.companyType)) &&
        c.languages.includes(Languages[authService.loggedInUser.company.language]))
      .map(t => {
        t.selectedLanguage = Languages[authService.loggedInUser.company.language]
        return t
      })
    this.sowTemplates = Object.values(themeService.sowTemplates)
      .filter(c => c.companyType.includes(String(authService.loggedInUser.company.companyType)) &&
        c.languages.includes(Languages[authService.loggedInUser.company.language]))
      .map(t => {
        t.selectedLanguage = Languages[authService.loggedInUser.company.language]
        return t
      })

    this.getCdnConfig()
  }

  getCdnConfig() {
    this.store.select(BootstrapSelectors.selectCdnConfig).pipe(this.destroy$()).subscribe((config: CdnConfig) => {
      this.cdnConfig = config!;
    });
  }

  getTitle() {
    if (this.data.type === 'scope') {
      return `Theme Gallery for a Single ${this.langService.get('scope')} ${this.langService.get('doc_template')}`;
    } else {
      return `Theme Gallery for a ${this.langService.get('scope_of_work')} ${this.langService.get('doc_template')}`;
    }
  }

  createTemplate(documentTemplate: OutputThemeDefinition) {
    this.formSubmitted = true
    if(!this.name) {
      return;
    }

    let template = plainToInstance(OutputTemplate,
      {
        name: this.name,
        thumbnailUri: documentTemplate.thumbnailUri,
        documentTemplate: documentTemplate.name,
        language: documentTemplate.selectedLanguage.code,
        templateType: this.data.type.toUpperCase(),
        enableTemplateBorders: false,
        pageBreakable: documentTemplate.pageBreakable,
        locked: false,
        shared: false,
        inherited: false,
        outputFields: []
      })

    this.themeService.composeSections(template, documentTemplate).subscribe({
      next: () => {
        this.manageService.createCompanyCustomOutputTemplate(template).subscribe({
          next: (result) => {
            this.data.successFn(result)
          },
          error: (response) => {
            let message = "Unable to add {LANG:'output|l'}. Please try again later";
            if (response.status == 409) {
              message = this.langService.get('scope') + " template '" + this.name + "' already exists.";
            }
            this.snackbarService.showSnackbar(message, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
          }
        })
      }
    })
  }

  protected readonly trackById = trackById;
  protected readonly ckeConfig = ckeConfig;
}
