import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@core/service/language.service';
import { MatDialog } from '@angular/material/dialog';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import { trackById } from '@shared/utils/utils';
import { FormControl } from '@angular/forms';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { RatecardStructureVersion } from '@app/features/scope-overview/model/ratecard-structure.model';
import { Company } from '@core/model/company.model';
import { UploadModalComponent } from '@shared/components/upload-modal/upload-modal.component';
import { ScopeTraffickingService } from '@app/features/scope-overview/service/scope-trafficking.service';
import {
  ImportRateCardModalComponent,
} from '@app/features/company-management/components/import-rate-card-modal/import-rate-card-modal.component';
import { UploadType } from '@app/features/company-management/models/upload-modal-config';

@Component({
  selector: 'rate-cards',
  templateUrl: './rate-cards.component.html',
  styleUrls: ['./rate-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateCardsComponent {
  loggedInUser!: User
  loadingRateCards = true
  rateCards: RatecardVersion[]
  filteredRateCards: RatecardVersion[]
  rateCardStructures: RatecardStructureVersion[]
  countCostPluses: number
  menuOptions: any[]
  downloadInProgress = {}
  searchText = ''

  constructor(private authService: AuthService,
              private manageService: CompanyManagementService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              public route: ActivatedRoute,
              private lang: LanguageService,
              private dialog: MatDialog,
              private snackbarService: SnackbarService,
              private traffickingService: ScopeTraffickingService) {
    this.loggedInUser = this.authService.loggedInUser
    this.retrieveRateCards()
    if (this.loggedInUser.hasPrivilege("RATECARD_STRUCTURE__VIEW")) {
      this.retrieveRateCardStructures()
    }
    if (this.loggedInUser.company.hasApplicationSetting('COST_PLUS')) {
      this.retrieveCostPlus()
    }

    this.menuOptions = [
      {
        name: 'Import',
        icon: 'import_contacts',
        click: () => this.openImportRateCardModal(),
        isVisible: () => {
          return this.hasValidThirdPartySystemSyncSettings() &&
            this.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__IMPORT')
        }
      },
      {
        name: 'Sync',
        icon: 'sync',
        click: () => this.syncImported(),
        isVisible: () => {
          return this.hasValidThirdPartySystemSyncSettings() &&
            this.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__IMPORT')
        }
      },
      {
        name: `Upload Rate Card`,
        icon: 'import_export',
        click: () => this.upload(),
        isVisible: () => {
          return this.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__IMPORT')
        }
      },
      {
        name: `Export`,
        icon: 'file_download',
        click: () => this.export(),
        isVisible: () => {
          return this.rateCards?.length != 0
        }
      }
    ]
  }

  filterRateCards() {
    if (this.searchText) {
      let lowerCaseSearchText = this.searchText.toLowerCase();
      this.filteredRateCards = this.rateCards.filter(r => r.name.toLowerCase().includes(lowerCaseSearchText))
    } else {
      this.filteredRateCards = this.rateCards
    }
  }

  hasValidThirdPartySystemSyncSettings() {
    let company = this.loggedInUser.company;
    let ts = company.trafficSystemSettings ? company.trafficSystemSettings.trafficSystem : null;
    if (ts == null) {
      return false;
    }
    if (ts.sourceType == "SCOPE"){
      return true;
    }
    let config = ts.externalTrafficSystemConfig
    return !config || (config.ratecard && config.ratecard.url)
  }

  retrieveRateCards() {
    this.manageService.getAllRateCards().subscribe({
      next: (result) => {
        this.loadingRateCards = false
        this.rateCards = result
        this.filteredRateCards = result
        this.cdr.detectChanges()
      }
    })
  }

  retrieveRateCardStructures() {
    this.manageService.getRateCardStructures().subscribe({
      next: (result: RatecardStructureVersion[]) => {
        this.rateCardStructures = result.filter(r => !r.identity.archived).sort((a, b) => a.name.localeCompare(b.name))
      }
    })
  }

  retrieveCostPlus() {
    this.manageService.getAllCostPluses().subscribe({
      next: (result) => {
        this.countCostPluses = result.length;
      }
    })
  }

  selectRateCard(id) {
    this.router.navigate(
      [`/manage/ratecard/${id}`],
      {
        relativeTo: this.route
      }
    )
  }

  showDeleteRateCardModal(rateCard: RatecardVersion) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete Rate Card`,
        `Are you sure you want to delete rate card ${rateCard.name}?`,
        'Delete',
        undefined,
        () => {
          dialog.close()
          this.manageService.deleteRateCard(rateCard.rateCardIdentity.id).subscribe({
            next: () => {
              this.rateCards.splice(this.rateCards.indexOf(rateCard), 1)
              this.filterRateCards()
              this.cdr.detectChanges()
              this.snackbarService.showSnackbar(`${rateCard.name} successfully deleted`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            },
            error: () => this.snackbarService.showDefaultErrorSnackbar()
          })
        },
        undefined,
        undefined,
        undefined,
        true
      ),
    })
  }

  showDuplicateRateCardModal(rateCard: RatecardVersion) {
    let name = null
    let ind = 1
    while (name == null) {
      let rcName = rateCard.name + " (Duplicate " + ind + ")"
      let found = this.rateCards.find((rc) => {
        return rc.name.toLowerCase() == rcName.toLowerCase()
      })
      if (!found) name = rcName
      ind++;
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Duplicate Rate Card`,
        `Are you sure you want to duplicate rate card ${rateCard.name}?`,
        'Duplicate',
        undefined,
        (form: FormControl) => {
          dialog.close()
          this.manageService.duplicateRateCard(rateCard.id,
            { ratecardName: form.get('name')?.value, currency: form.get('currency')?.value }).subscribe({
            next: (result) => {
              this.rateCards.push(result)
              this.filterRateCards()
              this.cdr.detectChanges()
              this.snackbarService.showSnackbar(`${rateCard.name} successfully duplicated`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            },
            error: (error) => {
              if (error.status == 409) {
                form.get('name').setErrors({ conflict: true })
              } else {
                this.snackbarService.showDefaultErrorSnackbar()
              }
            }
          })
        },
        undefined,
        [
          { name: 'name', control: new FormControl(name), type: 'text', required: true,
            label: `Rate card name`, maxLength: 100 },
          { name: 'currency', control: new FormControl(rateCard.currencyCode), type: 'select', required: true,
            label: `Currency`, options: this.manageService.currencies }
        ],
        undefined,
        true,
        undefined,
        (form: FormControl) => !!form.get('name')?.value && form.get('currency')?.value
      ),
    })
  }

  showAddRateCardDialog() {
    let inputs: any[] = [
      { name: 'name', control: new FormControl(''), type: 'text', required: true,
        label: `Rate card name`, maxLength: 100 },
      { name: 'currency', control: new FormControl(null), type: 'select', required: true,
        label: `Currency`, options: this.manageService.currencies }
    ]

    if (this.loggedInUser.hasPrivilege('RATECARD_STRUCTURE__VIEW')) {
      inputs.push({ name: 'structure', control: new FormControl(null), type: 'select',
        label: `Rate Card Structure`, options: this.rateCardStructures })
    }

    if (this.loggedInUser.company.hasApplicationSetting('COST_PLUS') && this.countCostPluses) {
      inputs.push({ name: 'costPlus', control: new FormControl(false), type: 'toggle', label: `Cost Plus`,
        required: false })
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Create Rate Card`,
        `Create a new rate card providing name and currency. Optionally use a Rate Card Structure to base the rate card off.`,
        `Start building Rate Card`,
        undefined,
        (form: FormControl) => {
          let name = form.get('name').value
          if (this.rateCards.find(r => r.name === name)){
            form.get('name').setErrors({conflict: true})
            return
          }
          if (form.get('structure')?.value) {
            let newRateCard = {
              name: name,
              currencyUnit: form.get('currency').value,
              structureIdentity: { id: form.get('structure').value.identity.id },
              costPlus: form.get('costPlus').value
            }
            this.manageService.createRatecardFromStructure(newRateCard).subscribe({
              next: (result) => {
                dialog.close()
                this.selectRateCard(result.rateCardIdentity.id)
              }
            })
          } else {
            dialog.close()
            this.router.navigate(
              [`/manage/ratecard`],
              {
                relativeTo: this.route,
                queryParams: {
                  isDefault: false,
                  currencyCode: form.get('currency').value,
                  name: name,
                  isCostPlus: form.get('costPlus')?.value
                }
              },
            )
          }
        },
        undefined,
        inputs,
        false,
        true,
        undefined,
        (form: FormControl) => !!form.get('name')?.value && form.get('currency')?.value
      ),
    })
  }

  isParentCard(rateCard: RatecardVersion, company: Company){
    return rateCard.isOwnedByParentOf(company);
  }

  openImportRateCardModal(){
    this.traffickingService.assertActiveSystemConfigured(this.loggedInUser.company)

    let dialog = this.dialog.open(ImportRateCardModalComponent, {
        data: {
          trafficSystem: this.loggedInUser.company.trafficSystemSettings.trafficSystem,
          confirmCallback: (data: any[], hasErrors: boolean) => {
            data.forEach((rateCard) => {
              this.rateCards.push(rateCard)
            })
            this.filterRateCards()
            this.cdr.detectChanges()

            if (!hasErrors) {
              dialog.close()
            }
          },
          cancelCallback: () => dialog.close()
        }
      }
    )
  }

  syncImported() {
    this.traffickingService.assertActiveSystemConfigured(this.loggedInUser.company)

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Sync Rate cards`,
        'Syncing Options',
        `Sync`,
        undefined,
        (form: FormControl) => {
          this.manageService.syncTrafficImportsRateCard(form.get('appSettings').value).subscribe({
            next: () => {
              dialog.close()
              this.retrieveRateCards()
            },
            error: (error) => {
              this.traffickingService.validateServerError(error, () => dialog.close(), () => this.renderDownloadFileSync())
            }
          })
        },
        undefined,
        [
          { name: 'appSettings', control: new FormControl(''), type: 'toggle', required: true,
            label: `Force Sync Rate card names` }
        ],
        false,
        true,
        undefined
      ),
    })
  }

  renderDownloadFileSync () {
    this.manageService.renderTrafficSystemErrorOnSync('secondparty').subscribe({
      next: (response: any) => {
        let blob = new Blob([JSON.stringify(response.data)], {type: 'text/plain'})
        let contentDispositionHeader = response.headers('Content-Disposition')
        let result = (contentDispositionHeader.split(';')[1].trim().split('=')[1]).replace(/"/g, '').trim()
        let name = result.length > 0 ? result : "fileData.txt"
        let downloadUrl = (window.URL || window.webkitURL).createObjectURL(blob)
        let a = $(`<a href='${downloadUrl}' download="${name}"></a>`)
        a[0].click()
      },
      error: (error) => {
        if (error.status == 404) {
          this.snackbarService.showSnackbar("The cached data has expired.", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
        }
      }
    })
  }

  upload() {
    let dialog = this.dialog.open(UploadModalComponent, {
        data: {
          title: 'Upload Rate Card',
          body: `Upload Rate Card using Excel file`,
          type: UploadType.RATECARD,
          verifyService: this.manageService,
          submitFn: (file: File) => this.manageService.import(file, 'ratecard'),
          successFn: () => {
            dialog.close()
            this.snackbarService.showSnackbar(`File was successfully uploaded. Please, refresh the page.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.SUCCESS)
          },
          showBody: true
        }
      }
    )
  }

  private export() {
    let options = [{ id: null, name: 'All Rate Cards' }].concat(this.rateCards)

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Download Rate Card(s)`,
        undefined,
        `Download`,
        undefined,
        (form: FormControl) => {
          dialog.close()
          let rateCard = form.get('rateCard').value
          if (this.downloadInProgress[rateCard.id]) {
            this.snackbarService.showSnackbar("Download already in progress", SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
          } else {
            this.manageService.requestExportOfRatecard(rateCard).subscribe({
              next: (result: any) => {
                if (!result.resolved) {
                  this.downloadInProgress[rateCard.id] = true
                  this.snackbarService.showSnackbar("Export started and when complete file will automatically download", SNACKBAR_LENGTH_LONG, SnackbarEventType.INFO)
                }
              },
              error: () => {
                this.downloadInProgress[rateCard.id] = false
                this.snackbarService.showSnackbar("Failed to download export", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
              }
            });
          }
        },
        undefined,
        [
          { name: 'rateCard', control: new FormControl({ id: null, name: 'All Rate Cards' }), type: 'select', required: true,
            label: `Choose the rate card`, options: options }
        ],
        false,
        true,
        undefined
      ),
    })
  }

  protected readonly trackById = trackById;
}
