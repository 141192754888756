import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';
import { LanguageService } from '@core/service/language.service';
import { MatDialog } from '@angular/material/dialog';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { SNACKBAR_LENGTH_SHORT, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { trackById, untilDestroyed } from '@shared/utils/utils';
import { FormControl } from '@angular/forms';
import { ScopeType } from '@app/features/scope-overview/model/scope-type.model';
import { Company } from '@core/model/company.model';

@Component({
  selector: 'scope-types',
  templateUrl: './scope-types.component.html',
  styleUrls: ['./scope-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScopeTypesComponent {
  private readonly destroy$;

  loggedInUser!: User
  loadingScopeTypes = true
  scopeTypes: ScopeType[]

  constructor(private authService: AuthService,
              private manageService: CompanyManagementService,
              private cdr: ChangeDetectorRef,
              private lang: LanguageService,
              private dialog: MatDialog,
              private snackbarService: SnackbarService) {
    this.destroy$ = untilDestroyed();
    this.loggedInUser = this.authService.loggedInUser
    this.retrieveGroups()
  }

  retrieveGroups() {
    this.manageService.getScopeTypes().subscribe({
      next: (result) => {
        this.loadingScopeTypes = false
        this.scopeTypes = result
        this.cdr.detectChanges()
      }
    })
  }

  isParentCard(scopeType: ScopeType, company: Company){
    return scopeType.isOwnedByParentOf(company);
  }

  showEditScopeTypeDialog(scopeType: ScopeType) {
    const disabled = !this.loggedInUser.hasPrivilege('SCOPE_TYPE__EDIT') || this.isParentCard(scopeType, this.loggedInUser.company);

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `${disabled ? 'View' : 'Edit'} ${this.lang.get('scope_type')}`,
        disabled ? "" : `Enter the details of the  ${this.lang.get('scope_type')}`,
        disabled ? "Close" :"Update",
        undefined,
        (form: FormControl) => {
          if (disabled) {
            dialog.close()
            return
          }

          let name = form.get('name').value
          let description = form.get('description').value
          this.manageService.updateScopeType({ ...scopeType, name: name, description: description }).subscribe({
            next: (result) => {
              scopeType.name = name
              scopeType.description = description
              this.snackbarService.showSnackbar(`${result.name} was updated`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
              this.cdr.detectChanges()
              dialog.close()
            },
            error: (error) => {
              if (error.status == 409) {
                form.get('name').setErrors({ conflict: true })
              } else {
                this.snackbarService.showDefaultErrorSnackbar()
              }
            }
          })
        },
        undefined,
        [
          { name: 'name', control: new FormControl(scopeType.name), type: 'text', required: true, label: `Name`,
            maxLength: 30, disabled: disabled },
          { name: 'description', control: new FormControl(scopeType.description), type: 'textarea', required: true,
            label: `Description`, maxLength: 10000, disabled: disabled }
        ],
        false,
        true,
        undefined,
        (form: FormControl) => !!form.get('name')?.value && !!form.get('description')?.value
      ),
    })
  }

  showAddScopeTypeDialog() {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Add ${this.lang.get('scope_type')}`,
        `Enter the details of the  ${this.lang.get('scope_type')}`,
        `Create`,
        undefined,
        (form: FormControl) => {
          let name = form.get('name').value
          let description = form.get('description').value
          this.manageService.createScopeType({name: name, description: description}).subscribe({
            next: (result) => {
              this.snackbarService.showSnackbar(`${result.name} was created`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
              this.scopeTypes.push(result)
              this.cdr.detectChanges()
              dialog.close()
            },
            error: (error) => {
              if (error.status == 409) {
                form.get('name').setErrors({ conflict: true })
              } else {
                this.snackbarService.showDefaultErrorSnackbar()
              }
            }
          })
        },
        undefined,
        [
          { name: 'name', control: new FormControl(''), type: 'text', required: true, label: `Name`, maxLength: 30 },
          { name: 'description', control: new FormControl(''), type: 'textarea', required: true, label: `Description`, maxLength: 10000 }
        ],
        false,
        true,
        undefined,
        (form: FormControl) => !!form.get('name')?.value && !!form.get('description')?.value
      ),
    })
  }

  showDeleteScopeTypeModal(scopeType: any) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete ${this.lang.get('scope_type')}`,
        `Are you sure you want to delete '${scopeType.name}'`,
        'Delete',
        undefined,
        () => {
          dialog.close()
          this.manageService.deleteScopeType(scopeType.id).subscribe({
            next: () => {
              this.scopeTypes.splice(this.scopeTypes.indexOf(scopeType), 1)
              this.cdr.detectChanges()
              this.snackbarService.showSnackbar(`${scopeType.name} successfully deleted`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            },
            error: () => this.snackbarService.showDefaultErrorSnackbar()
          })
        }
      ),
    })
  }

  protected readonly trackById = trackById;
}
