<div class="scope-ui-card" [ngClass]="onClick ? 'pressed' : 'unpressed'">
  <div class="flex gap-1">
    <div class="w-full">
      <div class="status">
        <div class="flex flex-col gap-2">
          <div class="flex" *ngIf="hasIcon || hasStatus">
            <ng-container *ngIf="hasIcon">
              <i [ngClass]="statusIconClass" class="material-icons material-symbols-rounded">{{ statusIcon }}</i>
            </ng-container>
            <ng-container *ngIf="hasStatus">
              <p class="status-text">{{ status }}</p>
            </ng-container>
          </div>
          <div class="card-title" isEllipsisActive>{{ cardTitle }}</div>
          <ng-container *ngIf="hasSubtitle">
            <div [ngClass]="{ bold: isBold }" class="sub-title">{{ subTitle }}</div>
          </ng-container>
        </div>
        <div class="profile-pic" *ngIf="hasProfilePic">
          <ng-container *ngIf="{ showAlt: false } as memberScope">
            <div *ngIf='memberScope.showAlt' class="user-avatar-placeholder">
              <p>{{ user.firstName.charAt(0) }}</p>
            </div>
            <img *ngIf='!memberScope.showAlt' class="user-avatar-placeholder" src="{{cdnConfig!.userProfileUrl + '/' + user.photoUri}}" alt="" (error)="memberScope.showAlt = true">
          </ng-container>
        </div>
      </div>

      <a class="pt-2.5" *ngIf="showDropdownToggle" (click)="$event.stopPropagation(); showDropdown = !showDropdown">{{ showDropdown ? 'Hide' : 'Show' }} {{ dropdownToggleText }}</a>

      <ng-container *ngIf="hasMetadata">
        <div class="meta-data">{{ metaData }}</div>
      </ng-container>

      <ng-container *ngIf="hasInputField">
        <scope-ui-input
          [hasPlaceholder]="hasPlaceholder"
          [inputPlaceholder]="inputPlaceholder"
          [inputLabel]="inputLabel"
          (onInputChange)="onInput($event)" />
      </ng-container>
    </div>

    <div class="flex flex-1 justify-end" *ngIf="hasButtons">
      <div class="action-buttons" *ngIf="hasButtons">
        <button *ngFor="let btn of actionButtons" (click)="handleButtonClick(btn, $event)" [disabled]="btn.disabled"
          [has-privilege]="btn.hasPrivilege" [has-privilege-entity]="btn.hasPrivilegeEntity">
          <i class="material-symbols-rounded">{{ btn.icon }}</i>
          <span>{{ btn.name }}</span>
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="hasDropDown && (!showDropdownToggle || showDropdown)">
    <scope-ui-dropdown
      class="pt-1 w-full"
      [dropdownClass]="'medium-dropdown'"
      [controlLabel]="controlLabel"
      [dropdownOptions]="dropdownOptions"
      [isStandardDropdown]="isStandardDropdown"
      [customSelectIcon]="customSelectIcon"
      [selectedValue]="dropdownSelectedValue"
      [valueFn]="dropdownValueFn"
      [minimum]="dropdownMinimum"
      (click)="$event.stopPropagation()"
      (onSelectionChange)="onSelection($event)" />
  </ng-container>
</div>
