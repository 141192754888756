export enum UserPreferencesColumns {
  MYSCOPES_USERCOLUMNS = 'myscopes-userColumns',
  SOWOVERVIEW_USERCOLUMNS = 'sowoverview-userColumns',
  SCOPEOVERVIEW_USERCOLUMNS = 'scopeoverview-userColumns',
  LIBRARY_USERCOLUMNS = 'library-userColumns',
  LIBRARY_ENTRIES_USERCOLUMNS = 'libraryEntries-userColumns',
  LIBRARY_FOLDERS_USERCOLUMNS = 'libraryFolders-userColumns',
  SECONDPARTIES_PAGESIZE = 'secondparties-pageSize',
  TASKS_USERCOLUMNS = 'tasks-userColumns'
}
