<div class="h-full" aria-label="Template preview">
  <mat-dialog-content class="output-preview-modal" (keydown)="onKeyDown($event)">
    <div class="preview-modal-header">
      <h6 class="product-heading-bold">{{loading || errorState ? 'Template preview' : 'Document / Template preview'}}</h6>
      <button mat-icon-button [disabled]="loading" (click)="download()"><mat-icon class="material-symbols-rounded">file_download</mat-icon></button>
      <button mat-icon-button mat-dialog-close><mat-icon class="material-symbols-rounded">close</mat-icon></button>
    </div>
    <div class="preview-modal-subheader">
      <h3>{{loading || errorState ? outputTemplateName : documentName + ' / ' + outputTemplateName}}</h3>
      <div>
        <label>Pages:</label><span>{{pageCount}} pages</span>
        <label class="language-label">Language:</label><span>{{templateLanguage}}</span>
      </div>
      <p>Please note the appearance of the document may vary depending on the software used. To access the final version, download and open using your preferred tool.</p>
    </div>
    <div *ngIf="loading" class="preview-loading-container">
      <div>
        <span>Please bear with us for<br>a few seconds as we fetch your data.</span>
        <mat-progress-bar mode="determinate" value="{{progress.count}}"/>
      </div>
    </div>
    <div [hidden]="loading || errorState" class="canvas-container">
      <div class="canvas-wrapper">
        <canvas #leftCanvas id="left-canvas"></canvas>
        <canvas #mainCanvas id="main-canvas"></canvas>
        <canvas #rightCanvas id="right-canvas"></canvas>
      </div>
      <div class="preview-nav">
        <button mat-icon-button [disabled]="pageNum <= 1" (click)="onPrevPage()"><mat-icon class="material-symbols-rounded">keyboard_arrow_left</mat-icon></button>
        <span>{{pageNum}} / {{pageCount}}</span>
        <button mat-icon-button [disabled]="pageNum >= pageCount" (click)="onNextPage()"><mat-icon class="material-symbols-rounded">keyboard_arrow_right</mat-icon></button>
      </div>
    </div>
    <div *ngIf="errorState" class="preview-loading-container">
      <div>
        <span>An error occurred while loading the preview.</span>
        <span>Please close the preview window and try again.</span>
      </div>
    </div>
  </mat-dialog-content>
</div>
