<div class="datapoint-nav-container">
  <div class="title">
    <mat-icon class="material-symbols-rounded">dataset_linked</mat-icon><h1 class="product-heading-bold">Data</h1>
  </div>
  <ul>
      <li *ngFor="let tab of tabs?.getTabs()" [ngClass]="{'active': tab.isSelected(), 'child': tab.subTab}"
          class="data-tab-nodes" (click)="onSelect(tab)">
        <mat-icon class="material-symbols-rounded">{{tab.icon}}</mat-icon>
        <span class="flex-1">{{tab.name}}</span>
        <mat-icon class="material-symbols-rounded orange">chevron_right</mat-icon>
      </li>
  </ul>
</div>

<div class="datapoint-content-container" *ngIf="tabs.selectedTab">
  <ng-container *ngIf="!tabs.get('TASKS')?.isSelected()">
    <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </ng-container>
  <ng-container *ngIf="tabs.get('TASKS')?.isSelected()">
    <tasks class="block px-10 py-6"/>
  </ng-container>
</div>
