import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { UploadModalConfig, UploadType } from '@app/features/company-management/models/upload-modal-config';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { PipesModule } from '@app/shared/pipe/index.module';
import { SharedModule } from '@app/shared/shared.module';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';
import { LanguageService } from '@core/service/language.service';
import {
  ScopeUiAutocompleteComponent,
} from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import _ from 'lodash';
import { throwError } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'upload-modal',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDialogModule, SharedModule, ScopeUiAutocompleteComponent],
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss'],
})
export class UploadModalComponent {
  fileName: string
  file: File
  errors: any[] = []
  verifyingOrImportingLoader = false
  errorText: any
  verifiedFile = false
  showErrorList = false
  fileUrl: string
  showGenerateTemplate = false
  selectedRateCard: any;
  loggedInUser: User

  @ViewChild('uploader') uploader: ElementRef

  get showGenericTemplateButton() {
    return this.data.type !== UploadType.COMPONENT && this.data.type !== UploadType.FIXED_FEE_COMPONENT
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UploadModalConfig,
    public dialog: MatDialog,
    private lang: LanguageService,
    private manageService: CompanyManagementService,
    private authService: AuthService
  ) {
    this.loggedInUser = this.authService.loggedInUser
    this.errorText = {
      FILE_NOT_CORRECT: `File is not valid. Please use ${this.data.type === UploadType.WORD_OUTPUT ? '.docx': '.xlsx'}`,
      IMPORT_FAILED: "Error while uploading document, please try again",
      SECOND_PARTY_NOT_MATCH: "Second Party name missing",
      SEE_ERRORS: "Error while uploading document, please see errors",
      DEFAULT_ERROR: "Value is not correct"
    };
  }

  uploadFile($event: any) {
    this.file = $event.target.files[0]
    this.fileName = $event.target.files[0].name;
    this.verifiedFile = false
    this.errors = [];
    this.verifiedFile = null
  }

  submit() {
    if (this.file && !this.verifyingOrImportingLoader) {
      let fileExt = this.fileName.split('.').pop().toLowerCase();
      if ((this.data.type !== UploadType.WORD_OUTPUT && fileExt.toLowerCase() === "xlsx") ||
        (this.data.type === UploadType.WORD_OUTPUT && fileExt.toLowerCase() === "docx")) {
        this.verifyingOrImportingLoader = true;
        this.data.submitFn(this.file).subscribe({
          next: this.data.successFn,
          error: (error) => {
            this.errors.push({errorType: 'IMPORT_FAILED'});
            this.verifyingOrImportingLoader = false
            return throwError(error);
          }
        })
      } else {
        this.errors.push({errorType: 'FILE_NOT_CORRECT'});
      }
    }
  }

  getInstructionUrl() {
    let staticUrl = "https://thevirtugroup.zendesk.com/hc/en-gb/articles/"
    switch (this.data.type) {
      case UploadType.CLIENTS:
        return staticUrl + "360007333137-How-do-I-import-clients-";
      case UploadType.LOCATION:
        return staticUrl + "360007393938-How-do-I-import-locations-";
      case UploadType.CONTACT:
        return staticUrl + "360007394338-How-do-I-import-contacts-";
      case UploadType.BRAND:
        return staticUrl + "360007394858-How-do-I-import-brands-";
      case UploadType.RATECARD:
        return staticUrl + "360007390718-How-do-I-import-ratecard-";
      case UploadType.DELIVERABLE:
      case UploadType.FIXED_FEE_DELIVERABLE:
        return staticUrl + "360007964497-how-do-i-upload-deliverables";
      case UploadType.TASK:
        return staticUrl + '23026786302109-How-do-I-upload-a-Task';
      case UploadType.COMPONENT:
      case UploadType.FIXED_FEE_COMPONENT:
        return staticUrl + "360007099138-How-do-I-upload-components";
      default:
        return "";
    }
  };

  onGenerateTemplate() {
    (this.data.type === UploadType.COMPONENT
      || (this.data.type === UploadType.FIXED_FEE_COMPONENT && this.data.includeRoleAndHour)
      || this.data.type === UploadType.TASK)
      && this.loggedInUser.hasPrivilege('RATECARD__VIEW')
      ? this.showGenerateTemplate = true
      : this.getTemplateExampleUrl()
  }

  getTemplateExampleUrl() {
    let headerRow;
    let rateCardRow;
    switch (this.data.type) {
      case UploadType.CLIENTS:
        headerRow = [`${this.lang.get('second_party')} name`, "Rate card", "Relationship Since", "Output Template", "FTE"];
        this.generateTemplate([headerRow], 'upload-clients.xlsx')
        break
      case UploadType.CONTACT:
        headerRow = ["Contact Name", `${this.lang.get('client')} Name`, "Job Title", "Phone", "Location", "Email"];
        this.generateTemplate([headerRow], 'upload-contacts.xlsx')
        break
      case UploadType.LOCATION:
        headerRow = ["Address Name", "Address 1", "Address 2", "City", "Post Code", "Country", "Phone", `${this.lang.get('second_party')} Name`];
        this.generateTemplate([headerRow], 'upload-locations.xlsx')
        break
      case UploadType.BRAND:
        headerRow = ["Brand Name", `${this.lang.get('client')} Name`, "Rate card", "Location", "Key Contact", "Output Template", "Relationship Since"];
        this.generateTemplate([headerRow], 'upload-brands.xlsx')
        break
      case UploadType.RATECARD:
        headerRow = ["Department Name", `${this.lang.get('agency')} Role`,"Location", "Rate", "Cost"];
        rateCardRow = ["Rate-card Name", "", "Currency", "", "Scope Mark Card", "N"]
        this.manageService.getRateCardMetaStructure().subscribe({
          next: (result: any) => {
            if (result && result.ratecardMetaStructureDefinition) {
              result.ratecardMetaStructureDefinition.roles.additionalFields.forEach(role => {
                headerRow.push(role.name)
              })
            }
            this.generateTemplate([rateCardRow, headerRow], 'upload-ratecard.xlsx')
          },
          error: () => {
            console.error('Failed to load the company rate card meta structure.');
          }
        });
        break
      case UploadType.DELIVERABLE:
        headerRow = [`${this.lang.get('deliverable')} Name`, "Discipline", `${this.lang.get('deliverable')} description`,
          `${this.lang.get('deliverable')} internal note`, "Rate-card Name"];
        this.generateTemplate([headerRow], 'upload-deliverables-template.xlsx')
        break
      case UploadType.FIXED_FEE_DELIVERABLE:
        headerRow = [`${this.lang.get('deliverable')} Name`, "Discipline", `${this.lang.get('deliverable')} description`,
          `${this.lang.get('deliverable')} internal note`, "Rate-card Name", "Fixed Fee Price", "Fixed Fee Price Editable (Y/N)"];
        this.generateTemplate([headerRow], 'upload-fixed-fee-deliverables-template.xlsx')
        break
      case UploadType.COMPONENT:
        rateCardRow = ["Rate-card Name"];
        headerRow = ["Name", "Discipline", "Description", "Internal note", "Complexity"];
        this.generateTemplate([rateCardRow, headerRow], 'upload-components-template.xlsx')
        break
      case UploadType.FIXED_FEE_COMPONENT:
        rateCardRow = ["Rate-card Name"];
        headerRow = [
          "Fixed Fee Component Name",
          "Discipline",
          "Description",
          "Internal note",
          "Complexity (S-XL)",
          this.data.includeRoleAndHour ? "" : "Fixed Fee Cost",
          "Fixed Fee Price",
          "Fixed Fee Price Editable (Y/N)"
        ].filter(r => r.length);
        this.generateTemplate(
          [rateCardRow, headerRow],
          `upload-fixed-fee-${this.data.includeRoleAndHour ? '' : 'no-roles-and-hours-'}components-template.xlsx`,
        )
        break
      case UploadType.TASK:
        rateCardRow = ["Rate-card Name", "Rate Card 1"];
        headerRow = ["Task Name", "Task description", "Task internal note", "Department 1", "", "", "", "Department 2", "", "", ""];
        let rolesRow = ["", "", "", "", "Role 1", "Role 2", "Role 3", "", "Role 4", "Role 5", "Role 6"]
        let valuesRow = ["Task 1", "My description", "My note", "", "1", "2", "3", "", "4", "", ""]
        this.generateTemplate([rateCardRow, headerRow, rolesRow, valuesRow], "generic-upload-tasks-template.xlsx", "Rate Card 1")
        break
    }
  }

  generateTemplate(rows: string[][], name: string, sheetName?: string) {
    let ws = XLSX.utils.aoa_to_sheet(rows);

    /!* add to workbook *!/
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /!* write workbook and force a download *!/
    XLSX.writeFile(wb, name);
  }

  validateExcelFile() {
    if (this.file) {
      var fileName = this.file.name;
      var fileExt = fileName.split('.').pop().toLowerCase();
      this.errors = [];

      if (fileExt.toLowerCase() === "xlsx") {
        if (!this.verifyingOrImportingLoader) {
          this.verifyingOrImportingLoader = true;
          this.verifiedFile = false;

          this.data.verifyService
            .verify(this.file, this.data.type, this.data.includeRoleAndHour)
            .subscribe({
              next: (context: any) => {
                if (context.byteLength > 0) {
                  var blob = new Blob([context], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  });
                  let url = window.URL.createObjectURL(blob);
                  window.open(url, '_self', '');
                  this.showErrorList = true;
                  this.verifyingOrImportingLoader = false;
                  this.uploader.nativeElement.value(null);
                } else {
                  this.verifiedFile = true;
                  this.verifyingOrImportingLoader = false;
                }
              },
              error: (error) => {
                if (error.status == 409) {
                  this.showErrorList = true;
                  this.errors.push({ errorType: 'SEE_ERRORS' });
                  var blob = new Blob([error.error], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  });
                  const originalFile = this.file.name;
                  let verifiedFileName = "Verified_";
                  let dateFormat = new Date().toISOString().slice(0, 16).replace('T', '_').replace(':', '').replaceAll('-', '')
                  verifiedFileName = verifiedFileName.concat(dateFormat, '-', originalFile);
                  this.fileName = verifiedFileName;
                  this.fileUrl = window.URL.createObjectURL(blob);
                } else {
                  this.errors.push({ errorType: 'FILE_NOT_CORRECT' });
                }

                this.verifiedFile = true;
                this.verifyingOrImportingLoader = false;
              }
            });
        }
      } else {
        this.errors = [];
        this.errors.push({errorType: 'FILE_NOT_CORRECT'});
      }
    }
  }

  downloadConflictedFile() {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = this.fileUrl;
    a.download = this.fileName;
    a.click();
  }

  generateRateCardTemplate() {
    if (!this.selectedRateCard) return;
    this.manageService.getRateCard(this.selectedRateCard.rateCardIdentity.id).subscribe({
      next: (rc: any) => {
        let sheetName = rc.name;
        if (rc.name.length > 31) {
          sheetName = rc.name.substring(0, 28) + "...";
        }
        let rateCardRow = ["Rate-card Name", rc.name];
        let headerRow
        let rolesRow
        let fileName

        switch (this.data.type) {
          case UploadType.COMPONENT:
            headerRow = ["Name", "Discipline", "Description", "Internal note", "Complexity"];
            rolesRow = ["", "", "", "", ""]
            fileName = `${rc.name}-upload-components-template.xlsx`
            break
          case UploadType.FIXED_FEE_COMPONENT:
            headerRow = [
              "Fixed Fee Component Name",
              "Discipline",
              "Description",
              "Internal note",
              "Complexity (S-XL)",
              this.data.includeRoleAndHour ? "" : "Fixed Fee Cost",
              "Fixed Fee Price",
              "Fixed Fee Price Editable (Y/N)"
            ].filter(r => r.length);
            fileName = `${rc.name}-upload-fixed-fee-${this.data.includeRoleAndHour ? '' : 'no-roles-and-hours-'}component-template.xlsx`
            if (this.data.includeRoleAndHour) rolesRow = ["", "", "", "", "", "", ""]
            break
          case UploadType.TASK:
            headerRow = ["Task Name", "Task description", "Task internal note"];
            rolesRow = ["", "", ""]
            fileName = `${rc.name}-upload-tasks-template.xlsx`
            break
        }

        if (rolesRow) {
          _.sortBy(rc.departments, "name").forEach(dep => {
            rolesRow.push(``);
            headerRow.push(`${dep.name}`);
            _.sortBy(dep.roles, "order").forEach(role => {
              headerRow.push(``);
              rolesRow.push(`${role.name}`);
            })
          });
        }

        this.generateTemplate([rateCardRow, headerRow, rolesRow], fileName, sheetName)
      }
    })
  }

  protected readonly UploadType = UploadType;
}