<form class="auto-complete-form">
  <mat-form-field
    class="auto-complete-form-field"
    [ngClass]="{ 'has-icon': hasIcon }"
    subscriptSizing="dynamic">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <span class="flex gap-2 items-center">
      <i *ngIf="hasIcon" class="material-icons material-symbols-rounded text-base">{{ iconName }}</i>
      <input type="text" id="{{label}}" placeholder="{{placeholder}}" matInput [formControl]="control" [matAutocomplete]="auto" [required]="required" (input)="onInput()" />
      <span *ngIf="markerProperty && selectedValue && isMarkerPropertyAvailable(selectedValue)" class="option-marker selected">{{markerText}}</span>
      <mat-icon *ngIf="control.value" class="material-symbols-rounded clear" aria-label="Clear" (click)="clear($event)" matSuffix>close</mat-icon>
      <mat-icon *ngIf="!control.value && !hideToggleIcon" class="material-symbols-rounded expand-icon" [ngClass]="{'panel-open': matAutocompleteTrigger?.panelOpen}" aria-label="Expand" (click)="toggle($event)" matSuffix>expand_more</mat-icon>
    </span>
    <mat-autocomplete
      (optionSelected)="onSelection($event)"
      #auto="matAutocomplete"
      class="{{ selectedValue ? 'selected' : '' }}"
      [displayWith]="displayFn">
      <mat-option
        *ngFor="let option of filterByAction ? _options : (filteredOptions | async | orderBy : orderBy)"
        [class]="optionClass"
        [value]="option"
        [innerHTML]="getOptionView(option)"
        [disabled]="!enableDefaultOption && option.id === -1">
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="control.hasError('required')">This is required</mat-error>
  </mat-form-field>
</form>
