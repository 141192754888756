import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { combineLatest, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SpreadsheetTemplate } from '@app/features/scope-overview/model/spreadsheet-template.model';
import { LanguageService } from '@core/service/language.service';
import {
  AddOutputModalComponent
} from '@app/features/company-management/components/add-output-modal/add-output-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuOptions } from '@core/model/definitions/menu-options.interface';
import { OutputThemeService } from '@app/features/company-management/service/output-theme.service';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import { trackById } from '@shared/utils/utils';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'output-templates',
  templateUrl: './output-templates.component.html',
  styleUrls: ['./output-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutputTemplatesComponent {
  loggedInUser!: User
  documentTemplates: OutputTemplate[] = []
  xlsxTemplates: SpreadsheetTemplate[] = []
  loadingDocumentTemplates = true
  loadingXlsxTemplates = true
  templateTypes: MenuOptions[]

  constructor(private authService: AuthService,
              private manageService: CompanyManagementService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              public route: ActivatedRoute,
              private lang: LanguageService,
              private dialog: MatDialog,
              private themeService: OutputThemeService,
              private snackbarService: SnackbarService) {
    this.loggedInUser = this.authService.loggedInUser
    this.retrieveOutputTemplates()
    this.retrieveXlsxTemplates()
  }

  ngOnInit() {
    this.templateTypes = [
      {
        name: () => this.lang.get('scope') + " Document Template",
        icon: () => 'draft',
        isHidden: () => !this.loggedInUser.hasPrivilege('SCOPE__CREATE'),
        callback: () => this.showAddDocumentTemplateModal('scope')
      },
      {
        name: () => this.lang.get('sow') + " Document Template",
        icon: () => 'draft',
        isHidden: () => !(this.loggedInUser.hasPrivilege('SOW__CREATE') && this.loggedInUser.company.hasApplicationSetting("SOW__GLOBAL")),
        callback: () => this.showAddDocumentTemplateModal('sow')
      },
      {
        name:  () => this.lang.get('scope') + " Spreadsheet Template",
        icon: () => 'view_list',
        isHidden: () => !this.loggedInUser.hasPrivilege('SCOPE__EXPORT_XLSX'),
        callback: () => this.showAddXlsxTemplateModal('scope excel')
      },
      {
        name:  () => this.lang.get('sow') + " Spreadsheet Template",
        icon: () => 'view_list',
        isHidden: () => !this.loggedInUser.hasPrivilege('SCOPE__EXPORT_XLSX'),
        callback: () => this.showAddXlsxTemplateModal('sow excel')
      }
    ];
  }

  retrieveOutputTemplates() {
    combineLatest([this.manageService.getAllWordTemplates(), this.manageService.getParentOutputTemplatesWhitelist(this.loggedInUser.company.id)]).pipe(
      map(([templates, whitelist]) => {
        this.documentTemplates = templates
        this.loadingDocumentTemplates = false
        this.documentTemplates.forEach(template => {
          template.fromTemplate = this.themeService.getFromTemplate(template.templateScopeType, template.documentTemplate)
          if ((whitelist.inheritAll && template.company.id === this.loggedInUser.company.parentCompany.id) || (whitelist.templatesIds?.length > 0 && whitelist.templatesIds?.includes(template.id))){
            template.inherited = true;
          }
        })
        this.cdr.detectChanges()
      })
    ).subscribe()
  }

  retrieveXlsxTemplates() {
    this.manageService.getAllXlsxTemplates().subscribe(templates => {
      this.xlsxTemplates = templates
      this.loadingXlsxTemplates = false
      this.cdr.detectChanges()
    })
  }

  onSelect($event: string) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { 'tab': 'output', 'type': $event }
      }
    )
  }

  selectDocumentOutput($event: any) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { 'tab': 'output', 'type': 'document', 'id': $event },
        replaceUrl: true
      }
    )
  }

  selectXlsxOutput($event: any) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { 'tab': 'output', 'type': 'spreadsheet', 'id': $event },
        replaceUrl: true
      }
    )
  }

  showAddDocumentTemplateModal(event) {
    let dialog = this.dialog.open(AddOutputModalComponent, {
        data: {
          type: event,
          successFn: (result) => {
            dialog.close()
            this.selectDocumentOutput(result.id)
            this.cdr.markForCheck()
          }
        }
      }
    )
  };

  showAddXlsxTemplateModal(event: string) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
        data: {
          type: event,
          title: event === 'scope excel' ?
            `Excel Theme for a Single ${this.lang.get('scope')}` :
            `Excel Theme for a ${this.lang.get('scope_of_work')}`,
          confirmCallback: (form: FormControl) => {
            let name = form.get('name')?.value
            let template = {
              name: name,
              active: false,
              templateType: event == 'scope excel' ? 'SCOPE' : 'SOW'
            }
            this.manageService.createXlsxOutputTemplate(template).subscribe({
              next: (result) => {
                dialog.close()
                this.selectXlsxOutput(result.id)
                this.cdr.markForCheck()
              },
              error: (response) => {
                let message = `Unable to add ${this.lang.get('scope')}. Please try again later`;
                if (response.status == 409) {
                  message = this.lang.get('output') + " template '" + name + "' already exists.";
                }
                this.snackbarService.showSnackbar(message, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
              }
            })
          },
          inputs: [{ name: 'name', control: new FormControl(''), type: 'text', label: `Theme name`, maxLength: 50 }],
          isFormValid: (form: FormControl) => {
            return !!form.get('name')?.value
          }
        }
      }
    )
  };

  showDeleteDocumentTemplateModal(template: any) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete ${this.lang.get('output')} template`,
        `Are you sure you want to delete ${template.name}`,
        'Delete',
        undefined,
        () => {
          dialog.close()
          this.manageService.deleteCompanyCustomOutputTemplate(template.id).subscribe({
            next: () => {
              this.documentTemplates.splice(this.documentTemplates.indexOf(template), 1)
              this.cdr.detectChanges()
              this.snackbarService.showSnackbar(`Template ${template.name} successfully deleted`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            },
            error: () => this.snackbarService.showDefaultErrorSnackbar()
          })
        }
      ),
    })
  }

  showDeleteXlsxTemplateModal(template: SpreadsheetTemplate) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete Xlsx template`,
        `Are you sure you want to delete ${template.name}`,
        'Delete',
        undefined,
        () => {
          dialog.close()
          this.manageService.deleteXlsxOutputTemplate(template.id).subscribe({
            next: () => {
              this.xlsxTemplates.splice(this.xlsxTemplates.indexOf(template), 1)
              this.cdr.detectChanges()
              this.snackbarService.showSnackbar(`Template ${template.name} successfully deleted`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            },
            error: () => this.snackbarService.showDefaultErrorSnackbar()
          })
        }
      ),
    })
  }

  protected readonly trackById = trackById;
}
