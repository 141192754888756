import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { MatMenu, MatMenuTrigger } from '@angular/material/menu'

import { CommonModule } from '@angular/common'
import { Preference } from '@app/core/model/user-preferences.interface'
import { SharedModule } from '@app/shared/shared.module'

@Component({
  selector: 'scope-ui-toggle-list',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './toggle-list.component.html',
  styleUrls: ['./toggle-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleListComponent implements AfterViewInit {
  _preferences!: Preference[]
  @Input() set preferences(value: Preference[]) {
    if (value) {
      this._preferences = value.filter((p) => (p.isVisibleInList ? p.isVisibleInList() : true))
    }
  }

  @Input() maxSelected: number = 9

  @Input() isOpen: boolean

  @Output() onTogglePref = new EventEmitter<Preference>()

  @Output() onOpenClose = new EventEmitter<boolean>()

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger
  @ViewChild(MatMenu) menu: MatMenu

  disableAnimation: boolean = false

  ngOnInit() {
    if (this.isOpen) {
      this.disableAnimation = true
    }
  }

  ngAfterViewInit() {
    if (this.isOpen) {
      this.menuTrigger.openMenu()
    }
  }

  get selectedCount(): number {
    return this._preferences.filter((p) => p.selected && p.key !== 'SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS').length
  }

  onTogglePreference(value: Event | any, preference: Preference) {
    event?.stopPropagation()
    const updatedPreference: Preference = { ...preference, selected: value.checked }

    if (this.selectedCount > this.maxSelected) {
      updatedPreference.selected = false
    }

    this.onTogglePref.emit(updatedPreference)
  }

  updateState() {
    if (!this.isOpen) {
      this.disableAnimation = false
    }
    this.onOpenClose.emit(this.isOpen)
  }
}
