<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ data.title }}</h1>
      </div>

      <div class="mt-2 w-450" *ngIf="data.showBody">
        <span *ngIf="!data.titleBody">{{data.title}} using Excel file</span>
        <span *ngIf="data.titleBody">{{data.titleBody}}</span>

        <div class="upload-help">
          <span><ng-container *ngIf="!this.showGenerateTemplate">Download and use the following <a
            (click)="onGenerateTemplate()" target="_self">Generate Template</a>. </ng-container>
          Refer to <a href="{{getInstructionUrl()}}" target="_blank" style="outline: none;">Instruction Manual</a> for further information.</span>
        </div>
      </div>

      <button class="close-button" mat-dialog-close>
        <mat-icon class="material-symbols-rounded">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <loader-modal *ngIf="verifyingOrImportingLoader" [overlay]="true"></loader-modal>
    <ng-container *ngIf="!verifyingOrImportingLoader">
      <div *ngIf="(errors.length > 0 || !verifiedFile) && !showGenerateTemplate" class="modal__body text-center">
        <span class="modal-body-text" *ngIf="errors.length > 0 || !verifiedFile">{{ data.body }}</span>
        <div class="flex flex-col items-center" *ngIf="errors.length > 0 || !verifiedFile">
          <div class="mb-2">
            <mat-icon class="material-symbols-rounded pointer" *ngIf="fileName" (click)="downloadConflictedFile()">file_download</mat-icon>
            <mat-icon class="material-symbols-rounded" *ngIf="!fileName">insert_drive_file</mat-icon>
            <p class="file-name" *ngIf="fileName">{{fileName}}</p>
          </div>
          <input
            hidden
            type="file"
            accept="{{data.type === UploadType.WORD_OUTPUT ? '.docx' : '.xlsx'}}"
            #uploader
            (change)="uploadFile($event)"
          />
          <button class="browse-btn" (click)="uploader.click()">
            Browse
          </button>
        </div>
        <div *ngIf="errors.length > 0">
          <ul class="error-upload">
            <li *ngFor="let error of errors">{{errorText[error.errorType]}}{{error.additionalText ?
              ': ' + error.additionalText : ''}}. {{error.line ? 'Line: ' + error.line : ''}}
            </li>
          </ul>
          <ul *ngIf="showErrorList">
            <li><span style="color: #FF0000">Red</span> - Required</li>
            <li><span style="color: #0000FF">Blue</span> - Not Found</li>
            <li><span style="color: #00FF00">Green</span> - Duplicate</li>
            <li><span style="color: #A52A2A">Brown</span> - Not Valid</li>
          </ul>
        </div>
      </div>

      <div *ngIf="showGenerateTemplate" class="modal__body">
        <div class="flex flex-col">
          <scope-ui-autocomplete
            class="mb-6"
            [options]="data.rateCards$ | async"
            orderBy="name"
            [label]="'Rate Card'"
            [required]="true"
            (onSelectionChange)="selectedRateCard = $event.event"/>
          <button
            mat-button
            class="confirm scope-ui-modal-flat-button"
            [disabled]="selectedRateCard === null"
            (click)="generateRateCardTemplate()">Generate Template
          </button>
        </div>

        <ng-container *ngIf="showGenericTemplateButton">
          <br>
          <small>* Or download the <a class="orange" (click)="getTemplateExampleUrl()" target="_self">Generic Template</a></small>
        </ng-container>

        <br>
        <br>
        <small><a class="orange" (click)="showGenerateTemplate = false" >Go back to Upload a Template</a></small>

      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="!showGenerateTemplate">
    <h4 *ngIf="errors.length == 0 && verifiedFile" class="success-upload">
      File is verified. <br> Please, proceed with the upload.
    </h4>

    <button
      mat-button
      class="confirm scope-ui-modal-flat-button"
      *ngIf="data.type === UploadType.EXCEL_OUTPUT || data.type === UploadType.WORD_OUTPUT ||
      (errors.length == 0 && verifiedFile)"
      (click)="submit()">Upload
    </button>

    <button
      *ngIf="data.type !== UploadType.EXCEL_OUTPUT && data.type !== UploadType.WORD_OUTPUT &&
        (!verifiedFile || errors.length > 0)"
      mat-button
      class="confirm scope-ui-modal-flat-button"
      (click)="validateExcelFile()">Validate
    </button>

    <p *ngIf="data.showRemoveOption" class="mt-4">
      <b>{{data.removeOptionText}}</b>
      <a class="orange" (click)="data.removeFn()"> Click here</a>
    </p>
  </mat-dialog-actions>
</div>
