export class ThirdPartyCostType {
  name!: string
  id!: number
  parentId!: number
  overridden: boolean
  active: boolean
  sourceType: any
  groupType: any
  groupId: number
  description: string
  nonTranslatedName: string
  nonTranslatedDescription: string
  markupPercentage: number
  unitCost: number
  unitType: any
}
