import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { CdnConfig } from '@app/core/model/cdn-config.model';
import { CoreState, AuthActions } from '@core/store';
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, of } from 'rxjs';
import { AuthSelectors } from '@core/store/selectors/auth.selector'
import { AuthService } from '@core/service/auth.service'
import { Router } from '@angular/router';
import { HeaderStyleService } from '@shared/services/header-style.service';

@Component({
  selector: 'agency-site-header-content',
  templateUrl: './agency-site-header.component.html',
  styleUrls: ['./agency-site-header.component.scss'],
  host: { class: 'agency' },
})
export class AgencySiteHeaderContentComponent implements OnInit {
  cdnConfig$: Observable<CdnConfig | undefined> = this.store.select(BootstrapSelectors.selectCdnConfig);
  userLogo$: Observable<string>
  companyLogo$: Observable<string>

  constructor(private store: Store<CoreState>,
              public authService: AuthService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private headerStyleService: HeaderStyleService) {}

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data === 'updateUser') {
      this.store.dispatch(AuthActions.myAccount())
    } else if (event.data === 'goToLogin') {
      this.headerStyleService.setHeaderClass(null)
      this.router.navigateByUrl('/login')
      this.store.dispatch(AuthActions.logOutSuccess())
    }
  }

  ngOnInit(): void {
    this.subscribeToUserUpdates()
  }

  subscribeToUserUpdates() {
    combineLatest([this.cdnConfig$, this.store.select(AuthSelectors.selectUser)]).pipe(
      map(([cdnConfig, user]) => {
        if (user) {
          this.userLogo$ = of(cdnConfig.getProfileImageUrl(user.photoUri))
          this.companyLogo$ = of(cdnConfig.getCompanyLogoUrl(user.company.logoUri))
          this.cdr.detectChanges()
        }
      })
    ).subscribe()
  }

  logout() {
    this.store.dispatch(AuthActions.logOut());
  }
}
