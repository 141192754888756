import { Type } from 'class-transformer';
import { User } from '@core/model/user.model';
import { Company } from '@core/model/company.model';

export class ScopeType {
  id!: number;
  name!: string;
  description: string;
  @Type(() => Company) company: Company;
  deleted: boolean;
  @Type(() => Date) createdTs: Date;
  @Type(() => Date) updatedTs: Date;
  @Type(() => User) createdBy: User;
  @Type(() => User) updatedBy: User;

  isOwnedByParentOf(company: Company) {
    if (this.company && this.company.id){
      return this.company.id == company.parentCompany?.id;
    }
    return undefined;
  }
}
