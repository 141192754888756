import { EventEmitter, Injectable } from '@angular/core';
import { UserComment } from '@core/model/comment.model'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { ScopeComponent } from '@app/features/scoping/models/component.model'
import { Department } from '@app/features/scoping/models/department.model'
import { Role } from '@app/features/scoping/models/role.model'
import { DeliverableSection } from '@app/features/scope-overview/model/deliverable-section'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { ScopeVersion, StatusType } from '@core/model/scope-version'
import {
  ScopeReplacementPath,
  ScopeReplacementRole,
} from '@app/features/scope-overview/model/find-repace-roles.model'
import { Privilege } from '@core/model/enums/privilege.enum'
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service'
import { HttpClient } from '@angular/common/http'
import { environment } from '@envs/environment'
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector'
import { filter } from 'rxjs'
import { Store } from '@ngrx/store'
import { AuthService } from '@core/service/auth.service'

@Injectable({
  providedIn: 'root',
})
export class CommentingService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl;
  openComment: string
  currentScope: ScopeVersion

  constructor(private approvalFlowService: ApprovalFlowService, private http: HttpClient, private store: Store, private authService: AuthService) {
    this.store
      .select(ScopeOverviewSelectors.selectCurrentScope)
      .pipe(
        filter((currentScope) => !!currentScope)
      )
      .subscribe((currentScope) => {
        this.currentScope = currentScope
      });
  }

  globalSaveCommentable = (entity: any, rootEntity: any, comment: UserComment, postActionEvent: EventEmitter<any>) => {
    let scopeId = this.currentScope.identity.id
    if (entity instanceof Deliverable) {
      this.addDeliverableComment(scopeId, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof ScopeComponent) {
      this.addComponentComment(scopeId, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof Department) {
      this.addComponentDepartmentComment(scopeId, rootEntity.id, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof Role) {
      this.addComponentRoleComment(scopeId, rootEntity.id, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof DeliverableSection) {
      this.addDeliverableSectionComment(scopeId, rootEntity.id, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof ThirdPartyCost && rootEntity instanceof ScopeComponent) {
      this.addComponentThirdPartyCostComment(scopeId, rootEntity.id, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof ThirdPartyCost && rootEntity instanceof Deliverable) {
      this.addDeliverableThirdPartyCostComment(scopeId, rootEntity.id, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof ThirdPartyCost) {
      this.addScopeThirdPartyCostComment(scopeId, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof ScopeVersion) {
      this.addScopeInlineComment(scopeId, comment).subscribe(() => { postActionEvent.emit()});
    } else if (entity instanceof ScopeSection) {
      this.addScopeSectionComment(scopeId, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof ScopeReplacementRole) {
      this.addRoleReplaceDeliverableComment(scopeId, entity.id, comment).subscribe(() => {});
    } else if (entity instanceof ScopeReplacementPath) {
      this.addRoleReplaceDeliverablePathComment(scopeId, rootEntity.id, entity.id, comment).subscribe(() => {});
    } else {
      throw new Error("Unknown entity type!")
    }
  }

  globalShowInputCommentable = () => {
    if (!this.authService.loggedInUser.hasPrivilege('SCOPE_COMMENT__CREATE') || this.currentScope.isPrivilegeRestricted(Privilege.SCOPE_COMMENT__CREATE)) {
      return false
    } else {
      return this.approvalFlowService.getIsCommentable()
    }
  }

  globalUseCommentable = (commentable: any) => {
    if (this.approvalFlowService.stateChecks?.status === StatusType.DRAFT ||
      (this.approvalFlowService.stateChecks?.status === StatusType.CLOSED && commentable?.comments?.length == 0)) return false;
    return this.approvalFlowService.stateChecks?.isCurrentVersion || commentable?.comments?.length > 0;
  }

  addScopeInlineComment (scopeId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/inline-comment`, comment);
  };

  addDeliverableSectionComment (scopeId, deliverableId, sectionId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/section/${sectionId}/comment`, comment);
  };

  addScopeSectionComment(scopeId, sectionId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/section/${sectionId}/comment`, comment);
  };
  addDeliverableComment(scopeId, deliverableId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/comment`, comment);
  };

  addComponentComment (scopeId, componentId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/component/${componentId}/comment`, comment);
  };

  addComponentDepartmentComment (scopeId, componentId, departmentId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/component/${componentId}/department/${departmentId}/comment`, comment);
  };

  addComponentRoleComment (scopeId, componentId, roleId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/component/${componentId}/role/${roleId}/comment`, comment);
  };

  addComponentThirdPartyCostComment (scopeId, componentId, tpcId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/component/${componentId}/third-party-cost/${tpcId}/comment`, comment);
  };
  addDeliverableThirdPartyCostComment(scopeId, deliverableId, tpcId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/deliverable/${scopeId}/third-party-cost/${tpcId}/comment`, comment);
  };
  addScopeThirdPartyCostComment(scopeId, tpcId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/third-party-cost/${tpcId}/comment`, comment);
  };

  addRoleReplaceDeliverableComment(scopeId, replacementId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/roles/replacements/${replacementId}/comment`, comment);
  };

  addRoleReplaceDeliverablePathComment(scopeId, replacementId, pathId, comment) {
    return this.http.post(`${this.BASE_API_URL}scope/${scopeId}/roles/replacements/${replacementId}/path/${pathId}/comments`, comment);
  };
}
