import { Type } from 'class-transformer';
import { User } from '@core/model/user.model';

export class CompanyGroup {
  id!: number;
  name!: string;
  sharedSecondParties: any[];
  sharedFolders: any[];
  sharedCustomOutputTemplates: any[];
  pendingInvitedMembers: any[];
  members: any[];
  @Type(() => Date) createdTs: Date;
  @Type(() => Date) updatedTs: Date;
  @Type(() => User) createdBy: User;
  userPrivilegeRestrictions: any[];
  userAdditionalPrivileges: any[];
}
