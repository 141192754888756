import { Type } from 'class-transformer';
import { Company } from '@core/model/company.model';

export class RatecardIdentity {
  id!: number
  name!: string
  currentVersionNumber!: number
  trafficImported!: boolean
  costPlus!: boolean
  archived: boolean
  scopeMarkCard: boolean
  @Type(() => Company) company: Company
  externalType?: any
  defaultCard?: boolean
}
