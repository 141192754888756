import { CommonModule } from '@angular/common'
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { EventType } from '@app/core/model/enums/event-type.enum'
import { Preference } from '@app/core/model/user-preferences.interface'
import {
  ScopeApprovalFlowComponent,
} from '@app/features/scope-overview/approval-flow/scope-approval-flow/scope-approval-flow.component'
import {
  AddRolesModalComponent,
  AddRolesModalConfig,
} from '@app/features/scope-overview/components/add-roles-modal/add-roles-modal.component'
import {
  AddTpcModalComponent,
  AddTpcModalConfig,
} from '@app/features/scope-overview/components/add-tpc-modal/add-tpc-modal.component'
import {
  IntegrationTrafficModal
} from "@app/features/scope-overview/components/integration-traffic/integration-traffic.component"
import { ScopeActivityComponent } from '@app/features/scope-overview/components/scope-activity/scope-activity.component'
import {
  ScopeBreakdownComponent,
} from '@app/features/scope-overview/components/scope-breakdown/scope-breakdown.component'
import {
  ScopeByRoleTabComponent
} from '@app/features/scope-overview/components/scope-by-role-tab/scope-by-role-tab.component'
import { ScopeDetailsComponent } from '@app/features/scope-overview/components/scope-details/scope-details.component'
import { ScopeOutputComponent } from '@app/features/scope-overview/components/scope-output/scope-output.component'
import {
  AddEditDiscountModalComponent,
} from '@app/features/scope-overview/components/scope-tab/add-edit-discount-modal/add-edit-discount-modal.component'
import {
  AddEditFeeModalComponent,
} from '@app/features/scope-overview/components/scope-tab/add-edit-fee-modal/add-edit-fee-modal.component'
import {
  AddTaskModalComponent
} from '@app/features/scope-overview/components/scope-tab/add-task-modal/add-task-modal.component'
import {
  CreateDeliverableModalComponent,
} from '@app/features/scope-overview/components/scope-tab/create-deliverable-modal/create-deliverable-modal.component'
import {
  EditMsaModalComponent,
} from '@app/features/scope-overview/components/scope-tab/edit-msa-modal/edit-msa-modal.component'
import { ScopeTabComponent } from '@app/features/scope-overview/components/scope-tab/scope-tab.component'
import { TeamComponent } from '@app/features/scope-overview/components/team/team.component'
import { DeliverableType } from '@app/features/scope-overview/model/deliverable-type'
import { FeeItem, FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { IntegrationModalConfig } from "@app/features/scope-overview/model/integration-modal-config"
import { Money } from '@app/features/scope-overview/model/money.model'
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import {
  ScopeCustomFieldValueStructure,
} from '@app/features/scope-overview/model/scope-custom-field-value-structure.model'
import { ScopeRetainedHoursStats } from '@app/features/scope-overview/model/scope-retained-hours-stats.model'
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import { SowRetainedHoursStats } from '@app/features/scope-overview/model/sow-retained-hours-stats.model'
import { SowRetainedTeamStats } from '@app/features/scope-overview/model/sow-retained-team-stats.model'
import { ScopeDetailsService } from '@app/features/scope-overview/service/scope-details.service'
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service'
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service'
import { ScopeTeamService } from '@app/features/scope-overview/service/scope-team.service'
import { ScopeComponent } from '@app/features/scoping/models/component.model'
import { Deliverable, SelectedDeliverable } from '@app/features/scoping/models/deliverable.model'
import { Department } from '@app/features/scoping/models/department.model'
import { Role } from '@app/features/scoping/models/role.model'
import { DeliverablePreferences } from '@app/features/scoping/models/scope-columns.model'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service'
import { MyScopesService } from '@app/features/scoping/service/scoping.service'
import { ScopeActions } from '@app/features/scoping/store/actions/scoping.actions'
import { ScopingSelectors } from '@app/features/scoping/store/selectors/scoping.selector'
import { LoaderModalModule } from '@app/shared/components/loader-modal/loader-modal.module'
import {
  DetailsOverviewComponent,
} from '@app/shared/components/ui-components/details-overview/details-overview/details-overview.component'
import { ScopeUiTabComponent } from '@app/shared/components/ui-components/scope-ui-tab/scope-ui-tab.component'
import { ScopeUiTabsComponent } from '@app/shared/components/ui-components/scope-ui-tabs/scope-ui-tabs.component'
import { NotificationEvent, RegisteredSubscription, WebsocketService } from '@app/shared/services/websocket.service'
import { SharedModule } from '@app/shared/shared.module'
import { CdnConfig } from '@core/model/cdn-config.model'
import { FindRolesSearchParams } from '@core/model/definitions/findRoleSearchParams.interface'
import { GenericFilterValue, TypedFilterValue } from '@core/model/filter-option.interface'
import { ModalConfig } from '@core/model/modal-config.model'
import { NewTask } from '@core/model/new-task.model'
import { ScopeOverviewFilter, ScopeOverviewFilterDefaults } from '@core/model/scope-overview-filter.model'
import { ScopeTeamMember } from '@core/model/scope-team.model'
import { ScopeVersion, StatusType } from '@core/model/scope-version'
import { User } from '@core/model/user.model'
import { AuthService } from '@core/service/auth.service'
import { LanguageService } from '@core/service/language.service'
import { BootstrapActions } from '@core/store'
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector'
import { Store } from '@ngrx/store'
import { PreviewOutputComponent } from '@shared/components/preview-output/preview-output.component'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component'
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service'
import { formatDisplayHoursToMinutes, formatHours, untilDestroyed } from '@shared/utils/utils'
import { plainToInstance } from 'class-transformer'
import { cloneDeep, isEqual } from 'lodash'
import { BehaviorSubject, debounceTime, filter, forkJoin, map, Observable, of, Subject, switchMap, take } from 'rxjs'
import { ExportModalConfig } from '../../model/export-modal-config'
import { FindReplaceRole } from '../../model/find-repace-roles.model'
import { ApprovalFlowService } from '../../service/approval-flow.service'
import { ApprovalsService } from '../../service/approvals.service'
import { DialogEventsService } from '../../service/dialog-events.service'
import { FindReplaceService } from '../../service/find-replace.service'
import { ScopeTraffickingService } from '../../service/scope-trafficking.service'
import { UserSettingsService } from '../../service/user-settings.service'
import { ScopeOverviewActions } from '../../store/actions/scope-overview.action'
import { ScopeOverviewSelectors } from '../../store/selectors/scope-overview.selector'
import { ExportExcelModal } from '../export-excel/export-excel.component'
import { ExportModalComponent } from '../export-modal/export-modal.component'
import { ExportTrafficModal } from '../export-traffic/export-traffic.component'
import {
  ReviewSubmitModalComponent,
  ReviewSubmitModalConfig,
} from '../review-submit-modal/review-submit-modal.component'
import { TrackTradeModalComponent, TrackTradeModalConfig } from '../track-trade-modal/track-trade-modal.component'

@Component({
  selector: 'scope-overview',
  imports: [
    CommonModule,
    SharedModule,
    LoaderModalModule,
    DetailsOverviewComponent,
    ScopeUiTabComponent,
    ScopeUiTabsComponent,
    ScopeTabComponent,
    ScopeOutputComponent,
    ScopeDetailsComponent,
    TeamComponent,
    ScopeActivityComponent,
    ScopeBreakdownComponent,
    ScopeApprovalFlowComponent,
    ScopeUiInputComponent,
    ScopeByRoleTabComponent,
  ],
  templateUrl: './scope-overview.component.html',
  styleUrls: ['./scope-overview.component.scss'],
  standalone: true,
})
export class ScopeOverviewComponent implements OnDestroy {
  private readonly destroy$;

  @Input() id!: number;

  currentScope$: Observable<ScopeVersion>;

  cdnConfig$: Observable<CdnConfig | undefined>;

  cdnConfig!: CdnConfig;

  loggedInUser!: User | undefined;

  registeredSubs: RegisteredSubscription[] = [];

  isFindReplaceLoading$: Observable<boolean>;

  editScopeDescription: boolean = false;

  scopeRetainedHoursStats$!: Observable<ScopeRetainedHoursStats>;

  sowRetainedHoursStats$!: Observable<SowRetainedHoursStats>;

  thirdPartyCostBalance$!: Observable<Money>;

  showFilterView: boolean = false;

  breadcrumbs!: any;

  companyIntegrations : any = [];

  availableRoles$: Observable<FindReplaceRole[]>;

  filteredRoles$: Observable<any>;

  filteredDepartmentRoles$: Observable<Role[]>;

  findReplaceBreadcrumbs$: Observable<any>;

  availableComponents$: Observable<any>;

  availableDeliverables$: Observable<any>;

  availableDepartments$: Observable<any>;

  replaceSummarySuccess$: Observable<boolean>;

  replaceSummaryResponse$: Observable<any>;

  revertSummaryResponse$: Observable<any>;

  revertSummarySuccess$: Observable<any>;

  rateCard$: Observable<any>

  scopeOverviewFilters$!: Observable<ScopeOverviewFilter>;

  searchedComponents$!: Observable<TypedFilterValue[]>;

  searchedNonDistinctComponents$!: Observable<ScopeComponent[]>;

  searchedDepartments$!: Observable<GenericFilterValue[]>;

  searchedRoles$!: Observable<GenericFilterValue[]>;

  filteredSections?: ScopeSection[];

  filteredSectionsSource: BehaviorSubject<ScopeSection[]>;

  filteredSections$: Observable<ScopeSection[]>;

  filteredDeliverables$: Observable<Deliverable[]>;

  filteredComponents?: ScopeComponent[];

  filteredComponentsSource: BehaviorSubject<ScopeComponent[]>;

  filteredComponents$: Observable<ScopeComponent[]>;

  filteredDepartmentsSource: BehaviorSubject<Department[]>;

  filteredDepartments$: Observable<Department[]>;

  filteredRolesSource: BehaviorSubject<Role[]>;

  filteredScopeFees?: any[];

  filteredScopeFeesSource: BehaviorSubject<any[]>;

  filteredScopeFees$: Observable<any[]>;

  filteredDeliverableFees?: any[];

  filteredDeliverableFeesSource: BehaviorSubject<any[]>;

  filteredDeliverableFees$: Observable<any[]>;

  filteredComponentFees?: any[];

  filteredComponentFeesSource: BehaviorSubject<any[]>;

  filteredComponentFees$: Observable<any[]>;

  filteredDeliverableTPCsSource: BehaviorSubject<ThirdPartyCost[]>;

  filteredDeliverableTPCs$: Observable<ThirdPartyCost[]>;

  filteredDeliverableSectionTPCsSource: BehaviorSubject<ThirdPartyCost[]>;

  filteredDeliverableSectionTPCs$: Observable<ThirdPartyCost[]>;

  filteredComponentTPCsSource: BehaviorSubject<ThirdPartyCost[]>;

  filteredComponentTPCs$: Observable<ThirdPartyCost[]>;

  currentTab?: string;

  scopeColumnFilterPreferences$!: Observable<DeliverablePreferences>

  private completeReviewDialogConfig: MatDialogRef<ReviewSubmitModalComponent, any>

  isApprovalLoading: boolean;

  editName: boolean = false;

  showUseRetainedTeamToggle: boolean = false;

  private createDeliverableDialog: MatDialogRef<CreateDeliverableModalComponent, any>

  private currentRatecard: RatecardVersion

  loadingAllTasks$: Observable<boolean>

  constructor(
    private store: Store,
    public userSettingsService: UserSettingsService,
    private router: Router,
    private lang: LanguageService,
    private scopeOverviewService: ScopeOverviewService,
    private titleService: Title,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private scopingService: MyScopesService,
    public scopeApprovalFlowService: ApprovalFlowService,
    private approvalsService: ApprovalsService,
    private dialogEventsService: DialogEventsService,
    private traffickingService: ScopeTraffickingService,
    private webSocketService: WebsocketService,
    public findReplaceService: FindReplaceService,
    private mappingService: DataMappingService,
    public scopeDetailsService: ScopeDetailsService,
    public scopeTeamService: ScopeTeamService,
    public route: ActivatedRoute,
    private scopeTabService: ScopeTabService,
    private snackbarService: SnackbarService,
    private authService: AuthService
  ) {
    this.destroy$ = untilDestroyed();

    this.currentScope$ = this.store.select(ScopeOverviewSelectors.selectCurrentScope);

    this.cdnConfig$ = this.store.select(BootstrapSelectors.selectCdnConfig);

    this.isApprovalLoading = false;

    this.scopeColumnFilterPreferences$ = this.store.select(ScopingSelectors.selectScopeOverviewColumns);

    this.isFindReplaceLoading$ = this.store.select(ScopeOverviewSelectors.selectCurrentScopeLoading);

    this.scopeRetainedHoursStats$ = this.store.select(ScopeOverviewSelectors.selectScopeRetainedHourStats);

    this.sowRetainedHoursStats$ = this.store.select(ScopeOverviewSelectors.selectSowRetainedHourStats);

    this.thirdPartyCostBalance$ = this.store.select(ScopeOverviewSelectors.selectThirdPartyCostBalance);

    this.availableRoles$ = this.store.select(ScopingSelectors.selectAvailableRoles);

    this.filteredDepartmentRoles$ = this.store.select(ScopingSelectors.selectFilteredRoles);

    this.availableComponents$ = this.store.select(ScopingSelectors.selectAvailableComponents);

    this.availableDeliverables$ = this.store.select(ScopingSelectors.selectAvailableDeliverables);

    this.availableDepartments$ = this.store.select(ScopingSelectors.selectAvailableDepartments);

    this.findReplaceBreadcrumbs$ = this.store.select(ScopingSelectors.selectFindReplaceBreadcrumbs);

    this.replaceSummaryResponse$ = this.store.select(ScopingSelectors.selectReplaceRolesResponse);

    this.revertSummaryResponse$ = this.store.select(ScopingSelectors.selectRevertRolesResponse);

    this.revertSummarySuccess$ = this.store.select(ScopingSelectors.selectRevertSummarySuccess);

    this.replaceSummarySuccess$ = this.store.select(ScopingSelectors.selectReplaceSummarySuccess);

    this.rateCard$ = this.store.select(ScopingSelectors.selectRateCard)

    this.scopeOverviewFilters$ = this.store.select(ScopingSelectors.selectScopeOverviewFilters);

    this.searchedComponents$ = this.store.select(ScopeOverviewSelectors.selectSearchedComponentsForScope);

    this.searchedNonDistinctComponents$ = this.store.select(ScopeOverviewSelectors.selectSearchedNonDistinctComponentsForScope);

    this.searchedDepartments$ = this.store.select(ScopeOverviewSelectors.selectSearchedDepartmentsForScope);

    this.searchedRoles$ = this.store.select(ScopeOverviewSelectors.selectSearchedRolesForScope);

    this.filteredSectionsSource = new BehaviorSubject([]);

    this.filteredSections$ = this.filteredSectionsSource.asObservable();

    this.filteredDeliverables$ = this.store.select(ScopeOverviewSelectors.selectFilteredDeliverables);

    this.filteredComponentsSource = new BehaviorSubject([]);

    this.filteredComponents$ = this.filteredComponentsSource.asObservable();

    this.filteredDepartmentsSource = new BehaviorSubject([]);

    this.filteredDepartments$ = this.filteredDepartmentsSource.asObservable();

    this.filteredRolesSource = new BehaviorSubject([]);

    this.filteredRoles$ = this.filteredRolesSource.asObservable();

    this.filteredScopeFeesSource = new BehaviorSubject([]);

    this.filteredScopeFees$ = this.filteredScopeFeesSource.asObservable();

    this.filteredDeliverableFeesSource = new BehaviorSubject([]);

    this.filteredDeliverableFees$ = this.filteredDeliverableFeesSource.asObservable();

    this.filteredComponentFeesSource = new BehaviorSubject([]);

    this.filteredComponentFees$ = this.filteredComponentFeesSource.asObservable();

    this.filteredDeliverableTPCsSource = new BehaviorSubject([]);

    this.filteredDeliverableTPCs$ = this.filteredDeliverableTPCsSource.asObservable();

    this.filteredDeliverableSectionTPCsSource = new BehaviorSubject([]);

    this.filteredDeliverableSectionTPCs$ = this.filteredDeliverableSectionTPCsSource.asObservable();

    this.filteredComponentTPCsSource = new BehaviorSubject([]);

    this.filteredComponentTPCs$ = this.filteredComponentTPCsSource.asObservable();

    this.loadingAllTasks$ = this.store.select(ScopeOverviewSelectors.selectLoadingAllTasks)

    this.scopeApprovalFlowService.defaultStateChecks()

    this.setupDebouncedUpdate();
  }

  ngOnInit(): void {
    this.loggedInUser = this.authService.loggedInUser

    this.store
    .select(ScopeOverviewSelectors.selectCurrentScope)
    .pipe(
      filter((currentScope) => !!currentScope),
      take(1)
    )
    .subscribe((currentScope) => {
      this.titleService.setTitle(currentScope.name + ' | Scope Overview | Scope')

      this.scopeApprovalFlowService.updateApprovalStateChecks(currentScope)

      if (this.loggedInUser?.company.hasApplicationSetting('SOW__RETAINED_HOURS')) {
        this.initScopeRetainedStats(currentScope)
        this.store.select(ScopeOverviewSelectors.selectRetainedTeamStats).pipe(
          filter(stats => !!stats),
          take(1),
        ).subscribe(stats => {
          this.showUseRetainedTeamToggle = this.resolveShowUseRetainedTeamToggle(stats, currentScope.identity.rateCard.id);
        });
      }

      this.isPartOfScope(currentScope)

      if (this.scopeApprovalFlowService.isScopeEditable(currentScope)) {
        this.store.dispatch(ScopeActions.getRateCardForScope({ scopeId: currentScope.identity.id }))
      }

      this.editScopeDescription = this.scopeApprovalFlowService.isStateInReview(currentScope.status) || this.scopeApprovalFlowService.isStateReviewed(currentScope.status)
      this.scopeOverviewService.getRatecardVersion(currentScope.identity.rateCard.id).subscribe({
        next: (result) => {
          this.currentRatecard = result
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
          return error
        },
      })
    });

    if (this.registeredSubs.length == 0) {
      this.registeredSubs.push(this.webSocketService.registerScopeEventReceiver(this.id,
        (event: any) => this.onScopeEventReceived.call(this, event)
      ))
    }

    this.userSettingsService.getCompanyIntegrations().subscribe(response => {
      this.companyIntegrations = response
    })

    this.openDeliverableAcceptRejectModals()
    this.getThirdPartyBalance();
    this.getCdnConfig();
    this.store.dispatch(BootstrapActions.loadUserPreferences());
    this.onFindReplaceSuccess();
  }

  onFindReplaceSuccess() {
    this.replaceSummarySuccess$.pipe(
      filter((success) => success == true)
    ).subscribe(() => {
      this.store.dispatch(ScopeOverviewActions.getCurrentScope({ scopeId: this.id }))
    });

    this.revertSummarySuccess$.pipe(
      filter((success) => success == true)
    ).subscribe(() => {
      this.store.dispatch(ScopeOverviewActions.getCurrentScope({ scopeId: this.id }))
    });
  }

  isPartOfScope(currentScope: ScopeVersion) {
    if (currentScope == null || this.loggedInUser == null) {
      return;
    }
    if (!currentScope.identity.team.hasUser(this.loggedInUser.id)) {
      const message = `You are not part of this {LANG:scope|l} team, therefore can only view this {LANG:scope|l} in read-only mode.`;
      this.snackbarService.showPermanentSnackbar(message, SnackbarEventType.WARNING);
    }
  }

  openDeliverableAcceptRejectModals() {
    this.dialogEventsService.dialogEventEmitter
      .pipe(this.destroy$())
      .subscribe((data: { key: { key: string; optionalData: { text: string; hasOverride: boolean } } }) => {
        let {
          key: { key, optionalData },
        } = data

        if (key === 'openAcceptDeliverableDialog' || key === 'openRejectDeliverableDialog') {
          this.openAcceptDeliverablesModal(key)
        }

        if (key === 'openCompleteReviewModal') {
          this.submitScopeReview(optionalData.text, optionalData.hasOverride)
        }

        if (key === 'exportScopeToExcel') {
          this.openExcelExportModal()
        }

        if (key === 'exportTrafficThroughAgencyTrafficSystem') {
          this.exportTrafficThroughAgencyTrafficSystem()
        }

        if (key === 'exportTrafficThroughWorkato') {
          this.exportTrafficThroughWorkato()
        }
      })
  }

  ngOnDestroy() {
    this.registeredSubs.forEach((s) => this.webSocketService.unregisterReceiver(s));
    this.registeredSubs = [];
    this.snackbarService.hide();
  }

  tradeDeliverableModal(deliverable: Deliverable, currentScope: ScopeVersion) {
    const dialogConfig: TrackTradeModalConfig = {
      title: `Trade ${this.lang.get('deliverable')}`,
      deliverable,
      deliverables$: this.store.select(ScopeOverviewSelectors.selectAvailableDeliverableTypes(currentScope.identity.rateCard.currencyCode)),
      currentScope: currentScope,
      currencyCode: currentScope.identity.rateCard.currencyCode,
      onSearch: (searchText: string) => {
        if (searchText && searchText.length) {
          this.store.dispatch(
            ScopeOverviewActions.deliverableTypeSearch({
              searchQuery: searchText,
              rateCardVersionId: currentScope.identity.rateCard.id,
              language: currentScope.identity.language,
            })
          )
        } else {
          this.store.dispatch(
            ScopeOverviewActions.getAvailableScopeDeliverableTypes({ mergeLibraryDeliverableEntryStrategy: 'DISCIPLINE' })
          )
        }
      }
    }

    this.dialog.open(TrackTradeModalComponent, {
      data: dialogConfig,
    })
  }

  addRolesModal(currentScope: ScopeVersion) {
    const dialogConfig: AddRolesModalConfig = {
      title: `Add/Update Role(s)`,
      rateCard$: this.rateCard$,
      currentScope: currentScope
    }

    this.dialog.open(AddRolesModalComponent, {
      data: dialogConfig,
    })
  }

  completeTradeModal(deliverable: Deliverable, currentScope: ScopeVersion) {
    const completeTradeModalConfig: ModalConfig = {
      title: 'Complete Deliverable',
      body: `Are you sure you want to mark ${deliverable.name} as complete?`,
      confirmText: 'Complete',
      confirmCallback: () => {
        this.store.dispatch(
          ScopeOverviewActions.completeTrade({ deliverableId: deliverable.id, scopeId: currentScope.identity.id })
        )
        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: completeTradeModalConfig,
    })
  }

  stopProgressTradeModal(deliverable: Deliverable, currentScope: ScopeVersion) {
    const stopProgressTradeModalConfig: ModalConfig = {
      title: 'Stop Progress of Deliverable',
      body: `Are you sure you want to stop progress of ${deliverable.name}?`,
      confirmText: 'Stop',
      confirmCallback: () => {
        this.store.dispatch(
          ScopeOverviewActions.stopTrade({ deliverableId: deliverable.id, scopeId: currentScope.identity.id })
        )
        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: stopProgressTradeModalConfig,
    })
  }

  confirmTradeModal(deliverable: Deliverable) {
    const confirmTradeModalConfig: ModalConfig = {
      title: 'Confirm Trade',
      body: `Are you sure you want to confirm trade for ${deliverable.name}?`,
      confirmText: 'Confirm Trade',
      confirmCallback: () => {
        this.store.dispatch(
          ScopeOverviewActions.confirmTrade({ scopeId: this.id, deliverableId: deliverable.id})
        )
        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: confirmTradeModalConfig,
    })
  }

  cancelTradeModal(deliverable: Deliverable, currentScope: ScopeVersion) {
    const cancelTradeModalConfig: ModalConfig = {
      title: 'Confirm Trade',
      body: `Are you sure you want to cancel trade for ${deliverable.name}?`,
      confirmText: 'Cancel Trade',
      confirmCallback: () => {
        this.store.dispatch(
          ScopeOverviewActions.cancelTrade({ deliverableId: deliverable.id, scopeId: currentScope.identity.id })
        )
        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: cancelTradeModalConfig,
    })
  }

  reopenTradeModal(deliverable: Deliverable, currentScope: ScopeVersion) {
    const reopenTradeModalConfig: ModalConfig = {
      title: 'Confirm Trade',
      body: `Are you sure you want to reopen for ${deliverable.name}?`,
      confirmText: 'Reopen',
      confirmCallback: () => {
        this.store.dispatch(
          ScopeOverviewActions.reopenTrade({ deliverableId: deliverable.id, scopeId: currentScope.identity.id })
        )
        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: reopenTradeModalConfig,
    })
  }

  refreshActuals(event: any, currentScope: ScopeVersion) {
    let trafficSystem = this.loggedInUser.company.trafficSystemSettings != null ? this.loggedInUser.company.trafficSystemSettings.trafficSystem : null;
    let timesheetConfigType = trafficSystem && trafficSystem.externalTrafficSystemConfig.scopeTimeSheet
    if (timesheetConfigType == null) {
      return
    }
    if (timesheetConfigType.type === 'SOW_UPLOAD') {
      this.snackbarService.showSnackbar('Refreshing actuals. Do not refresh the page.', SNACKBAR_LENGTH_LONG, SnackbarEventType.INFO)
      this.isApprovalLoading = true
      this.scopeOverviewService.refreshActuals(currentScope.scopeOfWorkVersion.identity.id).subscribe({
        next: (resp) => {
          this.snackbarService.showSnackbar('Actuals are updated successfully.', SNACKBAR_LENGTH_LONG, SnackbarEventType.INFO)
          this.store.dispatch(ScopeOverviewActions.getCurrentScope({ scopeId: this.id }))
          this.isApprovalLoading = false
        },
        error: (error) => {
          if (error.status === 500 && error.data == "") {
            this.snackbarService.showSnackbar('An error occurred. Please try again. ' +
              'Check if job code property is set for deliverables in this SOW' + error.data, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
          } else if (error.status == 400 && error.data != "") {
            this.showConflictDatesModal(error)
          } else {
            this.snackbarService.showDefaultErrorSnackbar()
          }
          this.isApprovalLoading = false
          this.cdr.detectChanges();
        },
      })
    } else if (timesheetConfigType.type === 'URL') {
      this.scopeOverviewService.importScopeTimeSheets(this.id).subscribe({
        next: (resp) => {
        this.snackbarService.showSnackbar('Actuals update request was sent.', SNACKBAR_LENGTH_LONG, SnackbarEventType.INFO)
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
        }
      });

    }
  }

  showConflictDatesModal(error: any) {
    let desc = "";
    const conflicts = JSON.parse(error.error);
    Object.keys(conflicts).forEach(code => {
      conflicts[code].forEach(d => {
        desc += "<tr>" +
          "<td>" + d['deliverableName'] + "</td>" +
          "<td>" + d['externalId'] + "</td>" +
          "<td>" + d['startDate'].day + "/" + d['startDate'].month + "/" + d['startDate'].year + "</td>" +
          "<td>" + d['endDate'].day + "/" + d['endDate'].month + "/" + d['endDate'].year + "</td>" +
          "</tr>";
      })
    });
    let table = `<div class="conflict-container"><table class="conflict-table">
                    <tbody>
                    <tr class="conflict-title">
                        <td>Name</td>
                        <td>Job code</td>
                        <td>Start date</td>
                        <td>End date</td>
                    </tr>`
                    + desc +
                    `</tbody>
                    </table></div>`

    this.isApprovalLoading = false
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Error - found conflicting dates in deliverables`,
        table,
        'Close',
        undefined,
        function (form: FormControl) {
          dialog.close()
        },
        undefined,
        [], false, false, false, undefined, false, true
      ),
    })

  }

  showDeliverableApplyLocationCardDialog(event: { deliverable: Deliverable, rateCard: RatecardVersion }) {
    const modalConfig: ModalConfig = {
      title: `Apply location card to ${this.lang.get('deliverable')}`,
      body: `All roles within the ${this.lang.get('deliverable')} will be assigned with the rate and cost of the location card. The default ratecard rate and cost will be applied where there is no role within the location card.`,
      confirmText: 'Apply',
      confirmCallback: (form: FormControl, selections: any[]) => {
        this.setApprovalLoading(true);
        this.scopeOverviewService.applyLocationCardToDeliverable(this.id, event.deliverable.id, selections[0].id).subscribe({
          next: (deliverable) => {
            this.store.dispatch(
              ScopeOverviewActions.getScopeDeliverableSuccess({ deliverable }),
            );
            this.setApprovalLoading(false);
            dialog.close();
          },
          error: (error) => {
            this.snackbarService.showSnackbar(`An error occurred while applying the location card`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
            console.error(error);
            this.setApprovalLoading(false);
            dialog.close();
          },
        });
      },
      cancelCallback: undefined,
      inputs: [
        {
          name: 'locationCard',
          control: new FormControl(''),
          type: 'autocomplete',
          label: 'Location Card',
          placeholder: 'Location Card',
          options: event.rateCard.locationCards,
          multiselect: false,
          optionFn: (location: any) => location?.rateCardLocation?.name
        },
      ],
      isMemberModal: false,
      limitBodyWidth: true,
      isFormValid: (form: FormControl, selections: any[]) => {
        return !!selections.length
      }
    }
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: modalConfig,
    })
  }


  addThirdPartyCost(currentScope: ScopeVersion) {
    const dialogConfig: AddTpcModalConfig = {
      currentScope: currentScope
    }

    this.dialog.open(AddTpcModalComponent, {
      data: dialogConfig,
    })
  }
  private updateSubject = new Subject<{ event: ThirdPartyCost }>();

  setupDebouncedUpdate() {
    this.updateSubject.pipe(
      debounceTime(1000),
      switchMap(({event }) =>
        this.scopeOverviewService.updateScopeThirdPartyCost(this.id, event)
      )
    ).subscribe(response => {
      this.store.dispatch(ScopeOverviewActions.updateScopeTpcSuccess({ thirdPartyCost: response }));
    }, error => {
      console.error('Update error:', error);
    });
  }

  updateThirdPartyCost(event: ThirdPartyCost) {
    this.updateSubject.next({ event });
  }

  deleteScopeThirdPartyCost(tpc: ThirdPartyCost) {
    const deleteScopeTpcModalConfig: ModalConfig = {
      title: 'Delete TPC',
      body: `Are you sure you want to delete third party cost?`,
      confirmText: 'Delete',
      confirmCallback: () => {
        this.scopeOverviewService.deleteScopeThirdPartyCost(this.id, tpc.id).subscribe({
          next: () => {
            this.store.dispatch(
              ScopeOverviewActions.deleteScopeTpcSuccess({ thirdPartyCost: tpc })
            )
          }, error: () => console.log('err')
        })

        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: deleteScopeTpcModalConfig,
    })

  }


  addDiscount(scope: ScopeVersion) {
    let dialog = this.dialog.open(AddEditDiscountModalComponent)
    dialog.componentInstance.currencyCode = scope.identity.rateCard.currencyCode
    dialog.componentInstance.discount = scope.discount
    dialog.componentInstance.maxDiscount = scope.getNaturalSellingPrice({ includeTpc: true }).amount
    dialog.componentInstance.onSubmit.subscribe((discount: any) => {
      dialog.close()
      this.scopeOverviewService.updateScopeDiscount(this.id, discount).subscribe({
        next: () => {
          this.store.dispatch(ScopeOverviewActions.updateScopeDiscountSuccess({discount: discount}))
        },
        error: (error) => {
          if (error.error?.errorCode == "DISCOUNT_NOT_WITHIN_RANGE") {
            this.snackbarService.showPrioritisedSnackbar("Discount exceeds permitted range " + error.error.message, 5000, SnackbarEventType.ERROR)
          } else {
            this.snackbarService.showDefaultErrorSnackbar()
          }
          return error
        },
      })
    })
  }

  deleteDiscount(currentScope: ScopeVersion) {
    const deleteDiscountModalConfig: ModalConfig = {
      title: 'Delete discount',
      body: `Are you sure you want to delete discount?`,
      confirmText: 'Delete',
      confirmCallback: () => {
        this.store.dispatch(
          ScopeOverviewActions.deleteScopeDiscount({ scopeId: currentScope.identity.id })
        )
        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: deleteDiscountModalConfig,
    })
  }

  editMsaLineItem(scope: ScopeVersion) {
    let dialog = this.dialog.open(EditMsaModalComponent)
    dialog.componentInstance.currencyCode = scope.identity.rateCard.currencyCode
    dialog.componentInstance.msaLineItem = scope.msaLineItem
    dialog.componentInstance.onSubmit.subscribe((msaLineItem: any) => {
      dialog.close()
      this.scopeOverviewService.updateScopeMsa(this.id, msaLineItem).subscribe({
        next: () => {
          this.store.dispatch(ScopeOverviewActions.updateScopeMsaSuccess({msaLineItem: msaLineItem}))
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
          console.error(error)
          return error
        },
      })
    })
  }

  deleteMsaLineItem(card: any, currentScope: ScopeVersion) {
    const deleteDiscountModalConfig: ModalConfig = {
      title: 'Remove MSA',
      body: `Are you sure you want to permanently remove the MSA from this ${this.lang.get('scope|l')}?`,
      confirmText: 'Remove',
      confirmCallback: (form: FormControl) => {
        this.store.dispatch(
          ScopeOverviewActions.deleteScopeMsa({ scopeId: currentScope.identity.id, reason: form.get('reason')?.value })
        )
        if (card) {
          let deletedCard = { ...card, disabled: true }
          Object.assign(card, deletedCard)
        }
        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [{ name: 'reason', control: new FormControl(''), type: 'textarea', label: 'Reason', maxLength: 250 }],
      isMemberModal: undefined,
      limitBodyWidth: true,
      isFormValid: (form: FormControl) => {
        return !!form.get('reason')?.value
      }
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: deleteDiscountModalConfig,
    })
  }

  getLatestAndUpdateState = () => {
    this.store
      .select(ScopeOverviewSelectors.selectCurrentScope)
      .pipe(
        take(1)
      )
      .subscribe((currentScope) => {
        this.scopeApprovalFlowService.updateApprovalStateChecks(currentScope)
      })
  }

  onScopeEventReceived(event: NotificationEvent) {
    this.store
      .select(ScopeOverviewSelectors.selectCurrentScope)
      .pipe(
        take(1),
        filter((currentScope) => !!currentScope)
      )
      .subscribe((currentScope) => {
        if (!currentScope || currentScope.version !== currentScope.identity.currentVersionNumber) {
          return
        }
        switch (event.type) {
          case EventType.SCOPE_DELETED: {
            this.snackbarService.showPermanentSnackbar(`${this.lang.get('scope')} '` + currentScope.name + "' has been deleted by " + event.initiatedBy.fullName, SnackbarEventType.WARNING)
            this.router.navigateByUrl('/scopes');
            break
          }
          case EventType.SCOPE_ARCHIVED: {
            this.snackbarService.showPermanentSnackbar(`This ${this.lang.get('scope')} has been archived by ${event.initiatedBy.fullName}`, SnackbarEventType.WARNING)
            this.store.dispatch(ScopeOverviewActions.setScopeNotificationEvent({ scopeNotificationProps: { archived: true } }))
            break
          }
          case EventType.SCOPE_UNARCHIVED: {
            this.snackbarService.showPermanentSnackbar(`This ${this.lang.get('scope')} has been unarchived by ${event.initiatedBy.fullName}`, SnackbarEventType.WARNING)
            this.store.dispatch(ScopeOverviewActions.setScopeNotificationEvent({ scopeNotificationProps: { archived: false } }))
            break
          }
          case EventType.SCOPE_DELIVERABLE_DELETED: {
            let deliverableId = event.object as number;
            let deliverables = currentScope.deliverables.filter(s => s.id != deliverableId)
            this.store.dispatch(ScopeOverviewActions.setScopeNotificationEvent({ scopeNotificationProps: { deliverables: deliverables, readyToTraffic: true } }))
            break
          }
          case EventType.SCOPE_TEAM_MEMBER_ADDED: {
            let member = event.object.member as ScopeTeamMember

            if (member.type == "COLLABORATOR") {
              this.store.dispatch(ScopeOverviewActions.addScopeCollaboratorSuccess({ collaborators: [member] }))
            } else if (member.type == "REVIEWER") {
              this.store.dispatch(ScopeOverviewActions.addScopeReviewerSuccess({ reviewers: [member] }))
            } else if (member.type == "APPROVER") {
              this.store.dispatch(ScopeOverviewActions.addScopeApproverSuccess({ approvers: [member] }))
            } else if (member.type == "TRAFFICKER") {
              this.store.dispatch(ScopeOverviewActions.addScopeTraffickerSuccess({ traffickers: [member] }))
            }
            this.getLatestAndUpdateState()
            break
          }
          case EventType.SCOPE_TEAM_MEMBER_REMOVED: {
            let member = event.object.member as ScopeTeamMember

            if (member.type == "COLLABORATOR") {
              this.store.dispatch(ScopeOverviewActions.removeScopeCollaboratorSuccess({ member: member }))
            } else if (member.type == "REVIEWER") {
              this.store.dispatch(ScopeOverviewActions.removeScopeReviewerSuccess({ member: member }))
            } else if (member.type == "APPROVER") {
              this.store.dispatch(ScopeOverviewActions.removeScopeApproverSuccess({ member: member }))
            } else if (member.type == "TRAFFICKERS") {
              this.store.dispatch(ScopeOverviewActions.removeScopeTraffickerSuccess({ member: member }))
            }
            this.getLatestAndUpdateState()
            break
          }
          case EventType.SCOPE_DELIVERABLE_ADDED: {
            this.store.dispatch(ScopeOverviewActions.addScopeDeliverableSuccess({ deliverable: event.object }))
            break
          }
          case EventType.SCOPE_COMMENT: {
            this.updateScopeComment(event.object);
            break;
          }
          case EventType.SCOPE_ACTIVITY_COMMENT: {
            this.store.dispatch(ScopeOverviewActions.getActivities({ scopeId: this.id }))
            break
          }
          case EventType.SCOPE_APPROVAL_WEBHOOK_INFO_UPDATED: {
            let obj = event.object;
            let scopeApprovedWebHookInfo = currentScope.scopeApprovedWebHookInfo ? cloneDeep(currentScope.scopeApprovedWebHookInfo) : {}
            scopeApprovedWebHookInfo.status = obj.status
            scopeApprovedWebHookInfo.fileId = obj.fileId
            this.store.dispatch(ScopeOverviewActions.setScopeNotificationEvent({ scopeNotificationProps: { scopeApprovedWebHookInfo: scopeApprovedWebHookInfo } }))
            break;
          }
          case EventType.SCOPE_UPDATED: {
            let eventScope = plainToInstance(ScopeVersion, event.object)
            this.store.dispatch(ScopeOverviewActions.setScopeNotificationEvent({ scopeNotificationProps: { readyToTraffic: eventScope.readyToTraffic } }))
            this.scopeApprovalFlowService.updateApprovalStateChecks(eventScope)
            break
          }
          case EventType.SCOPE_VERSIONED: {
            this.store.dispatch(ScopeOverviewActions.getCurrentScope({ scopeId: this.id }))
            break
          }
          case EventType.SCOPE_SUBMISSION_WITHDREW: {
            let eventScope = plainToInstance(ScopeVersion, event.object)
            this.store.dispatch(ScopeOverviewActions.setScopeNotificationEvent({ scopeNotificationProps: { status: eventScope.status, collaborators:  eventScope.collaborators } }))
            this.scopeApprovalFlowService.updateApprovalStateChecks(eventScope)
            break
          }
          case EventType.SCOPE_SUBMITTED:
          case EventType.SCOPE_REVIEW_SUBMITTED:
          case EventType.SCOPE_APPROVAL_REJECTED:
          case EventType.SCOPE_CLIENT_APPROVAL_REJECTED:
          case EventType.SCOPE_APPROVAL_ACCEPTED:
          case EventType.SCOPE_CLIENT_APPROVAL_ACCEPTED: {
            let eventScope = plainToInstance(ScopeVersion, event.object)
            this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: eventScope }))
            this.store.dispatch(ScopeOverviewActions.getActivities({ scopeId: this.id }))
            this.scopeApprovalFlowService.updateApprovalStateChecks(eventScope)
            break;
          }
          case EventType.SCOPE_CLOSED: {
            let eventScope = plainToInstance(ScopeVersion, event.object)
            this.store.dispatch(ScopeOverviewActions.setScopeNotificationEvent({ scopeNotificationProps: { status: eventScope.status } }))
            this.store.dispatch(ScopeOverviewActions.getActivities({ scopeId: this.id }))
            this.scopeApprovalFlowService.updateApprovalStateChecks(eventScope)
            break;
          }
          case EventType.SCOPE_DELIVERABLE_REVIEW_REJECTED:
          case EventType.SCOPE_DELIVERABLE_REVIEW_ACCEPTED: {
            let update = plainToInstance(Deliverable, event.object)
            let eventScope = cloneDeep(currentScope)
            let deliverable = eventScope.deliverables.find(d => d.id === update.id)
            deliverable.reviewers = update.reviewers
            this.store.dispatch(ScopeOverviewActions.setScopeNotificationEvent({ scopeNotificationProps: { deliverables: eventScope.deliverables } }))
            this.store.dispatch(ScopeOverviewActions.getActivities({ scopeId: this.id }))
            this.scopeApprovalFlowService.updateApprovalStateChecks(eventScope)
            break;
          }
          case EventType.SCOPE_EXTERNAL_TRANSFER_STARTED: {
            this.traffickingService.total = event.object.noOfTransfers
            this.traffickingService.exportReference = event.object.exportReference
            this.traffickingService.deliverableSuccessCount = 0
            this.traffickingService.exportStatus.setInProgress()
            break
          }
          case EventType.SCOPE_EXTERNAL_TRANSFER_ABORTED: {
            this.traffickingService.abortedErrorMessage = this.traffickingService.getAbortedErrorMessage(event.object.errorType)
            this.traffickingService.errorType = event.object.errorType
            this.traffickingService.exportStatus.setExportAborted()
            break
          }
          case EventType.SCOPE_EXTERNAL_TRANSFER_COMPLETE: {
            if (event.object.successful) {
              let status = (currentScope.approved) ? StatusType.TRAFFICKED : currentScope.status
              let readyToTraffic = false
              let deliverables = cloneDeep(currentScope.deliverables)
              deliverables.forEach(it => it.readyToTraffic = false)
              let lastTraffickedDate = new Date()
              this.store.dispatch(ScopeOverviewActions.setScopeNotificationEvent({ scopeNotificationProps: { status: status, deliverables: deliverables, readyToTraffic: readyToTraffic, lastTraffickedDate: lastTraffickedDate } }))
              this.getLatestAndUpdateState()
            }
            this.scopeApprovalFlowService.stateChecks.traffickedState.isTrafficking = false;
            this.traffickingService.isTrafficking = false;
            this.scopeApprovalFlowService.stateChecks.traffickedState.wasTrafficking = true;
            setTimeout(()=> {
              this.scopeApprovalFlowService.stateChecks.traffickedState.wasTrafficking = false;
            }, 2000);
            this.traffickingService.deliverableSuccessCount = event.object.counter.current;
            this.traffickingService.total = event.object.counter.total;
            this.traffickingService.exportStatus.setExportComplete();
            break
          }
          case EventType.SCOPE_EXTERNAL_TRANSFER_DELIVERABLE_TRANSFERRED: {
            if (!this.traffickingService.isTrafficking) { //Refresh or newly loaded page
              this.traffickingService.isTrafficking = true;
              this.scopeApprovalFlowService.stateChecks.traffickedState.isTrafficking = true;
              this.traffickingService.exportStatus.setInProgress();
            }

            this.traffickingService.total = event.object.counter.total;
            if (event.object.successful) {
              this.traffickingService.deliverableSuccessCount = event.object.counter.current;
            } else {
              this.traffickingService.deliverableFailCount = event.object.counter.current;
              let deliverable = currentScope.deliverables.find(d => d.id == event.object.deliverableId);
              this.traffickingService.deliverableErrors.push({ id: deliverable.id, name: deliverable.name, error: this.traffickingService.getAbortedErrorMessage(event.object.errorType) });
            }
            break
          }
        }
      })
  }

  updateScopeComment(object) {
    switch (object.comment.sourceType) {
      case 'DELIVERABLE': {
        this.store.dispatch(ScopeOverviewActions.addDeliverableCommentSuccess({ deliverableId: object.deliverableId, comment: object.comment }))
        break;
      }
      case 'COMPONENT': {
        this.store.dispatch(ScopeOverviewActions.addComponentCommentSuccess({ deliverableId: object.deliverableId, componentId: object.componentId, comment: object.comment }))
        break;
      }
      case 'DEPARTMENT': {
        this.store.dispatch(ScopeOverviewActions.addDepartmentCommentSuccess({ deliverableId: object.deliverableId, componentId: object.componentId, departmentId: object.comment.source.id, comment: object.comment }))
        break;
      }
      case 'ROLE': {
        this.store.dispatch(ScopeOverviewActions.addRoleCommentSuccess({ deliverableId: object.deliverableId, componentId: object.componentId, roleId: object.comment.source.id, comment: object.comment }))
        break;
      }
      case 'SECTION': {
        this.store.dispatch(ScopeOverviewActions.addDeliverableSectionCommentSuccess({ deliverableId: object.deliverableId, sectionId: object.comment.source.id, comment: object.comment }))
        break;
      }
      case 'SCOPE_SECTION': {
        this.store.dispatch(ScopeOverviewActions.addScopeSectionCommentSuccess({ sectionId: object.comment.source.id, comment: object.comment }))
        break;
      }
      case 'DELIVERABLE_TPC': {
        this.store.dispatch(ScopeOverviewActions.addDeliverableTPCCommentSuccess({ deliverableId: object.deliverableId, tpcId: object.comment.source.id, comment: object.comment }))
        break;
      }
      case 'SCOPE_TPC': {
        this.store.dispatch(ScopeOverviewActions.addScopeTPCCommentSuccess({ tpcId: object.comment.source.id, comment: object.comment }))
        break;
      }
      case 'COMPONENT_TPC': {
        this.store.dispatch(ScopeOverviewActions.addComponentTPCCommentSuccess({ deliverableId: object.deliverableId, componentId: object.componentId, tpcId: object.comment.source.id, comment: object.comment }))
        break;
      }
      case 'SCOPE': {
        this.store.dispatch(ScopeOverviewActions.addScopeCommentSuccess({ comment: object.comment }))
        break;
      }
    }
  }

  showAddTeamMemberModal(currentScope: ScopeVersion) {
    if (this.scopeApprovalFlowService.isStateDraft(currentScope.status)) {
      this.scopeTeamService.showAddCollaboratorModal(currentScope, this.cdnConfig)
    } else if (this.scopeApprovalFlowService.isStateInReview(currentScope.status)) {
      this.scopeTeamService.showAddReviewerModal(currentScope, this.cdnConfig)
    } else if (this.scopeApprovalFlowService.isStateReviewed(currentScope.status)) {
      this.scopeTeamService.showAddApproverModal(currentScope, this.cdnConfig)
    } else if (
      this.scopeApprovalFlowService.isStateAgencyApproved(currentScope.status) &&
      !this.scopeApprovalFlowService.isStateApproved(currentScope)
    ) {
      this.scopeTeamService.showAddApproverModal(currentScope, this.cdnConfig)
    } else if (this.scopeApprovalFlowService.isStateApproved(currentScope)) {
      this.scopeTeamService.showAddTraffickerModal(currentScope, this.cdnConfig)
    }
  }

  sendNotificationReminder(currentScope: ScopeVersion) {
    const dialogConfig: ModalConfig = {
      title: currentScope.status === 'SUBMITTED' ? 'Send Approval Reminder' : 'Send Review Reminder',
      body: "You can send approval reminder email to the Approvers if you wish to make sure that the scopes approvals dosen't get forgotten",
      confirmText: 'Send Reminder',
      confirmCallback: (form: FormControl) => {
        let approvalText: string = form.get('comment').value
        this.store.dispatch(
          ScopeOverviewActions.sendNotificationReminder({
            scopeId: this.id,
            userId: this.loggedInUser.id,
            comment: approvalText,
          })
        )
        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', placeholder: 'Write a comment' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: dialogConfig,
    })
  }

  isDeliverable(value: any): value is Deliverable {
    return (value as Deliverable).id !== undefined;
  }

  setApprovalLoading = (value: boolean) => {
    this.isApprovalLoading = value
    this.cdr.detectChanges()
  }

  acceptDeliverable(deliverable: Deliverable) {
    const dialogConfig: ModalConfig = {
      title: 'Accept Deliverable Review',
      body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')} and guide them with their next steps`,
      confirmText: 'Accept Deliverable',
      confirmCallback: (form: FormControl) => {
        this.setApprovalLoading(true);
        let approvalText: string = form.get('comment').value;
        this.approvalsService
          .acceptDeliverableReview(this.id, approvalText, deliverable.id)
          .pipe(this.destroy$())
          .subscribe({
            next: () => {
              this.setApprovalLoading(false);
              this.scopeApprovalFlowService.stateChecks.deliverableStatus.deliverableAccepted = true;
              if (this.isDeliverable(deliverable)) {
                this.scopeApprovalFlowService.stateChecks.deliverableStatus.deliverableInReview = deliverable.id;
              }
              dialog.close();
            },
            error: (error: any) => {
              this.setApprovalLoading(false);
              this.snackbarService.showDefaultErrorSnackbar();
              console.error(error);
              return error;
            },
          });
      },
      cancelCallback: undefined,
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
    };

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: dialogConfig,
    });
  }

  rejectDeliverable(deliverable: Deliverable) {
    const dialogConfig: ModalConfig = {
      title: 'Reject Deliverable Review',
      body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')} and guide them with their next steps`,
      confirmText: 'Reject Deliverable',
      confirmCallback: (form: FormControl) => {
        this.setApprovalLoading(true);
        let approvalText: string = form.get('comment').value;
        this.approvalsService
          .rejectDeliverableReview(this.id, approvalText, deliverable.id)
          .pipe(this.destroy$())
          .subscribe({
            next: () => {
              this.setApprovalLoading(false);
              dialog.close();
            },
            error: (error: any) => {
              this.setApprovalLoading(false);
              this.snackbarService.showDefaultErrorSnackbar();
              console.error(error);
              return error;
            },
          });
      },
      cancelCallback: undefined,
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
    };

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: dialogConfig,
    });
  }

  openTrafficExportModal(currentScope: ScopeVersion) {
      if (!this.traffickingService.isTrafficking && this.traffickingService.deliverableErrors.length === 0) {
        this.traffickingService.setConfirmationPending()
      }

      if (!this.companyIntegrations || this.companyIntegrations.length == 0) {
        const dialogConfig: ExportModalConfig = {
          title: 'Export Traffic',
          body: `Are you sure you want to export your ${this.lang.get('scope')} for Trafficking?`,
          currentScope: currentScope,
          currentUser: this.loggedInUser,
          limitModalWidth: true,
          scopeId: this.id
        }
        this.dialog.open(ExportTrafficModal, {
          data: dialogConfig,
        })
      } else {
        const dialogConfig: IntegrationModalConfig = {
          title: 'Scope Traffic',
          body: `Select to which systems you want to send your ${this.lang.get('scope')} data`,
          integrations : this.companyIntegrations,
          currentScope: currentScope,
          currentUser: this.loggedInUser,
          limitModalWidth: true,
          scopeId: this.id
        }
        this.dialog.open(IntegrationTrafficModal, {
          data: dialogConfig,
        })
      }
  }

  exportTrafficThroughAgencyTrafficSystem() {
    this.store
      .select(ScopeOverviewSelectors.selectCurrentScope)
      .pipe(
        take(1),
        filter((currentScope) => !!currentScope)
      )
      .subscribe((currentScope) => {
        if (this.scopeApprovalFlowService.isTrafficDisabled(currentScope)) return

        this.traffickingService.assertActiveSystemConfigured(this.loggedInUser.company);
        this.exportToAgencyTrafficSystem();
      });
  }

  exportToAgencyTrafficSystem() {
    if (this.loggedInUser.company.trafficSystemSettings.trafficSystem.name == 'Workato') {
      this.store.dispatch(ScopeOverviewActions.exportTrafficScopeWorkato({
        target: 'WORKDAY__PROJECT',
        identityId: this.id,
      }));
    } else {
      this.store.dispatch(ScopeOverviewActions.exportTrafficScope({ identityId: this.id }));
    }
  }

  exportTrafficThroughWorkato() {
    this.store
      .select(ScopeOverviewSelectors.selectCurrentScope)
      .pipe(
        take(1),
        filter((currentScope) => !!currentScope),
      )
      .subscribe((currentScope) => {
        if (this.scopeApprovalFlowService.isTrafficDisabled(currentScope)) return;

        if (this.loggedInUser.company.hasWorkatoIntegration) {
          this.store.dispatch(ScopeOverviewActions.trafficScopeThroughWorkato({ identityId: this.id }));
        }
      });
  }

  submitDraftScopeModal(currentScope: ScopeVersion) {
    let canOverrideCollaborators = this.scopeApprovalFlowService.isSuperCollaborator(this.loggedInUser, currentScope) && this.scopeApprovalFlowService.stateChecks.currentStateMembers?.length > 1
    const dialogConfig: ModalConfig = {
      title: `Submit ${this.lang.get('scope')}`,
      body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')} and guide them with their next steps`,
      confirmText: 'Submit my own work',
      cancelText: canOverrideCollaborators ? "Submit everyone's work" : undefined,
      confirmCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let approvalText: string = form.get('comment').value
        this.approvalsService
          .submitScope(this.id, false, approvalText)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: scope }))
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(error)
              return error
            },
          })
      },
      cancelCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let approvalText: string = form.get('comment').value
        this.approvalsService
          .submitScope(this.id, true, approvalText)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: scope }))
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(error)
              return error
            },
          })
      },
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
      hasCancelButton: canOverrideCollaborators,
      flatButton: true
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: dialogConfig,
    })
  }

  withdrawSubmissionModal() {
    const dialogConfig: ModalConfig = {
      title: 'Withdraw my submission',
      body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')} and guide them with their next steps`,
      confirmText: 'Withdraw my submission',
      confirmCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let approvalText: string = form.get('comment').value
        this.approvalsService
          .withdrawSubmission(this.id, approvalText)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              this.store.dispatch(ScopeOverviewActions.getCurrentScope({ scopeId: this.id }))
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(error)
              return error
            },
          })
      },
      cancelCallback: undefined,
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: dialogConfig,
    })
  }

  submitScopeReview(approvalText: string, hasOverride: boolean): void {
    this.setApprovalLoading(true)
    this.approvalsService
      .submitScopeReview(this.id, hasOverride, approvalText)
      .pipe(this.destroy$())
      .subscribe({
        next: (scope: ScopeVersion) => {
          this.setApprovalLoading(false)
          this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
          this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: scope }))
          this.completeReviewDialogConfig.close()
        },
        error: (error: any) => {
          this.setApprovalLoading(false)
          this.snackbarService.showDefaultErrorSnackbar()
          console.error(error)
          return error
        }
      })
  }

  openExportModal(currentScope: ScopeVersion) {
    const dialogConfig: ExportModalConfig = {
      title: `Export ${this.lang.get('scope')}`,
      body: `Select which document file you want to output your ${this.lang.get('scope|l')}:`,
      currentScope: currentScope,
      currentUser: this.loggedInUser,
      limitModalWidth: true,
      scopeId: this.id,
    }

    this.dialog.open(ExportModalComponent, {
      data: dialogConfig,
    })
  }

  openExcelExportModal() {
    this.store
      .select(ScopeOverviewSelectors.selectCurrentScope)
      .pipe(
        take(1),
        filter((currentScope) => !!currentScope)
      )
      .subscribe((currentScope) => {
        const dialogConfig: ExportModalConfig = {
          title: 'Export as Excel Output',
          body: 'Please select which excel document file you want to Output for scope',
          currentScope: currentScope,
          currentUser: this.loggedInUser,
          limitModalWidth: true,
          scopeId: this.id,
        }

        this.dialog.open(ExportExcelModal, {
          data: dialogConfig,
        })
      })
  }

  completeReviewModal(currentScope: ScopeVersion, comment?: string) {
    const dialogConfig: ReviewSubmitModalConfig = {
      currentScope: currentScope,
      currentUser: this.loggedInUser!,
      deliverables: currentScope.deliverables,
      deliverableColumns: [{ key: 'DELIVERABLE' }, { key: 'REVIEW_STATUS' }] as Preference[],
      deliverableTableData: this.scopeApprovalFlowService.getReviewSummaryRowData(currentScope),
      stateChecks: this.scopeApprovalFlowService.stateChecks,
      title: `Complete ${this.lang.get('scope')} Review`,
      limitModalWidth: true,
      text: comment,
      customContainerClass: 'w-575'
    }

    this.completeReviewDialogConfig = this.dialog.open(ReviewSubmitModalComponent, {
      data: dialogConfig,
    })
  }

  openAcceptDeliverablesModal(key: string) {
    this.store
      .select(ScopeOverviewSelectors.selectCurrentScope)
      .pipe(
        take(1),
        filter((currentScope) => !!currentScope)
      )
      .subscribe((currentScope) => {
        this.completeReviewDialogConfig.close()

        const deliverableIds = this.scopeApprovalFlowService.getReviewSummaryRowData(currentScope).rowData.items.map(
          (deliverable: Deliverable) => deliverable.id
        )

        const title =
          key === 'openAcceptDeliverableDialog' ? 'Accept All Deliverable Reviews' : 'Reject All Deliverable Reviews'
        const buttonText = key === 'openAcceptDeliverableDialog' ? 'Accept All Deliverables' : 'Reject All Deliverables'

        let acceptComment: string

        const acceptDeliverableDialogConfig: ModalConfig = {
          title,
          body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')} and guide them with their next steps`,
          confirmText: buttonText,
          confirmCallback: (form: FormControl) => {
            acceptComment = form.get('comment').value
            if (key === 'openAcceptDeliverableDialog') {
              this.setApprovalLoading(true)
              this.approvalsService
                .acceptAllDeliverableReview(this.id, acceptComment, deliverableIds)
                .pipe(this.destroy$())
                .subscribe({
                  next: () => {
                    this.setApprovalLoading(false)
                    dialog.close()
                    this.completeReviewModal(currentScope, acceptComment)
                  },
                  error: (error: any) => {
                    this.setApprovalLoading(false)
                    this.snackbarService.showDefaultErrorSnackbar()
                    console.error(JSON.stringify(error))
                    return error
                  },
                })
            } else if (key === 'openRejectDeliverableDialog') {
              this.setApprovalLoading(true)
              let acceptComment: string = form.get('comment').value
              this.approvalsService
                .rejectAllDeliverableReview(this.id, acceptComment, deliverableIds)
                .pipe(this.destroy$())
                .subscribe({
                  next: () => {
                    this.setApprovalLoading(false)
                    dialog.close()
                    this.completeReviewModal(currentScope, acceptComment)
                  },
                  error: (error: any) => {
                    this.setApprovalLoading(false)
                    this.snackbarService.showDefaultErrorSnackbar()
                    console.error(JSON.stringify(error))
                    return error
                  },
                })
            }
          },
          cancelCallback: undefined,
          inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
          isMemberModal: undefined,
          limitBodyWidth: true,
        }

        let dialog = this.dialog.open(ScopeUiModalComponent, {
          data: acceptDeliverableDialogConfig,
        })
      });
  }

  agreeToScopeModal(currentScope: ScopeVersion) {
    let hasOverrideCollaborators = this.scopeApprovalFlowService.isSuperApprover(this.loggedInUser, currentScope) && this.scopeApprovalFlowService.stateChecks.currentStateMembers?.length > 1
    const agreeToScopeDialogConfig: ModalConfig = {
      title: `Agree ${this.lang.get('scope')}`,
      body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')} and guide them with their next steps`,
      confirmText: 'Accept',
      cancelText: hasOverrideCollaborators ? 'Accept for all Approvers' : undefined,
      confirmCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let acceptComment = form.get('comment').value
        this.approvalsService
          .agreeToScope(this.id, undefined, acceptComment)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: scope }))
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(JSON.stringify(error))
              return error
            },
          })
      },
      cancelCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let acceptComment = form.get('comment').value
        this.approvalsService
          .agreeToScope(this.id, hasOverrideCollaborators, acceptComment)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: scope }))
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(JSON.stringify(error))
              return error
            },
          })
      },
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
      hasCancelButton: hasOverrideCollaborators,
      flatButton: true
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: agreeToScopeDialogConfig,
    })
  }

  declineScopeModal() {
    const declineScopeDialogConfig: ModalConfig = {
      title: `Reject ${this.lang.get('scope')}`,
      body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')} and guide them with their next steps`,
      confirmText: 'Decline',
      confirmCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let acceptComment = form.get('comment').value
        this.approvalsService
          .rejectScope(this.id, acceptComment)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: scope }))
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(JSON.stringify(error))
              return error
            },
          })
      },
      cancelCallback: undefined,
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: declineScopeDialogConfig,
    })
  }

  acceptScopeModal() {
    const acceptDialogConfig: ModalConfig = {
      title: `Client Approve ${this.lang.get('scope')}`,
      body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')} and guide them with their next steps`,
      confirmText: 'Accept',
      confirmCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let acceptComment = form.get('comment').value
        this.approvalsService
          .clientAcceptScope(this.id, acceptComment)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: scope }))
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(JSON.stringify(error))
              return error
            },
          })
      },
      cancelCallback: undefined,
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: acceptDialogConfig,
    })
  }

  rejectScopeModal() {
    const rejectDialogConfig: ModalConfig = {
      title: `Client Reject ${this.lang.get('scope')}`,
      body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')} and guide them with their next steps`,
      confirmText: 'Decline for revision',
      cancelText: 'Decline and Close',
      confirmCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let acceptComment = form.get('comment').value
        this.approvalsService
          .clientRejectScope(this.id, acceptComment, false)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: scope }))
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(JSON.stringify(error))
              return error
            },
          })
      },
      cancelCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let rejectComment = form.get('comment').value
        this.approvalsService
          .clientRejectScope(this.id, rejectComment, true)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              this.store.dispatch(ScopeOverviewActions.getCurrentScopeSuccess({ currentScope: scope }))
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(JSON.stringify(error))
              return error
            },
          })
      },
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
      hasCancelButton: true,
      flatButton: true
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: rejectDialogConfig,
    })
  }

  closeScopeModal() {
    const rejectDialogConfig: ModalConfig = {
      title: `Close ${this.lang.get('scope')}`,
      body: `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')}`,
      confirmText: 'Submit',
      confirmCallback: (form: FormControl) => {
        this.setApprovalLoading(true)
        let closeComment = form.get('comment').value
        this.approvalsService
          .closeScope(this.id, closeComment)
          .pipe(this.destroy$())
          .subscribe({
            next: (scope: ScopeVersion) => {
              this.setApprovalLoading(false)
              this.scopeApprovalFlowService.updateApprovalStateChecks(scope)
              dialog.close()
            },
            error: (error: any) => {
              this.setApprovalLoading(false)
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(JSON.stringify(error))
              return error
            },
          })
      },
      inputs: [{ name: 'comment', control: new FormControl(''), type: 'textarea', label: 'Add text here' }],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: rejectDialogConfig,
    })
  }

  getThirdPartyBalance() {
    this.store.dispatch(ScopeOverviewActions.getThirdPartyBalance({ scopeId: this.id }));
  }

  resolveShowUseRetainedTeamToggle(retainedTeamStats: SowRetainedTeamStats[], rateCardId: number) {
    return retainedTeamStats?.some(teamStats => teamStats.rateCardId === rateCardId);
  }

  // Find Replace Logic

  submitReplacedRoles(event: { roles: any, roleId: number, replaceRoleId: number }) {
    this.store.dispatch(
      ScopeActions.replaceRoles({
        selectedRoleId: event.roleId,
        selectedRoleReplaceRoleId: event.replaceRoleId,
        roles: event.roles,
        scopeId: this.id,
      })
    );
  }

  onRevert(revertId: number) {
    this.store.dispatch(ScopeActions.revertReplacementRoles({ scopeId: this.id, revertId }));
  }

  onSearch(searchData: FindRolesSearchParams) {
    this.findReplaceService.onSearch(this.id, searchData);
  }

  onSearchReplaceRole(searchString: string) {
    this.findReplaceService.onSearchReplaceRole(searchString, this.currentRatecard);
  }

  onSelectionChange(selectionEvent: any) {
    this.findReplaceService.onSelectionChange(selectionEvent);
    this.breadcrumbs = this.generateBreadCrumbs(this.findReplaceService.selectedRole);
  }

  removeDuplicates(arr: any[]): any[] {
    return this.findReplaceService.removeDuplicates(arr);
  }

  generateBreadCrumbs(role: any) {
    return {
      result: {
        department: role?.department?.name,
        deliverable: role?.deliverable?.name,
        component: role?.component?.name,
        name: role?.name,
      },
    };
  }

  getCdnConfig() {
    this.cdnConfig$.pipe(this.destroy$()).subscribe((config: CdnConfig) => {
      this.cdnConfig = config!;
    });
  }

  updateScopeName(name: any, scope: ScopeVersion) {
    this.editName = false;
    let updatedScope = plainToInstance(ScopeVersion, { ...scope, name })
    this.store.dispatch(ScopeOverviewActions.updateScope({ scope: updatedScope }));
  }

  getCellClass(status: StatusType): string {
    if (status?.length) {
      switch (status.toString()) {
        case StatusType.CLOSED.toString():
          return 'text-red-500'
        case StatusType.SUBMITTED.toString():
          return 'text-orange-500'
        case StatusType.IN_REVIEW.toString():
          return 'text-yellow-400'
        case StatusType.AGENCY_APPROVED.toString():
          return 'text-green-500'
        case StatusType.CLIENT_APPROVED.toString():
          return 'text-green-500'
        case StatusType.SCOPING.toString():
          return 'text-green-500'
        case StatusType.TRAFFICKED.toString():
          return 'text-blue-500'
        case StatusType.DRAFT.toString():
          return 'text-slate-600'
        default:
          return ''
      }
    }
    return ''
  }

  initScopeRetainedStats(currentScope: ScopeVersion): void {
    if (currentScope.scopeOfWorkVersion && currentScope.useRetainedHours) {
      this.store.dispatch(
        ScopeOverviewActions.getScopeRetainedStats({
          sowId: currentScope.scopeOfWorkVersion.identity.id,
          scopeId: currentScope.id,
        })
      );
    }

    if (currentScope.scopeOfWorkVersion) {
      this.store.dispatch(
        ScopeOverviewActions.getSowRetainedStats({ folderId: currentScope.scopeOfWorkVersion.identity.id })
      );
    }
  }

  redirectToSow(sowId: number) {
    this.router.navigateByUrl('/scopes/folder-overview/' + sowId)
  }

  changeVersion = (id: number): void => {
    this.store.dispatch(
      ScopeOverviewActions.changeScopeVersion({ scopeId: this.id, versionId: id })
    );
  }

  addScopeSection() {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Add new ${this.lang.get('stage')}`,
        undefined,
        'Complete',
        undefined,
        (form: FormControl) => {
          dialog.close();
          const scopeSection = new ScopeSection();
          scopeSection.name = form.get('name')?.value;
          scopeSection.description = form.get('description')?.value;
          scopeSection.startDate = form.get('duration')?.value?.startDate;
          scopeSection.endDate = form.get('duration')?.value?.endDate;
          this.store.dispatch(
            ScopeOverviewActions.addScopeSection({ scopeId: this.id, section: scopeSection })
          );
        },
        undefined,
        [
          { name: 'name', control: new FormControl(''), type: 'text', label: 'Name' },
          { name: 'description', control: new FormControl(''), type: 'description' },
          { name: 'duration', control: new FormControl(''), type: 'duration' }
        ],
        undefined,
        undefined,
        undefined,
        (form: FormControl) => {
          return form.value.name
        }
      ),
    })
  }

  deleteScopeSection(section: ScopeSection, scope: ScopeVersion) {
    let body = `Are you sure you want to delete ` + section.name;
    if (scope.deliverables.find((d) => d.section?.id === section.id)) {
      body += ` and deliverables inside of it`;
    }
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete ` + section.name,
        body + `?`,
        'Delete',
        undefined,
        () => {
          dialog.close();
          this.store.dispatch(
            ScopeOverviewActions.deleteScopeSection({ scopeId: this.id, sectionId: section.id })
          );
        },
        undefined,
        [],
        false,
        true
      ),
    })
  }

  // deleteScope actions and state.
  deleteScope(currentScope: ScopeVersion) {
    let body = `Are you sure you want to delete this ${this.lang.get('scope')}?`;
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete "` + currentScope.name + `"`,
        body,
        'Delete',
        undefined,
        () => {
          dialog.close();
          this.scopingService.deleteScope(this.id).subscribe({
            next: () => {
              this.router.navigateByUrl('/scopes');
            },
            error: (error: any) => {
              this.snackbarService.showDefaultErrorSnackbar();
              console.error(error);
              return error;
            },
          });
        },
        undefined,
        [],
        false,
        true
      ),
    })
  }

  archiveScope(currentScope: ScopeVersion) {
    let body = `Archiving the scope will make it read-only. No further changes will be possible`;
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Archive "` + currentScope.name + `"`,
        body,
        'Archive',
        undefined,
        () => {
          dialog.close();
          this.store.dispatch(ScopeOverviewActions.archiveScope({ scopeId: this.id }));
        },
        undefined,
        [],
        false,
        true
      ),
    })
  }

  unarchiveScope(currentScope: ScopeVersion) {
    let body = `Are you sure you want to unarchive the ${this.lang.get('scope')}`;
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Unarchive "` + currentScope.name + `"`,
        body,
        'Unarchive',
        undefined,
        () => {
          dialog.close();
          this.store.dispatch(ScopeOverviewActions.unarchiveScope({ scopeId: this.id }));
        },
        undefined,
        [],
        false,
        true
      ),
    })
  }

  exportScopeByRole(currentScope: ScopeVersion) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Export Excel document`,
        `Do you want to download ${this.lang.get('scope|l')} "${currentScope.name}" as Excel document?`,
        'Export',
        undefined,
        () => {
          dialog.close();
          this.scopeOverviewService.exportScopeByRoleToExcel(this.id).subscribe({
            next: () => {
              this.snackbarService.showSnackbar("Download will start soon.", SNACKBAR_LENGTH_SHORT, SnackbarEventType.INFO)
            },
            error: (error: any) => {
              this.snackbarService.showDefaultErrorSnackbar();
              return error;
            },
          });
        },
        undefined,
        [],
        false,
        true
      ),
    })
  }

  saveScopeDescription(description: string, currentScope: ScopeVersion) {
    const updatedScope = plainToInstance(ScopeVersion, { ...currentScope, description })
    this.store.dispatch(ScopeOverviewActions.updateScope({ scope: updatedScope }));
  }

  saveScopeNote(internalNote: string, currentScope: ScopeVersion) {
    const updatedScope = plainToInstance(ScopeVersion, { ...currentScope, internalNote })
    this.store.dispatch(ScopeOverviewActions.updateScope({ scope: updatedScope }));
  }

  createDeliverable(currentScope: ScopeVersion, isFixedFee: boolean = false) {
    this.createDeliverableDialog = this.dialog.open(CreateDeliverableModalComponent);
    this.store.dispatch(ScopeOverviewActions.setDialogId({ id: this.createDeliverableDialog.id }))

    this.createDeliverableDialog.componentInstance.cdnConfig = this.cdnConfig!;
    this.createDeliverableDialog.componentInstance.isFixedFee = isFixedFee
    this.createDeliverableDialog.componentInstance.currentScope = currentScope
    this.createDeliverableDialog.componentInstance.owner = this.loggedInUser
    this.createDeliverableDialog.componentInstance.confirmCallback.subscribe((
      event: {
        form: FormControl,
        selection: SelectedDeliverable,
        isNewCustomDeliverable: boolean,
        quickAdd: boolean
      },
    ) => {
      if (event.isNewCustomDeliverable) {
        this.addCustomDeliverable(event.form, event.selection, currentScope, isFixedFee, event.quickAdd);
      } else {
        if (event.selection.deliverableType!.ratecardVersionId === currentScope.identity.rateCard.id || event.selection.deliverableType.sourceType === 'SCOPE') {
          this.createDeliverableDialog.close();
          this.addExistingDeliverable(event.form, event.selection, currentScope.identity.rateCard.currencyCode, event.quickAdd);
        } else {
          let targetRatecard$ =  this.scopeOverviewService.getScopeRatecardByVersion(currentScope.identity.id, currentScope.identity.rateCard.version)
          let sourceRatecard$ =  event.selection.deliverableType.sourceType == "LIBRARY" ?
            this.scopeOverviewService.getRatecardVersionForDeliverableEntry(event.selection.deliverableType.parentId, event.selection.deliverableType.id) :
            this.scopeOverviewService.getRatecardVersion(event.selection.deliverableType.ratecardVersionId);

          forkJoin([targetRatecard$, sourceRatecard$]).subscribe({
            next: ([targetRatecard, sourceRatecard]: [RatecardVersion, RatecardVersion]) => {
              this.scopeOverviewService.getDeliverableEntry(
                event.selection.deliverableType.parentId,
                event.selection.deliverableType.discipline.id,
                targetRatecard.id).subscribe({
                next: (entry) => {
                  event.selection.deliverableType = plainToInstance(DeliverableType, {
                    ...event.selection.deliverableType,
                    id: entry.id,
                    ratecardVersionId: entry.rateCardVersion.id
                  })
                  this.addExistingDeliverable(event.form, event.selection, currentScope.identity.rateCard.currencyCode, event.quickAdd);
                },
                error: () => {
                  this.showRoleMappingDialogOrCreateDeliverable(event, targetRatecard, sourceRatecard, currentScope.name, currentScope.identity.rateCard.currencyCode);
                }
              })
            },
            error: (error) => {
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(error)
              return error
            }
          });
        }
      }
    });
    this.createDeliverableDialog.componentInstance.currencyCode = currentScope.identity.rateCard.currencyCode
    this.createDeliverableDialog.componentInstance.availableDeliverables$ = this.store.select(ScopeOverviewSelectors.selectAvailableDeliverableTypes(currentScope.identity.rateCard.currencyCode))
    this.createDeliverableDialog.componentInstance.availableOwners$ = this.store.select(ScopeOverviewSelectors.selectCurrentScope)
      .pipe(map((scope) => scope.identity.team.collaborators.map(c => c.user)))

    this.createDeliverableDialog.componentInstance.onSearchDeliverables.subscribe((searchText: string) => {
      if (isFixedFee) {
        this.store.dispatch(
          ScopeOverviewActions.deliverableTypeSearch({
            fixedOnly: true,
            searchQuery: searchText,
            rateCardVersionId: currentScope.identity.rateCard.id,
            language: currentScope.identity.language,
          })
        )
      } else if (searchText && searchText.length) {
        this.store.dispatch(
          ScopeOverviewActions.deliverableTypeSearch({
            searchQuery: searchText,
            rateCardVersionId: currentScope.identity.rateCard.id,
            language: currentScope.identity.language,
          })
        )
      } else {
        this.store.dispatch(
          ScopeOverviewActions.getAvailableScopeDeliverableTypes({ mergeLibraryDeliverableEntryStrategy: 'DISCIPLINE' })
        )
      }
    });
    this.createDeliverableDialog.componentInstance.onInsertWithRemapRolesSuccess.subscribe((deliverableId: number) => {
      this.router.navigateByUrl(`/scopes/scope-overview/${this.id}/deliverable/${deliverableId}`)
      this.createDeliverableDialog.close()
    })
  }

  addCustomDeliverable(form: FormControl, selection: SelectedDeliverable, currentScope: ScopeVersion, fixedPricing: boolean, quickAdd: boolean) {
    let isFixed = form.get('libraryItemType')?.value != "DELIVERABLE";
    let deliverable: any = {
      name: form.get('name')?.value,
      description: selection.description,
      budget: new Money(form.get('budget')?.value || 0, currentScope.identity.rateCard.currencyCode),
      internalNote: '',
      language: currentScope.identity.language,
      fixedPricing: isFixed,
      libraryItemType: form.get('libraryItemType')?.value,
      itemType: 'LibraryDeliverableTemplate',
    };

    if (form.get('libraryItemType')?.value == "FIXED_COST") {
      deliverable.fixedPricing = true
    }
    if (deliverable.fixedPricing) {
      deliverable.libraryItemType = "FIXED_COST"
    }

    this.store.dispatch(
      ScopeOverviewActions.createLibraryDeliverableTemplate({
        scopeId: this.id,
        deliverable,
      })
    );

    return this.store.select(ScopeOverviewSelectors.selectDeliverableLibraryTemplate).pipe(
      filter((template) => template != undefined && template.name == form.get('name')?.value),
      take(1),
      switchMap((template) => {
        let entry: any = {
          discipline: form.get('discipline')?.value,
          rateCardVersion: currentScope.identity.rateCard,
          description: selection.description
        }
        entry.fixedPricing = isFixed;
        if (deliverable.fixedPricing) entry.fixedCostEditable = true

        this.store.dispatch(
          ScopeOverviewActions.createLibraryDeliverableEntry({
            templateId: template.id,
            entry: entry,
          })
        );

        return this.store.select(ScopeOverviewSelectors.selectDeliverableLibraryEntry).pipe(
          filter((updateEntry) => updateEntry != undefined && updateEntry.name == form.get('name')?.value),
          take(1),
          map((updateEntry) => ({updateEntry, template, currentScope}))
        );
      })
    ).subscribe(({updateEntry, template, currentScope}) => {
      let entry: any = {};
      entry.discipline = form.get('discipline')?.value;
      entry.rateCardVersion = currentScope.identity.rateCard;

      let searchResult = {
        id: updateEntry.id,
        name: template.name,
        description: template.description,
        isTrialRestricted: false,
        sourceType: 'LIBRARY',
      };
      searchResult.id = updateEntry.id;
      let scopeDeliverable = new Deliverable();
      scopeDeliverable.source = searchResult;
      scopeDeliverable.sourceType = searchResult.sourceType;
      scopeDeliverable.owner = form.get('owner')?.value ? form.get('owner')?.value : this.loggedInUser as User;
      scopeDeliverable.name = form.get('name')?.value;
      scopeDeliverable.description = searchResult.description;
      scopeDeliverable.fixedPricing = isFixed;
      scopeDeliverable.startDate = selection.startDate;
      scopeDeliverable.endDate = selection.endDate;
      scopeDeliverable.trafficSystemEntityMetadata = selection.trafficSystemEntityMetadata;
      // Dispatch the final action
      this.store.dispatch(
        ScopeOverviewActions.addScopeDeliverable({ scopeId: this.id, deliverable: scopeDeliverable, editLibDelEntryId : updateEntry.id, quickAdd })
      );
    });
  }

  addExistingDeliverable(form: FormControl, selection: SelectedDeliverable, currencyUnit: string, quickAdd: boolean) {
    let deliverable = new Deliverable();
    deliverable.source = selection.deliverableType;
    deliverable.sourceType = selection.deliverableType.sourceType;
    deliverable.owner = this.loggedInUser as User;
    deliverable.name = form.get('name')?.value ? form.get('name')?.value : selection.deliverableType.name;
    deliverable.description = selection.description || selection.deliverableType.description;
    deliverable.budget = new Money(form.get('budget')?.value || 0, currencyUnit);
    deliverable.startDate = selection.startDate;
    deliverable.endDate = selection.endDate;
    deliverable.discipline = selection.deliverableType.discipline;

    this.store.dispatch(
      ScopeOverviewActions.addScopeDeliverable({ scopeId: this.id, deliverable, quickAdd })
    )
  }

  showRoleMappingDialogOrCreateDeliverable = (event: {
                                                form: FormControl,
                                                selection: SelectedDeliverable,
                                                isNewCustomDeliverable: boolean,
                                                quickAdd: boolean
                                              },
                                              targetRatecard: RatecardVersion,
                                              sourceRatecard: RatecardVersion,
                                              currentScopeName: string,
                                              currencyCode: string) => {
    this.prepareMapping(event.selection.deliverableType.id, targetRatecard,
      (usedRoles: Role[], mappingRoles: Role[], targetRoles: Role[], mappingRoleKeys: { [key: number]: number }, deliverableEntry: any, showAgencyRates: boolean, distinctRolesMap: any)=> {
        if (usedRoles.length === mappingRoles.length) {
          this.scopeOverviewService.duplicateDeliverableEntry(deliverableEntry.deliverable.id, deliverableEntry.id, deliverableEntry.discipline, targetRatecard.id, mappingRoleKeys, true)
            .subscribe({
              next: (duplicatedEntry) => {
                this.createDeliverableDialog.close();
                event.selection.deliverableType = plainToInstance(DeliverableType, {
                  ...event.selection.deliverableType,
                  id: duplicatedEntry.id,
                  ratecardVersionId: duplicatedEntry.rateCardVersion.id
                })
                this.addExistingDeliverable(event.form, event.selection, currencyCode, event.quickAdd);
              },
              error: (error) => {
                this.snackbarService.showDefaultErrorSnackbar()
                console.error(error)
                return error
              }
            })
        } else {
          this.createDeliverableDialog.componentInstance.nextModalStep = true;
          this.createDeliverableDialog.componentInstance.mappingRoles = mappingRoles;
          this.createDeliverableDialog.componentInstance.targetRoles = [{name: 'Unassigned', id: null}].concat(targetRoles);
          this.createDeliverableDialog.componentInstance.sourceRatecard = sourceRatecard;
          this.createDeliverableDialog.componentInstance.targetRatecard = targetRatecard;
          this.createDeliverableDialog.componentInstance.showAgencyRates = showAgencyRates;
          this.createDeliverableDialog.componentInstance.deliverableEntry = deliverableEntry;
          this.createDeliverableDialog.componentInstance.distinctRolesMap = distinctRolesMap;
        }
      }
    );
  };

  prepareMapping = (deliverableId: number, targetRatecard: RatecardVersion, callback: (usedRoles: Role[], mappingRoles: Role[], targetRoles: Role[], mappingRoleKeys: { [key: number]: number }, deliverableEntry: any, showAgencyRates: boolean, distinctRolesMap: any) => void) => {
    let distinctRolesMap = {};
    let mappingRoles = [];
    let targetRoles = [];
    let usedRoles = [];
    let mappingRoleKeys: { [key: number]: number } = {};
    this.scopeOverviewService.getLibraryDeliverableEntry(null, deliverableId).subscribe({
      next: (deliverableEntry) => {
        let showAgencyRates = this.loggedInUser.company.id == deliverableEntry.createdBy.company.id;
        let targetDepartments = targetRatecard.departments;
        if (targetDepartments) {
          let targetDepartmentsRolesByName = {};

          targetDepartments.forEach(function(targetDepartment) {
            let roles = targetDepartment.roles;
            roles.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            roles.forEach(function(targetRole) {
              targetDepartmentsRolesByName[targetRole.name.toLowerCase()] = targetRole;
            });
          });

          deliverableEntry.libraryComponents.forEach(function(libraryDeliverableEntryComponent) {

            libraryDeliverableEntryComponent.roles.forEach(function(role) {
              let key = role.rateCardRole.name.toLowerCase()
              if (!distinctRolesMap[key]) {
                let distinctRole: any = {
                  name: role.rateCardRole.name,
                  id: role.rateCardRole.id,
                  rate: role.rateCardRole.rate
                };
                var targetRole = targetDepartmentsRolesByName[key];
                if (targetRole) {
                  distinctRole.targetMappingRole = targetRole;
                  usedRoles.push(targetRole.id);
                  mappingRoleKeys[role.rateCardRole.id] = targetRole.id;
                } else {
                  distinctRole.targetMappingRole = {name: 'Unassigned', id: null};
                }
                distinctRolesMap[key] = distinctRole;
              }
            });
          });
          mappingRoles = Object.values(distinctRolesMap);
        }

        if (targetDepartments) {
          targetRoles = targetDepartments.flatMap(function(department) {
            return department.roles
          })
        }

        callback(usedRoles, mappingRoles, targetRoles, mappingRoleKeys, deliverableEntry, showAgencyRates, distinctRolesMap);
      },
      error: (error) => {
        this.snackbarService.showDefaultErrorSnackbar()
        console.error(error)
        return error
      }
    })
  }

  addTask(ratecardVersionId: number) {
    let dialog = this.dialog.open(AddTaskModalComponent)
    dialog.componentInstance.taskTemplates$ = this.scopeOverviewService.getTaskTemplates(ratecardVersionId)
    dialog.componentInstance.onSubmit.subscribe((task: NewTask) => {
      dialog.close()
      this.scopeOverviewService.addTask(this.id, task).subscribe({
        next: (task) => {
          this.store.dispatch(ScopeOverviewActions.addScopeDeliverableSuccess({deliverable: task}))
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
          return error
        },
      })
    })
    dialog.componentInstance.onSubmitSavedTasks.subscribe((tasks: number[]) => {
      dialog.close()
      this.scopeOverviewService.addTasksFromLibrary(this.id, tasks).subscribe({
        next: (tasks) => {
          this.store.dispatch(ScopeOverviewActions.addScopeDeliverablesSuccess({deliverables: tasks}))
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
          return error
        },
      })
    })
  }

  fetchDeliverable(deliverableId: number) {
    this.store.dispatch(
      ScopeOverviewActions.getScopeDeliverable({ scopeId: this.id, deliverableId: deliverableId })
    )
  }

  updateDeliverable(deliverable: Deliverable) {
    this.scopeOverviewService.updateScopeDeliverable(this.id, deliverable ).subscribe({
      next: (result) => {
        this.store.dispatch(ScopeOverviewActions.updateScopeDeliverableSuccess({deliverable: result}))
      },
      error: (error) => {
        this.snackbarService.showDefaultErrorSnackbar()
        console.error(error)
        return error
      },
    })
  }

  updateComponent(component: ScopeComponent) {
    this.store.dispatch(
      ScopeOverviewActions.updateScopeComponent({ scopeId: this.id, deliverableId: component.deliverable.id, component, deliverable: null })
    )
  }

  onMoveComponent(event: {component: ScopeComponent, deliverable: Deliverable}, currentScope: ScopeVersion) {
    const delId : number = event.component.deliverable.id
    currentScope.deliverables.map(d => d.id == event.deliverable.id ? event.deliverable : d)
    this.store.dispatch(
      ScopeOverviewActions.updateScopeComponent({ scopeId: this.id, deliverableId: delId,component: event.component, deliverable: event.deliverable })
    )
  }

  onMoveComponentOutsideDeliverable(component: ScopeComponent, currentScope: ScopeVersion) {
    this.store.dispatch(
      ScopeOverviewActions.moveComponentToDeliverable({
        scopeId: currentScope.identity.id,
        componentId: component.id,
        deliverableId: component.deliverable.id,
        name: component.updatedName,
        order: component.order
      })
    )
  }

  duplicateDeliverable(deliverable: Deliverable) {
    this.store.dispatch(
      ScopeOverviewActions.duplicateScopeDeliverable({ scopeId: this.id, deliverableId: deliverable.id })
    )
  }

  deleteDeliverable(deliverable: Deliverable) {
    let body = `Are you sure you want to delete "` + deliverable.name + `"?`;
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete "` + deliverable.name + `"`,
        body,
        'Delete',
        undefined,
        () => {
          dialog.close();
          this.store.dispatch(
            ScopeOverviewActions.deleteDeliverable({
              scopeId: this.id,
              deliverableId: deliverable.id,
            })
          )
          this.getLatestAndUpdateState()
        },
        undefined,
        [],
        false,
        true
      ),
    })
  }

  addFee(currencyCode: string, element?: any) {
    let dialog = this.dialog.open(AddEditFeeModalComponent)
    this.store.dispatch(
      ScopeActions.getAllFeeItems()
    )
    dialog.componentInstance.existingFees$ = this.store.select(ScopingSelectors.selectAllFeeItems)
    dialog.componentInstance.currencyCode = currencyCode
    dialog.componentInstance.modalTitle = `Add ${
      (element instanceof ScopeSection) ? this.lang.get('stage|l') :
        (element instanceof Deliverable) ? this.lang.get('deliverable|l') :
          (element instanceof ScopeComponent) ? this.lang.get('component|l') :
            this.lang.get('scope|l')
    } fee`

    dialog.componentInstance.onSubmit.subscribe((event: { feeItem: FeeItem }) => {
      dialog.close()
      this.submitFee(event.feeItem, element)
    })
  }

  addAndLinkFee(currentScope: ScopeVersion) {
    let dialog = this.dialog.open(AddEditFeeModalComponent)
    this.store.dispatch(
      ScopeActions.getAllFeeItems()
    )
    dialog.componentInstance.existingFees$ = this.store.select(ScopingSelectors.selectAllFeeItems)
    dialog.componentInstance.currencyCode = currentScope.identity.rateCard.currencyCode
    dialog.componentInstance.linkFee = true
    dialog.componentInstance.currentUser = this.loggedInUser
    this.store.dispatch(
      ScopeOverviewActions.searchNonDistinctComponents({
        scopeId: this.id,
        versionId: currentScope.version,
        searchText: '',
      })
    )

    dialog.componentInstance.modalTitle = `Add fee`
    dialog.componentInstance.onSubmit.subscribe((event: { feeItem: FeeItem, element: any }) => {
      dialog.close()
      this.submitFee(event.feeItem, event.element)
    })
  }

  submitFee(feeItem: FeeItem, element: any) {
    if (element instanceof ScopeSection) {
      this.submitSectionFee(feeItem, element.id)
    } else if (element instanceof Deliverable) {
      this.submitDeliverableFee(feeItem, element.id)
    } else if (element instanceof ScopeComponent) {
      this.submitComponentFee(feeItem, element.deliverable.id, element.id)
    } else {
      this.submitScopeFee(feeItem)
    }
  }

  submitScopeFee(feeItem: FeeItem) {
    this.scopeOverviewService.createScopeFeeItem(this.id, feeItem).subscribe({
      next: (result) => {
        this.store.dispatch(ScopeOverviewActions.createScopeFeeItemSuccess({feeItem: result}))
      },
      error: (error) => {
        this.snackbarService.showDefaultErrorSnackbar()
        console.error(error)
        return error
      },
    })
  }

  submitSectionFee(feeItem: FeeItem, sectionId: number) {
    this.scopeOverviewService.createSectionFeeItem(this.id, sectionId, feeItem).subscribe({
      next: (result) => {
        this.store.dispatch(ScopeOverviewActions.createSectionFeeItemSuccess({sectionId, feeItem: result}))
      },
      error: (error) => {
        this.snackbarService.showDefaultErrorSnackbar()
        console.error(error)
        return error
      },
    })
  }

  submitDeliverableFee(feeItem: FeeItem, deliverableId: number) {
    this.scopeOverviewService.createDeliverableFeeItem(this.id, deliverableId, feeItem).subscribe({
      next: (result) => {
        this.store.select(ScopeOverviewSelectors.selectDeliverable(deliverableId)).pipe(
          take(1)
        ).subscribe((deliverable: Deliverable) => {
          if (!deliverable.componentsInitialised) {
            this.fetchDeliverable(deliverableId)
          }
        });
        this.store.dispatch(ScopeOverviewActions.createDeliverableFeeItemSuccess({deliverableId, feeItem: result}))
      },
      error: (error) => {
        this.snackbarService.showDefaultErrorSnackbar()
        console.error(error)
        return error
      },
    })
  }

  submitComponentFee(feeItem: FeeItem, deliverableId: number, componentId: number) {
    this.scopeOverviewService.createComponentFeeItem(this.id, deliverableId, componentId, feeItem).subscribe({
      next: (result) => {
        this.store.select(ScopeOverviewSelectors.selectDeliverable(deliverableId)).pipe(
          take(1)
        ).subscribe((deliverable: Deliverable) => {
          if (!deliverable.componentsInitialised) {
            this.fetchDeliverable(deliverableId)
          }
        });
        this.store.dispatch(ScopeOverviewActions.createComponentFeeItemSuccess({deliverableId, componentId, feeItem: result}))
      },
      error: (error) => {
        this.snackbarService.showDefaultErrorSnackbar()
        console.error(error)
        return error
      },
    })
  }

  editFee(event: {feeItem: any, sectionId?: number, deliverableId?: number, componentId?: number}, currencyCode: string) {
    let dialog = this.dialog.open(AddEditFeeModalComponent)
    this.store.dispatch(
      ScopeActions.getAllFeeItems()
    )
    dialog.componentInstance.existingFees$ = this.store.select(ScopingSelectors.selectAllFeeItems)
    dialog.componentInstance.currencyCode = currencyCode

    if (event.componentId) {
      this.editComponentFee(dialog, event.feeItem.id, event.feeItem.feeItem, event.deliverableId, event.componentId)
    } else if (event.deliverableId) {
      this.editDeliverableFee(dialog, event.feeItem.id, event.feeItem.feeItem, event.deliverableId)
    } else if (event.sectionId) {
      this.editSectionFee(dialog, event.feeItem.id, event.feeItem.feeItem, event.sectionId)
    } else {
      this.editScopeFee(dialog, event.feeItem.id, event.feeItem.feeItem)
    }
  }

  editScopeFee(dialog: MatDialogRef<AddEditFeeModalComponent>, feeItemId: number, feeItem: any) {
    dialog.componentInstance.modalTitle = `Edit "${feeItem.name}" ${this.lang.get('scope|l')} fee`
    dialog.componentInstance.feeItem = feeItem
    dialog.componentInstance.onSubmit.subscribe((event: { feeItem: FeeItem, element: any }) => {
      dialog.close()
      this.scopeOverviewService.updateScopeFeeItem(this.id, feeItemId, event.feeItem).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.editScopeFeeItemSuccess({feeItemId, feeItem: result}))
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
          console.error(error)
          return error
        },
      })
    })
  }

  editSectionFee(dialog: MatDialogRef<AddEditFeeModalComponent>, feeItemId: number, feeItem: any, sectionId: number) {
    dialog.componentInstance.modalTitle = `Edit "${feeItem.name}" ${this.lang.get('stage|l')} fee`
    dialog.componentInstance.feeItem = feeItem
    dialog.componentInstance.onSubmit.subscribe((event: { feeItem: FeeItem, element: any }) => {
      dialog.close()
      this.scopeOverviewService.updateSectionFeeItem(this.id, sectionId, feeItemId, event.feeItem).subscribe({
        next: (result) => {
          this.store.dispatch(ScopeOverviewActions.editSectionFeeItemSuccess({feeItemId, sectionId, feeItem: result}))
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
          console.error(error)
          return error
        },
      })
    })
  }

  editDeliverableFee(dialog: MatDialogRef<AddEditFeeModalComponent>, feeItemId: number, feeItem: any, deliverableId: number) {
    dialog.componentInstance.modalTitle = `Edit "${feeItem.name}" ${this.lang.get('deliverable|l')} fee`
    dialog.componentInstance.feeItem = feeItem
    dialog.componentInstance.onSubmit.subscribe((event: { feeItem: FeeItem, element: any }) => {
      dialog.close()
      this.scopeOverviewService.updateDeliverableFeeItem(this.id, deliverableId, feeItemId, event.feeItem).subscribe({
        next: (result) => {
          this.store.select(ScopeOverviewSelectors.selectDeliverable(deliverableId)).pipe(
            take(1)
          ).subscribe((deliverable: Deliverable) => {
            if (!deliverable.componentsInitialised) {
              this.fetchDeliverable(deliverableId)
            }
          });
          this.store.dispatch(ScopeOverviewActions.editDeliverableFeeItemSuccess({feeItemId, deliverableId, feeItem: result}))
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
          console.error(error)
          return error
        },
      })
    })
  }

  editComponentFee(dialog: MatDialogRef<AddEditFeeModalComponent>, feeItemId: number, feeItem: any, deliverableId: number, componentId: number) {
    dialog.componentInstance.modalTitle = `Edit "${feeItem.name}" ${this.lang.get('component|l')} fee`
    dialog.componentInstance.feeItem = feeItem
    dialog.componentInstance.onSubmit.subscribe((event: { feeItem: FeeItem, element: any }) => {
      dialog.close()
      this.scopeOverviewService.updateComponentFeeItem(this.id, deliverableId, componentId, feeItemId, event.feeItem).subscribe({
        next: (result) => {
          this.store.select(ScopeOverviewSelectors.selectDeliverable(deliverableId)).pipe(
            take(1)
          ).subscribe((deliverable: Deliverable) => {
            if (!deliverable.componentsInitialised) {
              this.fetchDeliverable(deliverableId)
            }
          });
          this.store.dispatch(ScopeOverviewActions.editComponentFeeItemSuccess({feeItemId, deliverableId, componentId, feeItem: result}))
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
          console.error(error)
          return error
        },
      })
    })
  }

  updateFee(event: {feeItem: FeeItemInstance, sectionId?: number, deliverableId?: number, componentId?: number}) {
    this.scopeOverviewService.updateCompanyFeeItem(event.feeItem.feeItem).subscribe({
      next: (result: FeeItem) => {
        if (event.componentId) {
          this.store.dispatch(ScopeOverviewActions.editComponentFeeItemSuccess({
            feeItemId: event.feeItem.id, deliverableId: event.deliverableId, componentId: event.componentId, feeItem: { ...event.feeItem, feeItem: result }
          }))
        } else if (event.deliverableId) {
          this.store.dispatch(ScopeOverviewActions.editDeliverableFeeItemSuccess({
            feeItemId: event.feeItem.id, deliverableId: event.deliverableId, feeItem: { ...event.feeItem, feeItem: result }
          }))
        } else if (event.sectionId) {
          this.store.dispatch(ScopeOverviewActions.editSectionFeeItemSuccess({
            feeItemId: event.feeItem.id, sectionId: event.sectionId, feeItem: { ...event.feeItem, feeItem: result }
          }))
        } else {
          this.store.dispatch(ScopeOverviewActions.editScopeFeeItemSuccess({
            feeItemId: event.feeItem.id, feeItem: { ...event.feeItem, feeItem: result }
          }))
        }
      },
      error: (error) => {
        this.snackbarService.showDefaultErrorSnackbar()
        console.error(error)
        return error
      },
    })
  }

  deleteFee(fee: { feeItem: FeeItemInstance, sectionId?: number, deliverableId?: number, componentId?: number }) {
    let body = `Are you sure you want to delete "${fee.feeItem.feeItem.name}"?`;
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete "${fee.feeItem.feeItem.name}"`,
        body,
        'Delete',
        undefined,
        () => {
          dialog.close();
          if (fee.componentId) {
            this.scopeOverviewService.deleteComponentFeeItem(this.id, fee.deliverableId, fee.componentId, fee.feeItem.id).subscribe({
              next: () => {
                this.store.select(ScopeOverviewSelectors.selectDeliverable(fee.deliverableId)).pipe(
                  take(1)
                ).subscribe((deliverable: Deliverable) => {
                  if (!deliverable.componentsInitialised) {
                    this.fetchDeliverable(fee.deliverableId)
                  }
                });
                this.store.dispatch(ScopeOverviewActions.deleteComponentFeeSuccess({ deliverableId: fee.deliverableId, componentId: fee.componentId, feeItemId: fee.feeItem.id }))
                if (this.filteredComponentFees) {
                  this.filteredComponentFees = this.filteredComponentFees.filter((s) => s.id != fee.feeItem.id);
                  this.filteredComponentFeesSource.next(this.filteredComponentFees);
                }
              },
              error: (error) => {
                this.snackbarService.showSnackbar(`An error occurred during deletion of ${this.lang.get('component|l')} fee`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
                return of(ScopeOverviewActions.deleteComponentFeeFail({ error }))
              },
            })
          } else if (fee.deliverableId) {
            this.scopeOverviewService.deleteDeliverableFeeItem(this.id, fee.deliverableId, fee.feeItem.id).subscribe({
              next: () => {
                this.store.select(ScopeOverviewSelectors.selectDeliverable(fee.deliverableId)).pipe(
                  take(1)
                ).subscribe((deliverable: Deliverable) => {
                  if (!deliverable.componentsInitialised) {
                    this.fetchDeliverable(fee.deliverableId)
                  }
                });
                this.store.dispatch(ScopeOverviewActions.deleteDeliverableFeeItemSuccess({deliverableId: fee.deliverableId, feeItemId: fee.feeItem.id}))
                if (this.filteredDeliverableFees) {
                  this.filteredDeliverableFees = this.filteredDeliverableFees.filter((s) => s.id != fee.feeItem.id);
                  this.filteredDeliverableFeesSource.next(this.filteredDeliverableFees);
                }
              },
              error: (error) => {
                this.snackbarService.showSnackbar(`An error occurred during deletion of ${this.lang.get('deliverable|l')} fee`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
                return of(ScopeOverviewActions.deleteDeliverableFeeItemFail({ error }))
              },
            })
          } else if (fee.sectionId) {
            this.store.dispatch(ScopeOverviewActions.deleteSectionFeeItem({ scopeId: this.id, sectionId: fee.sectionId, feeItemId: fee.feeItem.id }));
          } else {
            this.store.dispatch(ScopeOverviewActions.deleteScopeFeeItem({ scopeId: this.id, feeItemId: fee.feeItem.id }));

            if (this.filteredScopeFees) {
              this.filteredScopeFees = this.filteredScopeFees.filter((s) => s.id != fee.feeItem.id);
              this.filteredScopeFeesSource.next(this.filteredScopeFees);
            }
          }
        },
        undefined,
        [],
        false,
        true
      ),
    })
  }

  confirmMetaStructureUpdate = (currentScope: ScopeVersion) => {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Confirm the update of the company custom fields`,
        `The custom fields will be updated, proceed?`,
        'Confirm',
        'Cancel',
        () => {
          dialog.close();
          this.store.dispatch(
            ScopeOverviewActions.refreshOutdatedCustomFields({
              scopeId: this.id,
              customFieldValueStructure: currentScope.scopeVersionCustomFieldValueStructure || new ScopeCustomFieldValueStructure(),
            })
          );
        },
        undefined
      ),
    });
  }

  onOverrideQuantity(deliverable: Deliverable, component: ScopeComponent) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Change ${this.lang.get('component')} Quantity`,
        `Enter the new quantity for ${component.source.name + (component.name? " ~ " + component.name : "")}`,
        'Confirm change',
        undefined,
        (form: FormControl) => {
          dialog.close();
          let updatedComponent = plainToInstance(ScopeComponent, {...component, quantity: form.get('quantity')?.value})

          this.scopeOverviewService.updateScopeComponent(this.id, updatedComponent, true).subscribe({
            next: (update: ScopeComponent) => {
              updatedComponent.comments = updatedComponent.comments.concat(update.comments)
              this.store.dispatch(
                ScopeOverviewActions.overrideScopeComponentSuccess({
                  component: updatedComponent,
                })
              );
            },
            error: (error) => {
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(error)
              return error
            }
          });
        },
        undefined,
        [
          {
            name: 'quantity',
            control: new FormControl(component.quantity),
            type: 'number',
            label: 'Quantity'
          },
        ]
      ),
    });
  };

  onOverrideRateCardRoleHours(deliverable: Deliverable, component: ScopeComponent, role: Role) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Change Role Hours`,
        `Enter the new hours for ${role.name}`,
        'Confirm change',
        undefined,
        (form: FormControl) => {
          dialog.close();
          let minutes = formatDisplayHoursToMinutes(form.get('rateCardMinutes')?.value)
          let updatedRole = plainToInstance(Role, {...role, rateCardMinutes: minutes})

          this.scopeOverviewService.updateComponentRole(this.id, component.id, updatedRole, true).subscribe({
            next: () => {
              this.store.dispatch(
                ScopeOverviewActions.overrideRoleSuccess({
                  component: component,
                  role: updatedRole
                })
              );
            },
            error: (error) => {
              this.snackbarService.showDefaultErrorSnackbar()
              console.error(error)
              return error
            }
          });
        },
        undefined,
        [
          {
            name: 'rateCardMinutes',
            control: new FormControl(formatHours(role.rateCardMinutes / 60)),
            type: this.loggedInUser.company.hasApplicationSetting('SCOPE__AGENCY_HOURS_AND_MINUTES') ? 'hoursMinutes' : 'hours',
            label: `${this.lang.get('agency')} Hours`
          },
        ]
      ),
    });
  };

  saveDetails(currentScope: ScopeVersion) {
    this.store.dispatch(ScopeOverviewActions.updateScope({ scope: currentScope }));
    this.store
      .select(ScopeOverviewSelectors.selectUpdateScopeDetailsLoaded)
      .pipe(
        filter((response) => {
          return !!response;
        }),
        take(1)
      )
      .subscribe(() => {
        this.snackbarService.showSnackbar(`${this.lang.get('scope')} successfully updated`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS);
      });
  }

  onSearchComponents($event: string, currentScope: ScopeVersion) {
    this.store.dispatch(
      ScopeOverviewActions.searchComponentsForScope({
        scopeId: this.id,
        versionId: currentScope.version,
        searchText: $event,
      })
    )
  }

  onSearchDepartments($event: string, currentScope: ScopeVersion) {
    this.store.dispatch(
      ScopeOverviewActions.searchDepartmentsForScope({
        scopeId: this.id,
        versionId: currentScope.version,
        searchText: $event,
      })
    )
  }

  onSearchRoles($event: string, currentScope: ScopeVersion) {
    this.store.dispatch(
      ScopeOverviewActions.searchRolesForScope({
        scopeId: this.id,
        versionId: currentScope.version,
        searchText: $event,
      })
    )
  }

  // // Scope Overview Filters
  resetFilteredItems() {
    this.filteredSections = [];
    this.filteredSectionsSource.next(this.filteredSections);
    this.store.dispatch(ScopeOverviewActions.filterDeliverablesSuccess({ filteredDeliverables: [] }))
    this.filteredComponents = [];
    this.filteredComponentsSource.next(this.filteredComponents);
    this.filteredDepartmentsSource.next([]);
    this.filteredRolesSource.next([]);
    this.filteredScopeFees = [];
    this.filteredScopeFeesSource.next(this.filteredScopeFees);
    this.filteredDeliverableFees = [];
    this.filteredDeliverableFeesSource.next(this.filteredDeliverableFees);
    this.filteredComponentFees = [];
    this.filteredComponentFeesSource.next(this.filteredComponentFees);
    this.filteredDeliverableTPCsSource.next([]);
    this.filteredDeliverableSectionTPCsSource.next([]);
    this.filteredComponentTPCsSource.next([]);
  }

  onFilterScopes(filters: ScopeOverviewFilter, currentScope: ScopeVersion) {
    this.resetFilteredItems();

    if (isEqual(filters, ScopeOverviewFilterDefaults)) {
      this.showFilterView = false;
      return;
    }

    this.showFilterView = true;
    let hasBudgetFilters = filters.budget?.minimum || filters.budget?.maximum;
    let hasTpcFilters = filters.thirdPartyCost?.minimum || filters.thirdPartyCost?.maximum;
    let hasObjectFilters =
      filters.stages?.length ||
      filters.deliverables?.length ||
      filters.components?.components?.length ||
      filters.components?.complexity ||
      hasTpcFilters ||
      filters.departments?.length ||
      filters.roles?.length ||
      filters.disciplines?.length ||
      hasBudgetFilters;
    let hasProfitFilters =
      filters.margin?.minimum || filters.margin?.maximum || filters.profit?.minimum || filters.profit?.maximum;
    let hasMarkupFilters = filters.markUp?.minimum || filters.markUp?.maximum;

    if (!hasObjectFilters || filters.stages?.length) {
      this.scopeOverviewService
        .filterScopeSections(this.id, currentScope.version, filters)
        .subscribe((sections) => {
          sections.forEach((s) => {
            s.deliverables = currentScope.deliverables.filter((d) => d.section?.id === s.id);
          });
          this.filteredSections = sections;
          this.filteredSectionsSource.next(this.filteredSections);
        });
    } else {
      this.filteredSections = [];
      this.filteredSectionsSource.next(this.filteredSections);
    }
    if (!hasObjectFilters || filters.deliverables?.length || filters.disciplines?.length || hasBudgetFilters) {
      this.store.dispatch(ScopeOverviewActions.filterDeliverables({ scopeId: this.id, versionId: currentScope.version, filters }))
    } else {
      this.store.dispatch(ScopeOverviewActions.filterDeliverablesSuccess({ filteredDeliverables: [] }))
    }
    if (!hasObjectFilters || filters.components?.components?.length || filters.components?.complexity) {
      this.scopeOverviewService
        .filterScopeComponents(this.id, currentScope.version, filters)
        .subscribe((components) => {
          this.filteredComponents = components;
          this.filteredComponentsSource.next(this.filteredComponents);
        });
    } else {
      this.filteredComponents = [];
      this.filteredComponentsSource.next(this.filteredComponents);
    }
    if ((!hasObjectFilters || filters.departments?.length) && !hasProfitFilters && !hasMarkupFilters) {
      this.scopeOverviewService
        .filterScopeDepartments(this.id, currentScope.version, filters)
        .subscribe((departments) => {
          this.filteredDepartmentsSource.next(departments);
        });
    } else {
      this.filteredDepartmentsSource.next([]);
    }
    if ((!hasObjectFilters || filters.roles?.length) && !hasProfitFilters && !hasMarkupFilters) {
      this.scopeOverviewService
        .filterScopeRoles(this.id, currentScope.version, filters)
        .subscribe((roles) => {
          this.filteredRolesSource.next(roles);
        });
    } else {
      this.filteredRolesSource.next([]);
    }
    // Only filter fees by name
    if (!hasObjectFilters && !hasProfitFilters && !hasMarkupFilters && filters.name.length) {
      this.scopeOverviewService
        .filterScopeFees(this.id, currentScope.version, filters)
        .subscribe((fees) => {
          this.filteredScopeFees = fees;
          this.filteredScopeFeesSource.next(this.filteredScopeFees);
        });
      this.scopeOverviewService
        .filterScopeDeliverableFees(this.id, currentScope.version, filters)
        .subscribe((fees) => {
          this.filteredDeliverableFees = fees;
          this.filteredDeliverableFeesSource.next(this.filteredDeliverableFees);
        });
      this.scopeOverviewService
        .filterScopeComponentFees(this.id, currentScope.version, filters)
        .subscribe((fees) => {
          this.filteredComponentFees = fees;
          this.filteredComponentFeesSource.next(this.filteredComponentFees);
        });
    } else {
      this.filteredScopeFees = [];
      this.filteredScopeFeesSource.next(this.filteredScopeFees);
      this.filteredDeliverableFees = [];
      this.filteredDeliverableFeesSource.next(this.filteredDeliverableFees);
      this.filteredComponentFees = [];
      this.filteredComponentFeesSource.next(this.filteredComponentFees);
    }
    if ((!hasObjectFilters && !hasProfitFilters) || hasTpcFilters) {
      this.scopeOverviewService
        .filterScopeDeliverableTPCs(this.id, currentScope.version, filters)
        .subscribe((thirdPartyCosts) => {
          this.filteredDeliverableTPCsSource.next(thirdPartyCosts);
        });
      this.scopeOverviewService
        .filterScopeDeliverableSectionTPCs(this.id, currentScope.version, filters)
        .subscribe((thirdPartyCosts) => {
          this.filteredDeliverableSectionTPCsSource.next(thirdPartyCosts);
        });
      this.scopeOverviewService
        .filterScopeComponentTPCs(this.id, currentScope.version, filters)
        .subscribe((thirdPartyCosts) => {
          this.filteredComponentTPCsSource.next(thirdPartyCosts);
        });
    } else {
      this.filteredDeliverableTPCsSource.next([]);
      this.filteredDeliverableSectionTPCsSource.next([]);
      this.filteredComponentTPCsSource.next([]);
    }
  }

  previewExport = (template: OutputTemplate) => {
    let dialog = this.dialog.open(PreviewOutputComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
    });

    dialog.afterClosed().subscribe(() => document.body.classList.remove('cdk-global-scrollblock'));
    document.body.classList.add('cdk-global-scrollblock');
    dialog.componentInstance.outputTemplateName = template.name;
    dialog.componentInstance.templateLanguage = template.language;

    dialog.componentInstance.onDownloadExport.subscribe((fileName: string) => {
      this.scopeOverviewService.downloadExport(template.id, fileName);
    });

    this.store.dispatch(ScopeOverviewActions.previewScopeDocx({ scopeId: this.id }));
    this.store
      .select(ScopeOverviewSelectors.selectDocxPreview)
      .pipe(
        filter((response) => !!response),
        take(1)
      )
      .subscribe((response) => {
        dialog.componentInstance.documentPreview = response;
      });
    this.store
      .select(ScopeOverviewSelectors.selectPreviewError)
      .pipe(
        filter((error) => !!error),
        take(1)
      )
      .subscribe((error) => {
        console.error(error);
        this.snackbarService.showDefaultErrorSnackbar()
        dialog.componentInstance.loading = false;
        dialog.componentInstance.errorState = true;
      });
  };

  selectTab($event: string) {
    this.currentTab = $event
    let tabParam = $event != 'Scope' ? $event.toLowerCase().split(' ')[0] : null
    this.router.navigate([], { queryParams: { tab: tabParam } })
  }
}
