import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PasswordParams } from '@app/core/model/definitions/password-params.interface';
import { PasswordValidatorService } from '@core/service/password-validator.service';
import { AuthActions } from '@core/store';
import { Store } from '@ngrx/store';
import { untilDestroyed } from '@shared/utils/utils';
import { filter } from 'rxjs';
import { AuthService } from '@core/service/auth.service'

type ValidateResponseItem = { key: string; valid: boolean };

type ValidateResponse = ValidateResponseItem[];

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  private readonly destroy$;

  passwordResetForm: UntypedFormGroup;

  token!: string;

  minLength = 12;

  isValidating: boolean = false;

  isPasswordValid: boolean = false;

  constructor(
    private store: Store,
    private validationService: PasswordValidatorService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.destroy$ = untilDestroyed();

    this.passwordResetForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(this.minLength),
        (control: AbstractControl) => this.validationService.containsLowerCase(control),
        (control: AbstractControl) => this.validationService.containsUpperCase(control),
        (control: AbstractControl) => this.validationService.containsSpecialCharacter(control),
        (control: AbstractControl) => this.validationService.containsDigit(control),
      ]),
    });
  }

  ngOnInit() {
    this.subscribeToFormChanges();
    this.route.paramMap.pipe(this.destroy$()).subscribe((params: ParamMap) => {
      const token = params.get('token');
      this.token = token as string;
    });
  }

  get password() {
    return this.passwordResetForm.get('password');
  }

  validateOnBE() {
    this.isValidating = true;
    this.isPasswordValid = false;

    this.validationService
      .validateNewPassword({ password: this.password?.value, token: this.token })
      .pipe(
        this.destroy$(),
        filter((validateResponse: ValidateResponse) =>
          validateResponse?.some(
            (error: ValidateResponseItem) => error.hasOwnProperty('key') && error.hasOwnProperty('valid')
          )
        )
      )
      .subscribe((validateResponse: ValidateResponse) => {
        this.passwordResetForm.controls['password'].setErrors(null);
        validateResponse.forEach((error: ValidateResponseItem) => {
          const validateResponseItem = error as ValidateResponseItem;
          if (!validateResponseItem.valid) {
            const errorObj: { [key: string]: any } = {};
            errorObj[validateResponseItem.key] = true;
            this.passwordResetForm.controls['password'].setErrors({
              ...(this.passwordResetForm.controls['password'].errors || {}),
              ...errorObj,
            });
          }
        });
        this.isValidating = false;
        this.isPasswordValid = !this.password?.errors;
        this.changeDetectorRef.detectChanges();
      });
  }

  resetPassword() {
    if (this.passwordResetForm.valid) {
      const passwordParams: PasswordParams = {
        password: this.password?.value,
        token: this.token,
      };
      this.store.dispatch(AuthActions.resetPassword(passwordParams));
    }
  }

  private subscribeToFormChanges(): void {
    this.passwordResetForm.valueChanges.pipe(this.destroy$()).subscribe(() => {
      if (this.passwordResetForm.valid) {
        this.validateOnBE()
      } else {
        this.isValidating = false;
        this.isPasswordValid = false;
      }
    })
  }
}
