<div class="manage-tab-wrapper">
  <div class="output-templates-header">
    <span>
        Create and edit your Word and Excel Templates to match your needs. You can even bring your own templates
      to the table, making every project a true reflection of your individuality. The templates are available to be downloaded
      in various formats such as Word document, PDF or Excel document.
    </span>
    <scope-ui-options-menu
      class="ml-4"
      [menuOptions]="templateTypes"
      [buttonText]="'Add Template'" />
  </div>
</div>
<scope-ui-tabs (onSelect)="onSelect($event)">
  <scope-ui-tab tabTitle="Document templates ({{documentTemplates?.length}})" routeLink="document"
                [active]='route.snapshot.queryParamMap.get("type") === "document"'>
    <ng-container>
      <div class="manage-tab-wrapper">
        <div class="card-wrapper p-10" [hidden]="!loadingDocumentTemplates">
          <div class="card-skeleton" *ngFor=" let x of [].constructor(16)"></div>
        </div>
        <div class="card-wrapper p-10">
          <scope-ui-card-master
            *ngFor="let template of documentTemplates | orderBy: ['isDefault', 'name']: ['desc', 'asc']: false; trackBy: trackById"
            (click)="selectDocumentOutput(template.id)"
            [actionButtons]="[{
              icon: template.isDefault ? 'check_circle' : 'delete',
              name: template.isDefault ? 'Default' : 'Delete', eventId: 'Delete', disabled: template.isDefault }]"
            [hasStatus]="true"
            [hasMetadata]="template.shared || template.inherited"
            [metaData]="template.inherited ? 'Inherited from parent company' : 'Shared to user as a group member in company ' + template.company.name"
            [hasButtons]="!template.shared && !template.inherited"
            [cardTitle]="template.name"
            [subTitle]="template.templateScopeType ? ('scope'| lang) : ('sow' | lang)"
            [status]="template.fromTemplate | uppercase"
            [statusIcon]="'copy_all'"
            [hasIcon]="true"
            [statusIconClass]="'from-template'"
            (buttonClicked)="showDeleteDocumentTemplateModal(template)" />
        </div>
      </div>
    </ng-container>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Spreadsheet templates ({{xlsxTemplates?.length}})" routeLink="spreadsheet"
                [active]='route.snapshot.queryParamMap.get("type") === "spreadsheet"'>
    <ng-container>
      <div class="manage-tab-wrapper">
        <div class="card-wrapper p-10" [hidden]="!loadingXlsxTemplates">
          <div class="card-skeleton" *ngFor=" let x of [].constructor(16)"></div>
        </div>
        <div class="card-wrapper p-10">
          <scope-ui-card-master
            *ngFor="let template of xlsxTemplates | orderBy: 'name': 'asc': false; trackBy: trackById"
            (click)="selectXlsxOutput(template.id)"
            [actionButtons]="[{ icon: 'delete', name: 'Delete', eventId: 'Delete' }]"
            [hasButtons]="true"
            [cardTitle]="template.name"
            [subTitle]="template.templateType === 'SCOPE' ? ('scope'| lang) : ('sow' | lang)"
            (buttonClicked)="showDeleteXlsxTemplateModal(template)" />
        </div>
      </div>
    </ng-container>
  </scope-ui-tab>
</scope-ui-tabs>
