<div class="top-panel">
  <div class="justify-start">
    <button class="text-link" aria-label="Back to Templates gallery" (click)="backToGallery()">
      <mat-icon class="material-symbols-rounded orange">chevron_left</mat-icon>
      Back to Templates gallery
    </button>
  </div>
</div>
<app-output-editor class="flex-1" *ngIf='outputTemplate' (addBlock)="updateTemplate($event)"
                   [outputTemplate]='outputTemplate' [currentUser]='loggedInUser' (onDuplicate)="duplicate($event)"
                   (onUpdateTemplate)="updateTemplate($event)" (onPreviewExport)="previewExport()"
                   [templateWhitelist]="templateWhitelist" (onDeleteField)="deleteField($event)"
                   (onDownload)="updateTemplate($event, true)" [(updateInProgress)]="updateInProgress"
                   (onUpdateTemplateImmediate)="sendUpdateRequest($event)" (onUploadBaseDocx)="uploadBaseDocx()"></app-output-editor>
