import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '@app/shared/pipe/index.module';
import { SharedModule } from '@app/shared/shared.module';
import { LanguageService } from '@core/service/language.service';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { ScopeTraffickingService } from '@app/features/scope-overview/service/scope-trafficking.service';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';

@Component({
  selector: 'import-rate-card-modal',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDialogModule, SharedModule],
  templateUrl: './import-rate-card-modal.component.html',
  styleUrls: ['./import-rate-card-modal.component.scss'],
})
export class ImportRateCardModalComponent {
  showLoader = true
  page: any
  IMPORT_LIMIT = 30;
  trafficSystem: any
  selectedRateCardsToImport = {};
  cachedPages = {};
  importErrors = {
    unrequested : [],
    not_received: [],
    addNotReceived: function(exId, name){
      this.not_received.push({
        id: exId,
        name: name,
        debugFetch: null
      })
    },
    hasErrors: function() {
      return this.unrequested.length > 0 || this.not_received.length > 0
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private lang: LanguageService,
    private manageService: CompanyManagementService,
    private traffickingService: ScopeTraffickingService,
    private snackbarService: SnackbarService
  ) {
    this.trafficSystem = data.trafficSystem
    this.getRateCards()
  }

  countSelected() {
    return Object.keys(this.selectedRateCardsToImport).length;
  }

  getRateCards(pageNumber = 1) {
    this.showLoader = true
    let cache = this.cachedPages[pageNumber];
    if (cache != null){
      this.page = cache;
    } else {
      this.manageService.getTrafficSystemRateCards(pageNumber).subscribe({
        next: (data: any) => {
          this.showLoader = false
          this.cachedPages[pageNumber] = data;
          let nameIdxCount = {};
          this.page = data;
          this.page.data = data.data
            .map((rateCard) => {
              //Already imported
              rateCard.selected = rateCard.imported;

              if (!rateCard.imported) {
                //Already selected
                let selected = this.selectedRateCardsToImport[rateCard.id];
                if (selected) {
                  rateCard.selected = true;
                  rateCard.newName = selected.newName;
                }

                //Same name
                let count = this.getNameCount(nameIdxCount, rateCard);
                if (count > 1) {
                  if (rateCard.namingConflict) {
                    count++;
                  }
                  rateCard.namingConflict = true;
                  rateCard.newName = rateCard.name + ' (' + --count + ')';
                  this.decrementNameCount(nameIdxCount, rateCard);
                } else {
                  rateCard.newName = rateCard.name + ' (1)';
                }
              }
              return rateCard;
            });

          this.page.importedData = this.page.data.filter(d => d.imported)
        },
        error: (error) => {
          this.showLoader = false
          this.traffickingService.validateServerError(error, () => {
            this.data.cancelCallback()
          }, () => {
            this.renderDownloadFile()
          })
        }
      })
    }
  }

  selectItem(item){
    if (item.selected){
      this.selectedRateCardsToImport[item.id] = item;
    } else {
      delete this.selectedRateCardsToImport[item.id]
    }
  };

  renderDownloadFile() {
    let pageNumber = 1;
    if (this.page) {
      pageNumber = this.page.pageInfo.pageNumber
    }
    this.manageService.renderTrafficSystemRateCardError(pageNumber).subscribe({
      next: (response: any) => {
        let blob = new Blob([JSON.stringify(response.data)], { type: 'text/plain' });
        let name = "JsonData.txt";
        let downloadUrl = (window.URL || window.webkitURL).createObjectURL(blob);
        let a = $(`<a href="${downloadUrl}" download="${name}"></a>`);
        a[0].click();
      },
      error: () => {
        this.snackbarService.showSnackbar("The cached data has expired.", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
      }
    });
  }

  getNameCount(idx, rateCard){
    return idx[rateCard.name.trim().toLowerCase()]
  }

  decrementNameCount(idx, rateCard){
    idx[rateCard.name.trim().toLowerCase()] = --idx[rateCard.name.trim().toLowerCase()];
  }

  nextPage(){
    let currentPage = this.page;
    let pageInfo = currentPage.pageInfo;
    this.getRateCards(pageInfo.pageNumber+1)
  }

  previousPage(){
    let currentPage = this.page;
    let pageInfo = currentPage.pageInfo;
    this.getRateCards(pageInfo.pageNumber-1)
  }

  debugExternalId(debugRCWrapper){
    if (debugRCWrapper.fetching) return;

    debugRCWrapper.fetching = true
    debugRCWrapper.debugFetch = null
    this.manageService.getTrafficSystemRatecardsByExternalId(debugRCWrapper.id).subscribe({
      next: (rateCards) => {
        debugRCWrapper.debugFetch = rateCards[0]
        debugRCWrapper.fetching = false
      }, error: () => {
        debugRCWrapper.fetching = false
        this.snackbarService.showDefaultErrorSnackbar()
      }
    })
  }

  importRateCards(){
    let selected = Object.values(this.selectedRateCardsToImport);
    if (selected.length > 0){
      this.showLoader = true
      let selectedIdsToNewNameMap = {};
      let requestMap = {}
      selected.forEach((rateCard: any) =>{
        selectedIdsToNewNameMap[rateCard.id] = rateCard.namingConflict ? rateCard.newName : null
        requestMap[rateCard.id] = rateCard
      });

      this.manageService.importTrafficSystemRateCards(selectedIdsToNewNameMap).subscribe({
        next: (data) => {
          this.showLoader = false
          data.forEach((ratecard) => {
            const receivedExternalId = ratecard.rateCardIdentity.externalType?.id;
            const wasRequested = requestMap[receivedExternalId] != null;
            if (wasRequested){
              delete requestMap[receivedExternalId]
            } else {
              this.importErrors.unrequested.push(receivedExternalId)
            }
          });
          Object.keys(requestMap).forEach(i => this.importErrors.addNotReceived(i, requestMap[i].name));

          this.data.confirmCallback(data.filter(d => !requestMap[d.rateCardIdentity.externalType?.id]), this.importErrors.hasErrors())
        },
        error: (error) => {
          if (error.status == 409){
            let { rateCard, pageNumber } = this.findTrafficSystemSecondParty(error.data.conflictedTrafficSystemEntityId);
            if (this.page.pageInfo.pageNumber != pageNumber){
              this.getRateCards(pageNumber)
            } else {
              this.showLoader = false
            }
            rateCard.conflictingName = error.data.conflictedTrafficSystemEntityName
            rateCard.namingConflict = true
          } else {
            this.showLoader = false
            this.traffickingService.validateServerError(error, () => this.data.cancelCallback(), () => this.renderDownloadFile())
          }
        }
      })
    }
  }

  findTrafficSystemSecondParty(id){
    let values: any = Object.values(this.cachedPages);
    for (let i = 0; i < values.length; i++) {
      let secondParties = values[i].data.filter(function(rateCard){
        return rateCard.id == id;
      });
      if (secondParties.length == 1){
        return {pageNumber: i+1, rateCard: secondParties[0]};
      }
    }
    return null;
  }

  showNamingConflictError(rateCard){
    if (rateCard.namingConflict && rateCard.selected && !rateCard.imported){
      if (rateCard.conflictingName && rateCard.newName && rateCard.conflictingName.trim() == rateCard.newName.trim()){
        return true;
      }
      return !rateCard.newName || rateCard.newName.trim().length == 0 || rateCard.newName.trim() == rateCard.name.trim()
    }
    return false;
  }
}
