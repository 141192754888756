import { Type } from 'class-transformer';
import { RatecardIdentity } from '@app/features/scope-overview/model/ratecard-identity.model';
import { Department } from '@app/features/scoping/models/department.model';
import { Company } from '@core/model/company.model';

export class RatecardVersion {
  id!: number
  name!: string
  version!: number
  currencyCode!: string
  locationCards?: any[]
  @Type(() => RatecardIdentity) rateCardIdentity!: RatecardIdentity;
  @Type(() => Department) departments!: Department[];
  userPrivilegeRestrictions?: any[]

  getTotalRolesCount() {
    return this.departments.flatMap(d => d.roles).length;
  }

  isCostPlus(): boolean {
    return this.rateCardIdentity.costPlus;
  }

  isOwnedByParentOf(company: Company) {
    if (this.rateCardIdentity.company && this.rateCardIdentity.company.id){
      return this.rateCardIdentity.company.id == company.parentCompany?.id;
    }
    return undefined;
  }

  isPrivilegeRestricted (privilege) {
    return this.userPrivilegeRestrictions.indexOf(privilege) != -1;
  }
}
