import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss']
})
export class AccountVerificationComponent {
  key: string

  constructor(private router: Router, private route: ActivatedRoute, private titleService: Title) {
    this.titleService.setTitle('Account Verification')
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const keyParam = params['key'];
      if (keyParam != null) {
        this.key = keyParam
      }
    });
  }
}
