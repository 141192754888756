<div class="modal-container change-output-modal">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ getTitle() }}</h1>
      </div>

      <div class="mt-2">
        <span>Please select a theme which you wish to use for your {{'doc_template | l' | lang}}</span>
      </div>

      <button class="close-button" mat-dialog-close>
        <mat-icon class="material-symbols-rounded">close</mat-icon>
      </button>
    </div>
    <div class="theme-name">
      <scope-ui-input
        [inputLabel]="'Theme name'"
        [required]="true"
        [maximumLength]="50"
        [formSubmitted]="formSubmitted"
        (onInputChange)="name = $event"></scope-ui-input>
    </div>
  </div>
  <mat-dialog-content aria-label="Add Output Template modal">
    <div class="modal__body">
      <div class="submit-modal" *ngIf="data.type === 'scope' ? scopeTemplates : sowTemplates as templates">
        <div class="output-image-container text-center" *ngFor="let temp of templates; trackBy: trackById" [has-setting]="temp.applicationSetting || 'SKIP_RETURN_TRUE'">
          <div class="title">
            <span matTooltip="{{temp.title}}">{{temp.title}}</span>
          </div>
          <img *ngIf="temp.thumbnailUri" class="template-preview" height="260" width="271" ngSrc="{{cdnConfig.getStaticMediaUrl(temp.thumbnailUri)}}" alt="{{temp.title}}">
          <div *ngIf="!temp.thumbnailUri" class="template-preview">BLANK</div>
          <p *ngIf="temp.name !== 'BLANK' && !temp.blocks" class="note">Pre-set Word template with limited editing options for text sections</p>
          <p *ngIf="temp.name === 'BLANK' || temp.blocks" class="note">Editable template</p>
          <scope-ui-dropdown
            class="flex mx-5 mb-5"
            *ngIf="temp.languages?.length > 1 || temp.blocks"
            [selectedValue]="temp.selectedLanguage"
            [controlLabel]="'Language'"
            [dropdownOptions]="temp.languages"
            [isStandardDropdown]="true"
            (onSelectionChange)="temp.selectedLanguage = $event"/>
          <p class="d-flex d-flex-align-center d-flex-justify-center cursor-pointer font-bold orange" (click)="createTemplate(temp)">
            Use this template
          </p>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <ckeditor [hidden]="true" editorUrl="/assets/ckeditor/ckeditor.js" [config]='ckeConfig'></ckeditor>
</div>

