<div class="scope-card" (click)="onCardClick()" [ngClass]="cardActive ? 'card-active' : ''">
  <div class="scope-card-content">
    <span class="count">{{ card_data }}</span>
    <div class="text">
      <span>{{ heading }}</span>
      <span *ngIf="sub_heading" class="date">{{ sub_heading }}</span>
    </div>
    <div *ngIf="cardActive" class="icons">
      <i class="material-icons material-symbols-rounded">{{ icon_name }}</i>
    </div>
  </div>
</div>
