import { Observable } from 'rxjs'

export class MappedEntity<T> {
  entity!: T
  entityType?: MappedEntityType;
  [name: string]: any
  children$?: Observable<any[]>
  children?: any[]
  isNotExpandable: boolean
}

export enum MappedEntityType {
  SCOPE_VERSION,
  FOLDER_VERSION,
  LIBRARY_ITEM,
  LIBRARY_FOLDER,
}
