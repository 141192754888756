<div class="top-panel">
  <div class="justify-start">
    <button class="text-link" aria-label="Back to Templates gallery" (click)="backToGallery()">
      <mat-icon class="material-symbols-rounded orange">chevron_left</mat-icon>
      Back to Templates gallery
    </button>
  </div>
</div>
<div class="output-container flex-1" *ngIf="outputTemplate">
  <div class="output-container__header controls output">
    <div class="left output-left">
      <div class="def-output flex flex-row">
        <span class="font-newJuneBold text-sm" *ngIf="!editName">{{outputTemplate.name}}</span>
        <i (click)="editName = true" *ngIf="!editName"
           class="material-icons material-symbols-rounded fill orange text-sm ml-1.5 cursor-pointer">mode_edit</i>
        <scope-ui-input
          *ngIf="editName"
          [initialValue]="outputTemplate.name"
          [hasPlaceholder]="false"
          (onInputBlurChange)="outputTemplate.name = $event"
        />
        <mat-icon (click)="editName = false; updateTemplate();" *ngIf="editName" class="material-symbols-rounded orange cursor-pointer">check</mat-icon>
      </div>
    </div>
    <div class="right">
      <button
        class="scope-actions-button"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="optionsMenu">
        <mat-icon class="material-symbols-rounded">more_vert</mat-icon>
      </button>
      <mat-menu
        #optionsMenu="matMenu"
        backdropClass="scope-options-menu"
        [has-privilege]="'OUTPUT_TEMPLATE__DELETE'">
        <button
          (click)="showDeleteXlsxTemplateModal()"
          mat-menu-item
          class="scope-option">
          <mat-icon class="material-symbols-rounded orange">delete</mat-icon>
          <span>Delete Template</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="output-container__body flex w-full">
    <div class="container-updated-row">
      <div class="xlsx-info" *ngIf="outputTemplate.fileName && outputTemplate.version">
        <div class="mr-4">
          <img src="https://img.icons8.com/ios/50/000000/ms-excel.png" width="40" height="40">
        </div>
        <div class="right">
          <p>{{outputTemplate.fileName}}</p>
          <p>Version: {{outputTemplate.version}}</p>
        </div>
      </div>
      <button class="text-link mt-2 add-item-button"
              mat-button (click)="uploadExcelModal()">
        <mat-icon class="material-symbols-rounded">add</mat-icon>Upload Excel
      </button>
    </div>
    <div class="output-image-container">
      <img height="324px" width="271px" style="margin-bottom: 30px"
           src="https://img.icons8.com/ios/500/000000/ms-excel.png" alt="">

      <a (click)="downloadExcelTemplate()" class="browse-btn">Download</a>
    </div>
  </div>
</div>
