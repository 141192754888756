import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { ScopeVersion } from '@core/model/scope-version';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';
import { User } from '@app/core/model/user.model';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { Role } from '@app/features/scoping/models/role.model'
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import {
  DeliverableRowComponent
} from '@app/features/scope-overview/components/scope-tab/deliverable-row/deliverable-row.component'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action'
import { plainToInstance } from 'class-transformer'
import { Store } from '@ngrx/store'
import { formatMonetaryValue, trackById } from '@shared/utils/utils';
import { Money } from '@app/features/scope-overview/model/money.model'
import {
  ScopeUiDatepickerComponent
} from '@shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component'
import { MatMenuTrigger } from '@angular/material/menu'
import { MenuOptions } from '@core/model/definitions/menu-options.interface'
import { CdnConfig } from '@core/model/cdn-config.model';

@Component({
  selector: 'scope-section-row',
  imports: [CommonModule, SharedModule, ScopeUiTableComponent, NgxMaskDirective, DeliverableRowComponent, ScopeUiInputComponent, ScopeUiDatepickerComponent],
  templateUrl: './section-row.component.html',
  styleUrls: ['./section-row.component.scss'],
  standalone: true,
})
export class SectionRowComponent {
  @Input() isTimeline: boolean = false;

  @Input() section!: ScopeSection;

  @Input() currentUser!: User;

  @Input() userColumns!: any;

  @Input() scopeVersion!: ScopeVersion;

  @Input() feeMenuOptions!: MenuOptions[]

  @Input() currentRatecard: RatecardVersion

  @Input() sectionDrops: string[];

  @Input() mainColumnClass: string;

  @Input() componentMainColumnClass: string;

  @Input() sectionSelectedStates: { [id: number]: boolean };

  @Input() cdnConfig?: CdnConfig

  @Output() onFetchDeliverable!: EventEmitter<number>;

  @Output() onUpdateDeliverable!: EventEmitter<Deliverable>;

  @Output() onUpdateComponent!: EventEmitter<ScopeComponent>;

  @Output() onAcceptDeliverable!: EventEmitter<Deliverable>;

  @Output() onRejectDeliverable!: EventEmitter<Deliverable>;

  @Output() onDuplicateDeliverable!: EventEmitter<Deliverable>

  @Output() onDeleteDeliverable!: EventEmitter<Deliverable>

  @Output() onAddDeliverableFee!: EventEmitter<Deliverable>;

  @Output() onAddComponentFee!: EventEmitter<ScopeComponent>;

  @Output() onEditDeliverable!: EventEmitter<number>;

  @Output() onCompleteTrade!: EventEmitter<Deliverable>

  @Output() onTradeDeliverable!: EventEmitter<Deliverable>

  @Output() onStopProgressTrade!: EventEmitter<Deliverable>

  @Output() onAddDeliverableToTrade!: EventEmitter<Deliverable>

  @Output() onConfirmTrade!: EventEmitter<Deliverable>

  @Output() onCancelTrade!: EventEmitter<Deliverable>

  @Output() onReopenTrade!: EventEmitter<Deliverable>

  @Output() onShowDeliverableApplyLocationCardDialog!: EventEmitter<Deliverable>

  @Output() onUpdateFeeItem!: EventEmitter<{
    feeItem: FeeItemInstance,
    section?: ScopeSection,
    deliverable?: Deliverable,
    component?: ScopeComponent
  }>

  @Output() onOverrideQuantity!: EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>

  @Output() onOverrideRateCardRoleHours!: EventEmitter<{
    deliverable: Deliverable,
    component: ScopeComponent,
    role: Role
  }>

  @Output() onAddSectionFee: EventEmitter<ScopeSection>

  @Output() onDeleteSection: EventEmitter<ScopeSection>

  @Output() onDropDeliverable: EventEmitter<{ event: CdkDragDrop<any>, section: ScopeSection | null }>

  @ViewChild(MatMenuTrigger) startDateMenuTrigger: MatMenuTrigger
  @ViewChild(MatMenuTrigger) endDateMenuTrigger: MatMenuTrigger

  editSectionName: number

  constructor(public scopeApprovalFlowService: ApprovalFlowService, public scopeTabService: ScopeTabService, private store: Store) {
    this.onFetchDeliverable = new EventEmitter<number>()
    this.onUpdateDeliverable = new EventEmitter<Deliverable>()
    this.onUpdateComponent = new EventEmitter<ScopeComponent>()
    this.onDuplicateDeliverable = new EventEmitter<Deliverable>()
    this.onDeleteDeliverable = new EventEmitter<Deliverable>()
    this.onAcceptDeliverable = new EventEmitter<Deliverable>()
    this.onRejectDeliverable = new EventEmitter<Deliverable>()
    this.onAddDeliverableFee = new EventEmitter<Deliverable>()
    this.onAddComponentFee = new EventEmitter<ScopeComponent>()
    this.onEditDeliverable = new EventEmitter<number>()
    this.onCompleteTrade = new EventEmitter<Deliverable>()
    this.onTradeDeliverable = new EventEmitter<Deliverable>()
    this.onStopProgressTrade = new EventEmitter<Deliverable>()
    this.onAddDeliverableToTrade = new EventEmitter<Deliverable>()
    this.onConfirmTrade = new EventEmitter<Deliverable>()
    this.onCancelTrade = new EventEmitter<Deliverable>()
    this.onReopenTrade = new EventEmitter<Deliverable>()
    this.onShowDeliverableApplyLocationCardDialog = new EventEmitter<Deliverable>()
    this.onUpdateFeeItem = new EventEmitter<{
      feeItem: FeeItemInstance,
      section?: ScopeSection,
      deliverable?: Deliverable,
      component?: ScopeComponent
    }>()
    this.onOverrideQuantity = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>()
    this.onOverrideRateCardRoleHours = new EventEmitter<{
      deliverable: Deliverable,
      component: ScopeComponent,
      role: Role
    }>()
    this.onDropDeliverable = new EventEmitter<{ event: CdkDragDrop<any>, section: ScopeSection | null }>()
    this.onAddSectionFee = new EventEmitter<ScopeSection>()
    this.onDeleteSection = new EventEmitter<ScopeSection>()
  }

  dropDeliverable(event: CdkDragDrop<any>, section: ScopeSection | null) {
    this.onDropDeliverable.emit({ event, section })
  }

  addSectionFee() {
    this.onAddSectionFee.emit(this.section)
  }

  deleteSection() {
    this.onDeleteSection.emit(this.section)
  }

  fetchDeliverable(deliverableId: number) {
    this.onFetchDeliverable.emit(deliverableId);
  }

  updateDeliverable(deliverable: Deliverable) {
    this.onUpdateDeliverable.emit(deliverable);
  }

  updateComponent(component: ScopeComponent) {
    this.onUpdateComponent.emit(component);
  }

  duplicateDeliverable(deliverable: Deliverable) {
    this.onDuplicateDeliverable.emit(deliverable)
  }

  deleteDeliverable(deliverable: Deliverable) {
    this.onDeleteDeliverable.emit(deliverable);
  }

  addDeliverableFee(deliverable: Deliverable) {
    this.onAddDeliverableFee.emit(deliverable);
  }

  addComponentFee(component: ScopeComponent) {
    this.onAddComponentFee.emit(component);
  }

  editDeliverable(deliverableId: number) {
    this.onEditDeliverable.emit(deliverableId);
  }

  showDeliverableApplyLocationCardDialog(deliverable: Deliverable) {
    this.onShowDeliverableApplyLocationCardDialog.emit(deliverable)
  }

  updateFeeItem(feeItem: FeeItemInstance, section?: ScopeSection, deliverable?: Deliverable, component?: ScopeComponent) {
    this.onUpdateFeeItem.emit({ feeItem, section, deliverable, component })
  }

  overrideQuantity(event: { deliverable: Deliverable, component: ScopeComponent }) {
    this.onOverrideQuantity.emit(event)
  }

  overrideRateCardRoleHours(event: { deliverable: Deliverable, component: ScopeComponent, role: Role }) {
    this.onOverrideRateCardRoleHours.emit(event)
  }

  completeTrade(deliverable: Deliverable) {
    this.onCompleteTrade.emit(deliverable)
  }

  tradeDeliverable(deliverable: Deliverable) {
    this.onTradeDeliverable.emit(deliverable)
  }

  stopProgressTrade(deliverable: Deliverable) {
    this.onStopProgressTrade.emit(deliverable)
  }

  addDeliverableToTrade(deliverable: Deliverable) {
    this.onAddDeliverableToTrade.emit(deliverable)
  }

  confirmTrade(deliverable: Deliverable) {
    this.onConfirmTrade.emit(deliverable)
  }

  cancelTrade(deliverable: Deliverable) {
    this.onCancelTrade.emit(deliverable)
  }

  reopenTrade(deliverable: Deliverable) {
    this.onReopenTrade.emit(deliverable)
  }

  acceptDeliverable(deliverable: Deliverable) {
    this.onAcceptDeliverable.emit(deliverable);
  }

  rejectDeliverable(deliverable: Deliverable) {
    this.onRejectDeliverable.emit(deliverable);
  }

  updateSectionDescription(description: string, section: ScopeSection) {
    this.store.dispatch(
      ScopeOverviewActions.updateScopeSection({ scopeId: this.scopeVersion.identity.id, section: plainToInstance(ScopeSection, { ...section, description }) })
    )
  }

  updateSectionNote(internalNote: string, section: ScopeSection) {
    this.store.dispatch(
      ScopeOverviewActions.updateScopeSection({ scopeId: this.scopeVersion.identity.id, section: plainToInstance(ScopeSection, { ...section, internalNote }) })
    )
  }

  updateSectionName(name: any, section: ScopeSection) {
    this.editSectionName = null;
    this.store.dispatch(
      ScopeOverviewActions.updateScopeSection({ scopeId: this.scopeVersion.identity.id, section: plainToInstance(ScopeSection, { ...section, name }) })
    )
  }

  updateStartDate(startDate: Date) {
    this.startDateMenuTrigger.closeMenu()
    this.store.dispatch(
      ScopeOverviewActions.updateScopeSection({ scopeId: this.scopeVersion.identity.id, section: plainToInstance(ScopeSection, { ...this.section, startDate }) })
    )
  }

  updateEndDate(endDate: Date) {
    this.endDateMenuTrigger.closeMenu()
    this.store.dispatch(
      ScopeOverviewActions.updateScopeSection({ scopeId: this.scopeVersion.identity.id, section: plainToInstance(ScopeSection, { ...this.section, endDate }) })
    )
  }

  getProfitOfSection(section: ScopeSection) {
    if (section.totalProfit) {
      return formatMonetaryValue(section.totalProfit)
    }
    var result = new Money(0, this.scopeVersion.identity.rateCard.currencyCode);
    section.deliverables.forEach((deliverable) => {
      result = result.add(deliverable.getTotalProfit());
    });
    return formatMonetaryValue(result);
  }

  protected readonly trackById = trackById;
}
